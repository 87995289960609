import React from 'react';
import Header from '../../components/Header';
import SideBarSection from '../../components/Sidebar';
import { NotificationManager } from 'react-notifications';
import Pagination from 'react-js-pagination';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import {
  getOfflineOrderDetailAPI,
} from '../../core/services/APIServices';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-json-pretty/themes/monikai.css';

class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNo: '',
      orderType: '',
      deliveryCharges: '',
      amountPaid: '',
      orderDate: '',
      chefName: '',
      chefEmail: '',
      chefMobile: '',
      chefAddress: '',
      dinerName: '',
      dinerEmail: '',
      dinerMobile: '',
      dinerAddress: '',
      orderDate: '',
      taxAmount: '',
      deliveryPartner: '',
      trackingId: '',
      orderStatus: '',
      reason_for_chef_rejection: '',
      chefName: '',
      chefEmail: '',
      chefMobile: '',
      orderTakeaway: '',
      orderSameDayDelivery: '',
      pickupTime: '',
      orderCancelledAt: '',
      orderRejectedAt: '',
      reasonOfRejection: '',
      requestCustomization: '',
      chefTrackingURL: '',
      dinerTrackingURL: '',
      refundId: '',
      refundAmount: '',
      refundDate: '',
      productDetail: [],
      orderAmount: '',
      dishAmount: '',
      paymentApiResponse: '',
      deliveryApiResponse: '',
      refundApiResponse: '',
      displayPaymentApiLogPopup: false,
      discountAmount: '',
      deliveryWefastApiResponse: '',
      deliveryDunzoApiResponse: '',
      promoCode: '',
      couponDiscount: '',
      rescheduleDate: new Date(new Date().setDate(new Date().getDate())),
      currentDate: new Date(new Date().setDate(new Date().getDate())),
      currenHour: new Date().getHours(),
      timeOption: false,
      rescheduleTimeSlot: '',
      deliveryRemark: '',
      deliveryStatus: '',
      delivery_api_status: '',
      refund_api_response: '',
      payment_api_status: '',
      otherRejectReason: '',
      rejectReasonRadioInput: 'Ingredients not available',
      reasonTextDisabled: true,
      reasonSubmitBtnDisabled: false,
	};
  }

  componentDidMount() {
	this.checkCurrentDate();
    this.getOrderDetailData();
  }
  
  checkCurrentDate = () => {
	if(this.state.rescheduleDate.toDateString() === this.state.currentDate.toDateString()){
		this.setState({timeOption: true});
	}
  };

  getOrderDetailData = () => {
    var userId = localStorage.getItem('userID');
    var orderNo = localStorage.getItem('orderID');
    var token = localStorage.getItem('accessToken');
    return getOfflineOrderDetailAPI(userId, orderNo, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            orderType: response.data.data.order[0].order_type,
            orderNo: response.data.data.order[0].order_no,
            orderDate: response.data.data.order[0].order_date,
            deliveryDate: response.data.data.order[0].delivery_date,
            pickupTime: response.data.data.order[0].pickup_time,
            chefName: response.data.data.order[0].chef_name,
            chefEmail: response.data.data.order[0].chef_email,
            chefMobile: response.data.data.order[0].chef_mobile_no,
            chefAddress: response.data.data.order[0].chef_address,
            dinerName: response.data.data.order[0].diner_name,
            dinerEmail: response.data.data.order[0].diner_email_id,
            dinerMobile: response.data.data.order[0].diner_mobile_number,
            dinerAddress: response.data.data.order[0].diner_address,
            orderDetails: response.data.data.order[0].order_details,
            orderAmount: response.data.data.order[0].order_amount,
            deliveryAmount: response.data.data.order[0].delivery_amount,
            promoCode: response.data.data.order[0].promo_code,
            promoDiscount: response.data.data.order[0].promo_discount,
            receivedAmountDiner: response.data.data.order[0].received_amount_diner,
            isPaymentReceived: response.data.data.order[0].is_payment_received,
            isPaidToChef: response.data.data.order[0].is_paid_to_chef,
            isDelivered: response.data.data.order[0].is_delivered,
            remark: response.data.data.order[0].remark,
            razorpayID: response.data.data.order[0].razorpay_id,
            sendToSandeepAt: response.data.data.order[0].send_to_sandeep_at,
            reasonForOffline: response.data.data.order[0].reason_for_offline,
          });

        } else {
          this.setState({
            dataLoaded: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          dataLoaded: true
        });
      });
  };

  handleRadioInput = (e) => {
    if (e.target.value === 'Other') {
      this.setState({
        reasonTextDisabled: false
      });
    } else {
      this.setState({
        reasonTextDisabled: true,
        otherRejectReason: '',
        reasonFormErr: ''
      });
      if (document.getElementById('reason-textarea')) {
        document.getElementById('reason-textarea').value = '';
      }
    }
    this.setState({
      rejectReasonRadioInput: e.target.value
    });
  };

  render() {
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='body'>
                    <ul className='nav nav-tabs tab-nav-right' role='tablist'>
                      <li role='presentation' className='active'>
                        <a href='#Profile' data-toggle='tab'>
                          <i className='material-icons'>face</i>Offline Order Detail
                        </a>
                      </li>
                    </ul>
                    <div className='tab-content'>
                      <div
                        role='tabpanel'
                        className='tab-pane fade in active'
                        id='Profile'
                      >
                        <div className='row clearfix'>
                          <div className='col-xs-12 ol-sm-12 col-md-12 col-lg-12'>
                            <div
                              className='panel-group'
                              id='accordion_17'
                              role='tablist'
                              aria-multiselectable='true'
                            >
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingOne_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseOne_17'
                                      aria-expanded='true'
                                      aria-controls='collapseOne_17'
                                      className='collapsed'
                                    >
                                      Order Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseOne_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingOne_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Type</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.orderType
                                                  }
                                                  className='form-control'
                                                  name='orderType'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Number</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.orderNo
                                                  }
                                                  className='form-control'
                                                  name='orderNo'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Date</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.orderDate
                                                  }
                                                  className='form-control'
                                                  name='orderDate'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Delivery Date</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.deliveryDate
                                                  }
                                                  className='form-control'
                                                  name='deliveryDate'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Pickup Time</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.pickupTime
                                                  }
                                                  className='form-control'
                                                  name='pickupTime'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Chef Name</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .chefName == null
                                                      ? '-'
                                                      : this.state
                                                          .chefName
                                                  }
                                                  className='form-control'
                                                  name='chefName'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Chef EmailID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .chefEmail == null
                                                      ? '-'
                                                      : this.state
                                                          .chefEmail
                                                  }
                                                  className='form-control'
                                                  name='chefEmail'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Chef Mobile</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.chefMobile ==
                                                    null
                                                      ? '-'
                                                      : this.state.chefMobile
                                                  }
                                                  className='form-control'
                                                  name='chefMobile'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Chef Address</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.chefAddress ==
                                                    null
                                                      ? '-'
                                                      : this.state.chefAddress
                                                  }
                                                  className='form-control'
                                                  name='chefAddress'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Name</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.dinerName ==
                                                    null
                                                      ? '-'
                                                      : this.state.dinerName
                                                  }
                                                  className='form-control'
                                                  name='dinerName'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner EmailID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.dinerEmail ==
                                                    null
                                                      ? '-'
                                                      : this.state.dinerEmail
                                                  }
                                                  className='form-control'
                                                  name='dinerEmail'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Mobile</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.dinerMobile ==
                                                    null
                                                      ? '-'
                                                      : this.state.dinerMobile
                                                  }
                                                  className='form-control'
                                                  name='dinerMobile'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Address</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.dinerAddress ==
                                                    null
                                                      ? '-'
                                                      : this.state.dinerAddress
                                                  }
                                                  className='form-control'
                                                  name='dinerAddress'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Details</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.orderDetails ==
                                                    null
                                                      ? '-'
                                                      : this.state.orderDetails
                                                  }
                                                  className='form-control'
                                                  name='orderDetails'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Amount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.orderAmount
                                                  )}
                                                  className='form-control'
                                                  name='orderAmount'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Delivery Amount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.deliveryAmount
                                                  )}
                                                  className='form-control'
                                                  name='deliveryAmount'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Promo Code</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.promoCode
                                                  }
                                                  className='form-control'
                                                  name='promoCode'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Promo Discount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.promoDiscount
                                                  }
                                                  className='form-control'
                                                  name='promoDiscount'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Received Amount Diner</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.receivedAmountDiner
                                                  }
                                                  className='form-control'
                                                  name='receivedAmountDiner'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Is Payment Received</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.isPaymentReceived
                                                  }
                                                  className='form-control'
                                                  name='isPaymentReceived'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Is Paid To Chef</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.isPaidToChef
                                                  }
                                                  className='form-control'
                                                  name='isPaidToChef'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Is Delivered</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.isDelivered
                                                  }
                                                  className='form-control'
                                                  name='isDelivered'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Remark</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.remark
                                                  }
                                                  className='form-control'
                                                  name='remark'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Razorpay ID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.razorpayID
                                                  }
                                                  className='form-control'
                                                  name='razorpayID'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Send To Sandeep At</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.sendToSandeepAt
                                                  }
                                                  className='form-control'
                                                  name='sendToSandeepAt'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Reason For Offline</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.reasonForOffline
                                                  }
                                                  className='form-control'
                                                  name='reasonForOffline'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default OrderDetailPage;
