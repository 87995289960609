/*
#############################################################################
# Name : BusinessTab.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display chef business detail saved by the chef
#############################################################################
*/
import React, { Component } from "react";
import {
  getUserBusinessDetailAPI,
  getCateringFacilitiesAPI,
  updateChefBusinessDetailsAPI,
} from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";

const businessFormValid = ({ businessFormErrors, ...rest }) => {
  let valid = true;

  Object.values(businessFormErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Object.values(businessFormErrors).forEach((val) => {
  //   val === "form-control" || (valid = false);
  // });

  return valid;
};

export default class BusinessTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: '',
      userEmailID: '',
      userBusinessYear: "",
      userFssaiSelected: "",
      userFssaiRegistration: "",
      userFssaiRegistrationUrl: "",
      userGstSelected: "",
      userGstNumber: "",
      userFSSAINumber: "",
      userVerfication: "",
      userVerficationUrl: "",
      userOperationDay: [],
      userBusinessStartTime: "",
      startTimeState: "AM",
      userBusinessEndTime: "",
      endTimeState: "AM",
      userBusinessTakeaway: "",
      samedaydelivery: "0",
      samedaydeliverynotice: "1",
      allitemsamedaydelivery: "0",
      specialInstructionsForTakeaway: "",
      minimumOrderAmount: "",
      takePartyOrders: "",
      hostEventAtHome: "",
      cateringFacilities: [],
      venueFacilities: [],
      averagePricePerPerson: "",
      maximumPeopleCarter: "",
      maximumNumberOfGuests: "",
      buttonText: "Update Business Info",
      buttonDisabled: false,
      selectedAllWeekDays: false,
      daysOptions: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      timeList: [
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
      ],
      businessFormErrors: {
        userBusinessYear: "",
        userFssaiSelected: "",
        userGstSelected: "",
        userGstNumber: "",
        userBusinessStartTime: "",
        userBusinessEndTime: "",
        minimumOrderAmount: "",
        maximumPeopleCarter: "",
        maximumNumberOfGuests: "",
        averagePricePerPerson: "",
        businessFormErrors: "",
        averageMealPrice: "",
      },
      selectedWeekDays: [],
      selectedCateringFacilities: [],
      selectedVenueFacilities: [],
      cateringFacilities: [],
      venueFacilities: [],
      registerDocumentErr: "",
      verificationDocumentErr: "",
      dataLoaded: false,
	  isMarketingPackage: false,
	  packagess: 'Marketing Package',
      joinDate: '',
      expireDate: '',
      paymentDate: '',
      paymentdateerror: '',
      paidAmount: '',
      paidamounterror: '',
      paymentRemark: '',
      paymentremarkerror: '',
      pricePerPersonArr: [
        "500-1000",
        "1000-1500",
        "1500-2000",
        "2000-2500",
        "2500+",
      ],
      maxPeopleCaterArr: [
        "10",
        "20",
        "30",
        "40",
        "50",
        "60",
        "70",
        "80",
        "90",
        "100",
        "100+",
      ],
      weekDaysErr: "",
      averageMealPrice: "",
    };
  }
  componentDidMount() {
	var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
    this.getBusinessData();
    this.getCateringFacilities();
  }
  getCateringFacilities() {
    var userId = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    return getCateringFacilitiesAPI(userId, chefId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            cateringFacilities: response.data.data.catering_facilities,
            venueFacilities: response.data.data.venue_facilities,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getBusinessData() {
    var userId = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    return getUserBusinessDetailAPI(userId, chefId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            userBusinessYear: response.data["data"]["year_business_started"],
            userFssaiSelected: response.data["data"]["fssai_registration"],
            userFssaiRegistrationUrl:
              response.data["data"]["fssai_registration_document"],
            userGstSelected: response.data["data"]["is_gst_applicable"],
            userGstNumber: response.data["data"]["gst_registration_number"],
            userFSSAINumber: response.data["data"]["fssai_registration_number"],
            userVerficationUrl: response.data["data"]["verification_document"],
            userBusinessStartTime:
              response.data["data"]["opening_time"].split(" ")[0],
            startTimeState: response.data["data"]["opening_time"].split(" ")[1],
            userBusinessEndTime:
              response.data["data"]["closing_time"].split(" ")[0],
            endTimeState: response.data["data"]["closing_time"].split(" ")[1],
            userBusinessTakeaway:response.data["data"]["is_takeaway_available"],
            samedaydelivery:response.data["data"]["is_same_day_delivery"],
            samedaydeliverynotice:(response.data["data"]["same_day_delivery_notice"] == "0" || response.data["data"]["same_day_delivery_notice"] == null) ? "1" : response.data["data"]["same_day_delivery_notice"],
            allitemsamedaydelivery:response.data["data"]["is_all_items_same_day_delivery"],
            specialInstructionsForTakeaway:
              response.data["data"]["pickup_instructions"],
            minimumOrderAmount: response.data["data"]["minimum_order_amount"],
            takePartyOrders: response.data["data"]["party_orders_accepted"],
            hostEventAtHome:
              response.data["data"]["chef_host_event_at_their_home"],
            averagePricePerPerson:
              response.data["data"]["average_price_per_person"],
            maximumNumberOfGuests:
              response.data["data"]["maximum_number_of_guest_chef_can_host"],
            maximumPeopleCarter:
              response.data["data"]["maximum_people_to_cater"],
            averageMealPrice: response.data["data"]["average_meal_price"],
            dataLoaded: true,
			isMarketingPackage: response.data["data"].is_marketing_package == 1 ? true : false,
			packagess: response.data["data"].package,
            joinDate: response.data["data"].join_date,
            expireDate: response.data["data"].expire_date,
            paymentDate: response.data["data"].payment_date,
            paidAmount: response.data["data"].paid_amount,
            paymentRemark: response.data["data"].payment_remark,
          });
          if (response.data["data"]["operational_days"].length) {
            let array = [];
            response.data["data"]["operational_days"].forEach((item) => {
              array.push(item.day_name);
            });
            if (array.length == 7) {
              this.setState({
                selectedAllWeekDays: true,
              });
            }
            this.setState({
              selectedWeekDays: array,
            });
          }
          if (response.data["data"]["catering_facilities"].length) {
            let array = [];
            response.data["data"]["catering_facilities"].forEach((item) => {
              array.push(item.facility_id);
            });
            this.setState({
              selectedCateringFacilities: array,
            });
          }
          if (response.data["data"]["venue_facilities"].length) {
            let array = [];
            response.data["data"]["venue_facilities"].forEach((item) => {
              array.push(item.facility_id);
            });
            this.setState({
              selectedVenueFacilities: array,
            });
          }
        } else {
          this.setState({
            dataLoaded: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          dataLoaded: true,
        });
      });
  }

  onRegistrationFileChange = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0]) {
      if (
        file.name.match(
          /\.(jpg|jpeg|png|gif|txt|doc|docx|pdf|JPG|JPEG|PNG|GIF|TXT|DOC|DOCX|PDF)$/
        )
      ) {
        if (e.target.files[0].size > 52428800) {
          this.setState({
            registerDocumentErr: "The allowed maximum upload size is 50 MB",
          });
          document.getElementById("uploadRegisterFile").value = "";
        } else {
          this.setState({
            registerDocumentErr: "",
            userFssaiRegistration: e.target.files[0],
          });
        }
      } else {
        this.setState({
          registerDocumentErr:
            "Select valid file. Allowed format are .jpg, .jpeg, .png, .svg, .gif, .pdf, .doc, .docx and .txt",
        });
        document.getElementById("uploadRegisterFile").value = "";
      }
    } else {
      // if (this.state.userFssaiSelected === "Yes" || this.state.userFssaiSelected === "Applied") {
      //     this.setState({
      //         userFssaiRegistration: "",
      //         registerDocumentErr: "This is required"
      //     })
      // } else {
      this.setState({
        userFssaiRegistration: "",
        registerDocumentErr: "",
      });
      // }
    }
  };
	
  onVerificationFileChange = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0]) {
      if (
        file.name.match(
          /\.(jpg|jpeg|png|gif|txt|doc|docx|pdf|JPG|JPEG|PNG|GIF|TXT|DOC|DOCX|PDF)$/
        )
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            verificationDocumentErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadVerifyFile").value = "";
        } else {
          this.setState({
            verificationDocumentErr: "",
            userVerfication: e.target.files[0],
          });
        }
      } else {
        this.setState({
          verificationDocumentErr:
            "Select valid file. Allowed format are .jpg, .jpeg, .png, .svg, .gif, .pdf, .doc, .docx and .txt",
        });
        document.getElementById("uploadVerifyFile").value = "";
      }
    } else {
      this.setState({
        userFssaiRegistration: "",
        verificationDocumentErr: "",
      });
    }
  };
  
  handleCheckboxInput = (e) => {
    this.setState({
      isMarketingPackage: e.target.checked
    });
  };

  handleBusinessInputChange = (e) => {
    const { name, value } = e.target;
    let businessFormErrors = this.state.businessFormErrors;

    switch (name) {
      case "userBusinessYear":
        businessFormErrors.userBusinessYear =
          value.length && !value.match(/^[0-9\b]{4}$/)
            ? "Enter valid year"
            : "";
        break;

      case "userGstNumber":
        businessFormErrors.userGstNumber =
          value.length <= 0 && this.state.userGstSelected === "1"
            ? "This is required"
            : !value.match(
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
              )
            ? "Enter valid GST number"
            : "";
        break;

      case "minimumOrderAmount":
        businessFormErrors.minimumOrderAmount =
          value.length &&
          !value.match(/^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$/)
            ? "Enter valid order amount"
            : "";
        break;

      case "maximumNumberOfGuests":
        businessFormErrors.maximumNumberOfGuests =
          value.length && !value.match(/^[0-9\b]{1,}$/)
            ? "Enter valid number"
            : "";
        break;

      case "startTimeState":
        if (value === "AM" && this.state.userBusinessStartTime === "12:00") {
          this.setState({
            userBusinessStartTime: "",
          });
        }
        break;

      case "endTimeState":
        if (value === "AM" && this.state.userBusinessEndTime === "12:00") {
          this.setState({
            userBusinessEndTime: "",
          });
        }
        break;
      case "averageMealPrice":
        if (
          !isNaN(this.state.averageMealPrice) &&
          Number(this.state.averageMealPrice) > 0
        ) {
          this.setState({ averageMealPrice: "" });
        }
	  case "samedaydelivery":
        if (
          !isNaN(this.state.samedaydelivery) &&
          Number(this.state.samedaydelivery) > 0
        ) {
          this.setState({ samedaydelivery: "0" });
          this.setState({ samedaydeliverynotice: "0" });
        }
      default:
        break;
    }

    this.setState({ businessFormErrors, [name]: value });
  };

  submitBusinessForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let businessFormErrors = this.state.businessFormErrors;
	
	this.setState({
      paymentdateerror: '',
      paymentremarkerror: '',
      paidamounterror: '',
    });
	
	// check validation Error
	if(this.state.isMarketingPackage == true){
		if(this.state.paymentDate == ""){
			this.setState({
				 paymentdateerror: "Please select payment date."
			});
			return false;
		}
		if(this.state.paidAmount == "" || this.state.paidAmount <= 0){
			this.setState({
				 paidamounterror: "Please enter valid paid amount."
			});
			return false;
		}
		if(this.state.paymentRemark == ""){
			this.setState({
				 paymentremarkerror: "Please enter payment remark."
			});
			return false;
		}
	}
	
    const {
      userFssaiSelected,
      userGstSelected,
      userFssaiRegistration,
      userFssaiRegistrationUrl,
      userGstNumber,
      userFSSAINumber,
      userBusinessStartTime,
      userBusinessEndTime,
      minimumOrderAmount,
      maximumPeopleCarter,
      maximumNumberOfGuests,
      averagePricePerPerson,
      userVerfication,
      userVerficationUrl,
    } = this.state;

    // if ((userGstNumber === "" || userGstNumber === null) && userGstSelected === "1") {
    //     businessFormErrors.userGstNumber = "This is required";
    // }
    // if ((userFssaiSelected === "Yes" || userFssaiSelected === "Applied") && !userFssaiRegistration && !userFssaiRegistrationUrl) {
    //     this.state.registerDocumentErr = "This is required"
    // }

    this.setState({ businessFormErrors, [name]: value });
    setTimeout(() => {
      if (
        businessFormValid(this.state) &&
        this.state.verificationDocumentErr === "" &&
        this.state.registerDocumentErr === "" &&
        this.state.weekDaysErr === ""
      ) {
        var id = localStorage.getItem("userID");
        var chefId = localStorage.getItem("chefID");
        var token = localStorage.getItem("accessToken");
        this.setState({
          buttonText: "Updating...",
          buttonDisabled: true,
        });

        let data = {
          userBusinessYear: this.state.userBusinessYear,
          userFssaiSelected: this.state.userFssaiSelected,
          userFssaiRegistration: this.state.userFssaiRegistration,
          userGstSelected: this.state.userGstSelected,
          userGstNumber: this.state.userGstNumber,
          userFSSAINumber: this.state.userFSSAINumber,
          userVerfication: this.state.userVerfication,
          userBusinessStartTime: this.state.userBusinessStartTime,
          startTimeState: this.state.startTimeState,
          userBusinessEndTime: this.state.userBusinessEndTime,
          endTimeState: this.state.endTimeState,
          userBusinessTakeaway: this.state.userBusinessTakeaway,
          samedaydelivery: this.state.samedaydelivery,
          samedaydeliverynotice: this.state.samedaydeliverynotice,
          allitemsamedaydelivery: this.state.allitemsamedaydelivery,
          specialInstructionsForTakeaway:
            this.state.specialInstructionsForTakeaway,
          minimumOrderAmount: this.state.minimumOrderAmount,
          takePartyOrders: this.state.takePartyOrders,
          hostEventAtHome: this.state.hostEventAtHome,
          averagePricePerPerson: this.state.averagePricePerPerson,
          maximumPeopleCarter: this.state.maximumPeopleCarter,
          maximumNumberOfGuests: this.state.maximumNumberOfGuests,
          selectedWeekDays: this.state.selectedWeekDays,
          selectedCateringFacilities: this.state.selectedCateringFacilities,
          selectedVenueFacilities: this.state.selectedVenueFacilities,
          averageMealPrice: this.state.averageMealPrice,
		  is_marketing_package: this.state.isMarketingPackage ? 1 : 0,
		  packagess: this.state.packagess,
		  join_date: this.state.joinDate,
		  expire_date: this.state.expireDate,
		  payment_date: this.state.paymentDate,
		  paid_amount: this.state.paidAmount,
		  payment_remark: this.state.paymentRemark,
        };

        return updateChefBusinessDetailsAPI(id, chefId, token, data)
          .then((response) => {
            if (response.data["status"] === 200) {
              NotificationManager.success(
                "Business data updated successfully",
                "Success",
                3000
              );
              if (document.getElementById("uploadRegisterFile")) {
                document.getElementById("uploadRegisterFile").value = "";
              }

              document.getElementById("uploadVerifyFile").value = "";
              this.setState({
                userFssaiRegistration: null,
                userVerfication: null,
              });
              this.getBusinessData();
              this.setState({
                buttonText: "Update Business Info",
                buttonDisabled: false,
              });
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                buttonText: "Update Business Info",
                buttonDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              buttonText: "Update Business Info",
              buttonDisabled: false,
            });
          });
      } else {
        NotificationManager.error(
          "Please see if the required fields are entered",
          "Error",
          3000
        );
      }
    }, 200);
  };

  handleDayCheckboxInputChange = (e) => {
    if (e.target.value === "All days of week") {
      if (e.target.checked) {
        let array = this.state.selectedWeekDays;
        this.state.daysOptions.forEach((item) => {
          if (!array.includes(item)) {
            array.push(item);
          }
        });
        this.setState({
          selectedWeekDays: array,
          selectedAllWeekDays: true,
          weekDaysErr: "",
        });
      } else {
        this.setState({
          selectedWeekDays: [],
          selectedAllWeekDays: false,
          weekDaysErr: "",
        });
      }
    } else {
      if (e.target.checked) {
        let array = this.state.selectedWeekDays;
        if (!array.includes(e.target.value)) {
          array.push(e.target.value);
          if (array.length == 7) {
            this.setState({
              selectedAllWeekDays: true,
            });
          }
          this.setState({
            selectedWeekDays: array,
            weekDaysErr: "",
          });
        }
      } else {
        let array = this.state.selectedWeekDays.filter(
          (elm) => elm !== e.target.value
        );
        if (!array.length) {
          this.setState({
            weekDaysErr: "",
          });
        }
        this.setState({
          selectedWeekDays: array,
          selectedAllWeekDays: false,
        });
      }
    }
  };

  handleCatringCheckboxInputChange = (e) => {
    if (e.target.checked) {
      let array = this.state.selectedCateringFacilities;
      array.push(e.target.value);
      this.setState({
        selectedCateringFacilities: array,
      });
    } else {
      let array = this.state.selectedCateringFacilities.filter(
        (elm) => elm !== e.target.value
      );
      this.setState({
        selectedCateringFacilities: array,
      });
    }
    // setTimeout(()=>{
    //     console.log(this.state.selectedCateringFacilities)
    // },100)
  };

  handleVenueCheckboxInputChange = (e) => {
    if (e.target.checked) {
      let array = this.state.selectedVenueFacilities;
      array.push(e.target.value);
      this.setState({
        selectedVenueFacilities: array,
      });
    } else {
      let array = this.state.selectedVenueFacilities.filter(
        (elm) => elm !== e.target.value
      );
      this.setState({
        selectedVenueFacilities: array,
      });
    }
    // setTimeout(()=>{
    //     console.log(this.state.selectedVenueFacilities)
    // },100)
  };

  render() {
    const { businessFormErrors } = this.state;
    return (
      <form onSubmit={this.submitBusinessForm}>
        <div className="row clearfix">
          <div className="col-xs-12 ol-sm-12 col-md-12 col-lg-12">
            <div
              className="panel-group"
              id="accordion_18"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingOne_18">
                  <h4 className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_18"
                      href="#collapseOne_18"
                      aria-expanded="false"
                      aria-controls="collapseOne_18"
                      className="collapsed"
                    >
                      Documents{" "}
                      <i className="material-icons float-right">
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseOne_18"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne_18"
                  aria-expanded="false"
                  style={{ height: "0px" }}
                >
                  <div className="panel-body aniimated-thumbnials">
                    <div className="card">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-md-4">
                            <div className="form-group">
                              <b>Year when business started</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  defaultValue={this.state.userBusinessYear}
                                  className="form-control"
                                  name="userBusinessYear"
                                  onChange={this.handleBusinessInputChange}
                                  onBlur={this.handleBusinessInputChange}
                                />
                              </div>
                              {businessFormErrors.userBusinessYear.length >
                                0 && (
                                <span className="text-danger">
                                  {businessFormErrors.userBusinessYear}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <b>Do you have FSSAI registration?*</b>
                            <div className="input-group">
                              <input
                                name="userFssaiSelected"
                                type="radio"
                                id="fassi_1"
                                className="radio-col-orange"
                                value="Yes"
                                onChange={this.handleBusinessInputChange}
                                checked={this.state.userFssaiSelected === "Yes"}
                              />
                              <label htmlFor="fassi_1">Yes</label>
                              <input
                                name="userFssaiSelected"
                                type="radio"
                                id="fassi_2"
                                className="radio-col-orange"
                                value="No"
                                onChange={this.handleBusinessInputChange}
                                checked={this.state.userFssaiSelected === "No"}
                              />
                              <label htmlFor="fassi_2">No</label>
                              <input
                                name="userFssaiSelected"
                                type="radio"
                                id="fassi_3"
                                className="radio-col-orange"
                                value="Applied"
                                onChange={this.handleBusinessInputChange}
                                checked={
                                  this.state.userFssaiSelected === "Applied"
                                }
                              />
                              <label htmlFor="fassi_3">Applied for</label>
                            </div>
                            {businessFormErrors.userFssaiSelected.length >
                              0 && (
                              <span className="text-danger">
                                {businessFormErrors.userFssaiSelected}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <b>Enter FSSAI Licence Number</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  defaultValue={this.state.userFSSAINumber}
                                  className="form-control"
                                  name="userFSSAINumber"
                                  maxLength="14"
                                  onChange={this.handleBusinessInputChange}
                                  onBlur={this.handleBusinessInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          {this.state.userFssaiSelected === "Yes" ||
                          this.state.userFssaiSelected === "Applied" ? (
                            <div className="col-md-4">
                              <div className="input-group upload_img_one two">
                                <b className="d-block">
                                  Copy of your registration or application*
                                </b>
                                <div className="form-group">
                                  <div className="form-line">
                                    <input
                                      type="file"
                                      id="uploadRegisterFile"
                                      className="form-control"
                                      name="userFssaiRegistration"
                                      onChange={this.onRegistrationFileChange}
                                    />
                                  </div>
                                  {this.state.registerDocumentErr.length >
                                    0 && (
                                    <span className="text-danger">
                                      {this.state.registerDocumentErr}
                                    </span>
                                  )}
                                </div>
                                {this.state.userFssaiRegistrationUrl && (
                                  <div>
                                    <a
                                      href={this.state.userFssaiRegistrationUrl}
                                      target="_blank"
                                      className="a-mw-100"
                                    >
                                      View Uploaded Document
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <div className="col-md-4">
                            <b>Do you have a GST registration?</b>
                            <div className="input-group">
                              <input
                                name="userGstSelected"
                                type="radio"
                                id="gst_1"
                                className="radio-col-orange"
                                value="1"
                                onChange={this.handleBusinessInputChange}
                                checked={this.state.userGstSelected === "1"}
                              />
                              <label htmlFor="gst_1">Yes</label>
                              <input
                                name="userGstSelected"
                                type="radio"
                                id="gst_2"
                                className="radio-col-orange"
                                value="0"
                                onChange={this.handleBusinessInputChange}
                                checked={this.state.userGstSelected === "0"}
                              />
                              <label htmlFor="gst_2">No</label>
                            </div>
                            {businessFormErrors.userGstSelected.length > 0 && (
                              <span className="text-danger">
                                {businessFormErrors.userGstSelected}
                              </span>
                            )}
                          </div>
                          {this.state.userGstSelected === "1" && (
                            <div className="col-md-4">
                              <div className="form-group">
                                <b>Enter GST registration Number</b>
                                <div className="form-line">
                                  <input
                                    type="text"
                                    defaultValue={this.state.userGstNumber}
                                    className="form-control"
                                    name="userGstNumber"
                                    onChange={this.handleBusinessInputChange}
                                    onBlur={this.handleBusinessInputChange}
                                  />
                                </div>
                                {businessFormErrors.userGstNumber.length >
                                  0 && (
                                  <span className="text-danger">
                                    {businessFormErrors.userGstNumber}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="col-md-4">
                            <div className="input-group upload_img_one two">
                              <b className="d-block">Verification</b>
                              <div className="form-group">
                                <div className="form-line">
                                  <input
                                    type="file"
                                    id="uploadVerifyFile"
                                    className="form-control"
                                    name="userVerfication"
                                    onChange={this.onVerificationFileChange}
                                  />
                                </div>
                                {this.state.verificationDocumentErr.length >
                                  0 && (
                                  <span className="text-danger">
                                    {this.state.verificationDocumentErr}
                                  </span>
                                )}
                              </div>
                              {this.state.userVerficationUrl && (
                                <div>
                                  <a
                                    href={this.state.userVerficationUrl}
                                    target="_blank"
                                    className="a-mw-100"
                                  >
                                    View Uploaded Document
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingTwo_18">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_18"
                      href="#collapseTwo_18"
                      aria-expanded="false"
                      aria-controls="collapseTwo_18"
                    >
                      Operating preferences
                      <i className="material-icons float-right">
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo_18"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo_18"
                  aria-expanded="false"
                  style={{ height: "0px" }}
                >
                  <div className="panel-body">
                    <div className="card">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-lg-5 mb-0">
                            <div className="row">
                              <div className="col-md-12 font-weight-normal">
                                <b>Days of Operation</b>
                                <div className="input-group equal_checkbox mb-0">
                                  <div className="item_wrap">
                                    {this.state.daysOptions.map((item, i) => {
                                      return (
                                        <div key={"sdds" + i}>
                                          <label
                                            htmlFor={item}
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "100",
                                            }}
                                          >
                                            {item}
                                          </label>
                                          <input
                                            type="checkbox"
                                            id={item}
                                            value={item}
                                            className="filled-in"
                                            onChange={
                                              this.handleDayCheckboxInputChange
                                            }
                                            checked={this.state.selectedWeekDays.includes(
                                              item
                                            )}
                                          />
                                        </div>
                                      );
                                    })}
                                    <div>
                                      <label
                                        htmlFor="all-days"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "100",
                                        }}
                                      >
                                        All days of week
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="all-days"
                                        value="All days of week"
                                        className="filled-in"
                                        onChange={
                                          this.handleDayCheckboxInputChange
                                        }
                                        checked={this.state.selectedAllWeekDays}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {this.state.weekDaysErr.length > 0 && (
                                  <span className="text-danger">
                                    {this.state.weekDaysErr}
                                  </span>
                                )}
                              </div>
							  <div className="col-md-12">
                                <b>Do you offer same-day delivery?</b>
                                <div className="input-group">
                                  <input
                                    name="samedaydelivery"
                                    type="radio"
                                    id="sdd1"
                                    className="radio-col-orange"
                                    value="1"
                                    onChange={this.handleBusinessInputChange}
                                    checked={
                                      this.state.samedaydelivery === "1"
                                    }
                                  />
                                  <label htmlFor="sdd1">Yes</label>
                                  <input
                                    name="samedaydelivery"
                                    type="radio"
                                    id="sdd2"
                                    className="radio-col-orange"
                                    value="0"
                                    onChange={this.handleBusinessInputChange}
                                    checked={
                                      this.state.samedaydelivery === "0"
                                    }
                                  />
                                  <label htmlFor="sdd2">No</label>
                                </div>
                              </div>
							   {this.state.samedaydelivery === "1" && (
								<div className="col-md-12">
								  <div className="form-group">
									<b>Advance notice required for same-day delivery?</b>
									<div className="form-line">
									<select
									className="form-control"
									name="samedaydeliverynotice"
									onChange={
									  this.handleBusinessInputChange
									}
									>
									
									<option
									  value="1"
									  selected={
										this.state.samedaydeliverynotice ===
										"1"
									  }
									>
									  1 Hour
									</option>
									<option
									  value="2"
									  selected={
										this.state.samedaydeliverynotice ===
										"2"
									  }
									>
									  2 Hours
									</option>
									<option
									  value="3"
									  selected={
										this.state.samedaydeliverynotice ===
										"3"
									  }
									>
									  3 Hours
									</option>
									<option
									  value="4"
									  selected={
										this.state.samedaydeliverynotice ===
										"4"
									  }
									>
									  4 Hours
									</option>
									</select>
									</div>
								  </div>
								
									<b>Do you offer same-day delivery for all menu items?</b>
									<div className="input-group">
									  <input
										name="allitemsamedaydelivery"
										type="radio"
										id="allsdd1"
										className="radio-col-orange"
										value="1"
										onChange={this.handleBusinessInputChange}
										checked={
										  this.state.allitemsamedaydelivery === "1"
										}
									  />
									  <label htmlFor="allsdd1">Yes</label>
									  <input
										name="allitemsamedaydelivery"
										type="radio"
										id="allsdd2"
										className="radio-col-orange"
										value="0"
										onChange={this.handleBusinessInputChange}
										checked={
										  this.state.allitemsamedaydelivery === "0"
										}
									  />
									  <label htmlFor="allsdd2">No</label>
									</div>
								</div>
							  )}
                              <div className="col-md-12">
                                <b>Do you offer takeaway?</b>
                                <div className="input-group">
                                  <input
                                    name="userBusinessTakeaway"
                                    type="radio"
                                    id="takeaway_1"
                                    className="radio-col-orange"
                                    value="1"
                                    onChange={this.handleBusinessInputChange}
                                    checked={
                                      this.state.userBusinessTakeaway === "1"
                                    }
                                  />
                                  <label htmlFor="takeaway_1">Yes</label>
                                  <input
                                    name="userBusinessTakeaway"
                                    type="radio"
                                    id="takeaway_2"
                                    className="radio-col-orange"
                                    value="0"
                                    onChange={this.handleBusinessInputChange}
                                    checked={
                                      this.state.userBusinessTakeaway === "0"
                                    }
                                  />
                                  <label htmlFor="takeaway_2">No</label>
                                </div>
							  </div>
								<div className="col-md-12">
									<div className="for-group">
									<b htmlFor='isMarketingPackage'>Is Marketing Package?</b>
									<input
									  type='checkbox'
									  id='isMarketingPackage'
									  name='isMarketingPackage'
									  className='filled-in'
									  onChange={this.handleCheckboxInput}
									  checked={this.state.isMarketingPackage}
									/>
									</div>
								</div>
                            </div>
                          </div>
                          <div className="col-lg-7 mb-0">
                            <div className="row d-flex">
                              {this.state.dataLoaded ? (
                                <div className="col-md-6">
                                  <b>You take your first order starting?</b>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-line">
                                          <select
                                            className="form-control tel_number"
                                            name="startTimeState"
                                            onChange={
                                              this.handleBusinessInputChange
                                            }
                                          >
                                            <option
                                              value="AM"
                                              selected={
                                                this.state.startTimeState ===
                                                "AM"
                                              }
                                            >
                                              AM
                                            </option>
                                            <option
                                              value="PM"
                                              selected={
                                                this.state.startTimeState ===
                                                "PM"
                                              }
                                            >
                                              PM
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-line">
                                          <select
                                            className="form-control tel_number"
                                            name="userBusinessStartTime"
                                            onChange={
                                              this.handleBusinessInputChange
                                            }
                                          >
                                            <option value="">
                                              Select time
                                            </option>
                                            {this.state.timeList.map(
                                              (time, i) => {
                                                return (
                                                  <option
                                                    value={time}
                                                    key={"ttt" + i}
                                                    selected={
                                                      this.state
                                                        .userBusinessStartTime ===
                                                      time
                                                    }
                                                    disabled={
                                                      this.state
                                                        .startTimeState ===
                                                        "AM" && time === "12:00"
                                                    }
                                                  >
                                                    {time}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                        {businessFormErrors
                                          .userBusinessStartTime.length > 0 && (
                                          <span className="text-danger">
                                            {
                                              businessFormErrors.userBusinessStartTime
                                            }
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.dataLoaded ? (
                                <div className="col-md-6">
                                  <b>
                                    You take your last order at?
                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-line">
                                            <select
                                              className="form-control tel_number"
                                              name="endTimeState"
                                              onChange={
                                                this.handleBusinessInputChange
                                              }
                                            >
                                              <option
                                                value="AM"
                                                selected={
                                                  this.state.endTimeState ===
                                                  "AM"
                                                }
                                              >
                                                AM
                                              </option>
                                              <option
                                                value="PM"
                                                selected={
                                                  this.state.endTimeState ===
                                                  "PM"
                                                }
                                              >
                                                PM
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-line">
                                            <select
                                              className="form-control tel_number"
                                              name="userBusinessEndTime"
                                              onChange={
                                                this.handleBusinessInputChange
                                              }
                                            >
                                              <option value="">
                                                Select time
                                              </option>
                                              {this.state.timeList.map(
                                                (time, i) => {
                                                  return (
                                                    <option
                                                      value={time}
                                                      key={"ttt" + i}
                                                      selected={
                                                        this.state
                                                          .userBusinessEndTime ===
                                                        time
                                                      }
                                                      disabled={
                                                        this.state
                                                          .endTimeState ===
                                                          "AM" &&
                                                        time === "12:00"
                                                      }
                                                    >
                                                      {time}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </div>
                                          {businessFormErrors
                                            .userBusinessEndTime.length > 0 && (
                                            <span className="text-danger">
                                              {
                                                businessFormErrors.userBusinessEndTime
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </b>
                                </div>
                              ) : null}
                              <div className="col-md-12">
                                <b>
                                  Special Instructions for takeaway or delivery
                                  pick up
                                </b>
                                <div className="input-group">
                                  <div>
                                    <div className="form-group">
                                      <div className="form-line">
                                        <input
                                          type="text"
                                          defaultValue={
                                            this.state
                                              .specialInstructionsForTakeaway
                                          }
                                          maxLength={200}
                                          className="form-control"
                                          name="specialInstructionsForTakeaway"
                                          onChange={
                                            this.handleBusinessInputChange
                                          }
                                          onBlur={
                                            this.handleBusinessInputChange
                                          }
                                        />
                                      </div>
                                      {/* {businessFormErrors.specialInstructionsForTakeaway.length > 0 && (
                                                                <span className="text-danger">{businessFormErrors.specialInstructionsForTakeaway}</span>
                                                            )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <b>Minimum order amount</b>
                                <div className="input-group">
                                  <div>
                                    <div className="form-group">
                                      <div className="form-line">
                                        <input
                                          type="text"
                                          defaultValue={
                                            this.state.minimumOrderAmount
                                          }
                                          className="form-control"
                                          name="minimumOrderAmount"
                                          onChange={
                                            this.handleBusinessInputChange
                                          }
                                          onBlur={
                                            this.handleBusinessInputChange
                                          }
                                        />
                                      </div>
                                      {businessFormErrors.minimumOrderAmount
                                        .length > 0 && (
                                        <span className="text-danger">
                                          {
                                            businessFormErrors.minimumOrderAmount
                                          }
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <b>Cost for Two/Average Meal Price</b>
                                <div className="input-group">
                                  <div>
                                    <div className="form-group">
                                      <div className="form-line">
                                        <input
                                          type="number"
                                          value={this.state.averageMealPrice}
                                          className="form-control"
                                          name="averageMealPrice"
                                          onChange={
                                            this.handleBusinessInputChange
                                          }
                                          onBlur={
                                            this.handleBusinessInputChange
                                          }
                                        />
                                      </div>
                                      {businessFormErrors.averageMealPrice
                                        .length > 0 && (
                                        <span className="text-danger">
                                          {businessFormErrors.averageMealPrice}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
						 {this.state.isMarketingPackage === true && (
							  <div class="row">
								<div className="col-md-4">
									<div className="form-group">
										<b>Package*</b>
										<div className="form-line">
											<input
											  type="text"
											  placeholder=""
											  defaultValue={this.state.packagess}
											  onChange={this.handleBusinessInputChange}
											  name="packagess"
											  readonly="readonly"
											  className="form-control"
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<b>Join Date</b>
										<div className="form-line">
											<input
												type='date'
												defaultValue={this.state.joinDate}
												className='form-control'
												name='joinDate'
												readonly="readonly"
												onChange={this.handleBusinessInputChange}
												onBlur={this.handleBusinessInputChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<b>Expire Date</b>
										<div className="form-line">
											<input
												type='date'
												defaultValue={this.state.expireDate}
												className='form-control'
												name='expireDate'
												readonly="readonly"
												onChange={this.handleBusinessInputChange}
												onBlur={this.handleBusinessInputChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<b>Payment Date*</b>
										<div className="form-line">
											<input
												type='date'
												defaultValue={this.state.paymentDate}
												className='form-control'
												name='paymentDate'
												onChange={this.handleBusinessInputChange}
												onBlur={this.handleBusinessInputChange}
											/>
										</div>
										{this.state.paymentdateerror != '' && (
										  <small className="text-danger">
											{this.state.paymentdateerror}
										  </small>
										)}
									</div>
								</div>
								
								<div className="col-md-4">
									<div className="form-group">
										<b>Paid Amount*</b>
										<div className="form-line">
											<input
											  type="number"
											  step="any"
											  placeholder=""
											  defaultValue={this.state.paidAmount}
											  onChange={this.handleBusinessInputChange}
											  name="paidAmount"
											  className="form-control"
											/>
										</div>
										{this.state.paidamounterror != '' && (
										  <small className="text-danger">
											{this.state.paidamounterror}
										  </small>
										)}
									</div>
								</div>
								
								<div className="col-md-4">
									<div className="form-group">
										<b>Payment Remark*</b>
										<div className="form-line">
											<input
											  type="text"
											  placeholder=""
											  defaultValue={this.state.paymentRemark}
											  onChange={this.handleBusinessInputChange}
											  name="paymentRemark"
											  className="form-control"
											/>
										</div>
										{this.state.paymentremarkerror != '' && (
										  <small className="text-danger">
											{this.state.paymentremarkerror}
										  </small>
										)}
									</div>
								</div>
						  </div>
						  )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingThree_18">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_18"
                      href="#collapseThree_18"
                      aria-expanded="false"
                      aria-controls="collapseThree_18"
                    >
                      Other Events
                      <i className="material-icons float-right">
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseThree_18"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree_18"
                  aria-expanded="false"
                  style={{ height: "0px" }}
                >
                  <div className="panel-body">
                    <div className="card">
                      <div className="body">
                        <div className="row clearfix">
                          <div className="col-lg-6 mb-0">
                            <div className="row">
                              <div className="col-md-6">
                                <b>Do you take party orders?</b>
                                <div className="input-group">
                                  <input
                                    name="takePartyOrders"
                                    type="radio"
                                    id="party_1"
                                    className="radio-col-orange"
                                    value="1"
                                    onChange={this.handleBusinessInputChange}
                                    checked={this.state.takePartyOrders === "1"}
                                  />
                                  <label htmlFor="party_1">Yes</label>
                                  <input
                                    name="takePartyOrders"
                                    type="radio"
                                    id="party_2"
                                    className="radio-col-orange"
                                    value="0"
                                    onChange={this.handleBusinessInputChange}
                                    checked={this.state.takePartyOrders === "0"}
                                  />
                                  <label htmlFor="party_2">No</label>
                                </div>
                              </div>
                              {this.state.takePartyOrders === "1" && (
                                <div className="col-md-6">
                                  <b>Maximum people you can cater?</b>
                                  {this.state.dataLoaded && (
                                    <div className="form-group">
                                      <div className="form-line">
                                        {/* <input type="text" defaultValue={this.state.maximumPeopleCarter} className="form-control" name="maximumPeopleCarter"
                                                                            onChange={this.handleBusinessInputChange} onBlur={this.handleBusinessInputChange} /> */}
                                        <select
                                          className="form-control"
                                          name="maximumPeopleCarter"
                                          onChange={
                                            this.handleBusinessInputChange
                                          }
                                          onBlur={
                                            this.handleBusinessInputChange
                                          }
                                        >
                                          <option value="">Select</option>
                                          {this.state.maxPeopleCaterArr.map(
                                            (item, i) => {
                                              return (
                                                <option
                                                  key={"opk" + i}
                                                  value={item}
                                                  selected={
                                                    this.state
                                                      .maximumPeopleCarter ===
                                                    item
                                                  }
                                                >
                                                  {item}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      {businessFormErrors.maximumPeopleCarter
                                        .length > 0 && (
                                        <span className="text-danger">
                                          {
                                            businessFormErrors.maximumPeopleCarter
                                          }
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="col-md-6">
                                <b>Do you host events at your home?</b>
                                <div className="input-group">
                                  <input
                                    name="hostEventAtHome"
                                    type="radio"
                                    id="host_1"
                                    className="radio-col-orange"
                                    value="1"
                                    onChange={this.handleBusinessInputChange}
                                    checked={this.state.hostEventAtHome === "1"}
                                  />
                                  <label htmlFor="host_1">Yes</label>
                                  <input
                                    name="hostEventAtHome"
                                    type="radio"
                                    id="host_2"
                                    className="radio-col-orange"
                                    value="0"
                                    onChange={this.handleBusinessInputChange}
                                    checked={this.state.hostEventAtHome === "0"}
                                  />
                                  <label htmlFor="host_2">No</label>
                                </div>
                              </div>

                              {this.state.hostEventAtHome === "1" && (
                                <div className="col-md-6">
                                  <b>
                                    What is the maximum number of guests you can
                                    host?
                                  </b>
                                  <div className="form-group">
                                    <div className="form-line">
                                      <input
                                        type="text"
                                        defaultValue={
                                          this.state.maximumNumberOfGuests
                                        }
                                        className="form-control"
                                        name="maximumNumberOfGuests"
                                        onChange={
                                          this.handleBusinessInputChange
                                        }
                                        onBlur={this.handleBusinessInputChange}
                                      />
                                    </div>
                                    {businessFormErrors.maximumNumberOfGuests
                                      .length > 0 && (
                                      <span className="text-danger">
                                        {
                                          businessFormErrors.maximumNumberOfGuests
                                        }
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                              {this.state.hostEventAtHome === "1" && (
                                <div className="col-md-6">
                                  <b>
                                    What is the average price per person for
                                    your events? (INR)
                                  </b>
                                  {this.state.dataLoaded && (
                                    <div className="form-group">
                                      <div className="form-line">
                                        {/* <input type="text" defaultValue={this.state.averagePricePerPerson} className="form-control" name="averagePricePerPerson"
                                                                            onChange={this.handleBusinessInputChange} onBlur={this.handleBusinessInputChange} /> */}
                                        <select
                                          className="form-control"
                                          name="averagePricePerPerson"
                                          onChange={
                                            this.handleBusinessInputChange
                                          }
                                          onBlur={
                                            this.handleBusinessInputChange
                                          }
                                        >
                                          <option value="">Select</option>
                                          {this.state.pricePerPersonArr.map(
                                            (item, i) => {
                                              return (
                                                <option
                                                  key={"opk" + i}
                                                  value={item}
                                                  selected={
                                                    this.state
                                                      .averagePricePerPerson ===
                                                    item
                                                  }
                                                >
                                                  {item}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      {businessFormErrors.averagePricePerPerson
                                        .length > 0 && (
                                        <span className="text-danger">
                                          {
                                            businessFormErrors.averagePricePerPerson
                                          }
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-0">
                            <div className="row">
                              {this.state.takePartyOrders === "1" && (
                                <div className="col-md-12">
                                  <b>
                                    Which catering facilities do you provide?
                                  </b>
                                  <div className="item_wrap">
                                    {this.state.cateringFacilities.map(
                                      (item, i) => {
                                        return (
                                          <div key={"saas" + i}>
                                            <label
                                              htmlFor={item.facility_name}
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "100",
                                              }}
                                            >
                                              {item.facility_name}
                                            </label>
                                            <input
                                              type="checkbox"
                                              id={item.facility_name}
                                              value={item.id}
                                              className="filled-in"
                                              onChange={
                                                this
                                                  .handleCatringCheckboxInputChange
                                              }
                                              checked={this.state.selectedCateringFacilities.includes(
                                                item.id
                                              )}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}

                              {this.state.hostEventAtHome === "1" && (
                                <div className="col-md-12">
                                  <b>Facilities available at your venue</b>
                                  <div className="item_wrap">
                                    {this.state.venueFacilities.map(
                                      (item, i) => {
                                        return (
                                          <div key={"saas" + i}>
                                            <label
                                              htmlFor={item.facility_name}
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "100",
                                              }}
                                            >
                                              {item.facility_name}
                                            </label>
                                            <input
                                              type="checkbox"
                                              id={item.facility_name}
                                              value={item.id}
                                              className="filled-in"
                                              onChange={
                                                this
                                                  .handleVenueCheckboxInputChange
                                              }
                                              checked={this.state.selectedVenueFacilities.includes(
                                                item.id
                                              )}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
		{/*  Admin and Ops menu */}
		{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
		<button
          type="submit"
          disabled={this.state.buttonDisabled}
          className="btn"
        >
          {this.state.buttonText}
        </button>
		) : null}
        
      </form>
    );
  }
}
