/*
#############################################################################
# Name : EditReviewRating.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to edit the marketing cards which are already availalble
#############################################################################
*/
import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { detailChefReviewAPI, updateChefReviewAPI, getChefListAPI } from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach((val) => {
        val.length > 0 && (valid = false);
    });

    Object.values(formErrors).forEach((val) => {
        val === "form-control" && (valid = false);
    });

    return valid;
};
export default class EditReviewRating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            review: "",
            foodQuality: "",
            packaging: "",
            valueOfMoney: "",
            portionSize: "",
            moderationRemarks: "",
            averageRating: "",
            moderationStatus: "",
            orderDate: new Date(new Date().setDate(new Date().getDate())),
            resetFlag: false,
            formErrors: {
                userName: "",
                review: "",
				foodQuality: "",
				packaging: "",
				valueOfMoney: "",
				portionSize: "",
				selectChef: "",
            },
            buttonText: "Submit",
            buttonDisabled: false,
            chefArr: [],
            selectedChefArr: [],
            selectedChef: null,
            chefSelectErr: "",
            tableSectionError: "",
            ratingSectionError: "",
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.detailChefReviewAPI();
        this.getChefListAPI()
    }

    getChefListAPI = () => {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        return getChefListAPI(id, token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    if (response.data.data.length) {
                        let array = []
                        response.data.data.map(item => {
                            array.push({ value: item.id, label: item.chef_name })
                        })
                        this.setState({
                            chefArr: array
                        })
                    }
                } else {
                    NotificationManager.error(response.data["message"], "Error!");
                }
            })
            .catch((error) => {
                NotificationManager.error(error, "Error!");
            });
    }

    detailChefReviewAPI() {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var currentPage = this.state.activePage
        return detailChefReviewAPI(id, this.props.location.search.substr(4, 100), token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    this.setState({
                        userName: response.data.data.sender_name,
                        valueOfMoney: response.data.data.value_for_money,
                        review: response.data.data.review,
                        portionSize: response.data.data.portion_size,
                        packaging: response.data.data.packaging,
                        moderationRemarks: response.data.data.moderation_remarks,
                        foodQuality: response.data.data.food_quality,
                        averageRating: response.data.data.average_rating,
                        moderationStatus: response.data.data.moderation_status,
                        selectedChef: response.data.data.chef_id,
                        orderDate: new Date(new Date().setDate(new Date(response.data.data.order_date).getDate())),
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    })
                    NotificationManager.error(response.data["message"], "Error!");
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false
                })
                NotificationManager.error(error, "Error!");
            });
    }

    handleCheckboxInput = (e) => {
        this.setState({
            isPublished: e.target.checked
        })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        switch (name) {
            case "userName":
                formErrors.userName = value === "" ? "User name is required" : "";
                break;

            case "review":
                formErrors.review = value === "" ? "Review is required" : "";
                break;
        }
        this.setState({ formErrors, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let formErrors = this.state.formErrors;
        if (this.state.userName === "") {
            formErrors.userName = "User name is required";
        }
        if (this.state.review === "") {
            formErrors.review = "Review is required";
        }
		
		if (this.state.selectedChef === "" || this.state.selectedChef === "0") {
            formErrors.selectChef = "Chef is required";
        }
		
		if ((this.state.foodQuality == "" || this.state.foodQuality == "0") && (this.state.packaging == "" || this.state.packaging == "0") && (this.state.portionSize == "" || this.state.portionSize == "0") && (this.state.valueOfMoney == "" || this.state.valueOfMoney == "0")) {
			this.setState({
				ratingSectionError : "Please select at least one from rating selection."
            })
        }
		
        this.setState({ formErrors, [name]: value });

        setTimeout(() => {
            if (formValid(this.state) && this.state.ratingSectionError === "") {
                var id = localStorage.getItem("userID");
                var token = localStorage.getItem("accessToken");

                let formData = {
                    foodQuality: this.state.foodQuality,
                    packaging: this.state.packaging,
                    portionSize: this.state.portionSize,
                    valueOfMoney: this.state.valueOfMoney,
                    userName: this.state.userName,
                    review: this.state.review,
                    selected_chef: this.state.selectedChef,
                    order_date: `${this.state.orderDate.getFullYear()}-${
                                    this.state.orderDate.getMonth() + 1
                                  }-${this.state.orderDate.getDate()}`,
                }
                this.setState({
                    buttonText: "Submitting...",
                    buttonDisabled: true
                })
                return updateChefReviewAPI(id, token, this.props.location.search.substr(4, 100), formData)
                    .then((response) => {
                        if (response.data["status"] === 200) {
                            NotificationManager.success(
                                "Review updated successfully",
                                "Success",
                                3000
                            );
                            this.setState({
                                buttonText: "Submit",
                            })
                            setTimeout(() => {
                                this.props.history.push('/chef-review-list');
                            }, 2000)
                        } else {
                            NotificationManager.error("Something went wrong", "Error!");
                            this.setState({
                                buttonText: "Submit",
                                buttonDisabled: false
                            })
                        }
                    })
                    .catch((error) => {
                        NotificationManager.error(error, "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    });
            } else {
                console.log('error');
            }
        }, 100)
    }
	
    render() {
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        const { formErrors } = this.state;
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Edit Chef Review</h2>
                                    </div>
                                    {
                                        this.state.isLoading ?
                                            <center>
                                                <Loader
                                                    style={{ marginTop: 50, paddingBottom: 50 }}
                                                    type="Oval"
                                                    color="#F07C07"
                                                    height={50}
                                                    width={50}
                                                /></center> :
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <div className="col-sm-12">
                                                        <form onSubmit={this.handleSubmit}>
                                                            <div className="row clearfix">
																<div className="col-sm-12">
																	<div className="">
																	  <b>Order Date</b>
																	  <div className="form-line">
																		<DatePicker
																		  selected={this.state.orderDate}
																		  onChange={(date) => {
																			this.setState({ orderDate: date });
																		  }}
																		/>
																	  </div>
																	</div>
																</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																	<b>Food Quality*</b>
																	<div className="form-line">
																	  <select
																		className="form-control show-tick"
																		value={this.state.foodQuality}
																		name="foodQuality"
																		onChange={(v) => {
																		  const { name, value } = v.target;
																		 
																		  if (
																			value !== "-- Any --" ||
																			value !== "0"
																		  ) {
																			
																			this.setState({
																			  foodQuality: v.target.value,
																			  ratingSectionError: "",
																			});
																			
																			formErrors.foodQuality = "";
																			this.setState({ formErrors, [name]: value });
																			
																		  } else {
																			this.setState({
																			  foodQuality: undefined,
																			});
																		  }
																		}}
																	  >
																		<option value={"0"}>-- Any --</option>
																		<option value={"1"}>1</option>
																		<option value={"2"}>2</option>
																		<option value={"3"}>3</option>
																		<option value={"4"}>4</option>
																		<option value={"5"}>5</option>
																	  </select>
																	</div>
																	{formErrors.foodQuality.length > 0 && (
																		<span className="text-danger">{formErrors.foodQuality}</span>
																	)}
																  </div>
																</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																	<b>Portion Size*</b>
																	<div className="form-line">
																	  <select
																		className="form-control show-tick"
																		value={this.state.portionSize}
																		name="portionSize"
																		onChange={(v) => {
																		  const { name, value } = v.target;
																		  if (
																			value !== "-- Any --" ||
																			value !== "0"
																		  ) {
																			this.setState({
																			  portionSize: v.target.value,
																			  ratingSectionError: "",
																			});
																			formErrors.portionSize = "";
																			this.setState({ formErrors, [name]: value });
																		  } else {
																			this.setState({
																			  portionSize: undefined,
																			});
																		  }
																		}}
																	  >
																		<option value={"0"}>-- Any --</option>
																		<option value={"1"}>1</option>
																		<option value={"2"}>2</option>
																		<option value={"3"}>3</option>
																		<option value={"4"}>4</option>
																		<option value={"5"}>5</option>
																	  </select>
																	</div>
																	{formErrors.portionSize.length > 0 && (
																		<span className="text-danger">{formErrors.portionSize}</span>
																	)}
																  </div>
																</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																	<b>Packaging*</b>
																	<div className="form-line">
																	  <select
																		className="form-control show-tick"
																		value={this.state.packaging}
																		name="packaging"
																		onChange={(v) => {
																		  const { name, value } = v.target;
																		  if (
																			value !== "-- Any --" ||
																			value !== "0"
																		  ) {
																			this.setState({
																			  packaging: v.target.value,
																			  ratingSectionError: "",
																			});
																			formErrors.packaging = "";
																			this.setState({ formErrors, [name]: value });
																		  } else {
																			this.setState({
																			  packaging: undefined,
																			});
																		  }
																		}}
																	  >
																		<option value={"0"}>-- Any --</option>
																		<option value={"1"}>1</option>
																		<option value={"2"}>2</option>
																		<option value={"3"}>3</option>
																		<option value={"4"}>4</option>
																		<option value={"5"}>5</option>
																	  </select>
																	</div>
																	{formErrors.packaging.length > 0 && (
																		<span className="text-danger">{formErrors.packaging}</span>
																	)}
																  </div>
																</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																	<b>Value of Money*</b>
																	<div className="form-line">
																	  <select
																		className="form-control show-tick"
																		value={this.state.valueOfMoney}
																		name="valueOfMoney"
																		onChange={(v) => {
																		  const { name, value } = v.target;
																		  if (
																			value !== "-- Any --" ||
																			value !== "0"
																		  ) {
																			this.setState({
																			  valueOfMoney: v.target.value,
																			  ratingSectionError: "",
																			});
																			formErrors.valueOfMoney = "";
																			this.setState({ formErrors, [name]: value });
																		  } else {
																			this.setState({
																			  valueOfMoney: undefined,
																			});
																		  }
																		}}
																	  >
																		<option value={"0"}>-- Any --</option>
																		<option value={"1"}>1</option>
																		<option value={"2"}>2</option>
																		<option value={"3"}>3</option>
																		<option value={"4"}>4</option>
																		<option value={"5"}>5</option>
																	  </select>
																	</div>
																	{formErrors.valueOfMoney.length > 0 && (
																		<span className="text-danger">{formErrors.valueOfMoney}</span>
																	)}
																  </div>
																</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																	  <b>Chef*</b>
																	  <div className="form-line">
																		<select
																		  className="form-control show-tick"
																		 value={this.state.selectedChef}
																		  name="selectChef"
																		  onChange={(v) => {
																			const { name, value } = v.target;
																			if (
																			  value !== "-- Any --" ||
																			  value !== "0"
																			) {
																			  this.setState({
																				selectedChef: v.target.value,
																			  });
																			  formErrors.selectChef = "";
																			  this.setState({ formErrors, [name]: value });
																			} else {
																			  this.setState({
																				selectedChef: undefined,
																			  });
																			}
																		  }}
																		>
																		  <option value={"0"}>-- Any --</option>
																		  {this.state.chefArr.map((ec) => (
																			<option value={ec.value} key={ec.value}>
																			  {ec.label}
																			</option>
																		  ))}
																		</select>
																	  </div>
																	  {formErrors.selectChef.length > 0 && (
																		<span className="text-danger">{formErrors.selectChef}</span>
																	  )}
																	</div>
																</div>
																
																<div className="col-sm-6">
															<div className="form-group">
															  <b>Chef*</b>
															  <div className="form-line">
																<select
																  className="form-control show-tick"
																 value={this.state.selectedChef}
																  name="selectChef"
																  onChange={(v) => {
																	const { name, value } = v.target;
																	if (
																	  value !== "-- Any --" ||
																	  value !== "0"
																	) {
																	  this.setState({
																		selectedChef: v.target.value,
																	  });
																	  formErrors.selectChef = "";
																	  this.setState({ formErrors, [name]: value });
																	} else {
																	  this.setState({
																		selectedChef: undefined,
																	  });
																	}
																  }}
																>
																  <option value={"0"}>-- Any --</option>
																  {this.state.chefArr.map((ec) => (
																	<option value={ec.value} key={ec.value}>
																	  {ec.label}
																	</option>
																  ))}
																</select>
															  </div>
															  {formErrors.selectChef.length > 0 && (
																<span className="text-danger">{formErrors.selectChef}</span>
															  )}
															</div>
														</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																		<b>User Name*</b>
																		<div className="form-line">
																			<input type="text" className="form-control" name="userName"
																				value={this.state.userName}
																				onChange={this.handleInputChange} />
																		</div>
																		{formErrors.userName.length > 0 && (
																			<span className="text-danger">{formErrors.userName}</span>
																		)}
																	</div>
																</div>
																
																<div className="col-sm-6">
																	<div className="form-group">
																		<b>Review*</b>
																		<div className="form-line">
																			<textarea
																				className="form-control"
																				rows={3}
																				name="review"
																				onChange={this.handleInputChange}
																				value={this.state.review}
																			></textarea>
																		</div>
																		{formErrors.review.length > 0 && (
																			<span className="text-danger">{formErrors.review}</span>
																		)}
																	</div>
																</div>
																
																<div className="col-sm-12">
																{this.state.ratingSectionError.length > 0 && (
																	<span className="text-danger">{this.state.ratingSectionError}</span>
																)}
																 </div>

                                                                <div className="col-sm-12">
                                                                    <Link to="/chef-review-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                                    <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}