import React from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class CrowdFavouritePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crowdFavouritesName: "",
      crowdFavouritesImage: "",
      crowdFavouritesImageName: "",
      crowdFavouritesSelected: "",
      resetFlag: false,
      errorBrandImage: "",
      dishList: [
        {
          id: 1,
          value: "Biryani",
        },
        {
          id: 2,
          value: "Pav-vaada",
        },
        {
          id: 3,
          value: "Misal-Pav",
        },
      ],
      formErrors: {
        crowdFavouritesName: "",
        crowdFavouritesImageName: "",
        crowdFavouritesSelected: "",
      },
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "crowdFavouritesName":
        formErrors.crowdFavouritesName =
          value === "" ? "Crowd Favourite Name is required" : "";
        break;

      case "crowdFavouritesImageName":
        formErrors.crowdFavouritesImageName =
          value === "" ? "Crowd Favourite Image is required" : "";
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  handleSelectedChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "crowdFavouritesSelected":
        formErrors.crowdFavouritesSelected =
          value === "" ? "Crowd Favourite Item is required" : "";
        break;
    }
    this.setState({ formErrors, [name]: e.target.value });
  };

  submitMarketing = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.crowdFavouritesName === "") {
      formErrors.crowdFavouritesName = "Crowd Favourite Name is required";
    } else {
      formErrors.crowdFavouritesName = "";
    }

    if (this.state.crowdFavouritesImageName === "") {
      formErrors.crowdFavouritesImageName = "Crowd Favourite Image is required";
    } else {
      formErrors.crowdFavouritesImageName = "";
    }

    if (this.state.crowdFavouritesSelected === "") {
      formErrors.crowdFavouritesSelected = "Crowd Favourite Item is required";
    } else {
      formErrors.crowdFavouritesSelected = "";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.setState({ resetFlag: true });
      /*  console.log(this.state.crowdFavouritesName);
            console.log(this.state.crowdFavouritesImage);
            console.log(this.state.crowdFavouritesImageName);
            console.log(this.state.crowdFavouritesSelected); */
    } else {
      console.log("error");
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState({
      resetFlag: false,
      crowdFavouritesName: "",
      crowdFavouritesImage: "",
      crowdFavouritesImageName: "",
      crowdFavouritesSelected: "",
    });
    window.location.reload();
  };

  onChangeMarketingPhoto = (event) => {
    /* console.log("event.target.files[0]", event.target.files[0]); */
    event.stopPropagation();
    event.preventDefault();
    let formErrors = this.state.formErrors;
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorBrandImage: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorBrandImage: "" });
    }
    /* console.log("file.....:", file); */
    this.setState({
      crowdFavouritesImage: event.target.files[0],
      crowdFavouritesImageName: event.target.files[0]["name"].slice(-20),
    });
    if (event.target.files[0]) {
      formErrors.errorBrandImage = "";
      formErrors.crowdFavouritesImageName = "";
    }
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Crowd Favourite</h2>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <b>Crowd Favourite Name</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="crowdFavouritesName"
                                  value={this.state.crowdFavouritesName}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                              <small className="text-danger">
                                {formErrors.crowdFavouritesName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <b>Upload your crowd favourite image</b>
                              <div className="fileUpload d-flex">
                                <button className="fileUploadBtn form-control d-inline-flex"></button>
                                <div className="d-inline-flex align-items-center form-control">
                                  <span className="file_text">
                                    Choose file
                                    {this.state.crowdFavouritesImageName !=
                                    "" ? (
                                      <small>
                                        {this.state.crowdFavouritesImageName}
                                      </small>
                                    ) : (
                                      <small>No file selected</small>
                                    )}
                                  </span>
                                  <input
                                    type="file"
                                    className="uploadlogo"
                                    name="crowdFavouritesImageName"
                                    onChange={this.onChangeMarketingPhoto}
                                  />
                                  <small className="text-danger">
                                    {formErrors.crowdFavouritesImageName}
                                  </small>
                                  <small className="text-danger">
                                    {this.state.errorBrandImage}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <b>Crowd Favourites Item</b>
                              <div className="form-line">
                                <select
                                  name="crowdFavouritesSelected"
                                  className="form-control"
                                  value={this.state.crowdFavouritesSelected}
                                  onChange={this.handleSelectedChange}
                                >
                                  <option value="">Select dish</option>
                                  {this.state.dishList.map((dish, i) => {
                                    return (
                                      <option value={dish.value} key={i}>
                                        {dish.value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <small className="text-danger">
                                {formErrors.crowdFavouritesSelected}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-12 center_btn">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitMarketing}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CrowdFavouritePage;
