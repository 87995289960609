import React from "react";
import { Link } from "react-router-dom";
import AdminMenuSection from './adminMenu';
import MarketingMenuSection from './marketingMenu';
import FinanceMenuSection from './financeMenu';
import OpsMenuSection from './opsMenu';

class SideBarSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmailID: "",
      userRole: "",
      submenuToggle: false,
    };
  }

  componentDidMount() {
    var name = localStorage.getItem("userName");
    var email = localStorage.getItem("userEmail");
    var role = localStorage.getItem("userRole");
    this.setState({
      userName: name,
      userEmailID: email,
      userRole: role,
    });
  }

  handleSubmenuToggle = () => {
    this.setState({
      submenuToggle: !this.state.submenuToggle,
    });
  };

  render() {
    return (
		<div>
			{/*  Admin menu */}
			{this.state.userRole == 'Admin' ? (
			  <AdminMenuSection />
			) : null}
			{/*  Ops menu */}
			{this.state.userRole == 'Ops' ? (
			  <OpsMenuSection />
			) : null}
			{/*  Marketing menu */}
			{this.state.userRole == 'Marketing' ? (
			  <MarketingMenuSection />
			) : null}
			{/*  Finance menu */}
			{this.state.userRole == 'Finance' ? (
			  <FinanceMenuSection />
			) : null}
		</div>
    );
  }
}

export default SideBarSection;
