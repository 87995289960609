/*
#############################################################################
# Name : AddTag.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to add new tags from the backend
#############################################################################
*/
import React, { Component } from 'react'
import Header from "../../components/Header";
import { addTagAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import Moment from 'moment';
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { NotificationManager } from "react-notifications";

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === null && (valid = false)
    })

    return valid;
}

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class AddTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagName: '',
            tagDescription: '',
            formErrors: {
                tagName: '',
                tagDescription: '',
            },
            buttonText: "Submit",
            buttonDisabled: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'tagName':
                formErrors.tagName = value.length <= 0 ? 'Tag name is required' : !value.match(/^[a-zA-Z- ]+$/) ? "Enter valid tag name" : "";
                break;

            case 'tagDescription':
                formErrors.tagDescription = value.length <= 0 ? 'Tag description is required' : "";
                break;

            default:
                break;
        }

        this.setState({ formErrors, [name]: value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        const { tagName, tagDescription } = this.state;
        if (tagName === "") {
            formErrors.tagName = "Tag name is required";
        }
        if (tagDescription === "") {
            formErrors.tagDescription = "Tag description is required";
        }
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state)) {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            this.setState({
                buttonText: "Submitting...",
                buttonDisabled: true
            })
            return addTagAPI(id, token, tagName, tagDescription)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success(
                            "Tag added successfully",
                            "Success",
                            3000
                        );
                        this.setState({
                            buttonText: "Submit",
                        })
                        setTimeout(() => {
                            this.props.history.push('/tag-list');
                        }, 2000)
                    } else {
                        NotificationManager.error(response.data["message"], "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error, "Error!");
                    this.setState({
                        buttonText: "Submit",
                        buttonDisabled: false
                    })
                });
        } else {
            console.log('error');
        }
    }
    render() {
        const { formErrors } = this.state;
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Add Tag</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <b>Tag Name*</b>
                                                        <div className="form-line">
                                                            <input type="text" className="form-control" name="tagName"
                                                                onChange={this.handleInputChange} />
                                                        </div>
                                                        {formErrors.tagName.length > 0 && (
                                                            <span className="text-danger">{formErrors.tagName}</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <b>Tag Description*</b>
                                                        <div className="form-line">
                                                            <textarea
                                                                className="form-control"
                                                                rows={3}
                                                                name="tagDescription"
                                                                onChange={this.handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                        {formErrors.tagDescription.length > 0 && (
                                                            <span className="text-danger">{formErrors.tagDescription}</span>
                                                        )}
                                                    </div>
                                                    <Link to="/tag-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                    <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
