import React, { Component } from "react";
import ReactDOM from "react-dom";
import Header from "../../components/Header";
import { addArticleAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class SeoAddArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      editorFinalData: "",
      tagTitle: "",
      articleTitle: "",
      isLoading: true,
      formErrors: {
        tagTitle: "",
        articleTitle: "",
      },
      buttonText: "Submit",
      buttonDisabled: false,
    };
  }

  componentDidMount() {
    /* this.getDishDetailAPI(); */
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());

        const markup = draftToHtml(rawContentState);
        /* console.log("New change: " + markup); */
        this.setState({
          editorFinalData: markup,
        });
      },
      100
    );
  };

  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "tagTitle":
        formErrors.tagTitle = value.length <= 0 ? "Tag  title is required" : "";
        break;

      case "articleTitle":
        formErrors.articleTitle =
          value.length <= 0 ? "Article  title is required" : "";
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { tagTitle, articleTitle, editorState } = this.state;
    if (tagTitle === "") {
      formErrors.tagTitle = "Tag title is required";
    }
    if (articleTitle === "") {
      formErrors.articleTitle = "Article title is required";
    }
    if (editorState === "") {
      formErrors.articleTitle = "Article is required";
    }
    this.setState({ formErrors, [name]: value });
    console.log(tagTitle, articleTitle, this.state.editorFinalData);
    if (formValid(this.state)) {
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });

      return addArticleAPI(
        id,
        token,
        tagTitle,
        articleTitle,
        this.state.editorFinalData
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Article added successfully",
              "Success",
              3000
            );
            this.setState({
              buttonText: "Submit",
            });
            setTimeout(() => {
              this.props.history.push("/seo-article-list");
            }, 2000);
          } else {
            NotificationManager.error(response.data["message"], "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!");
          this.setState({
            buttonText: "Submit",
            buttonDisabled: false,
          });
        });
    } else {
      console.log("error");
    }
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Add Article</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <b>Tag Title*</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={this.handleMenuInputChange}
                                  name="tagTitle"
                                  className="form-control"
                                  value={this.state.tagTitle}
                                />
                              </div>
                              {formErrors.tagTitle.length > 0 && (
                                <small className="text-danger">
                                  {formErrors.tagTitle}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <div className="form-group">
                              <b>Article Title*</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={this.handleMenuInputChange}
                                  name="articleTitle"
                                  className="form-control"
                                  value={this.state.articleTitle}
                                />
                              </div>
                              {formErrors.articleTitle.length > 0 && (
                                <small className="text-danger">
                                  {formErrors.articleTitle}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 editor">
                            <Editor
                              editorState={this.state.editorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={this.onEditorStateChange}
                              toolbar={{
                                options: [
                                  "inline",
                                  "link",
                                  "list",
                                  "fontSize",
                                  "fontFamily",
                                ],
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                              }}
                            />
                          </div>
                          <div className="col-sm-8">
                            <Link
                              to="/seo-article-list"
                              className="btn btn-default waves-effect"
                              style={{ color: "#000", marginRight: "10px" }}
                            >
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              disabled={this.state.buttonDisabled}
                              className="btn"
                              onClick={this.handleSubmit}
                            >
                              {this.state.buttonText}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
  editor: {
    border: "1px solid gray",
    minHeight: "6em",
  },
};

export default SeoAddArticle;
