/*
#############################################################################
# Name : KitchenTab.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display kitchen detail added by the chef from the frontend
#############################################################################
*/
import React, { Component } from "react";
import {
  getUserKitchenDetailAPI,
  getKitchenCuisineAPI,
  storeSpecialityDishAPI,
  removeSpecialityDishAPI,
  getRequirementAllergiesAPI,
  storeKitchenImageAPI,
  removeKitchenImageAPI,
  storeChefKitchenDetailsAPI,
} from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const kitchenFormValid = ({ kitchenFormErrors, ...rest }) => {
  let valid = true;

  Object.values(kitchenFormErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Object.values(kitchenFormErrors).forEach((val) => {
  //   val === "form-control" || (valid = false);
  // });

  return valid;
};

export default class KitchenTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: '',
      userName: '',
      userEmailID: '',
      cookingQualifications: "",
      cuisinesYouServe: [],
      specialDishes: [],
      specialDishesImage: [],
      kitchenType: "",
      kitchenImages: [],
      kitchenDescription: "",
      auditImages: [],
      isHalala: "",
      isOrganic: "",
      isPackagingRecyclable: "",
      disposableCutlery: "",
      kitchenMaterial: [],
      kitchenItem: [],
      kitchenRequirement: [],
      cuisineArr: [],
      requirements: [],
      selectedCuisines: [],
      displayAddDishForm: false,
      newSpecialDishName: "",
      newSpecialDishImage: null,
      newDishNameErr: "",
      newDishImageErr: "",
      dishBtnText: "Add Dish",
      dishBtnDisabled: false,
      selectedRequirements: [],
      jainVegSelected: false,
      pureVegSelected: false,
      eggVegSelected: false,
      nonVegSelected: false,
      jainVegDisabled: false,
      pureVegDisabled: false,
      eggVegDisabled: false,
      nonVegDisabled: false,
      newKitchenImage: null,
      newAuditImage: null,
      suitable_for_jain_veg: 0,
      suitable_for_pure_veg: 0,
      suitable_for_veg_with_egg: 0,
      suitable_for_non_veg: 0,
      newKitchenImageErr: "",
      auditImageErr: "",
      displayKitchenImageForm: false,
      imageBtnText: "Add Image",
      imageBtnDisabled: false,
      kitchenId: "",
      selectedMaterials: [],
      selectedCookingMaterials: [],
      buttonText: "Update Kitchen Details",
      buttonDisabled: false,
      kitchenFormErrors: {
        kitchenDescription: "",
        isHalala: "",
      },
      cuisinesErr: "",
      specialDishErr: "",
      kitchenTypeErr: "",
      allMaterialIncluded: false,
      allCookingIncluded: false,
      enablediscount: "0",
      discounttype: "In Amount",
      discounterror: "",
      discountenddateerror: "",
      discountdates: "",
      discount: "0",
	  discountstartdate: new Date(new Date().setDate(new Date().getDate())),
	  discountenddate: new Date(new Date().setDate(new Date().getDate())),
      currentDate: new Date(new Date().setDate(new Date().getDate())),
    };
  }
  handleSuitableCheckboxInputChange = (e) => {
    if (e.target.value === "Jain Veg") {
      if (e.target.checked) {
        this.setState({
          nonVegDisabled: true,
          eggVegDisabled: true,
          eggVegSelected: false,
          nonVegSelected: false,
          jainVegSelected: true,
          suitable_for_jain_veg: "1",
          kitchenTypeErr: "",
        });
      } else {
        if (this.state.pureVegSelected === false) {
          this.setState({
            nonVegDisabled: false,
            eggVegDisabled: false,
          });
        }
        this.setState({
          suitable_for_jain_veg: "0",
          jainVegSelected: false,
        });
      }
    }

    if (e.target.value === "Pure Veg") {
      if (e.target.checked) {
        this.setState({
          nonVegDisabled: true,
          eggVegDisabled: true,
          eggVegSelected: false,
          nonVegSelected: false,
          pureVegSelected: true,
          suitable_for_pure_veg: "1",
          kitchenTypeErr: "",
        });
      } else {
        if (this.state.jainVegSelected === false) {
          this.setState({
            nonVegDisabled: false,
            eggVegDisabled: false,
          });
        }
        this.setState({
          suitable_for_pure_veg: "0",
          pureVegSelected: false,
        });
      }
    }

    if (e.target.value === "Veg") {
      if (e.target.checked) {
        this.setState({
          jainVegDisabled: true,
          pureVegDisabled: true,
          jainVegSelected: false,
          pureVegSelected: false,
          eggVegSelected: true,
          suitable_for_veg_with_egg: "1",
          kitchenTypeErr: "",
        });
      } else {
        if (this.state.nonVegSelected === false) {
          this.setState({
            jainVegDisabled: false,
            pureVegDisabled: false,
          });
        }
        this.setState({
          suitable_for_veg_with_egg: "0",
          eggVegSelected: false,
        });
      }
    }

    if (e.target.value === "Non-Veg") {
      if (e.target.checked) {
        this.setState({
          jainVegDisabled: true,
          pureVegDisabled: true,
          jainVegSelected: false,
          pureVegSelected: false,
          nonVegSelected: true,
          suitable_for_non_veg: "1",
          kitchenTypeErr: "",
        });
      } else {
        if (this.state.eggVegSelected === false) {
          this.setState({
            jainVegDisabled: false,
            pureVegDisabled: false,
          });
        }
        this.setState({
          suitable_for_non_veg: "0",
          nonVegSelected: false,
        });
      }
    }
    /* setTimeout(() => {
            console.log('suitable_for_jain_veg', this.state.suitable_for_jain_veg)
            console.log('suitable_for_pure_veg', this.state.suitable_for_pure_veg)
            console.log('suitable_for_veg_with_egg', this.state.suitable_for_veg_with_egg)
            console.log('suitable_for_non_veg', this.state.suitable_for_non_veg)
        }, 500) */
  };
  
  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { menuModalTitle } = this.state;

    this.setState({ formErrors, [name]: value });
  };
  
  handleCheckboxInputChange(e, type) {
    switch (type) {
      case "material":
        if (e.target.checked) {
          let array = this.state.selectedMaterials;
          array.push(e.target.value);
          this.setState({
            selectedMaterials: array,
          });
          if (array.length === this.props.kitchenMatArr.length) {
            this.setState({
              allMaterialIncluded: true,
            });
          }
        } else {
          let array = this.state.selectedMaterials.filter(
            (elm) => elm !== e.target.value
          );
          this.setState({
            selectedMaterials: array,
            allMaterialIncluded: false,
          });
        }
        break;

      case "requirement":
        if (e.target.checked) {
          let array = this.state.selectedRequirements;
          array.push(e.target.value);
          this.setState({
            selectedRequirements: array,
          });
        } else {
          let array = this.state.selectedRequirements.filter(
            (elm) => elm !== e.target.value
          );
          this.setState({
            selectedRequirements: array,
          });
        }
        break;

      case "cooking":
        if (e.target.checked) {
          let array = this.state.selectedCookingMaterials;
          array.push(e.target.value);
          this.setState({
            selectedCookingMaterials: array,
          });
          if (array.length === this.props.cookingMatArr.length) {
            this.setState({
              allCookingIncluded: true,
            });
          }
        } else {
          let array = this.state.selectedCookingMaterials.filter(
            (elm) => elm !== e.target.value
          );
          this.setState({
            selectedCookingMaterials: array,
            allCookingIncluded: false,
          });
        }
        break;
    }
  }
  handleCheckboxAllInputChange = (e, type) => {
    switch (type) {
      case "material":
        if (e.target.checked) {
          let array = [];
          this.props.kitchenMatArr.forEach((item) => {
            array.push(item.id);
          });
          this.setState({
            selectedMaterials: array,
            allMaterialIncluded: true,
          });
        } else {
          this.setState({
            selectedMaterials: [],
            allMaterialIncluded: false,
          });
        }
        break;

      case "cooking":
        if (e.target.checked) {
          let array = [];
          this.props.cookingMatArr.forEach((item) => {
            array.push(item.id);
          });
          this.setState({
            selectedCookingMaterials: array,
            allCookingIncluded: true,
          });
        } else {
          this.setState({
            selectedCookingMaterials: [],
            allCookingIncluded: false,
          });
        }
        break;
    }
  };
  componentDidMount() {
	var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
    this.getKitchenData();
    this.getKitchenCuisineAPI();
    this.getRequirementAllergiesAPI();
  }
  onSelectCuisine = (selectedList, selectedItem) => {
    this.setState({
      selectedCuisines: selectedList,
      cuisinesErr: "",
    });
  };

  onRemoveCuisine = (selectedList, removedItem) => {
    this.setState({
      selectedCuisines: selectedList,
    });
    if (!selectedList.length) {
      this.setState({
        cuisinesErr: "",
      });
    }
  };
  getKitchenCuisineAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getKitchenCuisineAPI(id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.length) {
            let array = [];
            response.data.data.map((item) => {
              array.push({ id: item.id, name: item.cuisine_name });
            });
            this.setState({
              cuisineArr: array,
            });
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
      });
  };
  getKitchenData() {
    var userId = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    return getUserKitchenDetailAPI(userId, chefId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            kitchenId: response.data["data"]["kitchen_id"],
            cookingQualifications:
              response.data["data"]["cooking_qualifications"],
            // cuisinesYouServe: response.data["data"]["cuisines"],
            specialDishes: response.data["data"]["special_dish"],
            kitchenType: response.data["data"]["kitchen_type"],
            kitchenImages: response.data["data"]["kitchen_images"],
            kitchenDescription: response.data["data"]["kitchen_description"],
            isHalala: response.data["data"]["non_veg_is_halal"],
            isOrganic: response.data["data"]["certified_organic_use"],
            isPackagingRecyclable: response.data["data"]["packaging_recylable"],
            discountstartdate: response.data["data"]["discountstartdate"],
            discountenddate: response.data["data"]["discountenddate"],
            discount: response.data["data"]["discount"],
            discounttype: response.data["data"]["discounttype"],
            enablediscount: response.data["data"]["enablediscount"],
            disposableCutlery:
              response.data["data"]["provide_dispoable_cutlery"],
            kitchenItem: response.data["data"]["kitchen_cooking_medium"],
            // kitchenMaterial: response.data["data"]["kitchen_material"],
            // kitchenRequirement:
            //     response.data["data"]["kitchen_requirements_and_allergies"],
            // jainVegSelected: response.data["data"]["suitable_for_jain_veg"] == 1 ? true : false,
            // nonVegSelected: response.data["data"]["suitable_for_non_veg"] == 1 ? true : false,
            // eggVegDisabled: response.data["data"]["suitable_for_veg_with_egg"] == 1 ? true : false,
            // pureSelected: response.data["data"]["suitable_for_pure_veg"] == 1 ? true : false,
          });
		  if (
            response.data["data"]["enablediscount"] == "0"
          ) {
			this.setState({
              discount: '0',
              discountstartdate: '',
              discountenddate: '',
              discounttype: 'In Amount',
              enablediscount: '0',
            });  
		  }
          if (
            response.data["data"]["suitable_for_jain_veg"] == "1" ||
            response.data["data"]["suitable_for_pure_veg"] == "1"
          ) {
            this.setState({
              jainVegSelected:
                response.data["data"]["suitable_for_jain_veg"] == "1"
                  ? true
                  : false,
              pureVegSelected:
                response.data["data"]["suitable_for_pure_veg"] == "1"
                  ? true
                  : false,
              suitable_for_jain_veg:
                response.data["data"]["suitable_for_jain_veg"],
              suitable_for_pure_veg:
                response.data["data"]["suitable_for_pure_veg"],
              nonVegSelected: false,
              eggVegSelected: false,
              eggVegDisabled: true,
              nonVegDisabled: true,
            });
          }
          if (
            response.data["data"]["suitable_for_non_veg"] == "1" ||
            response.data["data"]["suitable_for_veg_with_egg"] == "1"
          ) {
            this.setState({
              jainVegSelected: false,
              pureVegSelected: false,
              nonVegSelected:
                response.data["data"]["suitable_for_non_veg"] == "1"
                  ? true
                  : false,
              eggVegSelected:
                response.data["data"]["suitable_for_veg_with_egg"] == "1"
                  ? true
                  : false,
              suitable_for_veg_with_egg:
                response.data["data"]["suitable_for_veg_with_egg"],
              suitable_for_non_veg:
                response.data["data"]["suitable_for_non_veg"],
              pureVegDisabled: true,
              jainVegDisabled: true,
            });
          }
          if (response.data.data.cuisines.length) {
            let array = [];
            response.data.data.cuisines.forEach((item) => {
              array.push({ id: item.cuisine_id, name: item.cuisine_name });
            });
            this.setState({
              selectedCuisines: array,
            });
          }
          if (response.data.data.kitchen_material.length) {
            let array = [];
            response.data.data.kitchen_material.forEach((item) => {
              array.push(item.material_id);
            });
            this.setState({
              selectedMaterials: array,
            });
            setTimeout(() => {
              if (array.length == this.props.kitchenMatArr.length) {
                this.setState({
                  allMaterialIncluded: true,
                });
              }
            }, 500);
          }
          if (response.data.data.kitchen_cooking_medium.length) {
            let array = [];
            response.data.data.kitchen_cooking_medium.forEach((item) => {
              array.push(item.material_id);
            });
            this.setState({
              selectedCookingMaterials: array,
            });
            setTimeout(() => {
              if (array.length == this.props.cookingMatArr.length) {
                this.setState({
                  allCookingIncluded: true,
                });
              }
            }, 500);
          }
          if (response.data.data.kitchen_requirements_and_allergies.length) {
            let array = [];
            response.data.data.kitchen_requirements_and_allergies.forEach(
              (item) => {
                array.push(item.req_id);
                this.setState({
                  selectedRequirements: array,
                });
              }
            );
          }
          setTimeout(() => {
            this.state.specialDishes.map((item) => {
              this.setState({
                specialDishesImage: item.dish_images,
              });
            });
          }, 100);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getRequirementAllergiesAPI = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getRequirementAllergiesAPI(userId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            requirements: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onDishNameChange = (e) => {
    this.setState({
      newDishNameErr: !e.target.value.length ? "Please enter dish name" : "",
      newSpecialDishName: e.target.value,
    });
  };
  onDishImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //   menuImage: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("gif")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            newDishImageErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadFile").value = "";
        } else {
          this.setState({
            newDishImageErr: "",
            newSpecialDishImage: e.target.files[0],
          });
        }
      } else {
        this.setState({
          newDishImageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png and .gif",
        });
        document.getElementById("uploadFile").value = "";
      }
    } else {
      this.setState({
        newSpecialDishImage: null,
        /* newDishImageErr: "Image is required" */
      });
    }
  };
  onKitchenImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //   menuImage: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("gif")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            newKitchenImageErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadFile").value = "";
        } else {
          this.setState({
            newKitchenImageErr: "",
            newKitchenImage: e.target.files[0],
          });
        }
      } else {
        this.setState({
          newKitchenImageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png and .gif",
        });
        document.getElementById("uploadFile").value = "";
      }
    } else {
      this.setState({
        newKitchenImage: null,
        newKitchenImageErr: "Image is required",
      });
    }
  };

  onAuditImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //   menuImage: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("gif")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            auditImageErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadAuditFile").value = "";
        } else {
          this.setState(
            {
              auditImageErr: "",
              newAuditImage: e.target.files[0],
            },
            () => {
              console.log(this.state.newAuditImage);
            }
          );
        }
      } else {
        this.setState({
          auditImageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png and .gif",
        });
        document.getElementById("uploadAuditFile").value = "";
      }
    } else {
      this.setState({
        newAuditImage: null,
        auditImageErr: "Image is required",
      });
    }
  };

  removeSpecialityDishAPI(dish) {
    var id = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    return removeSpecialityDishAPI(id, chefId, token, dish.dish_name)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.special_dish.length) {
            this.setState({
              specialDishes: response.data.data.special_dish,
            });
          } else {
            this.setState({
              specialDishes: [],
            });
          }
          NotificationManager.success(
            "Special dish deleted successfully",
            "Success"
          );
        } else {
          NotificationManager.error(response.data["message"], "Error!");
          this.setState({
            dishBtnText: "Add Dish",
            dishBtnDisabled: true,
          });
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
        this.setState({
          dishBtnText: "Add Dish",
          dishBtnDisabled: true,
        });
      });
  }
  storeSpecialityDishAPI = () => {
    if (this.state.newSpecialDishName === "") {
      this.setState({
        newDishNameErr: "Please enter dish name",
      });
    }
    /* if (this.state.newSpecialDishImage === null || this.state.newSpecialDishImage === "") {
            this.setState({
                newDishImageErr: "Image is required"
            })
        } */
    setTimeout(() => {
      if (
        this.state.newDishNameErr === "" &&
        this.state.newDishImageErr === ""
      ) {
        var id = localStorage.getItem("userID");
        var chefId = localStorage.getItem("chefID");
        var token = localStorage.getItem("accessToken");
        let data = {
          special_dish_name: this.state.newSpecialDishName,
          special_dish_images: this.state.newSpecialDishImage,
        };
        this.setState({
          dishBtnText: "Adding Dish...",
          dishBtnDisabled: true,
        });
        return storeSpecialityDishAPI(id, chefId, token, data)
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data.data.special_dish.length) {
                this.setState({
                  specialDishErr: "",
                  specialDishes: response.data.data.special_dish,
                  displayAddDishForm: false,
                  dishBtnText: "Add Dish",
                  dishBtnDisabled: false,
                  newSpecialDishName: "",
                  newSpecialDishImage: null,
                });
              }
              NotificationManager.success(
                "Special dish added successfully",
                "Success"
              );
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                dishBtnText: "Add Dish",
                dishBtnDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              dishBtnText: "Add Dish",
              dishBtnDisabled: false,
            });
          });
      }
    }, 100);
  };

  storeKitchenImageAPI = () => {
    if (
      this.state.newKitchenImage === null ||
      this.state.newKitchenImage === ""
    ) {
      this.setState({
        newKitchenImageErr: "Image is required",
      });
    }
    setTimeout(() => {
      if (this.state.newKitchenImageErr === "") {
        var id = localStorage.getItem("userID");
        var chefId = localStorage.getItem("chefID");
        var token = localStorage.getItem("accessToken");
        this.setState({
          imageBtnText: "Adding Image...",
          imageBtnDisabled: true,
        });
        let data = {
          kitchen_id: this.state.kitchenId,
          kitchen_images: this.state.newKitchenImage,
        };
        return storeKitchenImageAPI(id, chefId, token, data)
          .then((response) => {
            if (response.data.status === 200) {
              /*  console.log(response.data) */
              this.setState({
                kitchenImages: response.data.data.kitchen_images,
                displayKitchenImageForm: false,
                imageBtnText: "Add Image",
                imageBtnDisabled: false,
                newKitchenImage: null,
              });
              NotificationManager.success(
                "Kitchen image added successfully",
                "Success"
              );
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                dishBtnText: "Add Dish",
                dishBtnDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              dishBtnText: "Add Dish",
              dishBtnDisabled: false,
            });
          });
      }
    }, 100);
  };
  removeKitchenImageAPI = (imageId) => {
    var id = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    return removeKitchenImageAPI(id, chefId, token, imageId)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.kitchen_images.length) {
            this.setState({
              kitchenImages: response.data.data.kitchen_images,
            });
          } else {
            this.setState({
              kitchenImages: [],
            });
          }
          NotificationManager.success(
            "Kitchen image deleted successfully",
            "Success"
          );
        } else {
          NotificationManager.error(response.data["message"], "Error!");
          this.setState({
            dishBtnText: "Add Dish",
            dishBtnDisabled: true,
          });
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
        this.setState({
          dishBtnText: "Add Dish",
          dishBtnDisabled: true,
        });
      });
  };

  handleCatringCheckboxInputChange = (e) => {
    if (e.target.checked) {
      let array = this.state.selectedRequirements;
      array.push(e.target.value);
      this.setState({
        selectedRequirements: array,
      });
    } else {
      let array = this.state.selectedRequirements.filter(
        (elm) => elm !== e.target.value
      );
      this.setState({
        selectedRequirements: array,
      });
    }
    // setTimeout(()=>{
    //     console.log(this.state.selectedRequirements)
    // },100)
  };

  handleKitchenInputChange = (e) => {
    const { name, value } = e.target;
    let kitchenFormErrors = this.state.kitchenFormErrors;

    switch (
      name
      // case 'kitchenDescription':
      //     kitchenFormErrors.kitchenDescription = value.length <= 0 ? 'This is required' : "";
      //     break;

      // case 'isHalala':
      //     kitchenFormErrors.isHalala = value.length <= 0 ? 'This is required' : "";
      //     break;
    ) {
    }

    this.setState({ kitchenFormErrors, [name]: value });
  };
  
  handleBusinessInputChange = (e) => {
    const { name, value } = e.target;
    let businessFormErrors = this.state.businessFormErrors;
    switch (name) {
	  case "enablediscount":
        if (
          !isNaN(this.state.enablediscount) &&
          Number(this.state.enablediscount) > 0
        ) {
          this.setState({ enablediscount: "0" });
        }
      default:
        break;
    }

    this.setState({ businessFormErrors, [name]: value });
  };

  submitKitchenForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let kitchenFormErrors = this.state.kitchenFormErrors;
    const { kitchenDescription, isHalala } = this.state;

    // if (kitchenDescription === "" || kitchenDescription === null) {
    //     kitchenFormErrors.kitchenDescription = "This is required";
    // }
    // if (isHalala === "" || isHalala === null) {
    //     kitchenFormErrors.isHalala = "This is required";
    // }
    // if (!this.state.selectedCuisines.length) {
    //     this.state.cuisinesErr = "This is required."
    // }
    // if (!this.state.specialDishes.length) {
    //     this.setState({
    //         specialDishErr: "This is required"
    //     })
    // }
    // if (
    //     !this.state.jainVegSelected &&
    //     !this.state.pureVegSelected &&
    //     !this.state.eggVegSelected &&
    //     !this.state.nonVegSelected
    // ) {
    //     this.setState({
    //         kitchenTypeErr: "This is required."
    //     })
    // }
	this.setState({
		discountdates : ''
	});
	this.setState({
		 discounterror: "",
		 discountenddateerror: ""
	});
	if(this.state.enablediscount == '1'){
		if(this.state.discount == '' || parseFloat(this.state.discount) <= 0){
			this.setState({
				 discounterror: "This is required and enter valid discount."
			});
			return false;
		}
		
		//if(this.state.discounttype == 'In Percentage'){
			if( parseFloat(this.state.discount) > 100){
				this.setState({
					 discounterror: "The discount should be less then 100%."
				});
				return false;
			}
		//}
		
		if(this.state.discountstartdate == ''){
			this.setState({
				 discountstartdateerror: "This is required."
			});
			return false;
		}else{
			var disdate = this.state.discountstartdate;
			/* this.setState({
				discountdates : `${disdate.getFullYear()}-${disdate.getMonth() + 1}-${disdate.getDate()}`
			}); */
		}
		
		if(this.state.discountenddate == ''){
			this.setState({
				 discountenddateerror: "This is required."
			});
			return false;
		}else{
			var disdate = this.state.discountenddate;
			/* this.setState({
				discountdates : `${disdate.getFullYear()}-${disdate.getMonth() + 1}-${disdate.getDate()}`
			}); */
		}
		
		if(this.state.discountenddate < this.state.discountstartdate){
			this.setState({
				 discountenddateerror: "The discount end date should be greater than start date."
			});
			return false;
		}
		
	}

    this.setState({ kitchenFormErrors, [name]: value });

    setTimeout(() => {
      if (
        kitchenFormValid(this.state) &&
        this.state.cuisinesErr === "" &&
        this.state.newDishNameErr === "" &&
        this.state.newDishImageErr === "" &&
        this.state.newKitchenImageErr === "" &&
        this.state.specialDishErr === "" &&
        this.state.kitchenTypeErr === ""
      ) {
        var id = localStorage.getItem("userID");
        var chefId = localStorage.getItem("chefID");
        var token = localStorage.getItem("accessToken");
        this.setState({
          buttonText: "Updating...",
          buttonDisabled: true,
        });

        let selectedCuisineIds = [];
        /* console.log(this.state.selectedCuisines) */
        this.state.selectedCuisines.forEach((item) => {
          selectedCuisineIds.push(item.name);
        });

        let data = {
          kitchen_id: this.state.kitchenId,
          cooking_qualifications: this.state.cookingQualifications,
          cuisines: selectedCuisineIds.toString(),
          kitchen_description: this.state.kitchenDescription,
          kitchen_type: this.state.kitchenType,
          non_veg_is_halal: this.state.isHalala,
          certified_organic_use: this.state.isOrganic,
          packaging_recylable: this.state.isPackagingRecyclable,
          provide_dispoable_cutlery: this.state.disposableCutlery,
          kitchen_material: this.state.selectedMaterials.toString(),
          kitchen_requirements_and_allergies:
            this.state.selectedRequirements.toString(),
          kitchen_cooking_medium:
            this.state.selectedCookingMaterials.toString(),
          suitable_for_jain_veg: this.state.suitable_for_jain_veg,
          suitable_for_non_veg: this.state.suitable_for_non_veg,
          suitable_for_pure_veg: this.state.suitable_for_pure_veg,
          suitable_for_veg_with_egg: this.state.suitable_for_veg_with_egg,
		  discountstartdate: this.state.discountstartdate,
		  discountenddate: this.state.discountenddate,
		  discount: this.state.discount,
          discounttype: this.state.discounttype,
          enablediscount: this.state.enablediscount,
        };

        return storeChefKitchenDetailsAPI(id, chefId, token, data)
          .then((response) => {
            if (response.data.status === 200) {
              NotificationManager.success(
                "Kithen details updated successfully",
                "Success"
              );
              this.setState({
                buttonText: "Update Kitchen Details",
                buttonDisabled: false,
              });
              this.getKitchenData();
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                buttonText: "Update Kitchen Details",
                buttonDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              buttonText: "Update Kitchen Details",
              buttonDisabled: false,
            });
          });
      } else {
        NotificationManager.error(
          "Please see if the required fields are entered",
          "Error",
          3000
        );
      }
    }, 200);
  };
  render() {
    const { kitchenFormErrors } = this.state;
    return (
      <div
        className="panel-group"
        id="accordion_19"
        role="tablist"
        aria-multiselectable="true"
      >
        <form onSubmit={this.submitKitchenForm}>
          <div className="panel">
            <div className="panel-heading" role="tab" id="headingOne_19">
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion_19"
                  href="#collapseOne_19"
                  aria-expanded="false"
                  aria-controls="collapseOne_19"
                  className="collapsed"
                >
                  Cuisines you serve{" "}
                  <i className="material-icons float-right">arrow_drop_down</i>
                </a>
              </h4>
            </div>
            <div
              id="collapseOne_19"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingOne_19"
              aria-expanded="false"
              style={{ height: "0px" }}
            >
              <div className="panel-body aniimated-thumbnials">
                <div className="card">
                  <div className="body">
                    <div className="row clearfix aniimated-thumbnials">
                      <div className="col-md-6">
                        <div className="form-group">
                          <b>Any cooking-related qualifications?</b>
                          <div className="form-line">
                            <input
                              type="text"
                              placeholder=""
                              defaultValue={this.state.cookingQualifications}
                              onChange={this.handleKitchenInputChange}
                              name="cookingQualifications"
                              className="form-control"
                            />
                          </div>
                          {/* {formErrors.menuName.length > 0 && (
                                                    <small className="text-danger">{formErrors.menuName}</small>
                                                )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <b>Which cuisines do you serve?</b>
                        <div className="input-group">
                          <Multiselect
                            options={this.state.cuisineArr} // Options to display in the dropdown
                            selectedValues={this.state.selectedCuisines} // Preselected value to persist in dropdown
                            onSelect={this.onSelectCuisine} // Function will trigger on select event
                            onRemove={this.onRemoveCuisine} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                          {this.state.cuisinesErr.length > 0 && (
                            <small className="text-danger">
                              {this.state.cuisinesErr}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <b>What are your speciality dishes?</b>
                          <div className="input-group">
                            <div className="input-group upload_img_one two new_img_wrap">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th width="15%" className="text-center">
                                      Sr. No.
                                    </th>
                                    <th width="55%" className="text-center">
                                      Dish Name
                                    </th>
                                    <th width="20%" className="text-center">
                                      Image
                                    </th>
                                    <th
                                      width="10%"
                                      className="text-center"
                                    ></th>
                                  </tr>
                                </thead>
                                {this.state.specialDishes.length ? (
                                  <tbody>
                                    {this.state.specialDishes.map((item, i) => {
                                      return (
                                        <tr key={"ghd" + i}>
                                          <td className="text-center">
                                            {i + 1}
                                          </td>
                                          <td>{item.dish_name}</td>
                                          <td className="text-center">
                                            {item.dish_images.length === 0 ? (
                                              <a
                                                href="#"
                                                data-sub-html="Profile Image"
                                              >
                                                <img
                                                  style={{
                                                    width: "60px",
                                                    height: "60px",
                                                  }}
                                                  className="img-responsive thumbnail height_responsive"
                                                  src="assets/images/noimage.png"
                                                />
                                              </a>
                                            ) : (
                                              item.dish_images.map(
                                                (images, j) => {
                                                  return (
                                                    <a
                                                      key={"f" + j}
                                                      target="_blank"
                                                      href={images.image_path}
                                                      data-sub-html="Profile Image"
                                                    >
                                                      <img
                                                        className="img-responsive thumbnail height_responsive"
                                                        src={images.image_path}
                                                      />
                                                    </a>
                                                  );
                                                }
                                              )
                                            )}
                                          </td>
                                          <td className="text-center">
											{/*  Admin and Ops menu */}
											{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                            <i
                                              className="material-icons view_link"
                                              onClick={() =>
                                                this.removeSpecialityDishAPI(
                                                  item
                                                )
                                              }
                                            >
                                              delete
                                            </i>
											) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className="text-center" colSpan="4">
                                        No dish added
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                                
                              </table>
                              {this.state.specialDishErr.length > 0 && (
                                <span
                                  className="text-danger"
                                  style={{ marginBottom: "15px" }}
                                >
                                  {this.state.specialDishErr}
                                  <br />
                                </span>
                              )}

                              {this.state.displayAddDishForm ? (
                                <div>
                                  <div className="form-group">
                                    <div className="form-line">
                                      <b>Dish Name*</b>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="newDishNameErr"
                                        onChange={this.onDishNameChange}
                                      />
                                    </div>
                                    {this.state.newDishNameErr.length > 0 && (
                                      <small className="text-danger">
                                        {this.state.newDishNameErr}
                                      </small>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <b>Upload dish Image*</b>
                                    <div className="form-line">
                                      <input
                                        id="uploadFile"
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .gif"
                                        className="form-control"
                                        name="newSpecialDishImage"
                                        onChange={this.onDishImageChange}
                                      />
                                    </div>
                                    <p>
                                      (Image to be uploaded: 186 x 186 pixels)
                                    </p>
                                    {this.state.newDishImageErr.length > 0 && (
                                      <small className="text-danger">
                                        {this.state.newDishImageErr}
                                      </small>
                                    )}
                                  </div>
                                  <button
                                    className="btn btn-default waves-effect"
                                    style={{
                                      color: "#000",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        displayAddDishForm: false,
                                        newSpecialDishName: "",
                                        newSpecialDishImage: null,
                                        newDishNameErr: "",
                                        newDishImageErr: "",
                                      });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn"
                                    type="button"
                                    disabled={this.state.dishBtnDisabled}
                                    onClick={this.storeSpecialityDishAPI}
                                  >
                                    {this.state.dishBtnText}
                                  </button>
                                </div>
                              ) : 
								(this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ?  <button
                                  className="btn"
                                  onClick={() =>
                                    this.setState({
                                      displayAddDishForm: true,
                                    })
                                  }
                                >
                                  Add more dish
                                </button> : null )
                              }
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <b>
                              Describe yourself, your cooking, what inspires you
                              and your motivation
                            </b>
                            <div className="form-line">
                              <textarea
                                placeholder=""
                                defaultValue={this.state.kitchenDescription}
                                onChange={this.handleKitchenInputChange}
                                name="kitchenDescription"
                                className="form-control"
                              />
                            </div>
                            {kitchenFormErrors.kitchenDescription.length >
                              0 && (
                              <small className="text-danger">
                                {kitchenFormErrors.kitchenDescription}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel">
            <div className="panel-heading" role="tab" id="headingTwo_19">
              <h4 className="panel-title">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion_19"
                  href="#collapseTwo_19"
                  aria-expanded="false"
                  aria-controls="collapseTwo_19"
                >
                  Kitchen Details
                  <i className="material-icons float-right">arrow_drop_down</i>
                </a>
              </h4>
            </div>
            <div
              id="collapseTwo_19"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTwo_19"
              aria-expanded="false"
              style={{ height: "0px" }}
            >
              <div className="panel-body">
                <div className="card">
                  <div className="body">
                    <div className="row clearfix aniimated-thumbnials">
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <b>Type of Kitchen</b>
                            <div>
                              <input
                                name="kitchenType"
                                type="radio"
                                id="kitchen_1"
                                className="radio-col-orange"
                                value="Home"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.kitchenType === "Home"}
                              />
                              <label htmlFor="kitchen_1">Home</label>
                              <input
                                name="kitchenType"
                                type="radio"
                                id="kitchen_2"
                                className="radio-col-orange"
                                value="Commercial"
                                onChange={this.handleKitchenInputChange}
                                checked={
                                  this.state.kitchenType === "Commercial"
                                }
                              />
                              <label htmlFor="kitchen_2">Commercial</label>
                              <input
                                name="kitchenType"
                                type="radio"
                                id="kitchen_3"
                                className="radio-col-orange"
                                value="Off-Site"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.kitchenType === "Off-Site"}
                              />
                              <label htmlFor="kitchen_3">Offsite</label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <b>Is your non veg Halal?</b>
                            <div>
                              <input
                                name="isHalala"
                                type="radio"
                                id="halal_1"
                                className="radio-col-orange"
                                value="Yes"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.isHalala === "Yes"}
                              />
                              <label htmlFor="halal_1">Yes</label>
                              <input
                                name="isHalala"
                                type="radio"
                                id="halal_2"
                                className="radio-col-orange"
                                value="No"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.isHalala === "No"}
                              />
                              <label htmlFor="halal_2">No</label>
                              <input
                                name="isHalala"
                                type="radio"
                                id="halal_3"
                                className="radio-col-orange"
                                value="Not Sure"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.isHalala === "Not Sure"}
                              />
                              <label htmlFor="halal_3">Not Sure</label>
                              <input
                                name="isHalala"
                                type="radio"
                                id="halal_4"
                                className="radio-col-orange"
                                value="Only Serve Vegetarian"
                                onChange={this.handleKitchenInputChange}
                                checked={
                                  this.state.isHalala ===
                                  "Only Serve Vegetarian"
                                }
                              />
                              <label htmlFor="halal_4">Only Serve Vegetarian</label>
                            </div>
                            {kitchenFormErrors.isHalala.length > 0 && (
                              <small className="text-danger">
                                {kitchenFormErrors.isHalala}
                              </small>
                            )}
                          </div>
                          <div className="col-lg-12">
                            <b>Is the produce you use certified Organic?</b>
                            <div>
                              <input
                                name="isOrganic"
                                type="radio"
                                id="organic_1"
                                className="radio-col-orange"
                                value="Yes"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.isOrganic === "Yes"}
                              />
                              <label htmlFor="organic_1">Yes</label>
                              <input
                                name="isOrganic"
                                type="radio"
                                id="organic_2"
                                className="radio-col-orange"
                                value="No"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.isOrganic === "No"}
                              />
                              <label htmlFor="organic_2">No</label>
                              <input
                                name="isOrganic"
                                type="radio"
                                id="organic_3"
                                className="radio-col-orange"
                                value="Not Sure"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.isOrganic === "Not Sure"}
                              />
                              <label htmlFor="organic_3">Not Sure</label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <b>Is all your packaging recyclable?</b>
                            <div>
                              <input
                                name="isPackagingRecyclable"
                                type="radio"
                                id="recycle_1"
                                className="radio-col-orange"
                                value="Yes"
                                onChange={this.handleKitchenInputChange}
                                checked={
                                  this.state.isPackagingRecyclable === "Yes"
                                }
                              />
                              <label htmlFor="recycle_1">Yes</label>
                              <input
                                name="isPackagingRecyclable"
                                type="radio"
                                id="recycle_2"
                                className="radio-col-orange"
                                value="No"
                                onChange={this.handleKitchenInputChange}
                                checked={
                                  this.state.isPackagingRecyclable === "No"
                                }
                              />
                              <label htmlFor="recycle_2">No</label>
                              <input
                                name="isPackagingRecyclable"
                                type="radio"
                                id="recycle_3"
                                className="radio-col-orange"
                                value="Not Sure"
                                onChange={this.handleKitchenInputChange}
                                checked={
                                  this.state.isPackagingRecyclable ===
                                  "Not Sure"
                                }
                              />
                              <label htmlFor="recycle_3">Not Sure</label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <b>
                              Do you provide disposable cutlery with orders?
                            </b>
                            <div>
                              <input
                                name="disposableCutlery"
                                type="radio"
                                id="cutlary_1"
                                className="radio-col-orange"
                                value="1"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.disposableCutlery === "1"}
                              />
                              <label htmlFor="cutlary_1">Yes</label>
                              <input
                                name="disposableCutlery"
                                type="radio"
                                id="cutlary_2"
                                className="radio-col-orange"
                                value="0"
                                onChange={this.handleKitchenInputChange}
                                checked={this.state.disposableCutlery === "0"}
                              />
                              <label htmlFor="cutlary_2">No</label>
                            </div>
							
                          </div>
						  <div className="col-lg-12">
							<b>Do you enable discount for all the menu items?</b>
							<div className="">
							  <input
								name="enablediscount"
								type="radio"
								id="endis1"
								className="radio-col-orange"
								value="1"
								onChange={this.handleBusinessInputChange}
								checked={
								  this.state.enablediscount === "1"
								}
							  />
							  <label htmlFor="endis1">Yes</label>
							  <input
								name="enablediscount"
								type="radio"
								id="endis2"
								className="radio-col-orange"
								value="0"
								onChange={this.handleBusinessInputChange}
								checked={
								  this.state.enablediscount === "0"
								}
							  />
							  <label htmlFor="endis2">No</label>
							</div>
						  </div>
							{this.state.enablediscount === "1" && (
								<div className="col-lg-12">
									{/*<div className="form-group">
										<b>Discount type</b>
										<div className="form-line">
											<select
											className="form-control"
											name="discounttype"
											onChange={
											  this.handleBusinessInputChange
											}
											>
											<option
											  value="In Amount"
											  selected={
												this.state.discounttype ===
												"In Amount"
											  }
											>
												  In Amount
											</option>
											<option
											  value="In Percentage"
											  selected={
												this.state.discounttype ===
												"In Percentage"
											  }
											>
											  In Percentage
											</option>					
											</select>
										</div>
									</div>*/} 
									<div className="form-group">
										<b>Discount(%)</b>
										<div className="form-line">
											<input
											  type="number"
											  step="any"
											  placeholder=""
											  defaultValue={this.state.discount}
											  onChange={this.handleKitchenInputChange}
											  name="discount"
											  className="form-control"
											/>
										</div>
										{this.state.discounterror != '' && (
										  <small className="text-danger">
											{this.state.discounterror}
										  </small>
										)}
									</div>
									<div className="form-group">
										<b>Discount start date</b>
										<div className="form-line">
											<input
												type='date'
												defaultValue={this.state.discountstartdate}
												className='form-control'
												name='discountstartdate'
												onChange={this.handleMenuInputChange}
												onBlur={this.handleMenuInputChange}
											/>
										</div>
										{this.state.discountstartdateerror != '' && (
										  <small className="text-danger">
											{this.state.discountstartdateerror}
										  </small>
										)}
									</div>
									<div className="form-group">
										<b>Discount end date</b>
										<div className="form-line">
											<input
												type='date'
												defaultValue={this.state.discountenddate}
												className='form-control'
												name='discountenddate'
												onChange={this.handleMenuInputChange}
												onBlur={this.handleMenuInputChange}
											/>
										</div>
										{this.state.discountenddateerror != '' && (
										  <small className="text-danger">
											{this.state.discountenddateerror}
										  </small>
										)}
									</div>
								</div>
							)}	
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-12">
                            <b>Does your kitchen use any of the following?</b>
                            <div className="item_wrap">
                              {this.props.kitchenMatArr.length
                                ? this.props.kitchenMatArr.map((item, i) => {
                                    return (
                                      <div key={"bnjt" + i}>
                                        <label
                                          htmlFor={item.material_name}
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "100",
                                          }}
                                        >
                                          {item.material_name}
                                        </label>
                                        <input
                                          type="checkbox"
                                          id={item.material_name}
                                          value={item.id}
                                          className="filled-in"
                                          onChange={(e) =>
                                            this.handleCheckboxInputChange(
                                              e,
                                              "material"
                                            )
                                          }
                                          checked={this.state.selectedMaterials.includes(
                                            item.id
                                          )}
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                              <div>
                                <label
                                  htmlFor="all_included_item"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "100",
                                  }}
                                >
                                  All of the above
                                </label>
                                <input
                                  type="checkbox"
                                  id="all_included_item"
                                  value={true}
                                  className="filled-in"
                                  onChange={(e) =>
                                    this.handleCheckboxAllInputChange(
                                      e,
                                      "material"
                                    )
                                  }
                                  checked={this.state.allMaterialIncluded}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <b>Is your kitchen suitable for the following?*</b>
                            <div className="item_wrap">
                              <div>
                                <label
                                  htmlFor="jain-veg"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "100",
                                  }}
                                >
                                  Jain Veg (no onion and garlic)
                                </label>
                                <input
                                  type="checkbox"
                                  id="jain-veg"
                                  value="Jain Veg"
                                  className="filled-in"
                                  onChange={
                                    this.handleSuitableCheckboxInputChange
                                  }
                                  checked={this.state.jainVegSelected}
                                  disabled={this.state.jainVegDisabled}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="pure-veg"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "100",
                                  }}
                                >
                                  Pure Veg (no eggs)
                                </label>
                                <input
                                  type="checkbox"
                                  id="pure-veg"
                                  value="Pure Veg"
                                  className="filled-in"
                                  onChange={
                                    this.handleSuitableCheckboxInputChange
                                  }
                                  checked={this.state.pureVegSelected}
                                  disabled={this.state.pureVegDisabled}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="veg-eggs"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "100",
                                  }}
                                >
                                  Veg (with eggs)
                                </label>
                                <input
                                  type="checkbox"
                                  id="veg-eggs"
                                  value="Veg"
                                  className="filled-in"
                                  onChange={
                                    this.handleSuitableCheckboxInputChange
                                  }
                                  checked={this.state.eggVegSelected}
                                  disabled={this.state.eggVegDisabled}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="non-veg"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "100",
                                  }}
                                >
                                  Non-Veg
                                </label>
                                <input
                                  type="checkbox"
                                  id="non-veg"
                                  value="Non-Veg"
                                  className="filled-in"
                                  onChange={
                                    this.handleSuitableCheckboxInputChange
                                  }
                                  checked={this.state.nonVegSelected}
                                  disabled={this.state.nonVegDisabled}
                                />
                              </div>
                            </div>
                            {this.state.kitchenTypeErr.length > 0 && (
                              <span className="text-danger">
                                {this.state.kitchenTypeErr}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-12">
                            <b>
                              Do you cater to the following dietary requirements
                              and allergies?
                            </b>
                            <div className="item_wrap">
                              {this.state.requirements.length
                                ? this.state.requirements.map((item, i) => {
                                    return (
                                      <div key={"dkjfddddddd" + i}>
                                        <label
                                          htmlFor={
                                            "dsdfsdf" +
                                            item.requirement_and_allergies_name
                                          }
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "100",
                                          }}
                                        >
                                          {item.requirement_and_allergies_name}
                                        </label>
                                        <input
                                          type="checkbox"
                                          id={
                                            "dsdfsdf" +
                                            item.requirement_and_allergies_name
                                          }
                                          value={item.id}
                                          className="filled-in"
                                          onChange={(e) =>
                                            this.handleCheckboxInputChange(
                                              e,
                                              "requirement"
                                            )
                                          }
                                          checked={this.state.selectedRequirements.includes(
                                            item.id
                                          )}
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <b>What is your cooking medium?</b>
                            <div className="item_wrap">
                              {this.props.cookingMatArr.length
                                ? this.props.cookingMatArr.map((item, i) => {
                                    return (
                                      <div key={"bnjt" + i}>
                                        <label
                                          htmlFor={item.material_name}
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "100",
                                          }}
                                        >
                                          {item.material_name}
                                        </label>
                                        <input
                                          type="checkbox"
                                          id={item.material_name}
                                          value={item.id}
                                          className="filled-in"
                                          onChange={(e) =>
                                            this.handleCheckboxInputChange(
                                              e,
                                              "cooking"
                                            )
                                          }
                                          checked={this.state.selectedCookingMaterials.includes(
                                            item.id
                                          )}
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                              <div>
                                <label
                                  htmlFor="all_included_cooking"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "100",
                                  }}
                                >
                                  All of the above
                                </label>
                                <input
                                  type="checkbox"
                                  id="all_included_cooking"
                                  value={true}
                                  className="filled-in"
                                  onChange={(e) =>
                                    this.handleCheckboxAllInputChange(
                                      e,
                                      "cooking"
                                    )
                                  }
                                  checked={this.state.allCookingIncluded}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel">
            <div className="panel-heading" role="tab" id="headingTwo_19">
              <h4 className="panel-title">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion_100"
                  href="#collapseTwo_100"
                  aria-expanded="false"
                  aria-controls="collapseTwo_100"
                >
                  Kitchen/Venue Pictures
                  <i className="material-icons float-right">arrow_drop_down</i>
                </a>
              </h4>
            </div>
            <div
              id="collapseTwo_100"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTwo_19"
              aria-expanded="false"
              style={{ height: "0px" }}
            >
              <div className="panel-body">
                <div className="card">
                  <div className="body">
                    <div className="row clearfix aniimated-thumbnials">
                      <div className="col-md-6">
                        <div className="input-group">
                          <div className="input-group upload_img_one two new_img_wrap">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th width="15%" className="text-center">
                                    Sr. No.
                                  </th>
                                  <th width="20%" className="text-center">
                                    Image
                                  </th>
                                  <th width="10%" className="text-center"></th>
                                </tr>
                              </thead>
                              {this.state.kitchenImages.length ? (
                                <tbody>
                                  {this.state.kitchenImages.map((item, i) => {
                                    return (
                                      <tr key={"ghd" + i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">
                                          <a href={item.image} target="_blank">
                                            <img
                                              style={{ width: "100px" }}
                                              className="img-responsive thumbnail"
                                              src={item.image}
                                            />
                                          </a>
                                        </td>
                                        <td className="text-center">
                                          <i
                                            className="material-icons view_link"
                                            onClick={() =>
                                              this.removeKitchenImageAPI(
                                                item.image_id
                                              )
                                            }
                                          >
                                            delete
                                          </i>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td className="text-center" colSpan="4">
                                      No image added
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                              
                            </table>
                            {this.state.displayKitchenImageForm ? (
                              <div>
                                <div className="form-group">
                                  <b>Upload Image*</b>
                                  <div className="form-line">
                                    <input
                                      id="uploadFile"
                                      type="file"
                                      accept=".jpg, .jpeg, .png, .gif"
                                      className="form-control"
                                      name="newKitchenImage"
                                      onChange={this.onKitchenImageChange}
                                    />
                                  </div>
                                  <p>
                                    (Image to be uploaded: 186 x 186 pixels)
                                  </p>
                                  {this.state.newKitchenImageErr.length > 0 && (
                                    <small className="text-danger">
                                      {this.state.newKitchenImageErr}
                                    </small>
                                  )}
                                </div>
                                <button
                                  className="btn btn-default waves-effect"
                                  style={{ color: "#000", marginRight: "10px" }}
                                  onClick={() => {
                                    this.setState({
                                      displayKitchenImageForm: false,
                                      newKitchenImageErr: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn"
                                  type="button"
                                  disabled={this.state.imageBtnDisabled}
                                  onClick={this.storeKitchenImageAPI}
                                >
                                  {this.state.imageBtnText}
                                </button>
                              </div>
                            ) : 
							(this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ?  <button
                                className="btn"
                                onClick={() =>
                                  this.setState({
                                    displayKitchenImageForm: true,
                                    newKitchenImage: null,
                                  })
                                }
                              >
                                Add Image
                              </button> : null )
							}

                            {/* {this.state.specialDishes.length ?
                                                            this.state.specialDishes.map(
                                                                (item, i) => {
                                                                    return (
                                                                        <div className="dish_blocks" key={'e' + i}>

                                                                            {item.dish_images.length === 0 ? <a
                                                                                href="#"
                                                                                data-sub-html="Profile Image"
                                                                            ><img
                                                                                    className="img-responsive thumbnail"
                                                                                    src="assets/images/noimage.png"
                                                                                /></a> : item.dish_images.map((images, j) => {
                                                                                    return (
                                                                                        <a
                                                                                            key={'f' + j}
                                                                                            target="_blank"
                                                                                            href={images.image_path}
                                                                                            data-sub-html="Profile Image"
                                                                                        >
                                                                                            <img
                                                                                                className="img-responsive thumbnail"
                                                                                                src={images.image_path}
                                                                                            />
                                                                                        </a>
                                                                                    )

                                                                                })}
                                                                            <span key={i}>
                                                                                {item.dish_name}
                                                                                <br />
                                                                            </span>
                                                                        </div>

                                                                    );
                                                                }
                                                            ) : <span className="input-group-addon">-</span>
                                                        } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  <div className="panel">
            <div className="panel-heading" role="tab" id="headingTwo_20">
              <h4 className="panel-title">
                <a
                  className="collapsed"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion_100"
                  href="#collapseTwo_101"
                  aria-expanded="false"
                  aria-controls="collapseTwo_101"
                >
                  Audit Me Anytime
                  <i className="material-icons float-right">arrow_drop_down</i>
                </a>
              </h4>
            </div>
            <div
              id="collapseTwo_101"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTwo_20"
              aria-expanded="false"
              style={{ height: "0px" }}
            >
              <div className="panel-body">
                <div className="card">
                  <div className="body">
                    <div className="row clearfix aniimated-thumbnials">
                      <div className="col-md-6">
                        <div className="input-group">
                          <div className="input-group upload_img_one two new_img_wrap">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th width="15%" className="text-center">
                                    Sr. No.
                                  </th>
                                  <th width="20%" className="text-center">
                                    Image
                                  </th>
                                  <th width="10%" className="text-center"></th>
                                </tr>
                              </thead>
                              {this.state.kitchenImages.length ? (
                                <tbody>
                                  {this.state.kitchenImages.map((item, i) => {
                                    return (
                                      <tr key={"ghd" + i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">
                                          <a href={item.image} target="_blank">
                                            <img
                                              style={{ width: "100px" }}
                                              className="img-responsive thumbnail"
                                              src={item.image}
                                            />
                                          </a>
                                        </td>
                                        <td className="text-center">
                                          <i
                                            className="material-icons view_link"
                                            onClick={() =>
                                              this.removeKitchenImageAPI(
                                                item.image_id
                                              )
                                            }
                                          >
                                            delete
                                          </i>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td className="text-center" colSpan="4">
                                      No image added
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                              <tr></tr>
                            </table>
                            {this.state.displayKitchenImageForm ? (
                              <div>
                                <div className="form-group">
                                  <b>Upload Image*</b>
                                  <div className="form-line">
                                    <input
                                      id="uploadAuditFile"
                                      type="file"
                                      accept=".jpg, .jpeg, .png, .gif"
                                      className="form-control"
                                      name="newAuditImage"
                                      onChange={this.onAuditImageChange}
                                      multiple
                                    />
                                  </div>
                                  <p>
                                    (Image to be uploaded: 186 x 186 pixels)
                                  </p>
                                  {this.state.auditImageErr.length > 0 && (
                                    <small className="text-danger">
                                      {this.state.auditImageErr}
                                    </small>
                                  )}
                                </div>
                                <button
                                  className="btn btn-default waves-effect"
                                  style={{ color: "#000", marginRight: "10px" }}
                                  onClick={() => {
                                    this.setState({
                                      displayKitchenImageForm: false,
                                      auditImageErr: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn"
                                  type="button"
                                  disabled={this.state.imageBtnDisabled}
                                  onClick={this.storeKitchenImageAPI}
                                >
                                  {this.state.imageBtnText}
                                </button>
                              </div>
                            ) : (
                              <button
                                className="btn"
                                onClick={() =>
                                  this.setState({
                                    displayKitchenImageForm: true,
                                    newAuditImage: null,
                                  })
                                }
                              >
                                Add Image
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
			{/*  Admin and Ops menu */}
			{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
			<button
			type="submit"
			disabled={this.state.buttonDisabled}
			className="btn"
			style={{ marginTop: "20px" }}
			>
			{this.state.buttonText}
			</button>
			) : null}
          
        </form>
      </div>
    );
  }
}
