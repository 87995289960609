/*
#############################################################################
# Name : MarketingCardListing.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display the marketing cards data
#############################################################################
*/
import React, { Component } from "react";
import Header from "../../components/Header";
import {
  getMarketingCardListAPI,
  deleteMarketingCardAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class MarketingCardListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
		userRole: '',
		userName: '',
		userEmailID: '',
		isLoading: true,
		marketingCard: [],
		activePage: 1,
		totalRecords: 10,
		cityList: [],
    };
  }

  componentDidMount() {
	var name = localStorage.getItem('userName');
	var email = localStorage.getItem('userEmail');
	var role = localStorage.getItem('userRole');
	this.setState({
	  userRole: role,
	  userName: name,
	  userEmailID: email
	});
	this.getMarketingCardListAPI();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getMarketingCardListAPI();
    });
  }

  getUserView = (id, event) => {
    event.preventDefault();
    localStorage.setItem("chefID", id);
    this.props.history.push("/chef-data");
  };

  async getMarketingCardListAPI() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getMarketingCardListAPI(id, currentPage, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            marketingCard: response.data["data"].data,
            totalRecords: response.data["data"]["pager_count"],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  }

  handleClickDelete = (marketingCardId) => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteMarketingCardAPI(id, token, marketingCardId)
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            "Marketing card deleted successfully",
            "Success"
          );
          this.getMarketingCardListAPI();
        } else {
          NotificationManager.error("Something went wrong", "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  deleteMarketingCard = (marketingCardId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this marketing card?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(marketingCardId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Marketing Card Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
					{/*  Admin and Marketing menu */}
					{this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                    <Link className="btn" to="/add-marketing-card">
                      Add Marketing Card
                    </Link>
					) : null}
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="align-middle text-center">
                              Sr. No.
                            </th>
                            <th className="align-middle text-center">Name</th>
                            <th className="align-middle text-center">Slug</th>
                            <th className="align-middle text-center">City</th>

                            <th className="align-middle text-center">
                              Description
                            </th>
                            <th className="align-middle text-center">
                              Published
                            </th>
                            <th className="align-middle text-center">Image</th>
                            <th className="align-middle text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="9">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.marketingCard.length ? (
                          <tbody>
                            {this.state.marketingCard.map(
                              (marketingCard, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="align-middle text-center"
                                      width="10%"
                                    >
                                      {this.state.activePage > 1 && i + 1 != 10
                                        ? this.state.activePage -
                                          1 +
                                          "" +
                                          (i + 1)
                                        : this.state.activePage > 1 &&
                                          i + 1 == 10
                                        ? this.state.activePage * (i + 1)
                                        : i + 1}
                                    </td>
                                    <td className="align-middle" width="25%">
                                      {marketingCard.name}
                                    </td>
									 <td className="align-middle" width="25%">
                                      {marketingCard.slug}
                                    </td>
                                    <td className="align-middle" width="25%">
                                      {marketingCard.city}
                                    </td>
                                    <td className="align-middle" width="35%">
                                      {marketingCard.description.length > 100
                                        ? marketingCard.description.substr(
                                            0,
                                            100
                                          ) + "..."
                                        : marketingCard.description}
                                    </td>
                                    <td
                                      className="align-middle text-center"
                                      width="10%"
                                    >
                                      {marketingCard.published == 1
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                    <td
                                      className="align-middle text-center"
                                      width="10%"
                                    >
                                      <img
                                        src={marketingCard.image}
                                        width="50px"
                                      />
                                    </td>
                                    <td
                                      width="20%"
                                      className="align-middle text-center"
                                      style={styles.th}
                                    >
									{/*  Admin and Marketing menu */}
									{this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                                      <Link
                                        to={
                                          "/edit-marketing-card?id=" +
                                          marketingCard.id
                                        }
                                        style={{ marginRight: "10px" }}
                                      >
                                        <i className="material-icons view_link">
                                          create
                                        </i>
                                      </Link>
									  ) : null}
									  {/*  Admin and Marketing menu */}
									  {this.state.userRole == 'Admin' ? (
                                      <i
                                        className="material-icons view_link"
                                        onClick={() =>
                                          this.deleteMarketingCard(
                                            marketingCard.id
                                          )
                                        }
                                      >
                                        delete
                                      </i>
									  ) : null}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="9">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
  th: {
    textAlign: "center",
  },
};
