/*
#############################################################################
# Name : ProfileTab.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display the chef details
#############################################################################
*/
import React, { Component } from 'react';
import {
  getUserProfileDetailAPI,
  updateChefProfileDetailsAPI
} from '../../core/services/APIServices';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

const profileFormValid = ({ profileFormErrors, ...rest }) => {
  let valid = true;

  Object.values(profileFormErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Object.values(profileFormErrors).forEach((val) => {
  //   val === "form-control" || (valid = false);
  // });

  return valid;
};

export default class ProfileTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: '',
      userFirstName: '',
      userLastName: '',
      userEmailId: '',
      userMobileNo: '',
      userWhatsAppNo: '',
      userWhatsAppVisibility: '',
      userGender: '',
      brandName: '',
      userPhoneNo: '',
      stdCode: '',
      userProfileImage: '',
      userProfileImageUrl: '',
      userBrandImage: '',
      userBrandImageUrl: '',
      userHouseNo: '',
      userFloor: '',
      userBuilding: '',
      userStreet: '',
      userPincode: '',
      userLocality: '',
      userCity: '',
      userState: '',
      userLandmark: '',
      userFacebook: '',
      userInstagram: '',
      userBlog: '',
      userTwitter: '',
      userWebsite: '',
      userLat: '',
      userLng: '',
      profileFormErrors: {
        userFirstName: '',
        userLastName: '',
        userEmailId: '',
        userMobileNo: '',
        userWhatsAppNo: '',
        dataLoaded: false,
        userGender: '',
        stdCode: '',
        userPhoneNo: '',
        userHouseNo: '',
        userBuilding: '',
        userFloor: '',
        userStreet: '',
        userPincode: '',
        userCity: '',
        userState: ''
      },
      profileImgError: '',
      brandImgError: '',
      buttonText: 'Update Profile Info',
      passwordButtonText: 'Change Password',
      buttonDisabled: false
    };
  }
  componentDidMount() {
	var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
    this.getProfileData();
  }
  getProfileData() {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return getUserProfileDetailAPI(userId, chefId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          let dataValue = {
            fName: response.data['data']['first_name'],
            lName: response.data['data']['last_name']
          };
          this.props.onUserdataChanges(dataValue);
          this.setState({
            userFirstName:
              response.data['data']['first_name'] == 'null'
                ? ''
                : response.data['data']['first_name'],
            userLastName:
              response.data['data']['last_name'] == 'null'
                ? ''
                : response.data['data']['last_name'],
            userEmailId:
              response.data['data']['email'] == 'null'
                ? ''
                : response.data['data']['email'],
            userMobileNo:
              response.data['data']['mobile_number'] == 'null'
                ? ''
                : response.data['data']['mobile_number'],
            userWhatsAppNo:
              response.data['data']['whatsapp_mobile_number'] == 'null'
                ? ''
                : response.data['data']['whatsapp_mobile_number'],
			 userWhatsAppVisibility:
              response.data['data']['whatsapp_visibility'] == 'null'
                ? '0'
                : response.data['data']['whatsapp_visibility'],
            userGender:
              response.data['data']['gender'] == 'null'
                ? ''
                : response.data['data']['gender'],
            dataLoaded: true,
            brandName:
              response.data['data']['brand_name'] == 'null'
                ? ''
                : response.data['data']['brand_name'],
            userPhoneNo:
              response.data['data']['telephone_number'] == 'null'
                ? ''
                : response.data['data']['telephone_number'],
            userProfileImageUrl:
              response.data['data']['profile_image'] == 'null'
                ? ''
                : response.data['data']['profile_image'],
            userBrandImageUrl:
              response.data['data']['brand_logo'] == 'null'
                ? ''
                : response.data['data']['brand_logo'],
            userHouseNo:
              response.data['data']['house_or_flat_number'] == 'null'
                ? ''
                : response.data['data']['house_or_flat_number'],
            userFloor:
              response.data['data']['floor_number'] == 'null'
                ? ''
                : response.data['data']['floor_number'],
            userBuilding:
              response.data['data']['building_or_society_name'] == 'null'
                ? ''
                : response.data['data']['building_or_society_name'],
            userStreet:
              response.data['data']['street_name'] == 'null'
                ? ''
                : response.data['data']['street_name'],
            userPincode:
              response.data['data']['pincode'] == 'null'
                ? ''
                : response.data['data']['pincode'],
            userLocality:
              response.data['data']['locality'] == 'null'
                ? ''
                : response.data['data']['locality'],
            userCity:
              response.data['data']['city'] == 'null'
                ? ''
                : response.data['data']['city'],
            userState:
              response.data['data']['state'] == 'null'
                ? ''
                : response.data['data']['state'],
            userLandmark:
              response.data['data']['landmark'] == 'null'
                ? ''
                : response.data['data']['landmark'],
            userLat:
              response.data['data']['latitude'] == 'null'
                ? ''
                : response.data['data']['latitude'],
            userLng:
              response.data['data']['longitude'] == 'null'
                ? ''
                : response.data['data']['longitude'],
            userFacebook:
              response.data['data']['facebook'] == 'null'
                ? ''
                : response.data['data']['facebook'],
            userInstagram:
              response.data['data']['instagram'] == 'null'
                ? ''
                : response.data['data']['instagram'],
            userTwitter:
              response.data['data']['twitter'] == 'null'
                ? ''
                : response.data['data']['twitter'],
            userWebsite:
              response.data['data']['website_or_blog'] == 'null'
                ? ''
                : response.data['data']['website_or_blog'],
            stdCode:
              response.data['data']['std_code'] == 'null'
                ? ''
                : response.data['data']['std_code'],
            userBlog:
              response.data['data']['chefpin_blog_url'] == 'null'
                ? ''
                : response.data['data']['chefpin_blog_url']
          });
        } else {
          this.setState({
            dataLoaded: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          dataLoaded: true
        });
      });
  }
  onProfileImageChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        userProfileImage: e.target.files[0]
      });
      if (
        e.target.files[0].type.includes('jpg') ||
        e.target.files[0].type.includes('jpeg') ||
        e.target.files[0].type.includes('png') ||
        e.target.files[0].type.includes('gif')
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            profileImgError: 'The allowed maximum upload size is 64 MB'
          });
        } else {
          this.setState({
            profileImgError: ''
          });
        }
      } else {
        this.setState({
          profileImgError:
            'Select valid image. Allowed format are .jpg, .jpeg, .png and .gif'
        });
      }
    } else {
      this.setState({
        userProfileImage: null,
        profileImgError: ''
      });
    }
  };
  onBrandImageChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        userBrandImage: e.target.files[0]
      });
      if (
        e.target.files[0].type.includes('jpg') ||
        e.target.files[0].type.includes('jpeg') ||
        e.target.files[0].type.includes('png') ||
        e.target.files[0].type.includes('gif')
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            brandImgError: 'The allowed maximum upload size is 64 MB'
          });
        } else {
          this.setState({
            brandImgError: ''
          });
        }
      } else {
        this.setState({
          brandImgError:
            'Select valid image. Allowed format are .jpg, .jpeg, .png and .gif'
        });
      }
    }
  };
  
  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    this.setState({ formErrors, [name]: value });
  };
  
  handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    let profileFormErrors = this.state.profileFormErrors;

    switch (name) {
      case 'userFirstName':
        profileFormErrors.userFirstName =
          value.length && !value.match(/^[a-zA-Z]+$/)
            ? 'Enter valid first name'
            : '';
        break;

      case 'userLastName':
        profileFormErrors.userLastName =
          value.length && !value.match(/^[a-zA-Z]+$/)
            ? 'Enter valid last name'
            : '';
        break;

      case 'userEmailId':
        profileFormErrors.userEmailId =
          value.length &&
          !value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
            ? 'Enter valid email'
            : '';
        break;

      case 'userMobileNo':
        profileFormErrors.userMobileNo =
          value.length && !value.match(/^[789]\d{9}$/)
            ? 'Enter valid mobile number'
            : '';
        break;

      case 'userWhatsAppNo':
        profileFormErrors.userWhatsAppNo =
          value.length && !value.match(/^[789]\d{9}$/)
            ? 'Enter valid whatsapp number'
            : '';
        break;

      case 'userPhoneNo':
        profileFormErrors.userPhoneNo =
          value.length && !value.match(/^[0-9\b]{6,8}$/)
            ? 'Enter valid phone number'
            : '';
        break;

      case 'stdCode':
        profileFormErrors.stdCode =
          value.length && !value.match(/^[0-9\b]{2,4}$/)
            ? 'Enter valid std code'
            : '';
        break;

      case 'userPincode':
        profileFormErrors.userPincode =
          value.length && !value.match(/^[1-9][0-9]{5}$/)
            ? 'Enter valid pincode'
            : '';
        if (value.length === 6) {
          axios
            .get(
              'https://maps.googleapis.com/maps/api/geocode/json?address=+' +
                value +
                '+&sensor=true&key=AIzaSyAM9tXQe4ikJvpt4PwbWVR9gVOaYPoWuT8',
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
              }
            )
            .then((response) => {
              if (response.data.status === 'OK') {
                var cityCnt = 0;
                var stateCnt = 0;
                response.data.results[0].address_components.forEach((item) => {
                  item.types.forEach((type) => {
                    if (
                      type === 'administrative_area_level_2' ||
                      type === 'locality'
                    ) {
                      if (item.long_name) {
                        profileFormErrors.userCity = '';
                      }
                      this.setState({
                        userCity: item.long_name
                      });
                      cityCnt++;
                    }
                    if (type === 'administrative_area_level_1') {
                      if (item.long_name) {
                        profileFormErrors.userState = '';
                      }
                      this.setState({
                        userState: item.long_name
                      });
                      stateCnt++;
                    }
                  });
                });

                if (cityCnt === 0) {
                  this.setState({
                    userCity: ''
                  });
                }
                if (stateCnt === 0) {
                  this.setState({
                    userState: ''
                  });
                }
              } else {
                this.setState({
                  userCity: '',
                  userState: ''
                });
                profileFormErrors.userPincode = 'Pincode is invalid';
              }
            })
            .catch((error) => {
              NotificationManager.error(error);
            });
        }
        break;
    }

    this.setState({ profileFormErrors, [name]: value });
  };
  submitProfileForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let profileFormErrors = this.state.profileFormErrors;
    const {
      userFirstName,
      userLastName,
      userEmailId,
      userMobileNo,
      userWhatsAppNo,
      userWhatsAppVisibility,
      userGender,
      userHouseNo,
      userBuilding,
      userStreet,
      userPincode,
      userCity,
      userProfileImage,
      userBrandImage,
      userProfileImageUrl,
      userBrandImageUrl,
      profileImgError,
      brandImgError,
      userState,
      userBlog
    } = this.state;

    // if (userFirstName === "") {
    //     profileFormErrors.userFirstName = "First name is required";
    // }
    // if (userLastName === "") {
    //     profileFormErrors.userLastName = "Last name is required";
    // }
    // if (userEmailId === "") {
    //     profileFormErrors.userEmailId = "Email is required";
    // }
    // if (userMobileNo === "") {
    //     profileFormErrors.userMobileNo = "Mobile number is required";
    // }
    // if (userWhatsAppNo === "") {
    //     profileFormErrors.userWhatsAppNo = "Whatsapp number is required";
    // }
    // if (userGender === "") {
    //     profileFormErrors.userGender = "Gender is required";
    // }
    // if (userHouseNo === "") {
    //     profileFormErrors.userHouseNo = "House number is required";
    // }
    // if (userBuilding === "") {
    //     profileFormErrors.userBuilding = "This is required";
    // }
    // if (userStreet === "") {
    //     profileFormErrors.userStreet = "Street is required";
    // }
    // if (userPincode === "") {
    //     profileFormErrors.userPincode = "Pincode is required";
    // }
    // if (userCity === "") {
    //     profileFormErrors.userCity = "City is required";
    // }
    // if (userState === "") {
    //     profileFormErrors.userState = "State is required";
    // }
    // if (!userProfileImage && !userProfileImageUrl) {
    //     this.state.profileImgError = "Profile image is required";
    // }
    // this.setState({ profileFormErrors, [name]: value });
    if (
      profileFormValid(this.state) &&
      profileImgError === '' &&
      brandImgError === ''
    ) {
      var id = localStorage.getItem('userID');
      var chefId = localStorage.getItem('chefID');
      var token = localStorage.getItem('accessToken');
      this.setState({
        buttonText: 'Updating...',
        buttonDisabled: true
      });
      let data = {
        userFirstName: this.state.userFirstName,
        userLastName: this.state.userLastName,
        userEmailId: this.state.userEmailId,
        userMobileNo: this.state.userMobileNo,
        userWhatsAppNo: this.state.userWhatsAppNo,
        userWhatsAppVisibility: this.state.userWhatsAppVisibility,
        userGender: this.state.userGender,
        brandName: this.state.brandName,
        userPhoneNo: this.state.userPhoneNo,
        stdCode: this.state.stdCode,
        userProfileImage: this.state.userProfileImage,
        userBrandImage: this.state.userBrandImage,
        userHouseNo: this.state.userHouseNo,
        userFloor: this.state.userFloor,
        userBuilding: this.state.userBuilding,
        userStreet: this.state.userStreet,
        userPincode: this.state.userPincode,
        userLocality: this.state.userLocality,
        userCity: this.state.userCity,
        userState: this.state.userState,
        userLandmark: this.state.userLandmark,
        userLat: this.state.userLat,
        userLng: this.state.userLng,
        userFacebook: this.state.userFacebook,
        userInstagram: this.state.userInstagram,
        userTwitter: this.state.userTwitter,
        userWebsite: this.state.userWebsite,
        userBlog: this.state.userBlog
      };
      return updateChefProfileDetailsAPI(id, chefId, token, data)
        .then((response) => {
          if (response.data['status'] === 200) {
            NotificationManager.success(
              'Profile data updated successfully',
              'Success',
              3000
            );
            this.getProfileData();
            this.setState({
              buttonText: 'Update Profile Info',
              buttonDisabled: false
            });
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
            this.setState({
              buttonText: 'Update Profile Info',
              buttonDisabled: false
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, 'Error!');
          this.setState({
            buttonText: 'Update Profile Info',
            buttonDisabled: false
          });
        });
    } else {
      NotificationManager.error(
        'Please see if the required fields are entered',
        'Error',
        3000
      );
    }
  };
  render() {
    const { profileFormErrors } = this.state;
    return (
      <form onSubmit={this.submitProfileForm}>
        <div className='row clearfix'>
          <div className='col-xs-12 ol-sm-12 col-md-12 col-lg-12'>
            <div
              className='panel-group'
              id='accordion_17'
              role='tablist'
              aria-multiselectable='true'
            >
              <div className='panel'>
                <div className='panel-heading' role='tab' id='headingOne_17'>
                  <h4 className='panel-title'>
                    <a
                      role='button'
                      data-toggle='collapse'
                      data-parent='#accordion_17'
                      href='#collapseOne_17'
                      aria-expanded='false'
                      aria-controls='collapseOne_17'
                      className='collapsed'
                    >
                      Profile{' '}
                      <i className='material-icons float-right'>
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id='collapseOne_17'
                  className='panel-collapse collapse'
                  role='tabpanel'
                  aria-labelledby='headingOne_17'
                  aria-expanded='false'
                  style={{ height: '0px' }}
                >
                  <div className='panel-body aniimated-thumbnials'>
                    <div className='card'>
                      <div className='body'>
                        <div className='row clearfix'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <b>First Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userFirstName}
                                  className='form-control'
                                  name='userFirstName'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userFirstName.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userFirstName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <b>Last Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userLastName}
                                  className='form-control'
                                  name='userLastName'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userLastName.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userLastName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <b>Mobile Number</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userMobileNo}
                                  className='form-control'
                                  name='userMobileNo'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userMobileNo.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userMobileNo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <b>Whatsapp Number</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userWhatsAppNo}
                                  className='form-control'
                                  name='userWhatsAppNo'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userWhatsAppNo.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userWhatsAppNo}
                                </span>
                              )}
                            </div>
                          </div>
						  <div className='col-md-4'>
                            <div className='form-group'>
                              <b>Whatsapp Visibility</b>
                              <div className='form-line'>
                                <select
								  className='form-control show-tick'
								  name='userWhatsAppVisibility'
								  onChange={this.handleMenuInputChange}
								>
								  <option value='1' selected={
                                      this.state.userWhatsAppVisibility === '1'
                                    }>Yes</option>
								  <option value='0' selected={
                                      this.state.userWhatsAppVisibility === '0'
                                    }>No</option>
								</select>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <b>Email ID</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userEmailId}
                                  className='form-control'
                                  name='userEmailId'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userEmailId.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userEmailId}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-4'>
                            {this.state.dataLoaded ? (
                              <div className='form-group'>
                                <b>Gender</b>
                                <div className='form-line'>
                                  <select
                                    className='form-control'
                                    name='userGender'
                                    onChange={this.handleProfileInputChange}
                                    onBlur={this.handleProfileInputChange}
                                  >
                                    <option value=''>Select Gender</option>
                                    <option
                                      value='Male'
                                      selected={this.state.userGender == 'Male'}
                                    >
                                      Male
                                    </option>
                                    <option
                                      value='Female'
                                      selected={
                                        this.state.userGender == 'Female'
                                      }
                                    >
                                      Female
                                    </option>
                                  </select>
                                </div>
                                {profileFormErrors.userGender.length > 0 && (
                                  <span className='text-danger'>
                                    {profileFormErrors.userGender}
                                  </span>
                                )}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Brand or Business Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.brandName}
                                  className='form-control'
                                  name='brandName'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.brandName.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.brandName}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>STD Code</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.stdCode}
                                  className='form-control'
                                  name='stdCode'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.stdCode.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.stdCode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Phone Number</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userPhoneNo}
                                  className='form-control'
                                  name='userPhoneNo'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userPhoneNo.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userPhoneNo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='input-group upload_img_one mb-0'>
                              {!this.state.userProfileImageUrl ? (
                                <div className='form-group'>
                                  <b>Upload your profile picture</b>
                                  <div className='form-line'>
                                    <input
                                      type='file'
                                      accept='.jpg, .jpeg, .png, .gif'
                                      className='form-control'
                                      name='userProfileImage'
                                      onChange={this.onProfileImageChange}
                                    />
                                  </div>
                                  <p>(Image to be uploaded: 92 x 92 pixels)</p>
                                  {this.state.profileImgError.length > 0 && (
                                    <span className='text-danger'>
                                      {this.state.profileImgError}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <b>Profile picture</b>
                                  <div>
                                    <a
                                      className='imageDisplay'
                                      href={this.state.userProfileImageUrl}
                                      data-sub-html='Profile Image'
                                      target='_blank'
                                    >
                                      <img
                                        className='img-responsive thumbnail'
                                        src={this.state.userProfileImageUrl}
                                      />
                                    </a>
                                    <div
                                      className='removeImage'
                                      onClick={() =>
                                        this.setState({
                                          userProfileImageUrl: '',
                                          profileImgError: ''
                                        })
                                      }
                                    >
                                      <i className='material-icons imageIconDisplay'>
                                        clear
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='col-md-3'>
                            <div className='input-group upload_img_one mb-0'>
                              {!this.state.userBrandImageUrl ? (
                                <div className='form-group'>
                                  <b>Upload your brand logo</b>
                                  <div className='form-line'>
                                    <input
                                      type='file'
                                      accept='.jpg, .jpeg, .png, .gif'
                                      className='form-control'
                                      name='userBrandImage'
                                      onChange={this.onBrandImageChange}
                                    />
                                  </div>
                                  <p>(Image to be uploaded: 92 x 92 pixels)</p>
                                  {this.state.brandImgError.length > 0 && (
                                    <span className='text-danger'>
                                      {this.state.brandImgError}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <b>Brand logo</b>
                                  <div>
                                    <a
                                      href={this.state.userBrandImageUrl}
                                      data-sub-html='Profile Image'
                                      target='_blank'
                                    >
                                      <img
                                        className='img-responsive thumbnail'
                                        src={this.state.userBrandImageUrl}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: -5,
                                        right: 0,
                                        height: '20px',
                                        width: '20px',
                                        backgroundColor: 'red',
                                        borderRadius: '20px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          userBrandImageUrl: ''
                                        })
                                      }
                                    >
                                      <i
                                        className='material-icons'
                                        style={{
                                          color: 'white',
                                          fontSize: '13px',
                                          marginLeft: '3px',
                                          marginTop: '2px'
                                        }}
                                      >
                                        clear
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel'>
                <div className='panel-heading' role='tab' id='headingTwo_17'>
                  <h4 className='panel-title'>
                    <a
                      className='collapsed'
                      role='button'
                      data-toggle='collapse'
                      data-parent='#accordion_17'
                      href='#collapseTwo_17'
                      aria-expanded='false'
                      aria-controls='collapseTwo_17'
                    >
                      Address
                      <i className='material-icons float-right'>
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id='collapseTwo_17'
                  className='panel-collapse collapse'
                  role='tabpanel'
                  aria-labelledby='headingTwo_17'
                  aria-expanded='false'
                  style={{ height: '0px' }}
                >
                  <div className='panel-body'>
                    <div className='card'>
                      <div className='body'>
                        <div className='row clearfix'>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>House/Flat No</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userHouseNo}
                                  className='form-control'
                                  name='userHouseNo'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userHouseNo.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userHouseNo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Floor</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userFloor}
                                  className='form-control'
                                  name='userFloor'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userFloor.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userFloor}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Building/Society</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userBuilding}
                                  className='form-control'
                                  name='userBuilding'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userBuilding.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userBuilding}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Street</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userStreet}
                                  className='form-control'
                                  name='userStreet'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userStreet.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userStreet}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-2'>
                            <div className='form-group'>
                              <b>Pincode</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userPincode}
                                  className='form-control'
                                  name='userPincode'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userPincode.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userPincode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-2'>
                            <div className='form-group'>
                              <b>Locality</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userLocality}
                                  className='form-control'
                                  name='userLocality'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-2'>
                            <div className='form-group'>
                              <b>City</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userCity}
                                  className='form-control'
                                  name='userCity'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userCity.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userCity}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>State</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userState}
                                  className='form-control'
                                  name='userState'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {profileFormErrors.userState.length > 0 && (
                                <span className='text-danger'>
                                  {profileFormErrors.userState}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Landmark</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userLandmark}
                                  className='form-control'
                                  name='userLandmark'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Latitude</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userLat}
                                  className='form-control'
                                  name='userLat'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Longitude</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userLng}
                                  className='form-control'
                                  name='userLng'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel'>
                <div className='panel-heading' role='tab' id='headingThree_17'>
                  <h4 className='panel-title'>
                    <a
                      className='collapsed'
                      role='button'
                      data-toggle='collapse'
                      data-parent='#accordion_17'
                      href='#collapseThree_17'
                      aria-expanded='false'
                      aria-controls='collapseThree_17'
                    >
                      Social Media Handles
                      <i className='material-icons float-right'>
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id='collapseThree_17'
                  className='panel-collapse collapse'
                  role='tabpanel'
                  aria-labelledby='headingThree_17'
                  aria-expanded='false'
                  style={{ height: '0px' }}
                >
                  <div className='panel-body'>
                    <div className='card'>
                      <div className='body'>
                        <div className='row clearfix'>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Facebook</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userFacebook}
                                  className='form-control'
                                  name='userFacebook'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Instagram</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userInstagram}
                                  className='form-control'
                                  name='userInstagram'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Twitter</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userTwitter}
                                  className='form-control'
                                  name='userTwitter'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='form-group'>
                              <b>Website/Blog</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userWebsite}
                                  className='form-control'
                                  name='userWebsite'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                              {/* {profileFormErrors.state.length > 0 && (
                                                    <span className="text-danger">{profileFormErrors.state}</span>
                                                  )} */}
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <b>Chefpin Blog</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  defaultValue={this.state.userBlog}
                                  className='form-control'
                                  name='userBlog'
                                  onChange={this.handleProfileInputChange}
                                  onBlur={this.handleProfileInputChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
		{/*  Admin and Ops menu */}
		{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
        <button
          type='submit'
          disabled={this.state.buttonDisabled}
          className='btn'
        >
          {this.state.buttonText}
        </button>
		) : null}
		{/*  Admin and Ops menu */}
		{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
        <button
          type='button'
          disabled={this.state.buttonDisabled}
          className='btn'
          style={{ marginLeft: '10px' }}
          data-toggle='modal'
          data-target='#myModalPasswordChange'
        >
          {this.state.passwordButtonText}
        </button>
		) : null}
      </form>
    );
  }
}
