/*
#############################################################################
# Name : App.js
# Created by : Arvind Yadav
# Date       : 7th Oct 2020
# Purpose    : This file is used to design the routes for the Admin Panel
#############################################################################
*/
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import SignInPage from "./screens/signIn/signin";
import UserInfoPage from "./screens/userFormInfo/userInfo";
import QueryListPage from "./screens/userList/queryList";
import CityReport from "./screens/reports/cityReport";
import StepWiseReport from "./screens/reports/stepWiseReport";
import FilterReport from "./screens/reports/filterReport";
import MarketingPage from "./screens/marketingCard/marketingCard";
import CrowdFavouritePage from "./screens/crowdFavourite/crowdFavourites";
import CollectionForPage from "./screens/collectionFor/collectionFor";
import CuisineListing from "./screens/manage-cuisine/CuisineListing";
import AddCuisine from "./screens/manage-cuisine/AddCuisine";
import EditCuisine from "./screens/manage-cuisine/EditCuisine";
import CategoryListing from "./screens/manage-categories/CategoryListing";
import AddCategory from "./screens/manage-categories/AddCategory";
import EditCategory from "./screens/manage-categories/EditCategory";
import TagListing from "./screens/manage-tags/TagListing";
import AddTag from "./screens/manage-tags/AddTag";
import EditTag from "./screens/manage-tags/EditTag";
import CityListing from "./screens/manage-cities/CityListing";
import AddCity from "./screens/manage-cities/AddCity";
import EditCity from "./screens/manage-cities/EditCity";
import KitchenMaterialListing from "./screens/manage-kitchen-materials/KitchenMaterialListing";
import AddKitchenMaterial from "./screens/manage-kitchen-materials/AddKitchenMaterial";
import EditKitchenMaterial from "./screens/manage-kitchen-materials/EditKitchenMaterial";
import RequirementListing from "./screens/manage-requirements/RequirementListing";
import AddRequirement from "./screens/manage-requirements/AddRequirement";
import EditRequirement from "./screens/manage-requirements/EditRequirement";
import HomepagePopupListing from "./screens/manage-homepage-popup/HomepagePopupListing";
import AddHomepagePopup from "./screens/manage-homepage-popup/AddHomepagePopup";
import EditHomepagePopup from "./screens/manage-homepage-popup/EditHomepagePopup";
import MarketingCardListing from "./screens/manage-marketing-cards/MarketingCardListing";
import AddMarketingCard from "./screens/manage-marketing-cards/AddMarketingCard";
import EditMarketingCard from "./screens/manage-marketing-cards/EditMarketingCard";
import CrowdFavouriteListing from "./screens/manage-crowd-favourite/CrowdFavouriteListing";
import AddCrowdFavourite from "./screens/manage-crowd-favourite/AddCrowdFavourite";
import EditCrowdFavourite from "./screens/manage-crowd-favourite/EditCrowdFavourite";
import CollectionListing from "./screens/manage-collections/CollectionListing";
import AddCollection from "./screens/manage-collections/AddCollection";
import EditCollection from "./screens/manage-collections/EditCollection";
import GlobalSettingListing from "./screens/manage-global-setting/GlobalSettingListing";
import AddGlobalSetting from "./screens/manage-global-setting/AddGlobalSetting";
import EditGlobalSetting from "./screens/manage-global-setting/EditGlobalSetting";
import SubscriptionPackageListing from "./screens/manage-subscription-package/SubscriptionPackageListing";
import AddSubscriptionPackage from "./screens/manage-subscription-package/AddSubscriptionPackage";
import EditSubscriptionPackage from "./screens/manage-subscription-package/EditSubscriptionPackage";
import MembershipPlanListing from "./screens/manage-membership-plan/MembershipPlanListing";
import AddMembershipPlan from "./screens/manage-membership-plan/AddMembershipPlan";
import EditMembershipPlan from "./screens/manage-membership-plan/EditMembershipPlan";
import BannerListing from "./screens/manage-banner/BannerListing";
import AddBanner from "./screens/manage-banner/AddBanner";
import EditBanner from "./screens/manage-banner/EditBanner";
import DinerListing from "./screens/manage-diner/DinerListing";
import DinerProfile from "./screens/manage-diner/DinerProfile";

import ChatListingPage from "./screens/manage-chef-chat-count/chatListing";
import ChefRejectCountListingPage from "./screens/manage-chef-reject-timeout-count/chefRejectCountListing";

import PartyListingPage from "./screens/manage-party-order/orderListing";
import PartyDetailPage from "./screens/manage-party-order/orderDetail";

import DeleteAccountListingPage from "./screens/manage-delete-account/deleteAccountListing";

import TiffinListingPage from "./screens/manage-tiffin-order/orderListing";
import TiffinDetailPage from "./screens/manage-tiffin-order/orderDetail";

import ChefSubscriptionListingPage from "./screens/manage-chef-subscription-package/chefSubscribedListing";

import OrderListingPage from "./screens/manage-diner-order/orderListing";
import ChefOrderLedgerList from "./screens/manage-chef-order-ledger/chefOrderLedgerListing";

import OrderDetailPage from "./screens/manage-diner-order/orderDetail";
import ChefFAQListPage from "./screens/manage-faq/chefFAQList";
import DinerFAQListPage from "./screens/manage-faq/dinerFAQList";
import AddFAQPage from "./screens/manage-faq/addFAQ";
import EditFAQPage from "./screens/manage-faq/editFAQ";
import DinnerListPage from "./screens/userList/dinnerList";
import EnquiresListPage from "./screens/userList/enquiresList";
import MembershipListPage from "./screens/userList/membershipList";
import CouponSetting from "./screens/manage-coupon/coupon-setting";
import ReviewList from "./screens/manage-reviews/reviewList";
import AddDiscountCode from "./screens/manage-discount-coupon-code/AddDiscountCode";
import DiscountCodeListing from "./screens/manage-discount-coupon-code/DiscountCodeListing";
import EditDiscountCode from "./screens/manage-discount-coupon-code/EditDiscountCode";
import BlogListing from "./screens/manage-blogs/blogListing";
import DishSlider from "./screens/manage-dish-slider/dishSlider";
import ChefSlider from "./screens/manage-chef-slider/chefSlider";
import SeoAddArticle from "./screens/manage-seo-article/seoAddArticle";
import SeoEditArticle from "./screens/manage-seo-article/seoEditArticle";
import SeoArticleList from "./screens/manage-seo-article/seoArticleList";
import PromotionalMessageAdd from "./screens/manage-promotional-message/promotionalMessageAdd";
import PromotionalMessageEdit from "./screens/manage-promotional-message/promotionalMessageEdit";
import PromotionalMessageList from "./screens/manage-promotional-message/promotionalMessageList";
import BadWeatherList from "./screens/manage-bad-weather-alerts/badWeatherList";
import BadWeatherAdd from "./screens/manage-bad-weather-alerts/badWeatherAdd";
import BadWeatherEdit from "./screens/manage-bad-weather-alerts/badWeatherEdit";

import ReviewRatingList from "./screens/manage-reviews-rating/ReviewRatingList";
import AddReviewRating from "./screens/manage-reviews-rating/AddReviewRating";
import EditReviewRating from "./screens/manage-reviews-rating/EditReviewRating";

import AddOfflineOrder from "./screens/manage-diner-offline-order/addOfflineOrder";
import EditOfflineOrder from "./screens/manage-diner-offline-order/editOfflineOrder";
import OfflineOrderListingPage from "./screens/manage-diner-offline-order/orderListing";
import OfflineOrderDetailPage from "./screens/manage-diner-offline-order/orderDetail";

// React Notification
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={SignInPage} />
      {/* <Route path="/chef-list" component={UserListPage} /> */}
      <Route path="/chef-data" component={UserInfoPage} />
      <Route path="/query-list" component={QueryListPage} />
      <Route path="/diner-list" component={DinerListing} />
      <Route path="/diner-data" component={DinerProfile} />
      <Route path="/city-report" component={CityReport} />
      <Route path="/step-wise-report" component={StepWiseReport} />
      <Route path="/report-data" component={FilterReport} />
      <Route path="/marketing-card" component={MarketingPage} />
      <Route path="/crowd-favourites" component={CrowdFavouritePage} />
      <Route path="/collection-for" component={CollectionForPage} />
      <Route path="/cuisine-list" component={CuisineListing} />
      <Route path="/add-cuisine" component={AddCuisine} />
      <Route path="/edit-cuisine" component={EditCuisine} />
      <Route path="/category-list" component={CategoryListing} />
      <Route path="/add-category" component={AddCategory} />
      <Route path="/edit-category" component={EditCategory} />
      <Route path="/tag-list" component={TagListing} />
      <Route path="/add-tag" component={AddTag} />
      <Route path="/edit-tag" component={EditTag} />
      <Route path="/city-list" component={CityListing} />
      <Route path="/add-city" component={AddCity} />
      <Route path="/edit-city" component={EditCity} />
      <Route path="/kitchen-material-list" component={KitchenMaterialListing} />
      <Route path="/add-kitchen-material" component={AddKitchenMaterial} />
      <Route path="/edit-kitchen-material" component={EditKitchenMaterial} />
      <Route
        path="/requirements-and-allergy-list"
        component={RequirementListing}
      />
      <Route path="/add-requirement-and-allergy" component={AddRequirement} />
      <Route path="/edit-requirement-and-allergy" component={EditRequirement} />
	  <Route path="/homepage-popup-list" component={HomepagePopupListing} />
      <Route path="/add-homepage-popup" component={AddHomepagePopup} />
      <Route path="/edit-homepage-popup" component={EditHomepagePopup} />
      <Route path="/marketing-card-list" component={MarketingCardListing} />
      <Route path="/add-marketing-card" component={AddMarketingCard} />
      <Route path="/edit-marketing-card" component={EditMarketingCard} />
      <Route path="/crowd-favourite-list" component={CrowdFavouriteListing} />
      <Route path="/add-crowd-favourite" component={AddCrowdFavourite} />
      <Route path="/edit-crowd-favourite" component={EditCrowdFavourite} />
      <Route path="/collection-list" component={CollectionListing} />
      <Route path="/add-collection" component={AddCollection} />
      <Route path="/edit-collection" component={EditCollection} />
      <Route path="/discount-coupon-code-list" component={DiscountCodeListing} />
      <Route path="/add-discount-code" component={AddDiscountCode} />
      <Route path="/edit-discount-code" component={EditDiscountCode} />
      <Route path="/global-setting-list" component={GlobalSettingListing} />
	  <Route path="/add-global-setting" component={AddGlobalSetting} />
      <Route path="/edit-global-setting" component={EditGlobalSetting} />
      <Route path="/subscription-package-list" component={SubscriptionPackageListing} />
      <Route path="/add-subscription-package" component={AddSubscriptionPackage} />
      <Route path="/edit-subscription-package-list" component={EditSubscriptionPackage} />
      {/*   <Route path="/membership-plan-list" component={MembershipPlanListing} /> 
      <Route path="/add-membership-plan" component={AddMembershipPlan} />
      <Route path="/edit-membership-plan" component={EditMembershipPlan} />*/}
      <Route path="/banner-list" component={BannerListing} />
      <Route path="/add-banner" component={AddBanner} />
      <Route path="/edit-banner" component={EditBanner} />
      <Route path="/chef-subscription-listing" component={ChefSubscriptionListingPage} />
      <Route path="/diner-order-listing" component={OrderListingPage} />
      <Route path="/diner-order-detail" component={OrderDetailPage} />
	  <Route path="/add-offline-order" component={AddOfflineOrder} />
      <Route path="/edit-offline-order" component={EditOfflineOrder} />
	  <Route path="/offline-order-listing" component={OfflineOrderListingPage} />
      <Route path="/offline-order-detail" component={OfflineOrderDetailPage} />
	  <Route path="/party-order-listing" component={PartyListingPage} />
	  <Route path="/delete-account-request-listing" component={DeleteAccountListingPage} />
	  <Route path="/tiffin-enquiry-listing" component={TiffinListingPage} />
	  <Route path="/chef-reject-timeout-count" component={ChefRejectCountListingPage} />
	  <Route path="/chef-chat-count" component={ChatListingPage} />
      <Route path="/party-order-detail" component={PartyDetailPage} />
      <Route path="/tiffin-enquiry-detail" component={TiffinDetailPage} />
      <Route path="/diner-faq-list" component={DinerFAQListPage} />
      <Route path="/chef-faq-list" component={ChefFAQListPage} />
      <Route path="/add-faq" component={AddFAQPage} />
      <Route path="/edit-faq" component={EditFAQPage} />
      <Route path="/diner-query" component={DinnerListPage} />
      <Route path="/enquiry-query" component={EnquiresListPage} />
      <Route path="/membership-list" component={MembershipListPage} />
      <Route path="/global-free-delivery-setting" component={CouponSetting} />
      <Route path="/review-list" component={ReviewList} />
	  <Route path="/chef-review-list" component={ReviewRatingList} />
	  <Route path="/add-chef-review" component={AddReviewRating} />
      <Route path="/edit-chef-review" component={EditReviewRating} />
      <Route path="/blog-list" component={BlogListing}/>
      <Route path="/dish-slider" component={DishSlider}/>
      <Route path="/chef-slider" component={ChefSlider}/>
      <Route path="/seo-article-add" component={SeoAddArticle} />
      <Route path="/seo-article-edit" component={SeoEditArticle} />
      <Route path="/seo-article-list" component={SeoArticleList}/>
	  <Route path="/promotional-message-add" component={PromotionalMessageAdd} />
      <Route path="/promotional-message-edit" component={PromotionalMessageEdit} />
      <Route path="/promotional-message-list" component={PromotionalMessageList}/>
	  <Route path="/bad-weather-add" component={BadWeatherAdd} />
      <Route path="/bad-weather-edit" component={BadWeatherEdit} />
      <Route path="/bad-weather-list" component={BadWeatherList}/> 
	  {/* chef ledger report */}
	  <Route path="/chef-order-ledger-list" component={ChefOrderLedgerList} />
	  
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
