import React, { Component } from 'react'
import Header from "../../components/Header";
import { addOfflineOrderAPI, getChefListAPI } from "../../core/services/APIServices";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { NotificationManager } from "react-notifications";
import Select from 'react-select';

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === null && (valid = false)
    })

    return valid;
}

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class addOfflineOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderType: '',
            orderNo: '',
            orderDate: '',
            deliveryDate: '',
            pickupTime: '',
            dinerName: '',
            chefName: '',
            dinerMob: '',
            chefDetails: '',
			chefArr: [],
			dinerEmail: '',
			dinerAdd: '',
			orderAmount: '',
			deliveryAmount: '',
			promoCode: '',
			promoDiscount: '',
			receivedAmountDiner: '',
			isPaymentReceived: '',
			isDelivered: '',
			isPaidToChef: '',
			razorpayID: '',
			sendToSandeepAt: '',
			orderDetails: '',
			remark: '',
			reasonForOffline: '',
            formErrors: {
                orderType: '',
                orderNo: '',
                orderDate: '',
                deliveryDate: '',
                pickupTime: '',
                dinerName: '',
                chefName: '',
                dinerMob: '',
            },
            imageErr: '',
            buttonText: "Submit",
            buttonDisabled: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxInput = this.handleCheckboxInput.bind(this);
    }
	
	componentDidMount() {
        this.getChefListAPI();
    }
	
	getChefListAPI = () => {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        return getChefListAPI(id, token)
		.then((response) => {
			if (response.data["status"] === 200) {
				if (response.data.data.length) {
					let array = []
					response.data.data.map(item => {
						array.push({ value: item.id, label: item.chef_name, mobile_number: item.mobile_number,  city: item.city })
					})
					this.setState({
						chefArr: array
					})
				}
			} else {
				NotificationManager.error(response.data["message"], "Error!");
			}
		})
		.catch((error) => {
			NotificationManager.error(error, "Error!");
		});
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        this.setState({ formErrors, [name]: value })
    }
	
	handleFilterChange(key) {
		const { name, value } = key.target;
		let formErrors = this.state.formErrors;
		this.setState({
		  [key.target.name]: key.target.value
		});
	}

    handleCheckboxInput = (e) => {
        this.setState({
            isPublished: e.target.checked
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        const { orderType, orderNo, orderDate, deliveryDate, pickupTime, chefName, dinerName, dinerEmail, dinerMob, dinerAdd, orderAmount, deliveryAmount, promoCode, promoDiscount, receivedAmountDiner, isPaymentReceived, isDelivered, isPaidToChef, razorpayID, sendToSandeepAt, orderDetails, remark, reasonForOffline } = this.state;
        if (orderType === "") {
            formErrors.orderType = "Order type is required";
        }
        /* if (orderNo === "") {
            formErrors.orderNo = "Order no is required";
        } */
        if (orderDate === "") {
            formErrors.orderDate = "Order date is required";
        }
		if (deliveryDate === "") {
            formErrors.deliveryDate = "Delivery date is required";
        }
        if (pickupTime === "") {
            formErrors.pickupTime = "Pickup time is required";
        }
        if (chefName === "") {
            formErrors.chefName = "Chef name is required";
        }
		 if (dinerName === "") {
            formErrors.dinerName = "Diner name is required";
        }
        if (dinerMob === "") {
            formErrors.dinerMob = "Diner mobile no is required";
        }
		
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state)) {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            let formData = {
                orderType: this.state.orderType,
                orderNo: this.state.orderNo,
                orderDate: this.state.orderDate,
                deliveryDate: this.state.deliveryDate,
                pickupTime: this.state.pickupTime,
                chefName: this.state.chefName,
                dinerName: this.state.dinerName,
                dinerEmail: this.state.dinerEmail,
                dinerMob: this.state.dinerMob,
                dinerAdd: this.state.dinerAdd,
                orderAmount: this.state.orderAmount,
                deliveryAmount: this.state.deliveryAmount,
                promoCode: this.state.promoCode,
                promoDiscount: this.state.promoDiscount,
                receivedAmountDiner: this.state.receivedAmountDiner,
                isPaymentReceived: this.state.isPaymentReceived,
                isDelivered: this.state.isDelivered,
                isPaidToChef: this.state.isPaidToChef,
                razorpayID: this.state.razorpayID,
                sendToSandeepAt: this.state.sendToSandeepAt,
                orderDetails: this.state.orderDetails,
                remark: this.state.remark,
                reasonForOffline: this.state.reasonForOffline,
            }
            this.setState({
                buttonText: "Submitting...",
                buttonDisabled: true
            })
            return addOfflineOrderAPI(id, "", token, formData)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success(
                            "Offline order added successfully",
                            "Success",
                            3000
                        );
                        this.setState({
                            buttonText: "Submit",
                        })
                        setTimeout(() => {
                            this.props.history.push('/offline-order-listing');
                        }, 2000)
                    } else {
                        NotificationManager.error(response.data["message"], "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error, "Error!");
                    this.setState({
                        buttonText: "Submit",
                        buttonDisabled: false
                    })
                });
        } else {
            console.log('error');
        }
    }
    render() {
        const { formErrors } = this.state;
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Add Offline Order</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="row">
														<div className='col-sm-4'>
															<div className='form-group'>
															  <b>Order Type*</b>
															  <div className='form-line'>
																<select
																  className='form-control'
																  name='orderType'
																  onChange={this.handleFilterChange}
																  onBlur={this.handleFilterChange}
																>
																  <option value=''>Select Order Type</option>
																  <option
																	value='Tiffin'
																	selected={
																	  this.state.orderType == 'Tiffin'
																	}
																  >
																	Tiffin
																  </option>
																  <option
																	value='Party'
																	selected={
																	  this.state.orderType == 'Party'
																	}
																  >
																	Party
																  </option>
																  <option
																	value='Online'
																	selected={
																	  this.state.orderType == 'Online'
																	}
																  >
																	Online
																  </option>
																</select>
																</div>
																{formErrors.orderType.length > 0 && (
																	<span className="text-danger">{formErrors.orderType}</span>
																)}
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Razorpay ID</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="razorpayID"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														{/*<div className="col-md-4">
															<div className="form-group">
																<b>Order No*</b>
																<div className="form-line">
																	<input autoComplete="orderNo" type="text" className="form-control" name="orderNo" onChange={this.handleInputChange} />
																</div>
																{formErrors.orderNo.length > 0 && (
																	<span className="text-danger">{formErrors.orderNo}</span>
																)}
															</div>
														</div>*/}
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Order Date*</b>
																<div className="form-line">
																	<input autoComplete="orderDate" type="date" className="form-control" name="orderDate" onChange={this.handleInputChange} />
																</div>
																{formErrors.orderDate.length > 0 && (
																	<span className="text-danger">{formErrors.orderDate}</span>
																)}
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Delivery Date*</b>
																<div className="form-line">
																	<input autoComplete="deliveryDate" type="date" className="form-control" name="deliveryDate" onChange={this.handleInputChange} />
																</div>
																{formErrors.deliveryDate.length > 0 && (
																	<span className="text-danger">{formErrors.deliveryDate}</span>
																)}
															</div>
														</div>
														
														<div className='col-sm-4'>
															<div className='form-group'>
															  <b>Pickup Time*</b>
															  <div className='form-line'>
																<select
																  className='form-control'
																  name='pickupTime'
																  onChange={this.handleFilterChange}
																  onBlur={this.handleFilterChange}
																>
																  <option value=''>Select Pickup Time</option>
																  <option
																	value='01:00 PM'
																	selected={
																	  this.state.pickupTime == '01:00 PM'
																	}
																  >
																	01:00 PM
																  </option>
																  <option
																	value='02:00 PM'
																	selected={
																	  this.state.pickupTime == '02:00 PM'
																	}
																  >
																	02:00 PM
																  </option>
																  <option
																	value='03:00 PM'
																	selected={
																	  this.state.pickupTime == '03:00 PM'
																	}
																  >
																	03:00 PM
																  </option>
																  <option
																	value='04:00 PM'
																	selected={
																	  this.state.pickupTime == '04:00 PM'
																	}
																  >
																	04:00 PM
																  </option>
																  <option
																	value='05:00 PM'
																	selected={
																	  this.state.pickupTime == '05:00 PM'
																	}
																  >
																	05:00 PM
																  </option>
																  <option
																	value='06:00 PM'
																	selected={
																	  this.state.pickupTime == '06:00 PM'
																	}
																  >
																	06:00 PM
																  </option>
																  <option
																	value='07:00 PM'
																	selected={
																	  this.state.pickupTime == '07:00 PM'
																	}
																  >
																	07:00 PM
																  </option>
																  <option
																	value='08:00 PM'
																	selected={
																	  this.state.pickupTime == '08:00 PM'
																	}
																  >
																	08:00 PM
																  </option>
																  <option
																	value='09:00 PM'
																	selected={
																	  this.state.pickupTime == '09:00 PM'
																	}
																  >
																	09:00 PM
																  </option>
																  <option
																	value='10:00 PM'
																	selected={
																	  this.state.pickupTime == '10:00 PM'
																	}
																  >
																	10:00 PM
																  </option>
																  <option
																	value='11:00 PM'
																	selected={
																	  this.state.pickupTime == '11:00 PM'
																	}
																  >
																	11:00 PM
																  </option>
																  <option
																	value='12:00 PM'
																	selected={
																	  this.state.pickupTime == '12:00 PM'
																	}
																  >
																	12:00 PM
																  </option>
																  <option
																	value='01:00 AM'
																	selected={
																	  this.state.pickupTime == '01:00 AM'
																	}
																  >
																	01:00 AM
																  </option>
																  <option
																	value='02:00 AM'
																	selected={
																	  this.state.pickupTime == '02:00 AM'
																	}
																  >
																	02:00 AM
																  </option>
																  <option
																	value='03:00 AM'
																	selected={
																	  this.state.pickupTime == '03:00 AM'
																	}
																  >
																	03:00 AM
																  </option>
																  <option
																	value='04:00 AM'
																	selected={
																	  this.state.pickupTime == '04:00 AM'
																	}
																  >
																	04:00 AM
																  </option>
																  <option
																	value='05:00 AM'
																	selected={
																	  this.state.pickupTime == '05:00 AM'
																	}
																  >
																	05:00 AM
																  </option>
																  <option
																	value='06:00 AM'
																	selected={
																	  this.state.pickupTime == '06:00 AM'
																	}
																  >
																	06:00 AM
																  </option>
																  <option
																	value='07:00 AM'
																	selected={
																	  this.state.pickupTime == '07:00 AM'
																	}
																  >
																	07:00 AM
																  </option>
																  <option
																	value='08:00 AM'
																	selected={
																	  this.state.pickupTime == '08:00 AM'
																	}
																  >
																	08:00 AM
																  </option>
																  <option
																	value='09:00 AM'
																	selected={
																	  this.state.pickupTime == '09:00 AM'
																	}
																  >
																	09:00 AM
																  </option>
																  <option
																	value='10:00 AM'
																	selected={
																	  this.state.pickupTime == '10:00 AM'
																	}
																  >
																	10:00 AM
																  </option>
																  <option
																	value='11:00 AM'
																	selected={
																	  this.state.pickupTime == '11:00 AM'
																	}
																  >
																	11:00 AM
																  </option>
																  <option
																	value='12:00 AM'
																	selected={
																	  this.state.pickupTime == '12:00 AM'
																	}
																  >
																	12:00 AM
																  </option>
																</select>
															  </div>
															  {formErrors.pickupTime.length > 0 && (
																	<span className="text-danger">{formErrors.pickupTime}</span>
																)}
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Chef*</b>
																<div className="form-line">
																 <select
																	className="form-control show-tick"
																	value={this.state.chefName}
																	id="chefName"
																	onChange={(v) => {
																	  const { value } = v.target;
																	  if (
																		value !== "-- Any --" ||
																		value !== "0"
																	  ) {
																		this.setState({
																		  chefName: v.target.value,
																		  chefDetails: v.target.selectedOptions[0].attributes['data-mobile_number'].value +' - '+v.target.selectedOptions[0].attributes['data-city'].value,
																		});
																	  } else {
																		this.setState({
																		  chefName: undefined,
																		  chefDetails: '',
																		});
																	  }
																	}}
																  >
																	<option value={"0"} data-mobile_number={""} data-city={""}>Select Chef</option>
																	{this.state.chefArr.map((el) => (
																	
																	  <option value={el.value} data-mobile_number={el.mobile_number} data-city={el.city}>
																		{el.label}
																	  </option>
																	))}
																  </select>
																</div>
																{this.state.chefDetails != '' && (
																	<span>{this.state.chefDetails}</span>
																)}
																{formErrors.chefName.length > 0 && (
																	<span className="text-danger">{formErrors.chefName}</span>
																)}
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Diner Name*</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="dinerName"
																		onChange={this.handleInputChange} />
																</div>
																{formErrors.dinerName.length > 0 && (
																	<span className="text-danger">{formErrors.dinerName}</span>
																)}
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Diner Email ID</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="dinerEmail"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Diner Mobile No*</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="dinerMob"
																		onChange={this.handleInputChange} />
																</div>
																{formErrors.dinerMob.length > 0 && (
																	<span className="text-danger">{formErrors.dinerMob}</span>
																)}
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Diner Address</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="dinerAdd"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Order Amount</b>
																<div className="form-line">
																	<input type="number" step="any" className="form-control" name="orderAmount"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Delivery Amount</b>
																<div className="form-line">
																	<input type="number" step="any" className="form-control" name="deliveryAmount"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Promo Code</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="promoCode"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Promo Discount</b>
																<div className="form-line">
																	<input type="number" step="any" className="form-control" name="promoDiscount"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Received Amount Diner</b>
																<div className="form-line">
																	<input type="number" className="form-control" name="receivedAmountDiner"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
														
														<div className='col-sm-4'>
															<div className='form-group'>
															  <b>Is Delivered</b>
															  <div className='form-line'>
																<select
																  className='form-control'
																  name='isDelivered'
																  onChange={this.handleFilterChange}
																  onBlur={this.handleFilterChange}
																>
																  <option value=''>Select Is Delivered</option>
																  <option
																	value='Yes'
																	selected={
																	  this.state.isDelivered == 'Yes'
																	}
																  >
																	Yes
																  </option>
																  <option
																	value='No'
																	selected={
																	  this.state.isDelivered == 'No'
																	}
																  >
																	No
																  </option>
																</select>
																</div>
															</div>
														</div>
														
														<div className='col-sm-4'>
															<div className='form-group'>
															  <b>Is Payment Received</b>
															  <div className='form-line'>
																<select
																  className='form-control'
																  name='isPaymentReceived'
																  onChange={this.handleFilterChange}
																  onBlur={this.handleFilterChange}
																>
																  <option value=''>Select Is Payment Received</option>
																  <option
																	value='Yes'
																	selected={
																	  this.state.isPaymentReceived == 'Yes'
																	}
																  >
																	Yes
																  </option>
																  <option
																	value='No'
																	selected={
																	  this.state.isPaymentReceived == 'No'
																	}
																  >
																	No
																  </option>
																</select>
																</div>
															</div>
														</div>
														
														<div className='col-sm-4'>
															<div className='form-group'>
															  <b>Is Paid To Chef</b>
															  <div className='form-line'>
																<select
																  className='form-control'
																  name='isPaidToChef'
																  onChange={this.handleFilterChange}
																  onBlur={this.handleFilterChange}
																>
																  <option value=''>Select Is Paid To Chef</option>
																  <option
																	value='Yes'
																	selected={
																	  this.state.isPaidToChef == 'Yes'
																	}
																  >
																	Yes
																  </option>
																  <option
																	value='No'
																	selected={
																	  this.state.isPaidToChef == 'No'
																	}
																  >
																	No
																  </option>
																</select>
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Send To Sandeep At</b>
																<div className="form-line">
																	<input type="date" className="form-control" name="sendToSandeepAt"
																		onChange={this.handleInputChange} />
																</div>
															</div>
														</div>
													</div>
														
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<b>Order Details</b>
																<div className="form-line">
																	<textarea
																		className='form-control'
																		rows={3}
																		defaultValue={this.state.orderDetails}
																		onChange={(e) =>
																			this.setState({ orderDetails: e.target.value })
																		}
																		name='orderDetails'
																	></textarea>
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Remark</b>
																<div className="form-line">
																	<textarea
																		className='form-control'
																		rows={3}
																		defaultValue={this.state.remark}
																		onChange={(e) =>
																			this.setState({ remark: e.target.value })
																		}
																		name='remark'
																	></textarea>
																</div>
															</div>
														</div>
														
														<div className="col-md-4">
															<div className="form-group">
																<b>Reason For Offline</b>
																<div className="form-line">
																	<textarea
																		className='form-control'
																		rows={3}
																		defaultValue={this.state.reasonForOffline}
																		onChange={(e) =>
																			this.setState({ reasonForOffline: e.target.value })
																		}
																		name='reasonForOffline'
																	></textarea>
																</div>
															</div>
														</div>
                                                    </div>
													
													<div className="row">
														<div className="col-md-12">							
															<Link to="/offline-order-listing" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
															<button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
														</div>
													</div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}