/*
#############################################################################
# Name : AddKitchenMaterial.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to add new kitchen material from backend
#############################################################################
*/
import React, { Component } from 'react'
import Header from "../../components/Header";
import { addKitchenMaterialAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import Moment from 'moment';
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { NotificationManager } from "react-notifications";

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === null && (valid = false)
    })

    return valid;
}

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class AddKitchenMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kitchenMaterialName: '',
            kitchenMaterialDescription: '',
            isCookingMedium: false,
            isKitchenMedium: false,
            formErrors: {
                kitchenMaterialName: '',
                kitchenMaterialDescription: '',
            },
            buttonText: "Submit",
            buttonDisabled: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCheckInputChange = (e, type) => {
        switch (type) {
            case 'cooking':
                if (e.target.checked) {
                    this.setState({
                        isCookingMedium: true,
                        isKitchenMedium: false
                    })
                } else {
                    this.setState({
                        isCookingMedium: false
                    })
                }
                break

            case 'kitchen':
                if (e.target.checked) {
                    this.setState({
                        isCookingMedium: false,
                        isKitchenMedium: true
                    })
                } else {
                    this.setState({
                        isKitchenMedium: false
                    })
                }
                break
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'kitchenMaterialName':
                formErrors.kitchenMaterialName = value.length <= 0 ? 'Kitchen material name is required' : !value.match(/^[a-zA-Z ]+$/) ? "Enter valid kitchen material name" : "";
                break;

            case 'kitchenMaterialDescription':
                formErrors.kitchenMaterialDescription = value.length <= 0 ? 'Kitchen material description is required' : "";
                break;

            default:
                break;
        }

        this.setState({ formErrors, [name]: value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        const { kitchenMaterialName, kitchenMaterialDescription, isCookingMedium, isKitchenMedium } = this.state;
        if (kitchenMaterialName === "") {
            formErrors.kitchenMaterialName = "Kitchen material name is required";
        }
        if (kitchenMaterialDescription === "") {
            formErrors.kitchenMaterialDescription = "Kitchen material description is required";
        }
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state)) {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            this.setState({
                buttonText: "Submitting...",
                buttonDisabled: true
            })
            let fd = {
                kitchenMaterialName: kitchenMaterialName,
                kitchenMaterialDescription: kitchenMaterialDescription,
                isCookingMedium: this.state.isCookingMedium ? "1" : "0",
                isKitchenMedium: this.state.isKitchenMedium ? "1" : "0"
            }
            return addKitchenMaterialAPI(id, token, fd)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success(
                            "Kitchen material added successfully",
                            "Success",
                            3000
                        );
                        this.setState({
                            buttonText: "Submit",
                        })
                        setTimeout(() => {
                            this.props.history.push('/kitchen-material-list');
                        }, 2000)
                    } else {
                        NotificationManager.error(response.data["message"], "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error, "Error!");
                    this.setState({
                        buttonText: "Submit",
                        buttonDisabled: false
                    })
                });
        } else {
            console.log('error');
        }
    }
    render() {
        const { formErrors } = this.state;
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Add Kitchen and Cooking Material</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <b>Kitchen Material Name*</b>
                                                        <div className="form-line">
                                                            <input type="text" className="form-control" name="kitchenMaterialName"
                                                                onChange={this.handleInputChange} />
                                                        </div>
                                                        {formErrors.kitchenMaterialName.length > 0 && (
                                                            <span className="text-danger">{formErrors.kitchenMaterialName}</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <b>Kitchen Material Description*</b>
                                                        <div className="form-line">
                                                            <textarea
                                                                className="form-control"
                                                                rows={3}
                                                                name="kitchenMaterialDescription"
                                                                onChange={this.handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                        {formErrors.kitchenMaterialDescription.length > 0 && (
                                                            <span className="text-danger">{formErrors.kitchenMaterialDescription}</span>
                                                        )}
                                                    </div>

                                                    {/* <div className="form-group">
                                                        <div style={{ marginLeft: "20px" }}>
                                                            <label>Is cooking medium?</label>
                                                            <div>
                                                                <input name="isCookingMedium"
                                                                    type="radio"
                                                                    id="cook_1"
                                                                    className="radio-col-orange"
                                                                    value="1"
                                                                    onChange={this.handleInputChange}
                                                                />
                                                                <label for="cook_1">Yes</label>
                                                                <input name="isCookingMedium"
                                                                    type="radio"
                                                                    id="cook_2"
                                                                    className="radio-col-orange"
                                                                    value="0"
                                                                    onChange={this.handleInputChange}
                                                                />
                                                                <label for="cook_2">No</label>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="form-group">
                                                        <div>
                                                            {/* <label>Is Cooking medium?</label> */}
                                                            <div>
                                                                <label for="menu_1234545454" style={{ fontWeight: '100', marginLeft: "20px" }}>Is cooking medium?</label>
                                                                <input type="checkbox" id="menu_1234545454" name="isCookingMedium" className="filled-in" onChange={(e) => this.handleCheckInputChange(e, 'cooking')} checked={this.state.isCookingMedium} />
                                                            </div>
                                                            <div style={{ marginTop: "20px" }}>
                                                                <label for="menu_12345" style={{ fontWeight: '100', marginLeft: "20px" }}>Is kitchen medium?</label>
                                                                <input type="checkbox" id="menu_12345" name="isKitchenMedium" className="filled-in" onChange={(e) => this.handleCheckInputChange(e, 'kitchen')} checked={this.state.isKitchenMedium} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/kitchen-material-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                    <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
