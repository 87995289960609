import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { getFAQListAPI, deleteFAQAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

export default class DinerFAQListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          dinerFAQList: [],
          activePage: 1,
          totalRecords: 10,
        };
      }
    
      componentDidMount() {
        this.getDinerFAQList();
      }
    
      getDinerFAQList = () => {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var currentPage = this.state.activePage;
        return getFAQListAPI(id, currentPage, token, "Diner")
          .then((response) => {
            if (response.data["status"] === 200) {
              /* console.log(response.data["data"]); */
              this.setState({
                dinerFAQList: response.data["data"].data,
                totalRecords: response.data["data"]["pager_count"],
                isLoading: false,
              });
            } else {
              this.setState({
                isLoading: false,
              });
              NotificationManager.error(response.data["message"], "Error!");
            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            NotificationManager.error("Something went wrong", "Error!");
          });
      };
    
      handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber, isLoading: true }, () => {
          this.getDinerFAQList();
        });
        }
        
        deleteBanner = (faqId) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui text-center'>
                            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
                            <p style={{ marginTop: "20px", marginBottom: "40px" }}>You want to delete this banner?</p>
                            <button className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }} onClick={onClose}>No</button>
                            <button
                                class="btn"
                                onClick={() => {
                                    this.handleClickDelete(faqId);
                                    onClose();
                                }}
                            >
                                Yes, Delete it!
                        </button>
                        </div>
                    );
                }
            });
        }
    
        handleClickDelete = (faqId) => {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            return deleteFAQAPI(id, token, faqId)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success("FAQ deleted successfully", "Success");
                        this.getDinerFAQList()
                    } else {
                        NotificationManager.error(response.data["message"], "Error!");
                    }
                })
                .catch((error) => {
                    NotificationManager.error("Something went wrong", "Error!");
                });
        }
  render() {
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Diner FAQ's</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <Link className="btn" to="/add-faq">
                      Add FAQ's
                    </Link>
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                                    >
                                        <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="align-middle text-center">
                              Sr. No.
                            </th>
                            <th className="align-middle text-center">
                              Question
                            </th>
                            <th className="align-middle text-center">Answer</th>
                            <th className="align-middle text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="9">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.dinerFAQList.length ? (
                          <tbody>
                            {this.state.dinerFAQList.map((faq, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td width="20%" className="align-middle">
                                    {faq.question}
                                  </td>
                                  <td width="20%" className="align-middle">
                                    {faq.answer}
                                  </td>
                                  <td
                                    width="10%"
                                    className="align-middle"
                                    style={styles.th}
                                  >
                                    <Link
                                      to={"/edit-faq?id=" + faq.id}
                                      style={{ marginRight: "10px" }}
                                    >
                                      <i className="material-icons view_link">
                                        create
                                      </i>
                                    </Link>
                                    <i
                                      className="material-icons view_link"
                                      onClick={() => this.deleteBanner(faq.id)}
                                    >
                                      delete
                                    </i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="9">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
    th: {
      textAlign: "center",
    },
  };
  
