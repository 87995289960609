import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import ProfileTab from "./ProfileTab";
import OrderTab from "./orderTab";
import { NotificationManager } from "react-notifications";
import { updateUserPasswordAPI } from "../../core/services/APIServices";

export default class DinerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: '',
      userEmailID: '',
      fName: "",
      lName: "",
      userPassword: "",
      userConfirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      buttonText: "Submit",
      buttonDisabled: false,
    };
  }
  
  componentDidMount() {
    var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
  }

  handleUserdata = (dataValue) => {
    this.setState({
      fName: dataValue.fName,
      lName: dataValue.lName,
    });
  };

  onPasswordChange = (e) => {
    const { name, value } = e.target;
    /* console.log(value); */
    switch (name) {
      case "userPassword":
        this.setState({
          passwordError:
            value.length <= 0
              ? "Please enter new password"
              : value.length < 6
              ? "Password must be atleast 6 characters"
              : "",
        });
        break;

      case "userConfirmPassword":
        this.setState({
          confirmPasswordError:
            value.length <= 0
              ? "Please confirm new password"
              : value !== this.state.userPassword
              ? "Password is not matching!"
              : "",
        });
        break;
    }
    this.setState({ [name]: value });
  };
  changePasswordSubmit = (e) => {
    e.preventDefault();
    if (this.state.userPassword === "") {
      this.setState({
        passwordError: "Please enter new password",
      });
    }
    if (this.state.userConfirmPassword === "") {
      this.setState({
        confirmPasswordError: "Please confirm new password",
      });
    }
    setTimeout(() => {
      if (
        this.state.passwordError === "" &&
        this.state.confirmPasswordError === ""
      ) {
        this.setState({
          buttonText: "Submitting...",
          buttonDisabled: true,
        });

        var id = localStorage.getItem("userID");
        var dinerId = localStorage.getItem("dinerID");
        var token = localStorage.getItem("accessToken");
        let data = {
          password: this.state.userPassword,
          password_confirm: this.state.userConfirmPassword,
        };
        return updateUserPasswordAPI(id, dinerId, token, data)
          .then((response) => {
            if (response.data["status"] === 200) {
              NotificationManager.success(
                "Password changed successfully",
                "Success",
                3000
              );
              document.getElementById("changePasswordForm").reset();
              this.setState({
                userPassword: "",
                userConfirmPassword: "",
                passwordError: "",
                confirmPasswordError: "",
              });
              this.setState(
                {
                  buttonText: "Submit",
                  buttonDisabled: false,
                },
                () => {
                  document.getElementById("closePasswordButton").click();
                }
              );
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                buttonText: "Submit",
                buttonDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          });
      }
    }, 100);
  };

  menuModalClosed = () => {
    document.getElementById("changePasswordForm").reset();
    this.setState({
      userPassword: "",
      userConfirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
    });
  };

  render() {
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="body">
                    <div className="text-right">
                      <h6 className="user_name">
                        {this.state.fName + " " + this.state.lName}
                      </h6>
                    </div>
                    <ul className="nav nav-tabs tab-nav-right" role="tablist">
                      <li role="presentation" className="active">
                        <a href="#Profile" data-toggle="tab">
                          <i className="material-icons">face</i>Profile
                        </a>
                      </li>
                      <li role="presentation">
                        <a href="#Order" data-toggle="tab">
                          <i className="material-icons">menu</i>Order
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane fade in active"
                        id="Profile"
                      >
                        <ProfileTab onUserdataChanges={this.handleUserdata} />
                      </div>

                      <div role="tabpanel" className="tab-pane fade" id="Order">
                        <OrderTab />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade menu_list_pop add_menu_list_pop"
          id="myModalDinerPasswordChange"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h4>Change Password</h4>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={this.changePasswordSubmit}
                  id="changePasswordForm"
                >
                  <div className="form-group">
                    <b>New Password</b>
                    <div className="form-line">
                      <input
                        type="password"
                        className="form-control"
                        name="userPassword"
                        onChange={this.onPasswordChange}
                      />
                    </div>
                    {this.state.passwordError.length > 0 && (
                      <small className="text-danger">
                        {this.state.passwordError}
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="form-line">
                      <b>Confirm Password</b>
                      <input
                        type="password"
                        className="form-control"
                        name="userConfirmPassword"
                        onChange={this.onPasswordChange}
                      />
                    </div>
                    {this.state.confirmPasswordError.length > 0 && (
                      <small className="text-danger">
                        {this.state.confirmPasswordError}
                      </small>
                    )}
                  </div>
                  <div className="text-center" style={{ marginTop: "30px" }}>
					{/*  Admin and Ops menu */}
					  {this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
						<button
						  type="button"
						  data-dismiss="modal"
						  id="closePasswordButton"
						  aria-label="Close"
						  disabled={this.state.buttonDisabled}
						  className="btn btn-default waves-effect"
						  style={{ color: "#000", marginRight: "10px" }}
						  onClick={this.menuModalClosed}
						>
						  Cancel
						</button>
					  ) : null}
                    
					{/*  Admin and Ops menu */}
					  {this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
						 <button
						  type="submit"
						  disabled={this.state.buttonDisabled}
						  className="btn"
						>
						  {this.state.buttonText}
						</button>
					  ) : null}
					  
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
