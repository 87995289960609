import React, { Component } from "react";
import Header from "../../components/Header";
import {
  getDiscountCodeAPI,
  deleteDiscountCodeAPI,
  getDiscountCodeDetailAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class DiscountCodeListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
	  userName: '',
	  userEmailID: '',
      isLoading: true,
      discountCodeArr: [],
      discountCodeExcelData: [],
      activePage: 1,
      totalRecords: 10,
      couponCode: "",
      minimumOrser: "",
      perUserOrderLimit: "",
      perUserUsageLimit: "",
      selectedWeekDays: "",
      status: "",
      couponCodeName: "",
      visible: "",
      couponDesc: "",
      startDate: "",
      endDate: "",
      flatDiscount: "",
      freeDelivery: "",
      globalUsageLimit: "",
      createdOn: "",
      customErrorMessage: "",
      customSuccessMessage: "",
      couponStatus: "",
      couponFilter: "",
      currentDate: "",
      resetFlag: false,
      discount_type: "",
      maximum_discount_amount: "",
      percentile_discount: "",
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  componentDidMount() {
	var name = localStorage.getItem('userName');
	var email = localStorage.getItem('userEmail');
	var role = localStorage.getItem('userRole');
	this.setState({
	  userRole: role,
	  userName: name,
	  userEmailID: email
	});
    this.getDiscountCodeListAPI();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getDiscountCodeListAPI();
    });
  }

  getUserView = (id, event) => {
    event.preventDefault();
    localStorage.setItem("chefID", id);
    this.props.history.push("/chef-data");
  };

  getDiscountCodeListAPI() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getDiscountCodeAPI(
      id,
      currentPage,
      token,
      this.state.couponFilter,
      this.state.couponStatus
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            discountCodeArr: response.data["data"].data,
            discountCodeExcelData: response.data["data"]["all_records"],
            totalRecords: response.data["data"]["total_records"],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  }

  handleClickDelete = (couponId) => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteDiscountCodeAPI(id, couponId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            "Coupon Code deleted successfully",
            "Success"
          );
          this.getDiscountCodeListAPI();
        } else {
          NotificationManager.error("Something went wrong", "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  deleteCouponCode = (couponId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this Coupon Code?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(couponId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  getCouponCodeDetails = (couponId) => {
    console.log(couponId);
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getDiscountCodeDetailAPI(userId, couponId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          console.log(response.data.data);
          this.setState({
            couponCode: response.data.data.coupon_code,
            minimumOrser: response.data.data.minimum_order_amount,
            perUserOrderLimit: response.data.data.per_user_order_limit,
            perUserUsageLimit: response.data.data.per_user_usage_limit,
            selectedWeekDays: response.data.data.selected_weekday,
            status: response.data.data.status,
            couponCodeName: response.data.data.title,
            visible: response.data.data.visible,
            couponDesc: response.data.data.description,
            startDate: response.data.data.start_date,
            endDate: response.data.data.end_date,
            flatDiscount: response.data.data.flat_discount,
            freeDelivery: response.data.data.free_delivery_enabled,
            globalUsageLimit: response.data.data.global_usage_limit,
            createdOn: response.data.data.created_at,
            customErrorMessage: response.data.data.custom_error_message,
            customSuccessMessage: response.data.data.custom_success_message,
            discount_type: response.data.data.discount_type,
            maximum_discount_amount: response.data.data.maximum_discount_amount,
            percentile_discount: response.data.data.percentile_discount,
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleStatusChange(event) {
    this.setState({ couponStatus: event.target.value });
  }

  handleFilterChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        couponFilter: "",
        couponStatus: "",
      },
      () => {
        this.getDiscountCodeListAPI();
      }
    );
  };

  submitFilter = (event) => {
    event.preventDefault();
    this.setState({ resetFlag: true });
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = "1";
    this.setState({
      activePage: 1,
    });
    return getDiscountCodeAPI(
      id,
      currentPage,
      token,
      this.state.couponFilter,
      this.state.couponStatus
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            discountCodeArr: response.data["data"].data,
            discountCodeExcelData: response.data["data"]["all_records"],
            totalRecords: response.data["data"]["total_records"],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Discount Coupon Code Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Filter</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Coupon Code or Title"
                                  name="couponFilter"
                                  value={this.state.couponFilter}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <b>Status</b>
                              <div className="form-line">
                                <select
                                  className="form-control show-tick"
                                  value={this.state.couponStatus}
                                  onChange={this.handleStatusChange}
                                >
                                  <option value="">-- Any --</option>
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="COMPLETED">COMPLETED</option>
                                  <option value="EXPIRED">EXPIRED</option>
                                  <option value="CANCELLED">CANCELLED</option>
                                  {/*  <option value="DISABLED">DISABLED</option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 center_btn">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
					{/*  Admin and Marketing menu */}
					{this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                      <Link className="btn" to="/add-discount-code">
                        Add Coupon Code
                      </Link>
					  ) : null}
                      {this.state.discountCodeArr.length && (this.state.userRole == 'Admin' || this.state.userRole == 'Finance') ? (
                        <ExcelFile
                          filename={"Discount_Report_" + this.state.currentDate}
                          element={
                            <button className="btn pull-right m-b-10">
                              Download Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.discountCodeExcelData}
                            name="Discount Coupon Report"
                          >
                            <ExcelColumn
                              label="Coupon Date"
                              value="created_at"
                            />
                            <ExcelColumn label="Coupon Title" value="title" />
                            <ExcelColumn
                              label="Coupon Description"
                              value="description"
                            />
                            <ExcelColumn
                              label="Coupon Code"
                              value="coupon_code"
                            />
                            <ExcelColumn
                              label="Coupon Start Date"
                              value="start_date"
                            />
                            <ExcelColumn
                              label="Coupon End Date"
                              value="end_date"
                            />
                            <ExcelColumn
                              label="WeekDays"
                              value="selected_weekday"
                            />
                            <ExcelColumn
                              label="Minimum Order Amount"
                              value="minimum_order_amount"
                            />
                            <ExcelColumn
                              label="Coupon Visible"
                              value="visible"
                            />
                            <ExcelColumn
                              label="Flat Discount"
                              value="flat_discount"
                            />
                            <ExcelColumn
                              label="Global Usage Limit"
                              value="global_usage_limit"
                            />
                            <ExcelColumn
                              label="Order No. Condition"
                              value="per_user_order_limit"
                            />
                            <ExcelColumn
                              label="Per User Usage Limit"
                              value="per_user_usage_limit"
                            />
                            <ExcelColumn
                              label="Free Delivery"
                              value="free_delivery_enabled"
                            />
                            <ExcelColumn
                              label="Coupon Used"
                              value="usage_count"
                            />

                            <ExcelColumn
                              label="Coupon Unused"
                              value="unused_coupon_count"
                            />
                            <ExcelColumn label="Coupon Status" value="status" />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="align-middle text-center">
                              Sr. No.
                            </th>
                            <th className="align-middle text-center">
                              Coupon Code
                            </th>
                            <th className="align-middle text-center">Title</th>
                            <th className="align-middle text-center">
                              Minimum Order Amount
                            </th>
                            <th className="align-middle text-center">
                              Discount Type
                            </th>
                            <th className="align-middle text-center">
                              Flat Discount
                            </th>
                            <th className="align-middle text-center">
                              Percentage Discount
                            </th>
                            <th className="align-middle text-center">
                              Maximum Discount Amount
                            </th>

                            <th className="align-middle text-center">
                              Used coupon
                            </th>
                            <th className="align-middle text-center">
                              Unused coupon
                            </th>
                            <th className="align-middle text-center">
                              Free Delivery
                            </th>
                            <th className="align-middle text-center">
                              Visible
                            </th>
                            <th className="align-middle text-center">Status</th>
                            <th className="align-middle text-center">
                              Created On
                            </th>
                            <th className="align-middle text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="12">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.discountCodeArr.length ? (
                          <tbody>
                            {this.state.discountCodeArr.map((couponCode, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="5%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td className="align-middle" width="25%">
                                    {couponCode.coupon_code}
                                  </td>
                                  <td className="align-middle" width="25%">
                                    {couponCode.title}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.minimum_order_amount}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.discount_type}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.flat_discount}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.percentile_discount}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.maximum_discount_amount}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.usage_count}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.unused_coupon_count == null ||
                                    couponCode.unused_coupon_count == "null"
                                      ? "-"
                                      : couponCode.unused_coupon_count}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.free_delivery_enabled == null ||
                                    couponCode.free_delivery_enabled ==
                                      "null" ||
                                    couponCode.free_delivery_enabled == 0
                                      ? "No"
                                      : "Yes"}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {couponCode.visible == null ||
                                    couponCode.visible == "null" ||
                                    couponCode.visible == 0
                                      ? "No"
                                      : "Yes"}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="20%"
                                  >
                                    {couponCode.status}
                                  </td>
                                  <td className="align-middle" width="20%">
                                    {Moment(couponCode.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td
                                    width="10%"
                                    className="align-middle text-center"
                                    style={styles.th}
                                  >
                                    <i
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#myModal"
                                      className="material-icons view_link"
                                      onClick={() =>
                                        this.getCouponCodeDetails(couponCode.id)
                                      }
                                    >
                                      remove_red_eye
                                    </i>
									{/*  Admin and Marketing menu */}
									{this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                                    <Link
                                      to={
                                        "/edit-discount-code?id=" +
                                        couponCode.id
                                      }
                                      style={{
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      <i className="material-icons view_link">
                                        create
                                      </i>
                                    </Link>
									) : null}
									{/*  Admin and Marketing menu */}
									{this.state.userRole == 'Admin' ? (
                                    <i
                                      className="material-icons view_link"
                                      onClick={() =>
                                        this.deleteCouponCode(couponCode.id)
                                      }
                                    >
                                      delete
                                    </i>
									) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="12">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade menu_list_pop"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="add_another_btn">
                  <button
                    type="button"
                    className="close plus_icon"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <h4 className="modal-title color_C07" id="myModalLabel">
                  Discount Coupon Code Details
                </h4>
              </div>
              <div className="modal-body">
                <form id="chefMenuForm">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Coupon Code:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.couponCode}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Title:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.couponCodeName}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <b>Description:</b>
                        <div className="form-line">
                          <textarea
                            className="form-control"
                            rows={3}
                            defaultValue={this.state.couponDesc}
                            readOnly
                            name="menuName"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Coupon Status</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.status}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Coupon Created On</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.createdOn}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <b>Selected WeekDays:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.selectedWeekDays}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Start Date:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.startDate}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>End Date</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.endDate}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Minimum Order Amount</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.minimumOrser}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Per User Order Limit</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.perUserOrderLimit}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Per User Usage Limit</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.perUserUsageLimit}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.discount_type === "Delivery" ? null : (
                      <div className="col-sm-4">
                        <div className="input-group">
                          <input
                            name="discount_type"
                            type="radio"
                            id="percentage"
                            className="radio-col-orange"
                            value="Percentile"
                            checked={this.state.discount_type === "Percentile"}
                            readOnly
                          />
                          <label for="percentage">Percentage</label>

                          <input
                            name="discount_type"
                            type="radio"
                            id="flat_discount"
                            className="radio-col-orange"
                            value="Flat"
                            checked={this.state.discount_type === "Flat"}
                            readOnly
                          />
                          <label for="flat_discount">Flat Discount</label>
                        </div>
                        {this.state.discount_type === "Flat" ? (
                          <div className="form-group">
                            <b>Flat Discount*</b>
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="flatOrder"
                                value={this.state.flatDiscount}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="form-group">
                              <div className="form-line">
                                <b>Percentage Discount*</b>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="flatOrder"
                                  value={this.state.percentile_discount}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <b>Maximum Discount Amount in Ruppees*</b>

                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="flatOrder"
                                  value={this.state.maximum_discount_amount}
                                  maxLength="20"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Global Usage Limit</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.globalUsageLimit}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Custom Error Message</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.customErrorMessage}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Custom Success Message</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.customSuccessMessage}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Visible</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            value={this.state.visible === "1" ? "Yes" : "No"}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Free Delivery</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            value={
                              this.state.freeDelivery === "1" ? "Yes" : "No"
                            }
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  th: {
    textAlign: "center",
  },
};
