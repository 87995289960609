import axios from 'axios';
import config from '../../config/config';

async function signinAPI(email, password) {
  var formdata = new FormData();
  formdata.append('email', email);
  formdata.append('password', password);
  try {
    const response = await axios.post(config.APIURL + 'login', formdata);
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getUserListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-registered-chef-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getUserProfileDetailAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-chef-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getUserBusinessDetailAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-chef-business-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getUserKitchenDetailAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-chef-kitchen-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getKitchenMenuAPI(userId, chefId, menuId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('menu_id', menuId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-menu-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getKycDetailsAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_user_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-membership-assignment',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function submitKycDetailsAPI(userId, chefId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_user_id', chefId);
  formdata.append('membership_assignment', fd.membership_assignment);
  formdata.append('verified', fd.verified);
  formdata.append('promoted', fd.promoted);
  formdata.append('verify_mou_order', fd.verify_mou_order);
  formdata.append(
    'verify_chef_details_menu_order',
    fd.verify_chef_details_menu_order
  );
  formdata.append('kyc_verification_completed', fd.kyc_verification_completed);
  formdata.append('commission_rate', fd.commission_rate);
  formdata.append('is_published', fd.is_published);
  formdata.append('packagess', fd.packagess);
  formdata.append('join_date', fd.join_date);
  formdata.append('expire_date', fd.expire_date);
  formdata.append('payment_date', fd.payment_date);
  formdata.append('paid_amount', fd.paid_amount);
  formdata.append('payment_remark', fd.payment_remark);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-chef-membership',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getKitchenMenuListAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-kitchen-menu-list',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMenuDetailsAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-menu-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMenuDocumentsAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-menu-document-list',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteUserAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('profile_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-delete-chef-profile',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getQueryListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-query-messages-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDinnerListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-diner-messages-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCityWiseReportAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-city-wise-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getStepWiseReportAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-status-wise-list',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getReportAPI(
  userId,
  currentPage,
  first_name,
  last_name,
  brand_name,
  email,
  mobile_number,
  city_name,
  last_updated_filter,
  status_filter,
  same_day_delivery,
  fssai,
  from_date,
  to_date,
  marketing_fees,
  registration_fees,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('first_name', first_name);
  formdata.append('last_name', last_name);
  formdata.append('brand_name', brand_name);
  formdata.append('email', email);
  formdata.append('mobile_number', mobile_number);
  formdata.append('city_name', city_name);
  formdata.append('last_updated_filter', last_updated_filter);
  formdata.append('status_filter', status_filter);
  formdata.append('same_day_delivery', same_day_delivery);
  formdata.append('fssai', fssai);
  formdata.append('from_date', from_date);
  formdata.append('to_date', to_date);
  formdata.append('marketing_fees', marketing_fees);
  formdata.append('registration_fees', registration_fees);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-report-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Cuisine
async function getCuisineListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-cuisines?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCuisineAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-cuisine',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addCuisineAPI(userId, token, name, description) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('cuisine_name', name);
  formdata.append('description', description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/save-cuisines',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editCuisineAPI(userId, token, id, name, description) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('cuisine_name', name);
  formdata.append('description', description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/save-cuisines',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteCuisineAPI(userId, token, cuisineId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', cuisineId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-cuisines',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Category
async function getCategoryListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-categories?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addCategoryAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('category_name', fd.name);
  formdata.append('description', fd.description);
  formdata.append('x_order', fd.x_order);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-categories',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCategoryAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-category',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editCategoryAPI(userId, token, id, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('category_name', data.name);
  formdata.append('description', data.description);
  formdata.append('x_order', data.x_order);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-categories',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteCategoryAPI(userId, token, categoryId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', categoryId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-categories',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getArticleListAPI(userId, currentPage, token, search) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('search_term', search);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-seo-articles?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addArticleAPI(
  userId,
  token,
  tagTitle,
  articleTitle,
  articleContent
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('tag_title', tagTitle);
  formdata.append('article_title', articleTitle);
  formdata.append('article_content', articleContent);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-seo-article',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editArticleAPI(
  userId,
  token,
  articleId,
  tagTitle,
  articleTitle,
  articleContent
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', articleId);
  formdata.append('tag_title', tagTitle);
  formdata.append('article_title', articleTitle);
  formdata.append('article_content', articleContent);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-seo-article',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getArticleAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-seo-article',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteArticleAPI(userId, token, articleId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', articleId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-seo-article',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}
// Tags
async function getTagListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-tags?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addTagAPI(userId, token, name, description) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('tag_name', name);
  formdata.append('description', description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-tags',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteTagAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-tags',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getTagAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(config.APIURL + 'v1/get-tag', formdata, {
      headers: {
        Authorization: token
      }
    });
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editTagAPI(userId, token, id, name, description) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('tag_name', name);
  formdata.append('description', description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-tags',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// City
async function getCityListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-cities?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addCityAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('name', fd.cityName);
  formdata.append('state', fd.state);
  formdata.append('pincode', fd.pincode);
  formdata.append('country', fd.country);
  formdata.append('latitude', fd.latitude);
  formdata.append('longitude', fd.longitude);
  formdata.append('city_image', fd.image);
  formdata.append('is_published', fd.is_published);
  formdata.append('primary_delivery_type', fd.primary_delivery_type);
  formdata.append('secondary_delivery_type', fd.secondary_delivery_type);
  formdata.append('region', fd.region);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/save-cities',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteCityAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-cities',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCityAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(config.APIURL + 'v1/get-city', formdata, {
      headers: {
        Authorization: token
      }
    });
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editCityAPI(userId, token, id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('name', fd.cityName);
  formdata.append('state', fd.state);
  formdata.append('pincode', fd.pincode);
  formdata.append('country', fd.country);
  formdata.append('latitude', fd.latitude);
  formdata.append('longitude', fd.longitude);
  formdata.append('is_published', fd.is_published);
  formdata.append('primary_delivery_type', fd.primary_delivery_type);
  formdata.append('secondary_delivery_type', fd.secondary_delivery_type);
  formdata.append('region', fd.region);
  if (fd.image) {
    formdata.append('city_image', fd.image);
  } else {
    formdata.append('city_image', null);
  }
  try {
    const response = await axios.post(
      config.APIURL + 'v1/save-cities',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Kitchen material
async function getKitchenMaterialListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-kitchen-material?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addKitchenMaterialAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('material_name', fd.kitchenMaterialName);
  formdata.append('material_description', fd.kitchenMaterialDescription);
  formdata.append('is_cooking_medium', fd.isCookingMedium);
  formdata.append('is_menu_medium', fd.isKitchenMedium);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-kitchen-material',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteKitchenMaterialAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-kitchen-material',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getKitchenMaterialAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-kitchen-material-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editKitchenMaterialAPI(userId, token, id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('material_name', fd.kitchenMaterialName);
  formdata.append('material_description', fd.kitchenMaterialDescription);
  formdata.append('is_cooking_medium', fd.isCookingMedium);
  formdata.append('is_menu_medium', fd.isKitchenMedium);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-kitchen-material',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Requirements
async function getRequirementListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-requirement-and-allergies?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addRequirementAPI(userId, token, name, description) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('requirement_and_allergies_name', name);
  formdata.append('description', description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-requirement-and-allergies',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteRequirementAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-requirement-and-allergies',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getRequirementAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-requirement-and-allergy',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editRequirementAPI(userId, token, id, name, description) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('requirement_and_allergies_name', name);
  formdata.append('description', description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-requirement-and-allergies',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// HomepagePopup
async function getHomepagePopupListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-homepage-popup-details?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addHomepagePopupAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('name', fd.name);
  formdata.append('image', fd.image);
  formdata.append('published', fd.published);
  formdata.append('city_list', fd.city_list);
  formdata.append('url', fd.url);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-homepage-popup',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteHomepagePopupAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-homepage-popup',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getHomepagePopupAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-homepage-popup',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editHomepagePopupAPI(userId, token, id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('name', fd.name);
  formdata.append('image', fd.image);
  formdata.append('published', fd.published);
  formdata.append('city_list', fd.city_list);
  formdata.append('url', fd.url);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-homepage-popup',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Marketing Cards
async function getMarketingCardListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-marketing-ads?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addMarketingCardAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('name', fd.name);
  formdata.append('description', fd.description);
  formdata.append('image', fd.image);
  formdata.append('published', fd.published);
  formdata.append('selected_chef', fd.selected_chef);
  formdata.append('selected_cuisine', fd.selected_cuisine);
  formdata.append('selected_tag', fd.selected_tag);
  formdata.append('external_url', fd.external_url);
  formdata.append('city_list', fd.city_list);
  formdata.append("menu_category_list", fd.selected_menu_category);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-marketing-ads',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteMarketingCardAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-marketing-ads',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMarketingCardAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-marketig-cards',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editMarketingCardAPI(userId, token, id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('name', fd.name);
  formdata.append('description', fd.description);
  formdata.append('image', fd.image);
  formdata.append('published', fd.published);
  formdata.append('selected_chef', fd.selected_chef);
  formdata.append('selected_cuisine', fd.selected_cuisine);
  formdata.append('selected_tag', fd.selected_tag);
  formdata.append('external_url', fd.external_url);
  formdata.append('city_list', fd.city_list);
  formdata.append("menu_category_list", fd.selected_menu_category);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-marketing-ads',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getChefListAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-chef-list',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCuisinesListAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-cuisines-list',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMenuCategoriesListAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-marketings-menu-categories",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMenuListAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-menu-listing',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMarketingTagListAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-marketings-tags',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Crowd Favourite
async function getCrowdFavouriteListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-crowd-favourite-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteCrowdFavouriteAPI(userId, token, crowdFavoriteId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', crowdFavoriteId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-crowd-favourite',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addCrowdFavouriteAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('title', fd.title);
  formdata.append('description', fd.description);
  formdata.append('image', fd.image);
  formdata.append('is_published', fd.is_published);
  formdata.append('chef_list', fd.selected_chef);
  formdata.append('cuisine_list', fd.selected_cuisine);
  formdata.append('menu_list', fd.selected_menu);
  formdata.append('tag_list', fd.selected_tag);
  formdata.append("menu_category_list", fd.selected_category);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-crowd-favourite',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCrowdFavouriteAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-crowd-favourite-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editCrowdFavouriteAPI(userId, token, id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('title', fd.title);
  formdata.append('description', fd.description);
  formdata.append('image', fd.image);
  formdata.append('is_published', fd.is_published);
  formdata.append('chef_list', fd.selected_chef);
  formdata.append('cuisine_list', fd.selected_cuisine);
  formdata.append('menu_list', fd.selected_menu);
  formdata.append('tag_list', fd.selected_tag);
  formdata.append("menu_category_list", fd.selected_category);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-crowd-favourite',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Collection
async function getCollectionListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-collection-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteCollectionAPI(userId, token, crowdFavoriteId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', crowdFavoriteId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-collection',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addDiscountCodeAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('title', fd.title);
  formdata.append('description', fd.description);
  formdata.append('coupon_code', fd.coupon_code);
  formdata.append('chef_list', fd.chef_list);
  formdata.append('visible', fd.visible);
  formdata.append('start_date', fd.start_date);
  formdata.append('end_date', fd.end_date);
  formdata.append('selected_weekday', fd.selected_weekday);
  formdata.append('minimum_order_amount', fd.minimum_order_amount);
  formdata.append('flat_discount', fd.flat_discount);
  formdata.append('free_delivery_enabled', fd.free_delivery_enabled);
  formdata.append('global_usage_limit', fd.global_usage_limit);
  formdata.append('per_user_usage_limit', fd.per_user_usage_limit);
  formdata.append('per_user_order_limit', fd.per_user_order_limit);
  formdata.append('city_list', fd.city_list);
  formdata.append('discount_type', fd.discount_type);
  formdata.append('percentile_discount', fd.percentile_discount);
  formdata.append('maximum_discount_amount', fd.maximum_discount_amount);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/create-coupon',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editDiscountCodeAPI(userId, token, couponId, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', couponId);
  formdata.append('title', fd.title);
  formdata.append('description', fd.description);
  formdata.append('coupon_code', fd.coupon_code);
  formdata.append('chef_list', fd.chef_list);
  formdata.append('visible', fd.visible);
  formdata.append('start_date', fd.start_date);
  formdata.append('end_date', fd.end_date);
  formdata.append('selected_weekday', fd.selected_weekday);
  formdata.append('minimum_order_amount', fd.minimum_order_amount);
  formdata.append('flat_discount', fd.flat_discount);
  formdata.append('free_delivery_enabled', fd.free_delivery_enabled);
  formdata.append('global_usage_limit', fd.global_usage_limit);
  formdata.append('per_user_usage_limit', fd.per_user_usage_limit);
  formdata.append('per_user_order_limit', fd.per_user_order_limit);
  formdata.append('city_list', fd.city_list);
  formdata.append('status', fd.status);
  formdata.append('discount_type', fd.discount_type);
  formdata.append('percentile_discount', fd.percentile_discount);
  formdata.append('maximum_discount_amount', fd.maximum_discount_amount);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-coupon',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteDiscountCodeAPI(userId, couponId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', couponId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-coupon',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDiscountCodeDetailAPI(userId, couponId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', couponId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-coupon-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDiscountCodeAPI(userId, currentPage, token, filter, status) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('search_term', filter);
  formdata.append('status', status);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-coupon-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addCollectionAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('title', fd.title);
  formdata.append('ordering', fd.ordering);
  formdata.append('description', fd.description);
  formdata.append('image', fd.image);
  formdata.append('is_published', fd.is_published);
  formdata.append('chef_list', fd.selected_chef);
  formdata.append('cuisine_list', fd.selected_cuisine);
  formdata.append('menu_list', fd.selected_menu);
  formdata.append('tag_list', fd.selected_tag);
  formdata.append('city_list', fd.city_list);
  formdata.append("menu_category_list", fd.selected_category);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-collection',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCollectionAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-collection-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editCollectionAPI(userId, token, id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('title', fd.title);
  formdata.append('ordering', fd.ordering);
  formdata.append('description', fd.description);
  formdata.append('image', fd.image);
  formdata.append('is_published', fd.is_published);
  formdata.append('chef_list', fd.selected_chef);
  formdata.append('cuisine_list', fd.selected_cuisine);
  formdata.append('menu_list', fd.selected_menu);
  formdata.append('tag_list', fd.selected_tag);
  formdata.append('city_list', fd.city_list);
  formdata.append("menu_category_list", fd.selected_category);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-collection',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Global Setting
async function getGlobalSettingListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-global-settings-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getGlobalSettingAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-global-setting',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addGlobalSettingAPI(userId, token, title, value) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('title', title);
  formdata.append('setting_value', value);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-global-setting',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editGlobalSettingAPI(userId, token, id, title, value) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('title', title);
  formdata.append('setting_value', value);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-global-setting',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteGlobalSettingAPI(userId, token, cuisineId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', cuisineId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-global-setting',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// subscription-package-list Setting
async function getSubscriptionPackageListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-subscription-package-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getSubscriptionPackageAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-subscription-package',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addSubscriptionPackageAPI(userId, token, packagess, duration, basic_price, basic_strike_price, gst_percentage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('package', packagess);
  formdata.append('duration', duration);
  formdata.append('basic_price', basic_price);
  formdata.append('basic_strike_price', basic_strike_price);
  formdata.append('gst_percentage', gst_percentage);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-subscription-package',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editSubscriptionPackageAPI(userId, token, id, packagess, duration, basic_price, basic_strike_price, gst_percentage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('package', packagess);
  formdata.append('duration', duration);
  formdata.append('basic_price', basic_price);
  formdata.append('basic_strike_price', basic_strike_price);
  formdata.append('gst_percentage', gst_percentage);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-subscription-package',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteSubscriptionPackageAPI(userId, token, packageId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', packageId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-subscription-package',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Membership Plan
async function getMembershipPlanListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-membership-plan-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteMembershipPlanAPI(userId, token, cuisineId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', cuisineId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-membership-plan',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMembershipPlanAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-membership-plan',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addMembershipPlanAPI(userId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('title', data.title);
  formdata.append('description', data.description);
  formdata.append('commission_rate', data.commission_rate);
  formdata.append('price', data.price);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-membership-plan',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editMembershipPlanAPI(userId, token, id, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('title', data.title);
  formdata.append('description', data.description);
  formdata.append('commission_rate', data.commission_rate);
  formdata.append('price', data.price);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-membership-plan',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMenuCategoriesAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-categories-dropdown',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCateringFacilitiesAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIMURL + 'v1/get-catering-facilities',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getKitchenMatListAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-kitchen-materials',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getChefMenuListAPI(userId, chefId, token, currentPage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-kitchen-chef-menu-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function storeKitchenChefMenuAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('category_id', data.category_id);
  formdata.append('item_name', data.item_name);
  formdata.append('item_description', data.item_description);
  formdata.append('diet_type', data.diet_type);
  formdata.append('advanced_preparation_note', data.advanced_preparation_note);
  formdata.append('unit', data.unit);
  formdata.append(
    'standard_quantity_or_portion_size',
    data.standard_quantity_or_portion_size
  );
  formdata.append('item_price', data.item_price);
  formdata.append('menu_images', data.menu_images);
  formdata.append('menu_uses_material', data.menu_uses_material);
  formdata.append('tags', data.tags);
  formdata.append('menu_item_none', data.menu_item_none);
  formdata.append('enabled', data.enabled);
  formdata.append('is_chef_special', data.is_chef_special);
  formdata.append('disabled_from', data.disabled_from);
  formdata.append('disabled_to', data.disabled_to);
  formdata.append('is_same_day_delivery', data.sameDayDelivery);
  formdata.append('menuImageUrl', data.menuImageUrl);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-kitchen-chef-menu',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateKitchenChefMenuAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('menu_id', data.menu_id);
  formdata.append('category_id', data.category_id);
  formdata.append('item_name', data.item_name);
  formdata.append('item_description', data.item_description);
  formdata.append('diet_type', data.diet_type);
  formdata.append('advanced_preparation_note', data.advanced_preparation_note);
  formdata.append('unit', data.unit);
  formdata.append(
    'standard_quantity_or_portion_size',
    data.standard_quantity_or_portion_size
  );
  formdata.append('item_price', data.item_price);
  formdata.append('menu_images', data.menu_images);
  formdata.append('menu_uses_material', data.menu_uses_material);
  formdata.append('tags', data.tags);
  formdata.append('menu_item_none', data.menu_item_none);
  formdata.append('enabled', data.enabled);
  formdata.append('is_chef_special', data.is_chef_special);
  formdata.append('disabled_from', data.disabled_from);
  formdata.append('disabled_to', data.disabled_to);
  formdata.append('is_same_day_delivery', data.sameDayDelivery);
  formdata.append('menuImageUrl', data.menuImageUrl);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-kitchen-chef-menu',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDishTitleAPI(userId, chefId, token, data, currentPage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('flag', data.flag);
  formdata.append('title', data.title);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/add-seo-sliders?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editDishTitleAPI(userId, chefId, token, data, currentPage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('flag', data.flag);
  formdata.append('title', data.title);
  formdata.append('id', data.id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/edit-seo-sliders?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDishDetailAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('slider_id', data.slider_id);
  formdata.append('slider_image', data.slider_image);
  formdata.append('slider_image_description', data.slider_image_description);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/add-seo-sliders-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getChefMenuDataAPI(userId, chefId, token, menuId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('menu_id', menuId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-kitchen-chef-menu',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function uploadMenuCSVAPI(userId, chefId, token, csv) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('csvFile', csv);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/read-kitchen-csv',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteMenuItemAPI(userId, chefId, token, menuId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('menu_id', menuId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-chef-menu',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateChefProfileDetailsAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('first_name', data.userFirstName);
  formdata.append('last_name', data.userLastName);
  formdata.append('gender', data.userGender);
  formdata.append('email', data.userEmailId);
  formdata.append('mobile_number', data.userMobileNo);
  formdata.append('whatsapp_mobile_number', data.userWhatsAppNo);
  formdata.append('whatsapp_visibility', data.userWhatsAppVisibility);
  if (data.userProfileImage) {
    formdata.append('profile_image', data.userProfileImage);
  }

  if (data.userBrandImage) {
    formdata.append('brand_logo', data.userBrandImage);
  }

  formdata.append('brand_name', data.brandName);
  formdata.append('std_code', data.stdCode);
  formdata.append('telephone_number', data.userPhoneNo);
  formdata.append('house_or_flat_number', data.userHouseNo);
  formdata.append('floor_number', data.userFloor);
  formdata.append('building_or_society_name', data.userBuilding);
  formdata.append('street_name', data.userStreet);
  formdata.append('pincode', data.userPincode);
  formdata.append('locality', data.userLocality);
  formdata.append('city', data.userCity);
  formdata.append('state', data.userState);
  formdata.append('landmark', data.userLandmark);
  formdata.append('latitude', data.userLat);
  formdata.append('longitude', data.userLng);
  formdata.append('facebook', data.userFacebook);
  formdata.append('instagram', data.userInstagram);
  formdata.append('twitter', data.userTwitter);
  formdata.append('website_or_blog', data.userWebsite);
  formdata.append('chefpin_blog_url', data.userBlog);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-chef-profile',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateChefBusinessDetailsAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('year_business_started', data.userBusinessYear);
  formdata.append('fssai_registration', data.userFssaiSelected);
  if (data.userFssaiRegistration) {
    formdata.append('fssai_registration_document', data.userFssaiRegistration);
  }
  formdata.append('is_gst_applicable', data.userGstSelected);
  formdata.append('gst_registration_number', data.userGstNumber);
  formdata.append('fssai_registration_number', data.userFSSAINumber);
  if (data.userVerfication) {
    formdata.append('verification_document', data.userVerfication);
  }
  formdata.append('operational_days', data.selectedWeekDays);
  formdata.append(
    'opening_time',
    data.userBusinessStartTime + ' ' + data.startTimeState
  );
  formdata.append(
    'closing_time',
    data.userBusinessEndTime + ' ' + data.endTimeState
  );
  formdata.append('is_takeaway_available', data.userBusinessTakeaway);
  formdata.append('pickup_instructions', data.specialInstructionsForTakeaway);
  formdata.append('minimum_order_amount', data.minimumOrderAmount);
  formdata.append('party_orders_accepted', data.takePartyOrders);
  formdata.append('maximum_people_to_cater', data.maximumPeopleCarter);
  formdata.append('chef_host_event_at_their_home', data.hostEventAtHome);
  formdata.append(
    'maximum_number_of_guest_chef_can_host',
    data.maximumNumberOfGuests
  );
  formdata.append('catering_facilities', data.selectedCateringFacilities);
  formdata.append('venue_facilities', data.selectedVenueFacilities);
  formdata.append('average_price_per_person', data.averagePricePerPerson);
  formdata.append('average_meal_price', data.averageMealPrice);
  formdata.append('is_same_day_delivery', data.samedaydelivery);
  formdata.append('same_day_delivery_notice', data.samedaydeliverynotice);
  formdata.append('is_all_items_same_day_delivery', data.allitemsamedaydelivery);
  formdata.append('is_marketing_package', data.is_marketing_package);
  formdata.append('packagess', data.packagess);
  formdata.append('join_date', data.join_date);
  formdata.append('expire_date', data.expire_date);
  formdata.append('payment_date', data.payment_date);
  formdata.append('paid_amount', data.paid_amount);
  formdata.append('payment_remark', data.payment_remark);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-chef-business',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Banner
async function getBannerListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-banner-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addBannerAPI(userId, id, token, fd) {
  var formdata = new FormData();
  if (id !== '') {
    formdata.append('id', id);
  }
  formdata.append('user_id', userId);
  formdata.append('name', fd.bannerName);
  formdata.append('description', fd.bannerDescription);
  formdata.append('link', fd.bannerLink);
  formdata.append('is_published', fd.is_published);
  formdata.append('image', fd.image);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-banner',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteBannerAPI(userId, token, tagId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tagId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-banner',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editBannerAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/edit-banner-listing',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getKitchenCuisineAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-kitchen-cuisine',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateUserPasswordAPI(userId, passwordUserId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('password_user_id', passwordUserId);
  formdata.append('password', data.password);
  formdata.append('password_confirm', data.password_confirm);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/change-user-password',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function storeSpecialityDishAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('special_dish_name', data.special_dish_name);
  formdata.append('special_dish_images', data.special_dish_images);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-store-special-dish',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function removeSpecialityDishAPI(userId, chefId, token, dishName) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('special_dish_name', dishName);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-remove-special-dish',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addNewChefAPI(userId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('first_name', data.firstName);
  formdata.append('last_name', data.lastName);
  formdata.append('email', data.email);
  formdata.append('mobile_number', data.mobile);
  formdata.append('password', data.password);
  formdata.append('password_confirm', data.confirmPassword);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/create-chef-account',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getRequirementAllergiesAPI(userId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIMURL + 'v1/get-requirement-and-allergies',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getFeaturedImageListAPI(userId, chefId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-featured-image-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteDishImageAPI(userId, token, imageId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', imageId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-seo-sliders-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteAuditImageAPI(userId, token, imageId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', imageId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-audit-images',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteFeaturedImageAPI(userId, token, imageId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', imageId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-featured-image',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteMenuImageAPI(userId, token, imageId, chefId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('image_id', imageId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-remove-menu-document',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addAuditImageAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_user_id', chefId);
  for (const file of data.auditImage) {
    formdata.append('kitchen_images[]', file, file.name);
  }
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-audit-images',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getAuditImageDataAPI(userId, chefId, pageNo, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_user_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-audit-images?page=' + pageNo,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addFeaturedImageAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('image', data.featuredImage);
  formdata.append('x_order', data.x_order);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-featured-image',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateFeaturedImageAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('id', data.image_id);
  if (data.featuredImage) {
    formdata.append('image', data.featuredImage);
  }
  formdata.append('x_order', data.x_order);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-featured-image',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getFeaturedImageDataAPI(userId, chefId, token, imageId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('id', imageId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/edit-featured-image-listing',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function storeKitchenImageAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('kitchen_id', data.kitchen_id);
  formdata.append('kitchen_images', data.kitchen_images);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-kitchen-images',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function removeKitchenImageAPI(userId, chefId, token, imageId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('image_id', imageId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-remove-kitchen-images',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function storeChefKitchenDetailsAPI(userId, chefId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  formdata.append('kitchen_id', data.kitchen_id);
  formdata.append('cooking_qualifications', data.cooking_qualifications);
  formdata.append('cuisines', data.cuisines);
  formdata.append('kitchen_description', data.kitchen_description);
  formdata.append('kitchen_type', data.kitchen_type);
  formdata.append('non_veg_is_halal', data.non_veg_is_halal);
  formdata.append('certified_organic_use', data.certified_organic_use);
  formdata.append('packaging_recylable', data.packaging_recylable);
  formdata.append('provide_dispoable_cutlery', data.provide_dispoable_cutlery);
  formdata.append('kitchen_material', data.kitchen_material);
  formdata.append(
    'kitchen_requirements_and_allergies',
    data.kitchen_requirements_and_allergies
  );
  formdata.append('kitchen_cooking_medium', data.kitchen_cooking_medium);
  formdata.append('suitable_for_jain_veg', data.suitable_for_jain_veg);
  formdata.append('suitable_for_non_veg', data.suitable_for_non_veg);
  formdata.append('suitable_for_pure_veg', data.suitable_for_pure_veg);
  formdata.append('suitable_for_veg_with_egg', data.suitable_for_veg_with_egg);
  formdata.append('discountstartdate', data.discountstartdate);
  formdata.append('discountenddate', data.discountenddate);
  formdata.append('discount', data.discount);
  formdata.append('discounttype', data.discounttype);
  formdata.append('enablediscount', data.enablediscount);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-store-chef-kitchen-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getTagsListAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIMURL + 'v1/get-tags',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Diner API
async function getDinerListAPI(
  userId,
  currentPage,
  first_name,
  last_name,
  email,
  mobile_number,
  city_name,
  state,
  order_from_date,
  order_to_date,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('first_name', first_name);
  formdata.append('last_name', last_name);
  formdata.append('email', email);
  formdata.append('mobile_number', mobile_number);
  formdata.append('city_name', city_name);
  formdata.append('state', state);
  formdata.append('order_from_date', order_from_date);
  formdata.append('order_to_date', order_to_date);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-diner-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteDinerAPI(userId, dinerId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_user_id', dinerId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-diner-profile',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addNewDinerAPI(userId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('first_name', data.firstName);
  formdata.append('last_name', data.lastName);
  formdata.append('email', data.email);
  formdata.append('mobile_number', data.mobile);
  formdata.append('password', data.password);
  formdata.append('password_confirm', data.confirmPassword);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/create-diner-account',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDinerProfileDetailAPI(userId, dinerId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_user_id', parseFloat(dinerId));
  try {
    const response = await axios.post(
      config.APIURL + 'v1/show-diner-profile',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateDinerProfileDetailsAPI(userId, dinerId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_user_id', dinerId);
  formdata.append('first_name', data.userFirstName);
  formdata.append('last_name', data.userLastName);
  formdata.append('email', data.userEmailId);
  formdata.append('mobile_number', data.userMobileNo);
  formdata.append('dob', data.userDOB);
  if (data.userProfileImage) {
    formdata.append('profile_image', data.userProfileImage);
  }
  formdata.append('pincode', data.userPincode);
  formdata.append('city', data.userCity);
  formdata.append('state', data.userState);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-diner-account',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getChefOrderLedgerAPI(
  userId,
  currentPage,
  chef_name,
  brand_name,
  order_number,
  utr_number,
  order_from_date,
  order_to_date,
  settlement_from_date,
  settlement_to_date,
  type,
  order_type,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_name', chef_name);
  formdata.append('brand_name', brand_name);
  formdata.append('order_number', order_number);
  formdata.append('utr_number', utr_number);
  formdata.append('order_from_date', order_from_date);
  formdata.append('order_to_date', order_to_date);
  formdata.append('settlement_from_date', settlement_from_date);
  formdata.append('settlement_to_date', settlement_to_date);
  formdata.append('type', type);
  formdata.append('order_type', order_type);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/chef-order-ledger-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getChefSubscriptionPackageAPI(
  userId,
  currentPage,
  packagess,
  chef_name,
  status,
  join_from_date,
  join_to_date,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('package', packagess);
  formdata.append('chef_name', chef_name);
  formdata.append('status', status);
  formdata.append('join_from_date', join_from_date);
  formdata.append('join_to_date', join_to_date);
  
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-chef-subscription-package-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// get offline order api
async function getOfflineOrderAPI(
  userId,
  currentPage,
  order_number,
  order_type,
  diner_name,
  chef_name,
  is_payment_received,
  is_paid_to_chef,
  transaction_id,
  order_from_date,
  order_to_date,
  is_delivered,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_name', diner_name);
  formdata.append('chef_name', chef_name);
  formdata.append('order_number', order_number);
  formdata.append('order_type', order_type);
  formdata.append('is_payment_received', is_payment_received);
  formdata.append('order_from_date', order_from_date);
  formdata.append('order_to_date', order_to_date);
  formdata.append('transaction_id', transaction_id);
  formdata.append('is_paid_to_chef', is_paid_to_chef);
  formdata.append('is_delivered', is_delivered);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-offline-order-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getOfflineOrderDetailAPI(userId, orderID, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_id', orderID);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-offline-order-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addOfflineOrderAPI(userId, id, token, fd) {
  var formdata = new FormData();
				
  formdata.append('user_id', userId);
  formdata.append('order_type', fd.orderType);
  formdata.append('order_no', fd.orderNo);
  formdata.append('order_date', fd.orderDate);
  formdata.append('delivery_date', fd.deliveryDate);
  formdata.append('pickup_time', fd.pickupTime);
  formdata.append('chef_id', fd.chefName);
  formdata.append('diner_name', fd.dinerName);
  formdata.append('diner_email_id', fd.dinerEmail);
  formdata.append('diner_mobile_number', fd.dinerMob);
  formdata.append('diner_address', fd.dinerAdd);
  formdata.append('order_amount', fd.orderAmount);
  formdata.append('delivery_amount', fd.deliveryAmount);
  formdata.append('promo_code', fd.promoCode);
  formdata.append('promo_discount', fd.promoDiscount);
  formdata.append('received_amount_diner', fd.receivedAmountDiner);
  formdata.append('is_payment_received', fd.isPaymentReceived);
  formdata.append('is_delivered', fd.isDelivered);
  formdata.append('is_paid_to_chef', fd.isPaidToChef);
  formdata.append('razorpay_id', fd.razorpayID);
  formdata.append('send_to_sandeep_at', fd.sendToSandeepAt);
  formdata.append('order_details', fd.orderDetails);
  formdata.append('remark', fd.remark);
  formdata.append('reason_for_offline', fd.reasonForOffline);
  
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-offline-order',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editOfflineOrderAPI(userId, id, token, fd) {
  var formdata = new FormData();
  formdata.append('id', id);			
  formdata.append('user_id', userId);
  formdata.append('order_type', fd.orderType);
  formdata.append('order_no', fd.orderNo);
  formdata.append('order_date', fd.orderDate);
  formdata.append('delivery_date', fd.deliveryDate);
  formdata.append('pickup_time', fd.pickupTime);
  formdata.append('chef_id', fd.chefName);
  formdata.append('diner_name', fd.dinerName);
  formdata.append('diner_email_id', fd.dinerEmail);
  formdata.append('diner_mobile_number', fd.dinerMob);
  formdata.append('diner_address', fd.dinerAdd);
  formdata.append('order_amount', fd.orderAmount);
  formdata.append('delivery_amount', fd.deliveryAmount);
  formdata.append('promo_code', fd.promoCode);
  formdata.append('promo_discount', fd.promoDiscount);
  formdata.append('received_amount_diner', fd.receivedAmountDiner);
  formdata.append('is_payment_received', fd.isPaymentReceived);
  formdata.append('is_delivered', fd.isDelivered);
  formdata.append('is_paid_to_chef', fd.isPaidToChef);
  formdata.append('razorpay_id', fd.razorpayID);
  formdata.append('send_to_sandeep_at', fd.sendToSandeepAt);
  formdata.append('order_details', fd.orderDetails);
  formdata.append('remark', fd.remark);
  formdata.append('reason_for_offline', fd.reasonForOffline);
  
  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-offline-order',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteOfflineOrderAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-offline-order',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDinerOrderAPI(
  userId,
  currentPage,
  order_id,
  diner_name,
  chef_name,
  order_number,
  status,
  is_same_day_delivery,
  city,
  order_from_date,
  order_to_date,
  transaction_id,
  coupon_filter,
  order_from, 
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_id', order_id);
  formdata.append('diner_name', diner_name);
  formdata.append('chef_name', chef_name);
  formdata.append('order_number', order_number);
  formdata.append('status', status);
  formdata.append('is_same_day_delivery', is_same_day_delivery);
  formdata.append('city', city);
  formdata.append('order_from_date', order_from_date);
  formdata.append('order_to_date', order_to_date);
  formdata.append('transaction_id', transaction_id);
  formdata.append('coupon_filter', coupon_filter);
  formdata.append('order_from', order_from);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-order-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDinerOrderDetailAPI(userId, orderID, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_id', orderID);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-order-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// tiffin order API
async function getTiffinOrderAPI(
  userId,
  currentPage,
  diner_name,
  diner_email_id,
  diner_mobile_number,
  locations,
  meal_for,
  meal_type,
  tiffin_from_date,
  tiffin_to_date,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_name', diner_name);
  formdata.append('diner_email_id', diner_email_id);
  formdata.append('diner_mobile_number', diner_mobile_number);
  formdata.append('location', locations);
  formdata.append('meal_for', meal_for);
  formdata.append('meal_type', meal_type);
  formdata.append('tiffin_from_date', tiffin_from_date);
  formdata.append('tiffin_to_date', tiffin_to_date);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-tiffin-order-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// delete account request api
async function getDeleteAccountRequestAPI(
  userId,
  currentPage,
  name,
  email_id,
  mobile_number,
  create_from_date,
  create_to_date,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('name', name);
  formdata.append('email_id', email_id);
  formdata.append('mobile_number', mobile_number);
  formdata.append('create_from_date', create_from_date);
  formdata.append('create_to_date', create_to_date);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-delete-accounnt-request-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// party order API
async function getPartyOrderAPI(
  userId,
  currentPage,
  diner_name,
  diner_email_id,
  diner_mobile_number,
  locations,
  party_from_date,
  party_to_date,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_name', diner_name);
  formdata.append('diner_email_id', diner_email_id);
  formdata.append('diner_mobile_number', diner_mobile_number);
  formdata.append('location', locations);
  formdata.append('party_from_date', party_from_date);
  formdata.append('party_to_date', party_to_date);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-party-order-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// chef chat count liat
async function getChefChatCountAPI(
  userId,
  currentPage,
  diner_name,
  chef_name,
  from_date,
  to_date,
  type,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_name', diner_name);
  formdata.append('chef_name', chef_name);
  formdata.append('from_date', from_date);
  formdata.append('to_date', to_date);
  formdata.append('type', type);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-chef-chat-count-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// rejection order timeout
async function getChefRejectTimeOutCountAPI(
  userId,
  currentPage,
  status,
  chef_name,
  from_date,
  to_date,
  mstatus,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('status', status);
  formdata.append('chef_name', chef_name);
  formdata.append('from_date', from_date);
  formdata.append('to_date', to_date);
  formdata.append('mstatus', mstatus);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-rejection-timeout-count-listing?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// get party order api details
async function getTiffinOrderDetailAPI(userId, tiffinID, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', tiffinID);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-tiffin-order-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// get party order api details
async function getPartyOrderDetailAPI(userId, partyID, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', partyID);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-party-order-details',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getDinerOrderInfoAPI(userId, dinerId, token, currentPage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('diner_user_id', dinerId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-diner-order-details?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getFAQADDAPI(userId, token, question, answer, type) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('question', question);
  formdata.append('answer', answer);
  formdata.append('user_type', type);
  try {
    const response = await axios.post(config.APIURL + 'v1/add-faq', formdata, {
      headers: {
        Authorization: token
      }
    });
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getFAQListAPI(userId, currentPage, token, type) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('user_type', type);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/faq-details?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteFAQAPI(userId, token, faqId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', faqId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-faq',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getFAQAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(config.APIURL + 'v1/get-faq', formdata, {
      headers: {
        Authorization: token
      }
    });
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getFAQAEDITPI(userId, id, token, question, answer, type) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  formdata.append('question', question);
  formdata.append('answer', answer);
  formdata.append('user_type', type);
  try {
    const response = await axios.post(config.APIURL + 'v1/edit-faq', formdata, {
      headers: {
        Authorization: token
      }
    });
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getEnquireListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-chefpage-messages?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getMembershipListAPI(userId, currentPage, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-chef-membership-plan?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteMembershipAPI(userId, chefId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-chef-delete-membership-plan',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getChefMembershipLog(userId, chefId, token, currentPage) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_id', chefId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-chef-membership-log?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getCouponDataAPI(userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-coupon-setting',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateCouponDataAPI(userId, token, data) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('setting_value', data);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-coupon-setting',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function reviewListAPI(
  userId,
  currentPage,
  dinerEmail,
  chefEmail,
  userStatus,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_email', chefEmail);
  formdata.append('diner_email', dinerEmail);
  formdata.append('moderation_status', userStatus);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-rating-review-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteReviewAPI(userId, reviewId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('review_id', reviewId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-rating-review',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function detailReviewAPI(userId, reviewId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('review_id', reviewId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-rating-review-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateReviewAPI(
  userId,
  reviewId,
  moderateFlag,
  moderateDesc,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('review_id', reviewId);
  formdata.append('moderation_status', moderateFlag);
  formdata.append('moderation_remarks', moderateDesc);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/moderate-rating-review',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function chefReviewListAPI(
  userId,
  currentPage,
  userName,
  chefEmail,
  userStatus,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_email', chefEmail);
  formdata.append('user_name', userName);
  formdata.append('moderation_status', userStatus);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-extra-rating-review-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteChefReviewAPI(userId, reviewId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('review_id', reviewId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-extra-rating-review',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function detailChefReviewAPI(userId, reviewId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('review_id', reviewId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-extra-rating-review-detail',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addChefReviewAPI(userId, token, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('foodQuality', fd.foodQuality);
  formdata.append('packaging', fd.packaging);
  formdata.append('portionSize', fd.portionSize);
  formdata.append('valueOfMoney', fd.valueOfMoney);
  formdata.append('userName', fd.userName);
  formdata.append('review', fd.review);
  formdata.append('selected_chef', fd.selected_chef);
  formdata.append('order_date', fd.order_date);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/add-extra-rating-review',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function updateChefReviewAPI(userId, token, review_id, fd) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('review_id', review_id);
  formdata.append('foodQuality', fd.foodQuality);
  formdata.append('packaging', fd.packaging);
  formdata.append('portionSize', fd.portionSize);
  formdata.append('valueOfMoney', fd.valueOfMoney);
  formdata.append('userName', fd.userName);
  formdata.append('review', fd.review);
  formdata.append('selected_chef', fd.selected_chef);
  formdata.append('order_date', fd.order_date);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/update-extra-rating-review',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function rescheduleOrder(
  orderId,
  date,
  timeSlot,
  userId,
  orderNumber,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_number', orderNumber);
  formdata.append('delivery_date', date);
  formdata.append('delivery_time_slot', timeSlot);
  formdata.append('order_id', orderId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/reschedule-delivery-order',
      formdata,
      { headers: { Authorization: token } }
    );
    return response;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

async function reactivateOrder(
  orderId,
  date,
  timeSlot,
  userId,
  orderNumber,
  deliveryRemark,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_number', orderNumber);
  formdata.append('delivery_remark', deliveryRemark);
  formdata.append('delivery_date', date);
  formdata.append('delivery_time_slot', timeSlot);
  formdata.append('order_id', orderId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/reactivate-order',
      formdata,
      { headers: { Authorization: token } }
    );
    return response;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

async function markPaymentComplete(order_numbers, utr, amount, isoffline, remark, userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_numbers', JSON.stringify(order_numbers));
  formdata.append('utr_number', utr);
  formdata.append('paid_amount', amount);
  formdata.append('is_offline', isoffline);
  formdata.append('remark', remark);
  try {
    return await axios.post(
      config.APIURL + 'v1/chef-payment-completed',
      formdata,
      { headers: { Authorization: token } }
    );
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

async function markOfflinePaymentComplete(order_numbers, utr, amount, isoffline, remark, userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_numbers', JSON.stringify(order_numbers));
  formdata.append('utr_number', utr);
  formdata.append('paid_amount', amount);
  formdata.append('is_offline', isoffline);
  formdata.append('remark', remark);
  try {
    return await axios.post(
      config.APIURL + 'v1/chef-offline-payment-completed',
      formdata,
      { headers: { Authorization: token } }
    );
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

async function saveTiffinRemark(add_remark, id, userId, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('add_remark', add_remark);
  formdata.append('id', id);
  try {
    return await axios.post(
      config.APIURL + 'v1/save-tiffin-remark',
      formdata,
      { headers: { Authorization: token } }
    );
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

async function getBlogAPI(
  userId,
  chefEmail,
  chefName,
  chefMobile,
  currentPage,
  token
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('chef_email', chefEmail);
  formdata.append('chef_name', chefName);
  formdata.append('chef_mobile', chefMobile);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/admin-get-blog-document-list?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function rejectHoldOrder(userId, orderNumber, reason, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_number', orderNumber);
  formdata.append('reason_for_rejection', reason);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/reject-hold-order',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function cancelDelivery(orderId, deliveryRemark, userId, orderNumber, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_number', orderNumber);
  formdata.append('remark', deliveryRemark);
  formdata.append('orderId', orderId);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/cancel-delivery',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function orderDelivered(orderId, userId, orderNumber, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('order_number', orderNumber);
  formdata.append('orderId', orderId);

  try {
    const response = await axios.post(
      config.APIURL + 'v1/order-delivered',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// promotional message api call back
async function getPromotionalListAPI(userId, currentPage, token, search) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('search_term', search);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-promotional-messages?page=' + currentPage,
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function addPromotionalAPI(
  userId,
  token,
  message_title,
  message
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('message_title', message_title);
  formdata.append('message', message);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-promotional-message',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function editPromotionalAPI(
  userId,
  token,
  articleId,
  message_title,
  message
) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', articleId);
  formdata.append('message_title', message_title);
  formdata.append('message', message);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/store-promotional-message',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function getPromotionalAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', id);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/get-promotional-message',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deletePromotionalAPI(userId, token, articleId) {
  var formdata = new FormData();
  formdata.append('user_id', userId);
  formdata.append('id', articleId);
  try {
    const response = await axios.post(
      config.APIURL + 'v1/delete-promotional-message',
      formdata,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// bad weather api call back
async function getBadWeatherListAPI(userId, currentPage, token, search) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("search_term", search);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-bad-weather-list?page=" + currentPage,
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Bad weather add
async function addWeatherAPI(userId, token, message_title, message, city) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("message_title", message_title);
  formdata.append("message", message);
  formdata.append("city", city);
  console.log('city', city);
  try {
    const response = await axios.post(
      config.APIURL + "v1/store-weather-detail",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
   

  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

//Update API Weather

async function editWeatherAPI(
  userId,
  token,
  articleId,
  message_title,
  message,
  city,
  status,
) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("id", articleId);
  formdata.append("message_title", message_title);
  formdata.append("message", message);
  formdata.append("city", city);
  formdata.append("status", status);
  try {
    const response = await axios.post(
      config.APIURL + "v1/update-weather-detail",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

// Edit Weather list
async function getWeatherAPI(userId, id, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("id", id);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-bad-weather-detail",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

async function deleteWeatherAPI(userId, token, articleId) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("id", articleId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/delete-weather-detail",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    console.log(error.response);
    return error.response;
  }
}

export {
  getBlogAPI,
  chefReviewListAPI,
  addChefReviewAPI,
  updateChefReviewAPI,
  deleteChefReviewAPI,
  detailChefReviewAPI,
  reviewListAPI,
  updateReviewAPI,
  deleteReviewAPI,
  detailReviewAPI,
  signinAPI,
  getUserListAPI,
  getUserProfileDetailAPI,
  getUserBusinessDetailAPI,
  getUserKitchenDetailAPI,
  getKitchenMenuListAPI,
  getMenuDetailsAPI,
  getKitchenMenuAPI,
  getKycDetailsAPI,
  submitKycDetailsAPI,
  getMenuDocumentsAPI,
  deleteUserAPI,
  getQueryListAPI,
  getDinnerListAPI,
  getCityWiseReportAPI,
  getStepWiseReportAPI,
  getReportAPI,
  getCuisineListAPI,
  addCuisineAPI,
  getCuisineAPI,
  editCuisineAPI,
  deleteCuisineAPI,
  getCategoryListAPI,
  deleteCategoryAPI,
  addCategoryAPI,
  editCategoryAPI,
  getCategoryAPI,
  getTagListAPI,
  getArticleListAPI,
  addArticleAPI,
  editArticleAPI,
  getArticleAPI,
  deleteArticleAPI,
  deleteTagAPI,
  addTagAPI,
  getTagAPI,
  editTagAPI,
  getCityListAPI,
  addCityAPI,
  deleteCityAPI,
  getCityAPI,
  editCityAPI,
  getKitchenMaterialListAPI,
  addKitchenMaterialAPI,
  deleteKitchenMaterialAPI,
  getKitchenMaterialAPI,
  editKitchenMaterialAPI,
  getRequirementListAPI,
  addRequirementAPI,
  deleteRequirementAPI,
  getRequirementAPI,
  editRequirementAPI,
  getHomepagePopupListAPI,
  addHomepagePopupAPI,
  deleteHomepagePopupAPI,
  getHomepagePopupAPI,
  editHomepagePopupAPI,
  getMarketingCardListAPI,
  addMarketingCardAPI,
  deleteMarketingCardAPI,
  getMarketingCardAPI,
  editMarketingCardAPI,
  getChefListAPI,
  getCuisinesListAPI,
  getMenuCategoriesListAPI,
  getMenuListAPI,
  getMarketingTagListAPI,
  getCrowdFavouriteListAPI,
  deleteCrowdFavouriteAPI,
  addCrowdFavouriteAPI,
  getCrowdFavouriteAPI,
  editCrowdFavouriteAPI,
  getCollectionListAPI,
  deleteCollectionAPI,
  addCollectionAPI,
  getCollectionAPI,
  editCollectionAPI,
  addDiscountCodeAPI,
  editDiscountCodeAPI,
  deleteDiscountCodeAPI,
  getDiscountCodeDetailAPI,
  getDiscountCodeAPI,
  getGlobalSettingListAPI,
  addGlobalSettingAPI,
  getGlobalSettingAPI,
  editGlobalSettingAPI,
  deleteGlobalSettingAPI,
  getSubscriptionPackageListAPI,
  addSubscriptionPackageAPI,
  getSubscriptionPackageAPI,
  editSubscriptionPackageAPI,
  deleteSubscriptionPackageAPI,
  getMembershipPlanListAPI,
  deleteMembershipPlanAPI,
  getMembershipPlanAPI,
  addMembershipPlanAPI,
  editMembershipPlanAPI,
  getMenuCategoriesAPI,
  getKitchenMatListAPI,
  storeKitchenChefMenuAPI,
  getChefMenuListAPI,
  getChefMenuDataAPI,
  updateKitchenChefMenuAPI,
  getDishTitleAPI,
  editDishTitleAPI,
  getDishDetailAPI,
  uploadMenuCSVAPI,
  deleteMenuItemAPI,
  updateChefProfileDetailsAPI,
  getCateringFacilitiesAPI,
  updateChefBusinessDetailsAPI,
  getBannerListAPI,
  addBannerAPI,
  deleteBannerAPI,
  editBannerAPI,
  updateUserPasswordAPI,
  getKitchenCuisineAPI,
  storeSpecialityDishAPI,
  removeSpecialityDishAPI,
  addNewChefAPI,
  getRequirementAllergiesAPI,
  getFeaturedImageListAPI,
  deleteFeaturedImageAPI,
  deleteAuditImageAPI,
  deleteDishImageAPI,
  addFeaturedImageAPI,
  addAuditImageAPI,
  getAuditImageDataAPI,
  getFeaturedImageDataAPI,
  updateFeaturedImageAPI,
  storeKitchenImageAPI,
  removeKitchenImageAPI,
  storeChefKitchenDetailsAPI,
  getTagsListAPI,
  getDinerListAPI,
  deleteDinerAPI,
  addNewDinerAPI,
  updateDinerProfileDetailsAPI,
  getDinerProfileDetailAPI,
  getChefOrderLedgerAPI,
  getChefSubscriptionPackageAPI,
  getOfflineOrderAPI,
  getOfflineOrderDetailAPI,
  addOfflineOrderAPI,
  editOfflineOrderAPI,
  deleteOfflineOrderAPI,
  getDinerOrderAPI,
  getDinerOrderDetailAPI,
  getDinerOrderInfoAPI,
  getDeleteAccountRequestAPI,
  getPartyOrderAPI,
  getTiffinOrderAPI,
  getChefChatCountAPI,
  getChefRejectTimeOutCountAPI,
  getPartyOrderDetailAPI,
  getTiffinOrderDetailAPI,
  getFAQADDAPI,
  getFAQAEDITPI,
  getFAQListAPI,
  deleteFAQAPI,
  getFAQAPI,
  getEnquireListAPI,
  getMembershipListAPI,
  deleteMembershipAPI,
  deleteMenuImageAPI,
  getChefMembershipLog,
  getCouponDataAPI,
  updateCouponDataAPI,
  rescheduleOrder,
  reactivateOrder,
  markPaymentComplete,
  markOfflinePaymentComplete,
  saveTiffinRemark,
  rejectHoldOrder,
  cancelDelivery,
  orderDelivered,
  getPromotionalListAPI,
  addPromotionalAPI,
  editPromotionalAPI,
  getPromotionalAPI,
  deletePromotionalAPI,
  getBadWeatherListAPI,
  addWeatherAPI,
  getWeatherAPI,
  editWeatherAPI,
  deleteWeatherAPI,
};
