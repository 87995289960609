/*
#############################################################################
# Name : promotionalMessageList.js
# Created by : Tushar Gupta
# Date       : 12th Dec 2022
# Purpose    : This file is used to display promotional message listing
#############################################################################
*/
import React, { Component } from "react";
import Header from "../../components/Header";
import {
  getPromotionalListAPI,
  deletePromotionalAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import promotionalMessageEdit from "./promotionalMessageEdit";

export default class PromotionalMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: '',
	  userName: '',
	  userEmailID: '',
      isLoading: true,
      searchData: "",
      promotionalList: [],
      activePage: 1,
      totalRecords: 10,
      resetFlag: false,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
	var name = localStorage.getItem('userName');
	var email = localStorage.getItem('userEmail');
	var role = localStorage.getItem('userRole');
	this.setState({
	  userRole: role,
	  userName: name,
	  userEmailID: email
	});
    this.getPromotionalListAPI();
  }

  handleFilterChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }

  submitFilter = (event) => {
    event.preventDefault();
    this.setState({ resetFlag: true });
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = "1";
    this.setState({
      activePage: 1,
    });
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getPromotionalListAPI(id, currentPage, token, this.state.searchData)
      .then((response) => {
        if (response.data["status"] === 200) {
          console.log(response);
          this.setState({
            promotionalList: response.data["data"].messages,
            totalRecords: response.data["data"]["pager_count"],
            isLoading: false,
          });
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      resetFlag: false,
      searchData: "",
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getPromotionalListAPI();
    });
  }

  getUserView = (id, event) => {
    event.preventDefault();
    localStorage.setItem("chefID", id);
    this.props.history.push("/chef-data");
  };

  getPromotionalListAPI() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getPromotionalListAPI(id, currentPage, token, this.state.searchData)
      .then((response) => {
        if (response.data["status"] === 200) {
          console.log(response.data.data.messages);
          this.setState({
            promotionalList: response.data.data.messages,
            totalRecords: response.data["data"]["pager_count"],
            isLoading: false,
          });
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  }

  handleClickDelete = (tagId) => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deletePromotionalAPI(id, token, tagId)
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            "Promotional message deleted successfully",
            "Success"
          );
          this.getPromotionalListAPI();
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  deleteTag = (tagId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this message?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(tagId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Promotional Message Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <b>Search Data</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="searchData"
                                  value={this.state.searchData}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 center_btn">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
					{/*  Admin and Marketing menu */}
					{this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                    <Link className="btn" to="/promotional-message-add">
                      Add Promotional Message
                    </Link>
					 ) : null}
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="align-middle text-center">
                              Sr. No.
                            </th>
							<th className="align-middle text-center">
                              Message title
                            </th>
                            <th className="align-middle text-center">
                              Message
                            </th>
                            <th className="align-middle text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="4">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.promotionalList.length ? (
                          <tbody>
                            {this.state.promotionalList.map((tag, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td width="50%">{tag.message_title}</td>
                                  <td width="50%">{tag.message}</td>
                                  <td
                                    width="10%"
                                    className="align-middle"
                                    style={styles.th}
                                  >
								  {/*  Admin and Marketing menu */}
								  {this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                                    <Link
                                              to={"/promotional-message-edit?id=" + tag.id}
                                              state={{ editor: tag.message }}
                                      style={{ marginRight: "10px" }}
                                    >
                                      <i className="material-icons view_link">
                                        create{/*  */}
                                      </i>
                                    </Link>
									 ) : null}
								  {/*  Admin and Marketing menu */}
								  {this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                                    <i
                                      className="material-icons view_link"
                                      onClick={() => this.deleteTag(tag.id)}
                                    >
                                      delete
                                    </i>
									 ) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="4">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
  th: {
    textAlign: "center",
  },
};
