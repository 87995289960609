import React, { Component } from 'react'
import Header from "../../components/Header";
import { getGlobalSettingAPI, editGlobalSettingAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import Moment from 'moment';
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { NotificationManager } from "react-notifications";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === null && (valid = false)
    })

    return valid;
}

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class EditGlobalSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            settingTitle: '',
            settingValue: '',
            formErrors: {
                settingTitle: '',
                settingValue: '',
            },
            buttonText: "Submit",
            buttonDisabled: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getGlobalSettingAPI();
    }

    getGlobalSettingAPI() {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var currentPage = this.state.activePage
        return getGlobalSettingAPI(id, this.props.location.search.substr(4, 100), token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    this.setState({
                        settingTitle: response.data.data.title,
                        settingValue: response.data.data.setting_value,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                    NotificationManager.error("Something went wrong", "Error!");
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false
                })
                NotificationManager.error("Something went wrong", "Error!");
            });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'settingTitle':
                formErrors.settingTitle = value.length <= 0 ? 'Setting title is required' : !value.match(/^[a-zA-Z ]+$/) ? "Enter valid Setting title" : "";
                break;

            case 'settingValue':
                formErrors.settingValue = value.length <= 0 ? 'Setting value is required' : "";
                break;

            default:
                break;
        }

        this.setState({ formErrors, [name]: value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        const { settingTitle, settingValue } = this.state;
        if (settingTitle === "") {
            formErrors.settingTitle = "Setting title is required";
        }
        if (settingValue === "") {
            formErrors.settingValue = "Setting value is required";
        }
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state)) {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            this.setState({
                buttonText: "Submitting...",
                buttonDisabled: true
            })
            return editGlobalSettingAPI(id, token, this.props.location.search.substr(4, 100), settingTitle, settingValue)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success(
                            "Setting edited successfully",
                            "Success",
                            3000
                        );
                        this.setState({
                            buttonText: "Submit",
                        })
                        setTimeout(() => {
                            this.props.history.push('/global-setting-list');
                        }, 2000)
                    } else {
                        NotificationManager.error("Something went wrong", "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error, "Error!");
                    this.setState({
                        buttonText: "Submit",
                        buttonDisabled: false
                    })
                });
        } else {
            console.log('error');
        }
    }
    render() {
        const { formErrors } = this.state;
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Edit Global Setting</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    {
                                        this.state.isLoading ?
                                            <center>
                                                <Loader
                                                    style={{ marginTop: 50, paddingBottom: 50 }}
                                                    type="Oval"
                                                    color="#F07C07"
                                                    height={50}
                                                    width={50}
                                                /></center> :
                                            <div className="body">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <form onSubmit={this.handleSubmit}>
                                                            <div className="form-group">
                                                                <b>Setting Title*</b>
                                                                <div className="form-line">
                                                                    <input type="text" className="form-control" name="settingTitle"
                                                                        value={this.state.settingTitle}
                                                                        onChange={this.handleInputChange}
                                                                        onBlur={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                {formErrors.settingTitle.length > 0 && (
                                                                    <span className="text-danger">{formErrors.settingTitle}</span>
                                                                )}
                                                            </div>

                                                            <div className="form-group">
                                                                <b>Setting Value*</b>
                                                                <div className="form-line">
                                                                    <input type="text" className="form-control" name="settingValue"
                                                                        value={this.state.settingValue}
                                                                        onChange={this.handleInputChange}
                                                                        onBlur={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                {formErrors.settingValue.length > 0 && (
                                                                    <span className="text-danger">{formErrors.settingValue}</span>
                                                                )}
                                                            </div>
                                                            <Link to="/global-setting-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                            <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
