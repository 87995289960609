import React, { Component } from 'react'
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { getCouponDataAPI, updateCouponDataAPI } from '../../core/services/APIServices';

export default class CouponSetting extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isCoupon: false,
        };
    }

    componentDidMount() {
        this.getCouponDataAPI();
    }

    getCouponDataAPI = () => {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        return getCouponDataAPI(id, token).then((response) => {
            if (response.data["status"] == 200) {
                this.setState({
                    isCoupon:response.data.data == 1 ? true : false,
                })
            }
        }).catch((error)=>{
           /*  console.log(error); */
        })
    }

    checkCoupon = (e) => {
        this.setState({
          isCoupon: e.target.checked,
        },() => {
            console.log(this.state.isCoupon);
        });
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var data = e.target.checked == true ? 1 : 0
        return updateCouponDataAPI(id, token, data).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
      };

    render(){
        return(
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Global Free Delivery Setting</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <b>Free delivery setting</b>
                                        <div
                                            className="demo-switch"
                                            style={{ marginTop: "10px", marginBottom: "30px" }}
                                        >
                                            <div className="switch">
                                            <label>
                                                OFF
                                                <input
                                                type="checkbox"
                                                value={true}
                                                checked={this.state.isCoupon}
                                                onChange={this.checkCoupon} 
                                                />
                                                <span className="lever switch-col-deep-orange"></span>
                                                ON
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}