import React, { Component } from "react";
import {
  getDinerOrderInfoAPI,
  updateDinerProfileDetailsAPI,
} from "../../core/services/APIServices";
import { Link, Redirect, withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Moment from "moment";
import Pagination from "react-js-pagination";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class OrderTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: '',
      userEmailID: '',
      orderDetail: [],
      isLoading: true,
      activePage: 1,
      totalRecords: 10,
    };
  }

  componentDidMount() {
	var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
    this.getOrderData();
  }

  getOrderData = async () => {
    var userId = localStorage.getItem("userID");
    var dinerId = localStorage.getItem("dinerID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    try {
      const response = await getDinerOrderInfoAPI(userId, dinerId, token, currentPage);
      if (response.data["status"] == 200) {
        /* console.log(response); */
        this.setState({
          orderDetail: response.data.data.order,
          totalRecords: response.data.data.pager_count,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getOrderData();
    });
  }

  getOrderView = (id) => {
    /* console.log(id); */
    localStorage.setItem("orderID", id);
    this.props.history.push('/diner-order-detail'); 
    /* return <Redirect to = "/diner-order-detail"/> */
  };
  
  render() {
    return (
      <div>
        <div className="row clearfix">
          <div className="col-xs-12 ol-sm-12 col-md-12 col-lg-12">
            <div
              className="panel-group"
              id="accordion_17"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingOne_17">
                  <h4 className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_17"
                      href="#collapseOne_17"
                      aria-expanded="true"
                      aria-controls="collapseOne_17"
                      className="collapsed"
                    >
                      Order Info
                      <i className="material-icons float-right">
                        arrow_drop_down
                      </i>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseOne_17"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingOne_17"
                  aria-expanded="true"
                  style={{ height: "auto" }}
                >
                  <div className="panel-body aniimated-thumbnials">
                    <div className="card">
                      <div className="body">
                        <div className="table-responsive">
                          <table className="table table-bordered table-striped table-hover">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Order Date</th>
                                <th>Order ID</th>
                                <th>Order No.</th>
                                <th>Payment ID</th>
                                <th>Chef Name</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {this.state.isLoading ? (
                              <tbody>
                                <tr>
                                  <td className="text-center" colSpan="6">
                                    <Loader
                                      type="Oval"
                                      color="#F07C07"
                                      height={30}
                                      width={30}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            ) : this.state.orderDetail.length ? (
                              <tbody>
                                {this.state.orderDetail.map((order, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        className="align-middle text-center"
                                        width="10%"
                                      >
                                        {this.state.activePage > 1 &&
                                        i + 1 != 10
                                          ? this.state.activePage -
                                            1 +
                                            "" +
                                            (i + 1)
                                          : this.state.activePage > 1 &&
                                            i + 1 == 10
                                          ? this.state.activePage * (i + 1)
                                          : i + 1}
                                      </td>
                                      <td>{order.created_at}</td>
                                      <td>{order.order_id}</td>
                                      <td>{order.order_number}</td>
                                      <td>{order.transaction_id}</td>
                                      <td>{order.chef_info.split(", ")[0]}</td>
                                      <td>₹ {order.amount_paid}</td>
                                      <td className="textStatusView">
                                        {order.status == "created"
                                          ? "pending"
                                          : order.status}
                                      </td>
                                      <td className="text-center">
                                        <a className="view_link">
                                          <i
                                            className="material-icons"
                                            onClick={() =>
                                              this.getOrderView(order.order_id)
                                            }
                                          >
                                            remove_red_eye
                                          </i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colspan="9">No Records Found</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <div className="text-right">
                            <Pagination
                              activePage={this.state.activePage}
                              itemsCountPerPage={10}
                              totalItemsCount={this.state.totalRecords}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OrderTab);
