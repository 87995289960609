import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import {
  getChefListAPI,
  getCityListAPI,
  getDiscountCodeDetailAPI,
  editDiscountCodeAPI,
} from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

const couponCodePattern = /^[a-zA-Z\d-_]+$/;

export default class EditDiscountCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      resetFlag: false,
      buttonText: "Submit",
      buttonDisabled: false,
      chefArr: [],
      cityList: [],
      externalUrl: "",
      selectedChefArr: [],
      selectedChef: null,
      selectedCityArr: [],
      selectedCity: null,
      selectedCuisineArr: [],
      selectedCuisine: null,
      selectedMenuArr: [],
      selectedMenu: null,
      selectedTagArr: [],
      selectedTag: null,
      couponStatus: "",
      chefSelectErr: "",
      citySelectErr: "",
      cuisineSelectErr: "",
      menuSelectErr: "",
      tagSelectErr: "",
      tableSectionError: "",
      selectedTab: "",
      /* new variables */
      formatStartDate: "",
      formatEndDate: "",
      formStartDate: "",
      formStartEnd: "",
      daysOptions: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      selectedWeekDays: [],
      discountName: "",
      discountDescription: "",
      discountCouponeCode: "",
      isVisible: false,
      isDelivery: false,
      minimumOrder: "",
      flatOrder: "",
      /* customErrorMsg: "",
      customSuccessMsg: "", */
      globalUsageLimit: "",
      perUserUsageLimit: "",
      perUserOrderLimit: "",
      formErrors: {
        discountName: "",
        discountDescription: "",
        discountCouponeCode: "",
        minimumOrder: "",
        flatOrder: "",
        /* customErrorMsg: "",
        customSuccessMsg: "", */
        globalUsageLimit: "",
        perUserUsageLimit: "",
        perUserOrderLimit: "",
        percentageErrors: "",
      },
      errorDateMessage: "",
      statusDateErrorFlag: false,
      discount_type: "Percentile",
      percentile_discount: "",
      maximum_discount_amount: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  componentDidMount() {
    this.getCouponDataListAPI();
    this.getChefListAPI();
    this.getCityListAPI();
  }

  getCouponDataListAPI = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getDiscountCodeDetailAPI(
      userId,
      this.props.location.search.substr(4, 100),
      token
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          console.log(response.data.data);
          this.setState({
            discountName: response.data.data.title
              ? response.data.data.title
              : "",
            discountDescription: response.data.data.description
              ? response.data.data.description
              : "",
            discountCouponeCode: response.data.data.coupon_code
              ? response.data.data.coupon_code
              : "",
            isVisible: response.data.data.visible == 1 ? true : false,
            isDelivery:
              response.data.data.free_delivery_enabled == 1 ? true : false,
            minimumOrder: response.data.data.minimum_order_amount
              ? response.data.data.minimum_order_amount
              : "",
            flatOrder: response.data.data.flat_discount
              ? response.data.data.flat_discount
              : "",
            couponStatus: response.data.data.status,
            globalUsageLimit: response.data.data.global_usage_limit
              ? response.data.data.global_usage_limit
              : "",
            perUserUsageLimit: response.data.data.per_user_usage_limit
              ? response.data.data.per_user_usage_limit
              : "",
            perUserOrderLimit: response.data.data.per_user_order_limit
              ? response.data.data.per_user_order_limit
              : "",
            discount_type: response.data.data.flat_discount
              ? "Flat"
              : "Percentile",
            maximum_discount_amount: response.data.data.maximum_discount_amount,
            percentile_discount: response.data.data.percentile_discount,
          });

          if (response.data.data.selected_weekday) {
            console.log(response.data.data.selected_weekday);
            console.log(response.data.data.selected_weekday.split(","));
            let array = [];
            array = response.data.data.selected_weekday.split(",");
            this.setState({
              selectedWeekDays: array,
            });
          }

          let startDate = response.data.data.start_date
            ? Moment(response.data.data.start_date).toDate()
            : "";
          let endDate = response.data.data.end_date
            ? Moment(response.data.data.end_date).toDate()
            : "";
          this.setState({
            formStartDate: startDate,
            formatStartDate: response.data.data.start_date,
            formStartEnd: endDate,
            formatEndDate: response.data.data.end_date,
          });
          if (response.data.data.chef_list) {
            let array = [];
            response.data.data.chef_list.map((item) => {
              array.push({ value: item.chef_id, label: item.chef_name });
            });
            this.setState({
              selectedChefArr: array,
            });
            if (array.length) {
              this.setState({
                selectedTab: "chef",
              });
            }
          }
          if (response.data.data.city_list) {
            let array = [];
            response.data.data.city_list.map((item) => {
              array.push({ value: item.city_id, label: item.city_name });
            });
            this.setState({
              selectedCityArr: array,
            });
            if (array.length) {
              this.setState({
                selectedTab: "city",
              });
            }
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleStatusChange(event) {
    this.setState({ couponStatus: event.target.value });
  }

  getChefListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getChefListAPI(id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.length) {
            let array = [];
            response.data.data.map((item) => {
              array.push({ value: item.id, label: item.chef_name });
            });
            this.setState({
              chefArr: array,
            });
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
      });
  };

  getCityListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getCityListAPI(id, currentPage, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.data.length) {
            let array = [];
            response.data.data.data.map((item) => {
              array.push({ value: item.id, label: item.name });
            });
            this.setState(
              {
                cityList: array,
              },
              () => {
                console.log(this.state.cityList);
              },
              100
            );
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  handleStartDateChange = (event) => {
    console.log(event);
    this.setState({
      formStartDate: event,
      formatStartDate: Moment(event).format("YYYY-MM-DD"),
    });
  };

  handleEndDateChange = (event) => {
    this.setState({
      formStartEnd: event,
      formatEndDate: Moment(event).format("YYYY-MM-DD"),
    });
  };

  handleVisibleCheckboxInput = (e) => {
    this.setState({
      isVisible: e.target.checked,
    });
  };

  handleDeliveryCheckboxInput = (e) => {
    this.setState({
      isDelivery: e.target.checked,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "discountName":
        formErrors.discountName = value === "" ? "Title is required" : "";
        break;

      case "discountDescription":
        formErrors.discountDescription =
          value === "" ? "Description is required" : "";
        break;

      case "discountCouponeCode":
        formErrors.discountCouponeCode =
          value === ""
            ? "Coupon Code is required"
            : !couponCodePattern.test(e.target.value)
            ? "Enter valid Coupon Code."
            : "";
        break;

      case "minimumOrder":
        formErrors.minimumOrder =
          value === "" ? "Minimum Order is required" : "";
        break;

      case "flatOrder":
        formErrors.flatOrder = value === "" ? "Flat Discount is required" : "";
        break;
      case "percentile_discount":
        formErrors.percentageErrors =
          value === "" ? "Percentile discount is required" : "";
        break;
      case "maximum_discount_amount":
        formErrors.percentageErrors =
          value === "" ? "Maximum discount amount is required" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.formatEndDate === "") {
      this.setState(
        {
          statusDateErrorFlag: true,
        },
        () => {
          this.setState({ errorDateMessage: "End Date not selected" });
        },
        100
      );
    }

    if (this.state.formatStartDate == "" && this.state.formatEndDate == "") {
      this.setState(
        {
          statusDateErrorFlag: true,
        },
        () => {
          this.setState({ errorDateMessage: "Start Date not selected" });
        },
        100
      );
    }
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.discountName === "") {
      formErrors.discountName = "Title is required";
    }
    if (this.state.discountDescription === "") {
      formErrors.discountDescription = "Description is required";
    }
    if (this.state.discountCouponeCode === "") {
      formErrors.discountCouponeCode = "Coupon Code is required";
    }
    if (this.state.minimumOrder === "") {
      formErrors.minimumOrder = "Minimum Order is required";
    }
    if (this.state.isDelivery === false) {
      if (this.state.discount_type === "Flat" && this.state.flatOrder === "") {
        formErrors.flatOrder = "Flat Order is required";
      } else {
        formErrors.flatOrder = "";
      }
      if (
        this.state.discount_type === "Percentile" &&
        (this.state.percentile_discount === "" ||
          this.state.maximum_discount_amount === "")
      ) {
        formErrors.percentageErrors =
          "Both Percentage and maximum discount amount is required when the discount type is percentage";
      } else {
        formErrors.percentageErrors = "";
      }
      if (
        this.state.discount_type === "Percentile" &&
        Number(this.state.percentile_discount) <= 0
      ) {
        formErrors.percentageErrors = "Percentage has to be greater than 0";
      } else {
        formErrors.percentageErrors = "";
      }
      if (
        this.state.discount_type === "Percentile" &&
        Number(this.state.percentile_discount) > 100
      ) {
        formErrors.percentageErrors = "Percentage cannot be greater than 100";
      } else {
        formErrors.percentageErrors = "";
      }
      if (
        this.state.discount_type === "Percentile" &&
        Number(this.state.maximum_discount_amount) <= 0
      ) {
        formErrors.percentageErrors =
          "Maximum discount amount has to be greater than 0";
      } else {
        formErrors.percentageErrors = "";
      }
    }
    console.log("Form errors here: ", formErrors);
    this.setState({ formErrors, [name]: value, errorDateMessage: "" });
    setTimeout(() => {
      if (
        formValid(this.state) &&
        this.state.formatStartDate &&
        this.state.formatEndDate
      ) {
        this.setState({ statusDateErrorFlag: false });
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        let finalChefArray = [];
        let finalCityArray = [];
        if (this.state.selectedChefArr.length) {
          this.state.selectedChefArr.map((item) => {
            finalChefArray.push(item.value);
          });
        }

        if (this.state.selectedCityArr.length) {
          this.state.selectedCityArr.map((item) => {
            finalCityArray.push(item.value);
          });
        }

        let formData = {
          coupon_code: this.state.discountCouponeCode,
          title: this.state.discountName,
          description: this.state.discountDescription,
          visible: this.state.isVisible ? 1 : 0,
          start_date: this.state.formatStartDate,
          end_date: this.state.formatEndDate,
          selected_weekday: this.state.selectedWeekDays,
          minimum_order_amount: this.state.minimumOrder,
          flat_discount:
            this.state.isDelivery === false &&
            this.state.discount_type === "Flat"
              ? this.state.flatOrder
              : 0,
          free_delivery_enabled: this.state.isDelivery ? 1 : 0,
          /*   custom_error_message: this.state.customErrorMsg,
          custom_success_message: this.state.customSuccessMsg, */
          global_usage_limit: this.state.globalUsageLimit,
          per_user_usage_limit: this.state.perUserUsageLimit,
          per_user_order_limit: this.state.perUserOrderLimit,
          chef_list: finalChefArray.toString(),
          city_list: finalCityArray.toString(),
          status: this.state.couponStatus,
          discount_type:
            this.state.isDelivery === false
              ? this.state.discount_type
              : "Delivery",
          maximum_discount_amount:
            this.state.isDelivery === false &&
            this.state.discount_type === "Percentile"
              ? this.state.maximum_discount_amount
              : 0,
          percentile_discount:
            this.state.isDelivery === false &&
            this.state.discount_type === "Percentile"
              ? this.state.percentile_discount
              : 0,
        };
        this.setState({
          buttonText: "Submitting...",
          buttonDisabled: true,
        });
        return editDiscountCodeAPI(
          id,
          token,
          this.props.location.search.substr(4, 100),
          formData
        )
          .then((response) => {
            if (response.data["status"] === 200) {
              NotificationManager.success(
                "Discount Coupon Code updated successfully",
                "Success",
                3000
              );
              this.setState({
                buttonText: "Submit",
              });
              setTimeout(() => {
                this.props.history.push("/discount-coupon-code-list");
              }, 2000);
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                buttonText: "Submit",
                buttonDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          });
      } else {
        NotificationManager.error(
          "Please see if the required fields are entered",
          "Error",
          3000
        );
      }
    }, 100);
  };

  handleCityChange = (e) => {
    this.setState({
      selectedCity: e ? e : null,
    });
    if (e) {
      this.setState({
        citySelectErr: "",
      });
    }
  };

  handleAddCity = () => {
    if (this.state.selectedChefArr.length) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui text-center">
              <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
              <p style={{ marginTop: "20px", marginBottom: "40px" }}>
                You want to add the City then the Chef record will be deleted?
              </p>
              <button
                className="btn btn-default waves-effect"
                style={{ color: "#000", marginRight: "10px" }}
                onClick={onClose}
              >
                No
              </button>
              <button
                class="btn"
                onClick={() => {
                  this.setState({
                    selectedChef: null,
                    selectedChefArr: [],
                  });
                  onClose();
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          );
        },
      });
    } else {
      console.log("out");
      if (!this.state.selectedCity) {
        this.setState({
          citySelectErr: "Please select city",
        });
      } else {
        this.setState((state) => {
          const { selectedCityArr, selectedCity } = this.state;
          let itemAlreadyInArray = false;
          selectedCityArr.forEach((item) => {
            if (item.value === selectedCity.value) {
              itemAlreadyInArray = true;
              NotificationManager.error(
                selectedCity.label + " is already added in the list",
                "Error!"
              );
            }
          });
          if (!itemAlreadyInArray) {
            selectedCityArr.push({ ...selectedCity });
            this.setState({
              selectedCity: null,
              selectedCuisineArr: [],
              selectedMenuArr: [],
              selectedTagArr: [],
            });
          }
          return selectedCityArr;
        });
        this.setState({
          tableSectionError: "",
        });
      }
    }
  };

  deleteCityFromList = (value) => {
    let array = this.state.selectedCityArr.filter((elm) => elm.value !== value);
    this.setState({
      selectedCityArr: array,
    });
    if (!array.length) {
      this.setState({
        tableSectionError: "Please select at least one from chef / city.",
      });
    }
  };

  handleChefChange = (e) => {
    this.setState({
      selectedChef: e ? e : null,
    });
    if (e) {
      this.setState({
        chefSelectErr: "",
      });
    }
  };

  handleAddChef = () => {
    if (this.state.selectedCityArr.length) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui text-center">
              <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
              <p style={{ marginTop: "20px", marginBottom: "40px" }}>
                You want to add the Chef then the City record will be deleted?
              </p>
              <button
                className="btn btn-default waves-effect"
                style={{ color: "#000", marginRight: "10px" }}
                onClick={onClose}
              >
                No
              </button>
              <button
                class="btn"
                onClick={() => {
                  this.setState({
                    selectedCity: null,
                    selectedCityArr: [],
                  });
                  onClose();
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          );
        },
      });
    } else {
      console.log("out");
      if (!this.state.selectedChef) {
        this.setState({
          chefSelectErr: "Please select chef",
        });
      } else {
        this.setState((state) => {
          const { selectedChefArr, selectedChef } = this.state;
          let itemAlreadyInArray = false;
          selectedChefArr.forEach((item) => {
            if (item.value === selectedChef.value) {
              itemAlreadyInArray = true;
              NotificationManager.error(
                selectedChef.label + " is already added in the list",
                "Error!"
              );
            }
          });
          if (!itemAlreadyInArray) {
            selectedChefArr.push({ ...selectedChef });
            this.setState({
              selectedChef: null,
              selectedCuisineArr: [],
              selectedMenuArr: [],
              selectedTagArr: [],
            });
          }
          return selectedChefArr;
        });
        this.setState({
          tableSectionError: "",
        });
      }
    }
  };

  deleteChefFromList = (value) => {
    let array = this.state.selectedChefArr.filter((elm) => elm.value !== value);
    this.setState({
      selectedChefArr: array,
    });
    if (!array.length) {
      this.setState({
        tableSectionError: "Please select at least one from chef / city.",
      });
    }
  };

  handleDayCheckboxInputChange = (e) => {
    console.log(e.target.value);
    if (e.target.checked) {
      let array = this.state.selectedWeekDays;
      if (!array.includes(e.target.value)) {
        array.push(e.target.value);
        this.setState({
          selectedWeekDays: array,
          weekDaysErr: "",
        });
      }
    } else {
      let array = this.state.selectedWeekDays.filter(
        (elm) => elm !== e.target.value
      );
      if (!array.length) {
        this.setState({
          weekDaysErr: "",
        });
      }
      this.setState({
        selectedWeekDays: array,
      });
    }
  };

  handleDiscountTypeChange = (e) => {
    console.log("E: ", e);
    const { name, value } = e.target;
    console.log("Value here: ", value);
    console.log("name here: ", name);

    switch (value) {
      case "Percentile":
        this.setState({ flatOrder: "" });
        break;
      case "Flat":
        this.setState({ percentile_discount: "", maximum_discount_amount: "" });
        break;
      default:
        this.setState({
          maximum_discount_amount: "",
          flatOrder: "",
        });
    }
    this.setState({ discount_type: value });
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    console.log("Coupon status: ", this.state.couponStatus);
    const { formErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Edit Coupon Code</h2>
                  </div>
                  {this.state.isLoading ? (
                    <center>
                      <Loader
                        style={{ marginTop: 50, paddingBottom: 50 }}
                        type="Oval"
                        color="#F07C07"
                        height={50}
                        width={50}
                      />
                    </center>
                  ) : (
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row clearfix">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <b>Title*</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="discountName"
                                      value={this.state.discountName}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                  {formErrors.discountName.length > 0 && (
                                    <span className="text-danger">
                                      {formErrors.discountName}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <b>Description*</b>
                                  <div className="form-line">
                                    <textarea
                                      className="form-control"
                                      rows={3}
                                      name="discountDescription"
                                      value={this.state.discountDescription}
                                      onChange={this.handleInputChange}
                                    ></textarea>
                                  </div>
                                  {formErrors.discountDescription.length >
                                    0 && (
                                    <span className="text-danger">
                                      {formErrors.discountDescription}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <b>Coupon Code*</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="discountCouponeCode"
                                      value={this.state.discountCouponeCode}
                                      maxLength="40"
                                      onChange={this.handleInputChange}
                                      style={{ textTransform: "uppercase" }}
                                    />
                                  </div>
                                  {formErrors.discountCouponeCode.length >
                                    0 && (
                                    <span className="text-danger">
                                      {formErrors.discountCouponeCode}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <div style={{ marginLeft: "20px" }}>
                                    <label htmlFor="isVisible">
                                      Is Visible?
                                    </label>
                                    <input
                                      type="checkbox"
                                      id="isVisible"
                                      name="isVisible"
                                      className="filled-in"
                                      onChange={this.handleVisibleCheckboxInput}
                                      checked={this.state.isVisible}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-7">
                                <div className="form-group">
                                  <b>Date Range</b>
                                  <div className="datePickerFlex">
                                    <DatePicker
                                      selected={this.state.formStartDate}
                                      onChange={this.handleStartDateChange}
                                      maxDate={this.state.formStartEnd}
                                      minDate={new Date()}
                                    />
                                    <p>&nbsp;&nbsp;to </p>
                                    <DatePicker
                                      selected={this.state.formStartEnd}
                                      onChange={this.handleEndDateChange}
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                                {this.state.statusDateErrorFlag ? (
                                  <p className="errorMessage">
                                    {this.state.errorDateMessage}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-sm-5">
                                <div className="form-group">
                                  <b>Select Weekday</b>
                                  <div className="input-group equal_checkbox mb-0">
                                    <div className="item_wrap">
                                      {this.state.daysOptions.map((item, i) => {
                                        return (
                                          <div key={"sdds" + i}>
                                            <label
                                              htmlFor={item}
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "100",
                                              }}
                                            >
                                              {item}
                                            </label>
                                            <input
                                              type="checkbox"
                                              id={item}
                                              value={item}
                                              className="filled-in"
                                              onChange={
                                                this
                                                  .handleDayCheckboxInputChange
                                              }
                                              checked={this.state.selectedWeekDays.includes(
                                                item
                                              )}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*  <div className="col-sm-4">
                                <div className="form-group">
                                  <b>Custom Error Message</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="customErrorMsg"
                                      value={this.state.customErrorMsg}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <b>Custom Success Message</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="customSuccessMsg"
                                      value={this.state.customSuccessMsg}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div> */}
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <b>Global usage limit</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="globalUsageLimit"
                                      value={this.state.globalUsageLimit}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <b>Per User usage limit</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="perUserUsageLimit"
                                      value={this.state.perUserUsageLimit}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <b>Order No. Condition</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="perUserOrderLimit"
                                      value={this.state.perUserOrderLimit}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <b>Minimum Order*</b>
                                  <div className="form-line">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="minimumOrder"
                                      value={this.state.minimumOrder}
                                      maxLength="20"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                  {formErrors.minimumOrder.length > 0 && (
                                    <span className="text-danger">
                                      {formErrors.minimumOrder}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <div style={{ marginLeft: "20px" }}>
                                    <label htmlFor="isDelivery">
                                      Free Delivery
                                    </label>
                                    <input
                                      type="checkbox"
                                      id="isDelivery"
                                      name="isDelivery"
                                      className="filled-in"
                                      onChange={
                                        this.handleDeliveryCheckboxInput
                                      }
                                      checked={this.state.isDelivery}
                                    />
                                  </div>
                                </div>
                              </div>
                              {this.state.isDelivery ? null : (
                                <div className="col-sm-4">
                                  <div className="input-group">
                                    <input
                                      name="discount_type"
                                      type="radio"
                                      id="percentage"
                                      className="radio-col-orange"
                                      value="Percentile"
                                      onChange={this.handleDiscountTypeChange}
                                      checked={
                                        this.state.discount_type ===
                                        "Percentile"
                                      }
                                    />
                                    <label for="percentage">Percentage</label>

                                    <input
                                      name="discount_type"
                                      type="radio"
                                      id="flat_discount"
                                      className="radio-col-orange"
                                      value="Flat"
                                      onChange={this.handleDiscountTypeChange}
                                      checked={
                                        this.state.discount_type === "Flat"
                                      }
                                    />
                                    <label for="flat_discount">
                                      Flat Discount
                                    </label>
                                  </div>
                                  {this.state.discount_type === "Flat" ? (
                                    <div className="form-group">
                                      <b>Flat Discount*</b>
                                      <div className="form-line">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="flatOrder"
                                          value={this.state.flatOrder}
                                          maxLength="20"
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                      {formErrors.flatOrder.length > 0 && (
                                        <span className="text-danger">
                                          {formErrors.flatOrder}
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="form-group">
                                        <div className="form-line">
                                          <b>Percentage Discount*</b>
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="percentile_discount"
                                            value={
                                              this.state.percentile_discount
                                            }
                                            maxLength="20"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <b>
                                          Maximum Discount Amount in Ruppees*
                                        </b>

                                        <div className="form-line">
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="maximum_discount_amount"
                                            value={
                                              this.state.maximum_discount_amount
                                            }
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                      {formErrors.percentageErrors.length >
                                        0 && (
                                        <span className="text-danger">
                                          {formErrors.percentageErrors}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              {this.state.couponStatus.length && (
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <b>Status</b>
                                    <div className="form-line">
                                      <select
                                        className="form-control show-tick"
                                        value={this.state.couponStatus}
                                        onChange={this.handleStatusChange}
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="CANCELLED">
                                          CANCELLED
                                        </option>
                                        <option value="COMPLETED">
                                          COMPLETED
                                        </option>
                                        <option value="EXPIRED">EXPIRED</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="col-sm-12">
                                <ul
                                  className="nav nav-tabs tab-nav-right"
                                  role="tablist"
                                >
                                  <li role="presentation" className="active">
                                    <a href="#chef-tab" data-toggle="tab">
                                      <i className="material-icons">face</i>Chef
                                    </a>
                                  </li>
                                  <li role="presentation">
                                    <a href="#city-tab" data-toggle="tab">
                                      <i className="material-icons">business</i>
                                      City
                                    </a>
                                  </li>
                                </ul>

                                <div className="tab-content">
                                  <div
                                    role="tabpanel"
                                    className="tab-pane fade in active"
                                    id="chef-tab"
                                  >
                                    <div className="col-sm-8">
                                      <div className="form-group">
                                        {/* <div className="form-line"> */}
                                        <Select
                                          value={this.state.selectedChef}
                                          placeholder="Select Chef"
                                          onChange={this.handleChefChange}
                                          options={this.state.chefArr}
                                        />
                                        {/* </div> */}
                                        {this.state.chefSelectErr.length >
                                          0 && (
                                          <span className="text-danger">
                                            {this.state.chefSelectErr}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={this.handleAddChef}
                                      >
                                        Add Chef
                                      </button>
                                    </div>
                                    <table className="table table-bordered table-striped table-hover">
                                      <thead>
                                        <tr>
                                          <th
                                            className="text-center"
                                            width="20%"
                                          >
                                            Sr. No.
                                          </th>
                                          <th className="text-center">
                                            Chef Name
                                          </th>
                                          <th
                                            className="text-center"
                                            width="10%"
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      {this.state.selectedChefArr.length ? (
                                        <tbody>
                                          {this.state.selectedChefArr.map(
                                            (item, i) => {
                                              return (
                                                <tr key={"a" + i}>
                                                  <td className="text-center">
                                                    {i + 1}
                                                  </td>
                                                  <td>{item.label}</td>
                                                  <td className="text-center">
                                                    <i
                                                      className="material-icons view_link"
                                                      onClick={() =>
                                                        this.deleteChefFromList(
                                                          item.value
                                                        )
                                                      }
                                                    >
                                                      delete
                                                    </i>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="3"
                                              className="text-center"
                                            >
                                              No chef added.
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                  <div
                                    role="tabpanel"
                                    className="tab-pane fade in"
                                    id="city-tab"
                                  >
                                    <div className="col-sm-8">
                                      <div className="form-group">
                                        {/* <div className="form-line"> */}
                                        <Select
                                          value={this.state.selectedCity}
                                          placeholder="Select City"
                                          onChange={this.handleCityChange}
                                          options={this.state.cityList}
                                        />
                                        {/* </div> */}
                                        {this.state.citySelectErr.length >
                                          0 && (
                                          <span className="text-danger">
                                            {this.state.citySelectErr}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={this.handleAddCity}
                                      >
                                        Add City
                                      </button>
                                    </div>
                                    <table className="table table-bordered table-striped table-hover">
                                      <thead>
                                        <tr>
                                          <th
                                            className="text-center"
                                            width="20%"
                                          >
                                            Sr. No.
                                          </th>
                                          <th className="text-center">
                                            City Name
                                          </th>
                                          <th
                                            className="text-center"
                                            width="10%"
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      {this.state.selectedCityArr.length ? (
                                        <tbody>
                                          {this.state.selectedCityArr.map(
                                            (item, i) => {
                                              return (
                                                <tr key={"a" + i}>
                                                  <td className="text-center">
                                                    {i + 1}
                                                  </td>
                                                  <td>{item.label}</td>
                                                  <td className="text-center">
                                                    <i
                                                      className="material-icons view_link"
                                                      onClick={() =>
                                                        this.deleteCityFromList(
                                                          item.value
                                                        )
                                                      }
                                                    >
                                                      delete
                                                    </i>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="3"
                                              className="text-center"
                                            >
                                              No chef added.
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                </div>
                                {this.state.tableSectionError.length > 0 && (
                                  <span className="text-danger">
                                    {this.state.tableSectionError}
                                  </span>
                                )}
                              </div>
                              <div className="col-sm-12">
                                <Link
                                  to="/discount-coupon-code-list"
                                  className="btn btn-default waves-effect"
                                  style={{ color: "#000", marginRight: "10px" }}
                                >
                                  Cancel
                                </Link>
                                <button
                                  type="submit"
                                  disabled={this.state.buttonDisabled}
                                  className="btn"
                                >
                                  {this.state.buttonText}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
