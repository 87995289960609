import React, { Component } from "react";
import Header from "../../components/Header";
import { getCityAPI, editCityAPI } from "../../core/services/APIServices";
import Geocode from "react-geocode";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import cities from "../../config/cities";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(formErrors).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class EditCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tag: "",
      isLoading: true,
      cityName: "",
      country: "India",
      state: "",
      pincode: "",
      latitude: "",
      longitude: "",
      image: null,
      isPublished: false,
      primaryType: "",
      secondaryType: "",
      formErrors: {
        cityName: "",
        country: "",
        state: "",
        pincode: "",
        latitude: "",
        longitude: "",
      },
      imageErr: "",
      imageUrl: "",
      buttonText: "Submit",
      buttonDisabled: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getCityAPI();
  }

  handleCheckboxInput = (e) => {
    this.setState({
      isPublished: e.target.checked,
    });
  };

  getCityAPI() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getCityAPI(id, this.props.location.search.substr(4, 100), token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            cityName: response.data.data.name,
            state: response.data.data.state,
            pincode: response.data.data.pincode,
            country: response.data.data.country,
            latitude: response.data.data.latitude,
            longitude: response.data.data.longitude,
            imageUrl: response.data.data.city_image,
            isLoading: false,
            isPublished: response.data.data.is_published == 1 ? true : false,
            primaryType: response.data.data.primary_delivery_type,
            secondaryType: response.data.data.secondary_delivery_type,
          });
          if (response.data.data.region !== null && response.data.data.region !== "") {
            var valueRegion = response.data.data.region;
            var region = valueRegion.split(",");
            console.log(region);
            this.setState({
              tags: region,
            });
          } else {
            var valueRegion = response.data.data.name;
            var region = valueRegion.split(", ");
            console.log(region);
            this.setState({
              tags: region,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error("Something went wrong", "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  }

  clearPrevImage = () => {
    this.setState({
      imageUrl: "",
      imageErr: "Image is required",
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "cityName":
        if (value) {
          Geocode.setApiKey(config.placesKey);
          Geocode.fromAddress(value).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              this.setState({
                latitude: lat,
                longitude: lng,
              });
              formErrors.latitude = "";
              formErrors.longitude = "";
              var stateCnt = 0;
              var pinCnt = 0;
              response.results[0].address_components.forEach((item) => {
                item.types.forEach((type) => {
                  if (type === "administrative_area_level_1") {
                    if (item.long_name) {
                      formErrors.state = "";
                    }
                    this.setState({
                      state: item.long_name,
                    });
                    stateCnt++;
                  }
                  if (type === "postal_code") {
                    if (item.long_name) {
                      formErrors.pincode = "";
                    }
                    this.setState({
                      pincode: item.long_name,
                    });
                    pinCnt++;
                  }
                });
              });
              if (stateCnt === 0) {
                this.setState({
                  state: "",
                });
              }
              if (pinCnt === 0) {
                this.setState({
                  pincode: "",
                });
              }
            },
            (error) => {
              console.error(error);
            }
          );
        } else {
          this.setState({
            state: "",
            pincode: "",
            latitude: "",
            longitude: "",
          });
          formErrors.state = "State is required";
          formErrors.pincode = "Pincode is required";
          formErrors.latitude = "Latitude is required";
          formErrors.longitude = "Longitude is required";
        }
        formErrors.cityName = value.length <= 0 ? "City name is required" : "";
        break;

      case "country":
        formErrors.country =
          value.length <= 0
            ? "Country is required"
            : !value.match(/^[a-zA-Z ]+$/)
            ? "Enter valid country name"
            : "";
        break;

      case "state":
        formErrors.state =
          value.length <= 0
            ? "State is required"
            : !value.match(/^[a-zA-Z ]+$/)
            ? "Enter valid state name"
            : "";
        break;

      case "pincode":
        formErrors.pincode =
          value.length <= 0
            ? "Pincode is required"
            : !value.match(/^[1-9][0-9]{5}$/)
            ? "Enter valid pincode"
            : "";
        break;

      case "latitude":
        formErrors.latitude = value.length <= 0 ? "Latitude is required" : "";
        break;

      case "longitude":
        formErrors.latitude = value.length <= 0 ? "Latitude is required" : "";
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  onImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //     image: e.target.files[0],
      //     imageUrl: "",
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("svg") ||
        e.target.files[0].type.includes("gif")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            imageErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadFile").value = "";
        } else {
          this.setState({
            imageErr: "",
            image: e.target.files[0],
            imageUrl: "",
          });
        }
      } else {
        this.setState({
          imageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png, .gif and .svg",
        });
        document.getElementById("uploadFile").value = "";
      }
    } else {
      this.setState({
        image: null,
        imageErr: "Image is required",
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const {
      cityName,
      country,
      state,
      pincode,
      latitude,
      longitude,
      image,
      imageUrl,
    } = this.state;
    if (cityName === "") {
      formErrors.cityName = "City name is required";
    }
    if (country === "") {
      formErrors.country = "Country is required";
    }
    if (state === "") {
      formErrors.state = "State is required";
    }
    if (pincode === "") {
      formErrors.pincode = "Pincode is required";
    }
    if (latitude === "") {
      formErrors.latitude = "Latitude is required";
    }
    if (longitude === "") {
      formErrors.longitude = "Longitude is required";
    }
    if (image === null && imageUrl === "") {
      this.state.imageErr = "Image is required";
    }
    this.setState({ formErrors, [name]: value });

    if (formValid(this.state) && this.state.imageErr === "") {
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      let formData = {
        cityName: this.state.cityName,
        state: this.state.state,
        pincode: this.state.pincode,
        country: this.state.country,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        image: this.state.imageUrl ? null : this.state.image,
        is_published: this.state.isPublished ? 1 : 0,
        primary_delivery_type: this.state.primaryType,
        secondary_delivery_type: this.state.secondaryType,
        region: this.state.tags.toString(),
      };
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });
      return editCityAPI(
        id,
        token,
        this.props.location.search.substr(4, 100),
        formData
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "City edited successfully",
              "Success",
              3000
            );
            this.setState({
              buttonText: "Submit",
            });
            setTimeout(() => {
              this.props.history.push("/city-list");
            }, 2000);
          } else {
            NotificationManager.error(response.data["message"], "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!");
          this.setState({
            buttonText: "Submit",
            buttonDisabled: false,
          });
        });
    }
  };

  handleChange = (tags) => {
    this.setState({ tags });
  };

  render() {
    const { formErrors } = this.state;
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Edit City</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  {this.state.isLoading ? (
                    <center>
                      <Loader
                        style={{ marginTop: 50, paddingBottom: 50 }}
                        type="Oval"
                        color="#F07C07"
                        height={50}
                        width={50}
                      />
                    </center>
                  ) : (
                    <div className="body">
                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                              <b>City Name*</b>
                              <div className="form-line">
                                <select
                                  className="form-control"
                                  name="cityName"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                >
                                  {cities.map((item, i) => {
                                    return (
                                      <option
                                        value={item}
                                        key={"a" + i}
                                        selected={
                                          item === this.state.cityName
                                            ? true
                                            : false
                                        }
                                      >
                                        {item}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {formErrors.cityName.length > 0 && (
                                <span className="text-danger">
                                  {formErrors.cityName}
                                </span>
                              )}
                            </div>

                            <div className="form-group ">
                              <div className="form-line">
                                <TagsInput
                                  inputProps={{ placeholder: "Add Region" }}
                                  value={this.state.tags}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <b>State*</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  value={this.state.state}
                                  className="form-control"
                                  name="state"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                />
                              </div>
                              {formErrors.state.length > 0 && (
                                <span className="text-danger">
                                  {formErrors.state}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <b>Country*</b>
                              <div className="form-line">
                                <input
                                  disabled={true}
                                  value="India"
                                  type="text"
                                  className="form-control"
                                  name="country"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                />
                              </div>
                              {formErrors.country.length > 0 && (
                                <span className="text-danger">
                                  {formErrors.country}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <b>Pincode*</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  value={this.state.pincode}
                                  className="form-control"
                                  name="pincode"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                />
                              </div>
                              {formErrors.pincode.length > 0 && (
                                <span className="text-danger">
                                  {formErrors.pincode}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <b>Latitude*</b>
                              <div className="form-line">
                                <input
                                  disabled={true}
                                  value={this.state.latitude}
                                  type="text"
                                  className="form-control"
                                  name="latitude"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                />
                              </div>
                              {formErrors.latitude.length > 0 && (
                                <span className="text-danger">
                                  {formErrors.latitude}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <b>Longitude*</b>
                              <div className="form-line">
                                <input
                                  disabled={true}
                                  value={this.state.longitude}
                                  type="text"
                                  className="form-control"
                                  name="longitude"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                />
                              </div>
                              {formErrors.longitude.length > 0 && (
                                <span className="text-danger">
                                  {formErrors.longitude}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <b>City Image*</b>
                              <div className="form-line">
                                <input
                                  id="uploadFile"
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .svg, .gif"
                                  className="form-control"
                                  name="image"
                                  onChange={this.onImageChange}
                                />
                              </div>
                              {this.state.imageErr.length > 0 && (
                                <span className="text-danger">
                                  {this.state.imageErr}
                                </span>
                              )}
                            </div>

                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                position: "relative",
                                width: "160px",
                              }}
                            >
                              {this.state.imageUrl ? (
                                <div>
                                  <img
                                    width="150px"
                                    src={this.state.imageUrl}
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -5,
                                      right: 0,
                                      height: "20px",
                                      width: "20px",
                                      backgroundColor: "red",
                                      borderRadius: "20px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={this.clearPrevImage}
                                  >
                                    <i
                                      className="material-icons"
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                        marginLeft: "3px",
                                        marginTop: "2px",
                                      }}
                                    >
                                      clear
                                    </i>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <b>Primary Delivery</b>
                              <div className="form-line">
                                <select
                                  className="form-control"
                                  name="primaryType"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                >
                                  <option value="">
                                    Select Primary Delivery
                                  </option>
                                  <option
                                    value="Dunzo"
                                    selected={this.state.primaryType == "Dunzo"}
                                  >
                                    Dunzo
                                  </option>
                                  <option
                                    value="WeFast"
                                    selected={
                                      this.state.primaryType == "WeFast"
                                    }
                                  >
                                    WeFast
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="form-group">
                              <b>Secondary Delivery</b>
                              <div className="form-line">
                                <select
                                  className="form-control"
                                  name="secondaryType"
                                  onChange={this.handleInputChange}
                                  onBlur={this.handleInputChange}
                                >
                                  <option value="">
                                    Select Secondary Delivery
                                  </option>
                                  <option
                                    value="Dunzo"
                                    selected={
                                      this.state.secondaryType == "Dunzo"
                                    }
                                  >
                                    Dunzo
                                  </option>
                                  <option
                                    value="Wefast"
                                    selected={
                                      this.state.secondaryType == "Wefast"
                                    }
                                  >
                                    Wefast
                                  </option>
                                </select>
                              </div>
                            </div>
                            <hr />
                            <label>{this.state.isPublished}</label>
                            <div className="form-group">
                              <div style={{ marginLeft: "20px" }}>
                                <label for="isPublished">Is published?</label>
                                <input
                                  type="checkbox"
                                  id="isPublished"
                                  name="isPublished"
                                  className="filled-in"
                                  onChange={this.handleCheckboxInput}
                                  checked={this.state.isPublished}
                                />
                              </div>
                            </div>
                            <Link
                              to="/city-list"
                              className="btn btn-default waves-effect"
                              style={{ color: "#000", marginRight: "10px" }}
                            >
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              disabled={this.state.buttonDisabled}
                              className="btn"
                            >
                              {this.state.buttonText}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
