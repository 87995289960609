import React, { Component } from "react";
import ReactDOM from "react-dom";
import Header from "../../components/Header";
import {
  getWeatherAPI,
  editWeatherAPI,
  getCityListAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};
const html = `<p></p>`;
class BadWeatherEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      message_title: "",
      checkboxValue: 0,
      cityList: [],
	  selectedValues: [],
      city: [],
      tempArr: [],
      status: '',
      checked: false,
      isLoading: true,
      buttonDishDisabled: false,
      formErrors: {
        message: "",
        message_title: "",
        selectedValues: "",
      },
    };
  }

  componentWillMount() {
	this.getCityListAPI();
	this.getWeatherDetailAPI();
  }
  
   getCityListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getCityListAPI(id, currentPage, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.data.length) {
            let array = [];
            response.data.data.data.map((item) => {
              array.push({ value: item.id, label: item.name, isChecked: false });
            });
            this.setState(
              {
                cityList: array,
              },
              () => {
                console.log(this.state.cityList, "console");
              },
              100
            );
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong", "Error!");
      });
  };
  
  getWeatherDetailAPI() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getWeatherAPI(id, this.props.location.search.substr(4, 100), token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            message_title: response.data.data[0].message_title,
            message: response.data.data[0].message,
            tempArr: response.data.data[0].city,
            selectedValues: response.data.data[0].city,
            checkboxValue: response.data.data[0].status,
            isLoading: false,
          });
          if (response.data.data[0].status == "1") {
            this.setState({
              checked: true,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error("Something went wrong", "Error!");
        }
		/* var carray = this.state.cityList;
		  
		  debugger;
		  if(response.data.data[0].selectedValues.length > 0){
			carray.forEach((c) => {
				c.isChecked = this.state.selectedValues.indexOf(c.label) == -1 ? false : true;
				this.state.cityList.push(c);
			});
			debugger;
		  } */
        console.log("city...", response.data.data[0].city);
        console.log(response.data.data[0].status, "status");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
	}
  
	handleInputChange(event) {
		const { name, value } = event.target;
		const target = event.target;
		//var value = target.value;
		var index = this.state.tempArr.indexOf(value);
		let formErrors = this.state.formErrors;
		if(target.checked){
			this.state.tempArr.push(value);
		}else{
			this.state.tempArr.splice(index, 1);
		}
		this.state.selectedValues=this.state.tempArr;
		formErrors.selectedValues = "";
		this.setState({ formErrors, [name]: value });
	}

  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "message_title":
        formErrors.message_title =
          value.length <= 0 ? "Message title is required" : "";
        break;
      case "message":
        formErrors.message = value.length <= 0 ? "Message is required" : "";
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  submitTitle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
	const { message, message_title, selectedValues, checkboxValue } = this.state;

    if (message === "") {
      formErrors.message = "Message is required";
    }

    if (message_title === "") {
      formErrors.message_title = "Message title is required";
    }
	
	if (selectedValues.length === 0) {
       formErrors.selectedValues = "City is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });
      return editWeatherAPI(
        id,
        token,
        this.props.location.search.substr(4, 100),
        message_title,
        message,
		selectedValues,
		checkboxValue,
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Bad weather message updated successfully",
              "Success",
              3000
            );
            this.setState({
              buttonText: "Submit",
            });
            setTimeout(() => {
              this.props.history.push("/bad-weather-list");
            }, 2000);
          } else {
            NotificationManager.error(response.data["message"], "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!");
          this.setState({
            buttonText: "Submit",
            buttonDisabled: false,
          });
        });
    } else {
      console.log("error");
    }
  };

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;

    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Edit Weather Detail</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <b>Message Title*</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={this.handleMenuInputChange}
                                  name="message_title"
                                  className="form-control"
                                  value={this.state.message_title}
                                />
                              </div>
                              {formErrors.message_title.length > 0 && (
                                <small className="text-danger">
                                  {formErrors.message_title}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <b>Message*</b>
                              <div className="form-line">
                                <textarea
                                  id="message"
                                  className="form-control"
                                  placeholder="Enter message here"
                                  value={this.state.message}
                                  onChange={this.handleMenuInputChange}
                                  name="message"
                                ></textarea>
                              </div>
                              {formErrors.message.length > 0 && (
                                <small className="text-danger">
                                  {formErrors.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <div className="form-group">
                              <b>City</b>
                              <div className="list-container">
                                {this.state.cityList.map((item, index) => (
                                  <div key={index}>
                                    <input
                                      value={item.label}
                                      key={item.name}
                                      type="checkbox"
                                      name="city"
                                      checked= {(this.state.selectedValues.indexOf(item.label) == -1 ? false : true)}
                                      onChange={(e) => {
										this.handleInputChange(e);
									  }}
                                    />
                                    <span className="not-checked-item">
                                      {item.label}
                                    </span>
                                  </div>
                                ))}
                              </div>
                             {formErrors.selectedValues.length > 0 && (
								<small className="text-danger">
								  {formErrors.selectedValues}
								</small>
							  )}
                            </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <b>
                                  Status
                                </b>
								 <div className="list-container">
									<input
									  value={this.state.checkboxValue}
									  className=""
									  type="checkbox"
									  name="checked"
									  checked= {this.state.checked}
									  onChange={event => {
										const target = event.target;
										var value = target.value;
										if(target.checked){
											this.setState({
											  checkboxValue: 1,
											  checked: true,
											});
										}else{
											this.setState({
											  checkboxValue: 0,
											  checked: false,
											});
										}
									  }}
									/>
									<span className='not-checked-item'>
									Active/Inactive
                                    </span>
								</div>
                              </div>
						  </div>
                          
                          <div className="col-sm-12">
                            <Link
                              to="/bad-weather-list"
                              className="btn btn-default waves-effect"
                              style={{ color: "#000", marginRight: "10px" }}
                            >
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              className="btn"
                              onClick={
                                this.state.dishId
                                  ? this.updateTitle
                                  : this.submitTitle
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
  editor: {
    border: "1px solid gray",
    minHeight: "6em",
  },
};

export default BadWeatherEdit;
