import React from 'react';
import Header from '../../components/Header';
import {
  getOfflineOrderAPI,
  deleteOfflineOrderAPI,
  markOfflinePaymentComplete
} from '../../core/services/APIServices';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import SideBarSection from '../../components/Sidebar';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactExport from 'react-data-export';
import { NotificationManager } from 'react-notifications';

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

const couponCodePattern = /^[a-zA-Z\d-_]+$/;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class OrderListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userEmailID: '',
      orderData: [],
      orderExcelData: [],
      userName: '',
      userToken: '',
      isLoading: true,
      activePage: 1,
      totalRecords: 10,
      dinerName: '',
      chefName: '',
      orderNo: '',
      payID: '',
      paidChef: '',
      orderType: '',
      is_diner_payment_received: '',
      dinerOrderFromDate: '',
      dinerOrderToDate: '',
      formatStartDate: '',
      formatEndDate: '',
      resetFlag: false,
      errorDateMessage: '',
      currentDate: '',
      couponFilter: '',
      ordersMarkedForPayment: [],
      paymentAmount: '',
      paymentUtrNumber: '',
      paymentRemark: '',
      isClick: '0',
      isOffline: '',
      isDelivered: '',
      formErrors: {
        couponFilter: ''
      }
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    var name = localStorage.getItem('userName');
    var token = localStorage.getItem('accessToken');
    this.setState({
      userName: name,
      userToken: token
    });
	
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userEmailID: email
    });
	
    this.getOrderListing();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today
    });
  }

  handleFilterChange(key) {
    const { name, value } = key.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'couponFilter':
        formErrors.couponFilter = !couponCodePattern.test(key.target.value)
          ? 'Enter valid Coupon Code.'
          : '';
        break;
    }
    if (name === 'chefName' || name === 'isDelivered') {
      this.setState({
        ordersMarkedForPayment: [],
        paymentAmount: '',
        paymentRemark: '',
        isOffline: '',
        isDelivered: '',
        isClick: '0',
        paymentUtrNumber: ''
      });
    }
    this.setState({
      [key.target.name]: key.target.value
    });
  }
  
  handleCheckboxInput = (e) => {
    this.setState({
      isOffline: e.target.checked
    });
  };

  handleStartDateChange(event) {
    this.setState({
      dinerOrderFromDate: event,
      formatStartDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleEndDateChange(event) {
    this.setState({
      dinerOrderToDate: event,
      formatEndDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  getOrderListing = () => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getOfflineOrderAPI(
      id,
      currentPage,
      this.state.orderNo,
	  this.state.orderType,
      this.state.dinerName,
      this.state.chefName,
	  this.state.is_diner_payment_received,
      this.state.paidChef,
      this.state.payID,
      this.state.formatStartDate,
      this.state.formatEndDate,
      this.state.isDelivered,
      token
    )
      .then((response) => {
        if (response.data['status'] == 200) {
          /*  console.log(response); */
          this.setState({
            orderData: response.data.data.data,
            orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
            totalRecords: response.data.data.pager_count,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getOrderListing();
    });
  }
  
  handleClickDelete = (id) => {
    var userId = localStorage.getItem("userID");
    // var dinerID = localStorage.getItem('dinerID')
    var token = localStorage.getItem("accessToken");
    return deleteOfflineOrderAPI(userId, id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.getOrderListing();
          NotificationManager.success("Order deleted successfully", "Success");
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteOrder = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this order?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  getOrderView = (id) => {
    localStorage.setItem('orderID', id);
    this.props.history.push('/offline-order-detail');
  };

  submitFilter = (event) => {
    event.preventDefault();
    var statusDate = false;
    if (
      (this.state.formatEndDate == '' || this.state.dinerOrderToDate == '') &&
      this.state.formatStartDate
    ) {
      statusDate = true;
      this.setState({ errorDateMessage: 'End Date not selected' });
    }

    if (this.state.formatStartDate == '' && this.state.formatEndDate) {
      statusDate = true;
      this.setState({ errorDateMessage: 'Start Date not selected' });
    }

    if (this.state.formatStartDate && this.state.formatEndDate) {
      statusDate = false;
      this.setState({ errorDateMessage: '', resetFlag: true });
      /* console.log(this.state.paidChef); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getOfflineOrderAPI(
        id,
        currentPage,
        this.state.orderNo,
		this.state.orderType,
		this.state.dinerName,
		this.state.chefName,
		this.state.is_diner_payment_received,
		this.state.paidChef,
		this.state.payID,
		this.state.formatStartDate,
		this.state.formatEndDate,
		this.state.isDelivered,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
			  isClick: '1',
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }

    if (statusDate === false && this.state.errorDateMessage === '') {
      this.setState({
        resetFlag: true
      });
      /* console.log(this.state.paidChef); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getOfflineOrderAPI(
        id,
        currentPage,
        this.state.orderNo,
		this.state.orderType,
		this.state.dinerName,
		this.state.chefName,
		this.state.is_diner_payment_received,
		this.state.paidChef,
		this.state.payID,
		this.state.formatStartDate,
		this.state.formatEndDate,
		this.state.isDelivered,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
              isClick: '1',
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        dinerName: '',
        chefName: '',
        orderNo: '',
        payID: '',
        paidChef: '',
        orderType: '',
        is_diner_payment_received: '',
        isDelivered: '',
        dinerOrderFromDate: '',
        dinerOrderToDate: '',
        formatStartDate: '',
        formatEndDate: '',
		ordersMarkedForPayment: [],
        isClick: '0',
        couponFilter: ''
      },
      () => {
        this.getOrderListing();
      }
    );
  };

  render() {
    if (!localStorage.getItem('accessToken')) {
      return <Redirect to='/' />;
    }

    const { formErrors } = this.state;

    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row clearfix'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='header'>
                    <h2 className='fw_500'>Offline Order Report</h2>
                    <ul className='header-dropdown m-r--5'>
                      <li className='dropdown'>
                        <a
                          href='#'
                          className='dropdown-toggle'
                          data-toggle='dropdown'
                          role='button'
                          aria-haspopup='true'
                          aria-expanded='false'
                        ></a>
                        <ul className='dropdown-menu pull-right'>
                          <li>
                            <a href='#'>Action</a>
                          </li>
                          <li>
                            <a href='#'>Another action</a>
                          </li>
                          <li>
                            <a href='#'>Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className='body'>
                    <div className='row clearfix'>
                      <div className='col-sm-12'>
                        <div className='row clearfix'>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Order Number</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='orderNo'
                                  value={this.state.orderNo}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Razor Pay ID</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='payID'
                                  value={this.state.payID}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Diner Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='dinerName'
                                  value={this.state.dinerName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Chef Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='chefName'
                                  value={this.state.chefName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
						  <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Is Delivered?</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='isDelivered'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select</option>
                                  <option
                                    value='Yes'
                                    selected={
                                      this.state.isDelivered == 'Yes'
                                    }
                                  >
                                    Yes
                                  </option>
                                  <option
                                    value='No'
                                    selected={
                                      this.state.isDelivered == 'No'
                                    }
                                  >
                                    No
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
						  <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Is Diner Payment Received?</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='is_diner_payment_received'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select</option>
                                  <option
                                    value='Yes'
                                    selected={
                                      this.state.is_diner_payment_received == 'Yes'
                                    }
                                  >
                                    Yes
                                  </option>
                                  <option
                                    value='No'
                                    selected={
                                      this.state.is_diner_payment_received == 'No'
                                    }
                                  >
                                    No
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Paid To Chef</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='paidChef'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select</option>
                                  <option
                                    value='Yes'
                                    selected={
                                      this.state.paidChef == 'Yes'
                                    }
                                  >
                                    Yes
                                  </option>
                                  <option
                                    value='No'
                                    selected={
                                      this.state.paidChef == 'No'
                                    }
                                  >
                                    No
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
						 <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Order Type</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='orderType'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select Order Type</option>
                                  <option
                                    value='Tiffin'
                                    selected={
                                      this.state.orderType == 'Tiffin'
                                    }
                                  >
                                    Tiffin
                                  </option>
								  <option
                                    value='Party'
                                    selected={
                                      this.state.orderType == 'Party'
                                    }
                                  >
                                    Party
                                  </option>
								  <option
                                    value='Online'
                                    selected={
                                      this.state.orderType == 'Online'
                                    }
                                  >
                                    Online
                                  </option>
								</select>
								</div>
							</div>
						  </div>
						  
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <b>Date Range</b>
                              <div className='datePickerFlex'>
                                <DatePicker
                                  selected={this.state.dinerOrderFromDate}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.dinerOrderToDate}
                                />
                                <p>&nbsp;&nbsp;to</p>
                                <DatePicker
                                  selected={this.state.dinerOrderToDate}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.dinerOrderFromDate}
                                />
                              </div>
                            </div>
                            {this.state.errorDateMessage ? (
                              <p className='errorMessage'>
                                {this.state.errorDateMessage}
                              </p>
                            ) : null}
                          </div>
                          <div className='col-sm-12 center_btn'>
                            <button
                              type='submit'
                              className='btn'
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type='submit'
                                className='btn'
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
					  
					  {this.state.ordersMarkedForPayment &&
                        this.state.ordersMarkedForPayment.length > 0 && (
						<div style={{ marginRight: '1.6rem' }}>
						<button
						  className='btn pull-right m-b-10'
						  data-toggle='modal'
						  data-target='#myModalPayChefMenu'
						>
						  Pay
						</button><br/>
					  </div>
					)}
                    </div>
                    <div className='table-responsive'>
						{/*  Admin and Ops menu */}
						{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
						<a className="btn" href="/add-offline-order" style={{ marginBottom: "20px" }}>
						  Add Offline Order
						</a>
						) : null}
                      {this.state.orderExcelData.length  && (this.state.userRole == 'Admin' || this.state.userRole == 'Finance' || this.state.userRole == 'Ops' || this.state.userRole == 'Marketing') ? (
                        <ExcelFile
                          filename={'Offline_Order_Report_' + this.state.currentDate}
                          element={
                            <button className='btn pull-right m-b-10'>
							{(this.state.userRole == 'Finance' || this.state.userRole == 'Ops' || this.state.userRole == 'Marketing') ? 'Last 100 Days Download Excel' : 'Download Excel'}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.orderExcelData}
                            name='Offline Order Report'
                          >
                            <ExcelColumn
                              label='Order Type'
                              value='order_type'
                            />
                            <ExcelColumn
                              label='Order Number'
                              value='order_no'
                            />
							<ExcelColumn
                              label='Order Date'
                              value='order_date'
                            />
							<ExcelColumn
                              label='Delivery Date'
                              value='delivery_date'
                            />
							<ExcelColumn
                              label='Pickup Time'
                              value='pickup_time'
                            />
							<ExcelColumn 
							  label='Chef Name' 
							  value='chef_name' 
							/>
                            <ExcelColumn
                              label='Chef EmailID'
                              value='chef_email'
                            />
                            <ExcelColumn
                              label='Chef Mobile'
                              value='chef_mobile_no'
                            />
                            <ExcelColumn
                              label='Chef Address'
                              value='chef_address'
                            />
                            <ExcelColumn
                              label='Diner Name'
                              value='diner_name'
                            />
                            <ExcelColumn
                              label='Diner EmailID'
                              value='diner_email_id'
                            />
                            <ExcelColumn
                              label='Diner Mobile'
                              value='diner_mobile_number'
                            />
                            <ExcelColumn
                              label='Diner Address'
                              value='diner_address'
                            />
							<ExcelColumn
                              label='Order Details'
                              value='order_details'
                            />
							<ExcelColumn
                              label='Order Amount'
                              value='order_amount'
                            />
							<ExcelColumn
                              label='Delivery Amount'
                              value='delivery_amount'
                            />
							<ExcelColumn
                              label='Promo Code'
                              value='promo_code'
                            />
							<ExcelColumn
                              label='Promo Discount'
                              value='promo_discount'
                            />
							<ExcelColumn
                              label='Received Amount Diner'
                              value='received_amount_diner'
                            />
							<ExcelColumn
                              label='Is Payment Received'
                              value='is_payment_received'
                            />
							<ExcelColumn
                              label='Is Paid To Chef'
                              value='is_paid_to_chef'
                            />
							<ExcelColumn
                              label='Is Delivered'
                              value='is_delivered'
                            />
							<ExcelColumn
                              label='Remark'
                              value='remark'
                            />
							<ExcelColumn
                              label='Razorpay ID'
                              value='razorpay_id'
                            />
							<ExcelColumn
                              label='Send To Sandeep At'
                              value='send_to_sandeep_at'
                            />
							<ExcelColumn
                              label='Reason For Offline'
                              value='reason_for_offline'
                            />
							<ExcelColumn
                              label='Chef Payment Status'
                              value='chef_payment_status'
                            />
							<ExcelColumn
                              label='Chef Paid Amount'
                              value='chef_paid_amount'
                            />
							<ExcelColumn
                              label='UTR Number'
                              value='chef_paid_utr_number'
                            />
							<ExcelColumn
                              label='Payment Remark'
                              value='chef_paid_remark'
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className='table table-bordered table-striped table-hover tbl_responsive order-table'>
                        <thead>
                          <tr>
							{this.state.chefName !== '' &&
                              this.state.isDelivered === 'Yes' && this.state.isClick == '1' && (this.state.userRole == 'Admin' || this.state.userRole == 'Ops' || this.state.userRole == 'Finance') && (
                                <th>Mark</th>
                              )}
                            <th>Sr. No.</th>
                            <th>Order Type</th>
                            <th>Order Date</th>
                            <th>Delivery Date</th>
                            <th>Pickup Time</th>
                            <th>Order No</th>
                            <th>Pay ID</th>
							<th>Chef Name</th>
							<th>Chef Mob No</th>
							<th>Chef Address</th>
                            <th>Diner Name</th>
                            <th>Diner Mob No</th>
							<th>Diner Address</th>
							<th>Order Amount</th>
							<th>Delivery Amount</th>
							<th>Is Payment Received</th>
							<th>Is Delivered</th>
							<th>Is Paid To Chef</th>
							<th>Send To Sandeep At</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className='text-center' colSpan='21'>
                                <Loader
                                  type='Oval'
                                  color='#F07C07'
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.orderData.length ? (
                          <tbody>
                            {this.state.orderData.map((order, i) => {
                              return (
                                <tr key={i}>
								  {this.state.chefName !== '' &&
                                    this.state.isDelivered === 'Yes' && this.state.isClick == '1' && (this.state.userRole == 'Admin' || this.state.userRole == 'Ops' || this.state.userRole == 'Finance') && (
                                      <td>
                                        {order.chef_payment_status === null ? (
                                          <div
                                            className='form-group'
                                            style={{ position: 'relative' }}
                                          >
                                            <input
                                              type='checkbox'
                                              id='isPublished'
                                              name='isPublished'
                                              className='filled-in position-relative'
                                              onChange={() => {
                                                const indexOfOrderNumber =
                                                  this.state.ordersMarkedForPayment.indexOf(
                                                    order.order_no
                                                  );
                                                var newMarked = [
                                                  ...this.state
                                                    .ordersMarkedForPayment
                                                ];
                                                if (indexOfOrderNumber > -1) {
                                                  newMarked = newMarked.filter(
                                                    (el) =>
                                                      el !== order.order_no
                                                  );
                                                } else {
                                                  newMarked.push(
                                                    order.order_no
                                                  );
                                                }

                                                this.setState({
                                                  ordersMarkedForPayment:
                                                    newMarked
                                                });
                                              }}
                                              checked={
                                                this.state.ordersMarkedForPayment.indexOf(
                                                  order.order_no
                                                ) > -1
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <p
                                            style={{
                                              marginTop: '1.5rem',
                                              textAlign: 'center'
                                            }}
                                          >
                                            Paid
                                          </p>
                                        )}
                                      </td>
                                    )}
                                  <td
                                    className='align-middle text-center'
                                    width='10%'
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + '' + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{order.order_type}</td>
                                  <td>{order.order_date}</td>
                                  <td>{order.delivery_date}</td>
                                  <td>{order.pickup_time}</td>
                                  <td>{order.order_no}</td>
                                  <td>{order.razorpay_id}</td>
                                  <td>{order.chef_name}</td>
                                  <td>{order.chef_mobile_no}</td>
                                  <td>{order.chef_address}</td>
                                  <td>{order.diner_name}</td>
                                  <td>{order.diner_mobile_number}</td>
                                  <td>{order.diner_address}</td>
                                  <td>₹ {Math.round(order.order_amount)}</td>
                                  <td>₹ {Math.round(order.delivery_amount)}</td>
								  <td>{order.is_payment_received}</td>
								  <td>{order.is_delivered}</td>
								  <td>{order.is_paid_to_chef}</td>
								  <td>{order.send_to_sandeep_at}</td>
                                  <td className='text-center'>
									{/*  Admin menu */}
									{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                    <a className='view_link'>
                                      <i
                                        className='material-icons'
                                        onClick={() =>
                                          this.getOrderView(order.id)
                                        }
                                      >
                                        remove_red_eye
                                      </i>
                                    </a>
									) : null}
									
									{/*  Admin menu */}
									{this.state.userRole == 'Admin' ? (
										<Link to={"/edit-offline-order?id=" + order.id}>
										<i
											className="material-icons view_link"
										>
											create
										</i>
									</Link>
									) : null}

									{/*  Admin menu */}
									{this.state.userRole == 'Admin' ? (
										<a
										  href="#"
										  className="view_link"
										  style={{ marginLeft: "5px" }}
										>
										  <i
											className="material-icons"
											onClick={() =>
												this.deleteOrder(order.id)
											}
										  >
											delete_forever
										  </i>
										</a>
									) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan='21'>No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className='text-right'>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className='modal fade'
          id='myModalPayChefMenu'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header justify-content-start flex-column'>
                <h5
                  className='modal-title ml-0 text-uppercase'
                  id='pins_achievedTitle'
                >
                  Pay home chef details
                  <button
                    type='button'
                    className='close'
                    id='closeMenuFormButton'
                    data-dismiss='modal'
                    onClick={() =>
                      this.setState({
                        showRejectReasonAlert: false
                      })
                    }
                    aria-label='Close'
                  >
                    <img
                      src='assets/images/icons/black-close.svg'
                      alt='Close'
                    />
                  </button>
                </h5>
              </div>
              <div className='modal-body'>
                <div className='form-group'>
                  <b>Enter Amount</b>
                  <div className='form-line'>
                    <input
                      type='number'
                      className='form-control'
                      value={this.state.paymentAmount}
                      onChange={(e) =>
                        this.setState({ paymentAmount: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <b>Enter UTR Number</b>
                  <div className='form-line'>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.paymentUtrNumber}
                      onChange={(e) =>
                        this.setState({ paymentUtrNumber: e.target.value })
                      }
                    />
                  </div>
                </div>
				{/*<div className='form-group'>
                   <div style={{ marginLeft: '20px' }}>
					<label htmlFor='isOffline'>Is offline?</label>
					<input
					  type='checkbox'
					  id='isOffline'
					  name='isOffline'
					  className='filled-in'
					  onChange={this.handleCheckboxInput}
					  checked={this.state.isOffline}
					/>
				  </div>
				</div>*/}
				<div className='form-group'>
                  <b>Enter Remark</b>
                  <div className='form-line'>
                   <textarea
					className='form-control'
					rows={3}
					defaultValue={this.state.paymentRemark}
					onChange={(e) =>
						this.setState({ paymentRemark: e.target.value })
					}
					name='paymentRemark'
				  ></textarea>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '3rem',
                    display: 'flex',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <button
                    type='button'
                    data-dismiss='modal'
                    id='closePasswordButton'
                    aria-label='Close'
                    disabled={this.state.buttonDisabled}
                    className='btn btn-default waves-effect'
                    style={{ color: '#000', marginRight: '10px' }}
                    onClick={this.menuModalClosed}
                  >
                    Cancel
                  </button>
                  <button
                    data-toggle='modal'
                    data-target='#myModalPayChefMenu'
                    className='btn'
                    disabled={
                      this.state.paymentAmount === '' ||
                      this.state.paymentUtrNumber === '' ||
                      !this.state.paymentAmount
                    }
                    onClick={async () => {
                      console.log(
                        'OrderNumbers: ',
                        this.state.ordersMarkedForPayment
                      );
                      console.log('OrderAmount: ', this.state.paymentAmount);
                      console.log('OrderUtrNumber: ', this.state.paymentUtrNumber);
                      var userId = localStorage.getItem('userID');
                      var token = localStorage.getItem('accessToken');
                      const result = await markOfflinePaymentComplete(
                        this.state.ordersMarkedForPayment,
                        this.state.paymentUtrNumber,
                        this.state.paymentAmount,
                        this.state.isOffline,
                        this.state.paymentRemark,
                        userId,
                        token
                      );
                      console.log('Result: ', result.response);
                      if (result.status === 200) {
                        NotificationManager.success(
                          'Payment to home chef recorded successfully',
                          'Success'
                        );
                        this.setState({ isLoading: true }, () => {
                          this.getOrderListing();
                          document.getElementById('myModalPayChefMenu').click();
                        });
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderListingPage;
