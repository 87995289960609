import React from "react";
import {withRouter} from 'react-router-dom';
// React Notification
import { NotificationManager } from "react-notifications";

class Header extends React.Component {
    constructor(props){
        super(props);
    }

    logoutHandler = (e) => {
        localStorage.clear();
        this
            .props
            .history
            .push('/');
        NotificationManager.success('You have Logout Successfully', 'Successful!', 3000);
    }
  render() {
    return (
      <div>
        <nav className="navbar">
          <div className="container-fluid">
            <div className="navbar-header">
              <a
                href="#"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar-collapse"
                aria-expanded="false"
              ></a>
              <a href="#" className="bars"></a>
              <a className="navbar-brand" href="#">
                <img src="assets/images/chef-pin.png" alt="" />
              </a>
            </div>
            <div className="collapse navbar-collapse" id="navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="/">
                    <i className="material-icons" onClick= {e=>this.logoutHandler(e)}>input</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withRouter(Header);
