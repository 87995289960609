import React from "react";
import Header from "../../components/Header";
import Pagination from "react-js-pagination";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-data-export";

import {
  reviewListAPI,
  deleteReviewAPI,
  detailReviewAPI,
  updateReviewAPI,
} from "../../core/services/APIServices";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReviewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
	  userName: '',
	  userEmailID: '',
      reviewListArr: [],
      dinerEmail: "",
      chefEmail: "",
      activePage: 1,
      totalRecords: 10,
      isLoading: true,
      resetFlag: false,
      moderationFlag:"",
      displayDinerName: "",
      displayDinerReview: "",
      displayDinerEmail: "",
      displayChefName: "",
      displayChefEmail: "",
      displayOrderNumaber: "",
      displayCreactedAt: "",
      displayModerated: "",
      moderatedRemark: "",
      foodQualityRating: "",
      portionSizeRating: "",
      packagingRating: "",
      valueForMoneyRating: "",
      averageRating: "",
      reviewId: "",
      userStatus: "",
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  componentDidMount() {
	var name = localStorage.getItem('userName');
	var email = localStorage.getItem('userEmail');
	var role = localStorage.getItem('userRole');
	this.setState({
	  userRole: role,
	  userName: name,
	  userEmailID: email
	});
    this.getReviewList();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getReviewList();
    });
  }

  handleFilterChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }

  submitFilter = (event) => {
    event.preventDefault();
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = "1";
    this.setState({
      activePage: 1,
      resetFlag: true,
    });
    return reviewListAPI(
      id,
      currentPage,
      this.state.dinerEmail,
      this.state.chefEmail,
      this.state.userStatus,
      token
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            reviewListArr: response.data.data.data,
            totalRecords: response.data.data.pager_count,
            /*  dinerExcelData: response.data["data"]["records_found"],
            totalRecords: response.data.data.pager_count, */
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        dinerEmail: "",
        chefEmail: "",
      },
      () => {
        this.getReviewList();
      }
    );
  };

  getReviewList = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return reviewListAPI(
      id,
      currentPage,
      this.state.dinerEmail,
      this.state.chefEmail,
      this.state.userStatus,
      token
    )
      .then((response) => {
        /*  console.log(response.data);  */
        if (response.data["status"] === 200) {
          this.setState({
            reviewListArr: response.data["data"].data,
            totalRecords: response.data.data.pager_count,
            /* dinerQueryExcelData: response.data.data.records_found,
                totalRecords: response.data["data"]["pager_count"], */
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  deleteReview = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this review?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  handleClickDelete = (id) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteReviewAPI(userId, id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          // this.setState({
          //     isLoading: true
          // })
          this.getReviewList();
          NotificationManager.success("Review deleted successfully", "Success");
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getReviewDetails = (reviewId) => {
    console.log(reviewId);
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return detailReviewAPI(userId, reviewId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            displayDinerName: response.data.data.diner_name,
            displayDinerReview: response.data.data.review,
            displayDinerEmail: response.data.data.diner_email,
            displayChefName: response.data.data.chef_name,
            displayChefEmail: response.data.data.chef_email,
            displayOrderNumaber: response.data.data.order_number,
            displayCreactedAt: response.data.data.updated_at,
            displayModerated: response.data.data.is_moderated,
            reviewId: response.data.data.id,
            moderationFlag: response.data.data.moderation_status,
            averageRating: response.data.data.average_rating,
            foodQualityRating: response.data.data.food_quality,
            portionSizeRating: response.data.data.portion_size,
            packagingRating: response.data.data.packaging,
            valueForMoneyRating: response.data.data.value_for_money,
            moderatedRemark: response.data.data.moderation_remarks
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitMenuForm = (e) => {
    e.preventDefault();
    let id = localStorage.getItem("userID");
    let token = localStorage.getItem("accessToken");

    return updateReviewAPI(
      id,
      this.state.reviewId,
      this.state.moderationFlag,
      this.state.moderatedRemark,
      token
    )
      .then((response) => {
        console.log(response);
        document.getElementById("closeMenuFormButton").click();
        this.getReviewList();
        if (response.data["status"] === 200) {
          NotificationManager.success(
            response.data.message,
              "Success",
              3000
          );
        } else{
            NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleStatusChange(event) {
    this.setState({ userStatus: event.target.value });
  }


  render() {
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Review Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Diner Email</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dinerEmail"
                                  value={this.state.dinerEmail}
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Chef Email</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="chefEmail"
                                  value={this.state.chefEmail}
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Status</b>
                              <div className="form-line">
                                <select
                                  className="form-control show-tick"
                                  value={this.state.userStatus}
                                  onChange={this.handleStatusChange}
                                >
                                  <option value="">-- Any --</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="ACCEPTED">ACCEPTED</option>
                                  <option value="REJECTED">REJECTED</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 center_btn">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {/* {this.state.reviewListArr.length ? (
                        <ExcelFile
                          filename={"Enquiry_Report_" + this.state.currentDate}
                          element={
                            <button className="btn pull-right m-b-10">
                              Download Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.dinerQueryExcelData}
                            name="Enquiry Report"
                          >
                            <ExcelColumn label="Date" value="created_at" />
                            <ExcelColumn label="Diner Name" value="dinner_name" />
                            <ExcelColumn label="Diner Email ID" value="dinner_email" />
                            <ExcelColumn label="Dner Mobile Number" value="dinner_mobile_number" />
                            <ExcelColumn label="Chef Name" value="chef_name" />
                            <ExcelColumn label="Chef Email ID" value="email" />
                            <ExcelColumn
                              label="Chef Mobile Number"
                              value="mobile_number"
                            />
                            <ExcelColumn
                              label="Message"
                              value="message"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null} */}
                      <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Review Date</th>
                            <th>Date of Order</th>
                            <th>Order Detail</th>
                            <th>Diner Name</th>
                            <th>Diner Email</th>
                            <th>Chef Name</th>
                            <th>Chef Email</th>
                            <th>Status</th>
                            {/*   <th>Chef Mobile No.</th>
                            <th>Message</th> */}

                            <th>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="6">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.reviewListArr.length ? (
                          <tbody>
                            {this.state.reviewListArr.map((user, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>
                                    {Moment(user.created_at).format(
                                      "DD/MM/YYYY hh:mm a"
                                    )}
                                  </td>
                                  <td>
                                    {(user.order_date == "1970-01-01" || user.order_date == null || user.order_date == "") ? "-" : Moment(user.order_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>{user.order_details == null ? user.order_number:user.order_details}</td>
                                  <td>{user.diner_name}</td>
                                  <td>{user.diner_email}</td>
                                  <td>{user.chef_name}</td>
                                  <td>{user.chef_email}</td>
                                
                                  <td>{user.moderation_status}</td>

                                  <td>
									{/*  Admin and Marketing menu */}
									{this.state.userRole == 'Admin' ? (
                                    <a href="#" className="view_link">
                                      <i
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                        className="material-icons view_link"
                                        onClick={() =>
                                          this.getReviewDetails(user.id)
                                        }
                                      >
                                        remove_red_eye
                                      </i>
                                    </a>
									) : null}
									{/*  Admin and Marketing menu */}
									{this.state.userRole == 'Admin' ? (
                                    <a href="#" className="view_link">
                                      <i
                                        className="material-icons"
                                        onClick={this.deleteReview.bind(
                                          this,
                                          user.id
                                        )}
                                      >
                                        delete_forever
                                      </i>
                                    </a>
									 ) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="6">No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade menu_list_pop"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="add_another_btn">
                  <button
                    type="button"
                    className="close plus_icon"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <h4 className="modal-title color_C07" id="myModalLabel">
                  Review Details
                </h4>
              </div>
              <div className="modal-body">
                <form id="chefMenuForm" onSubmit={this.submitMenuForm}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Order Number:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayOrderNumaber}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Review Date:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayCreactedAt}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Review Description:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayDinerReview}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Diner Name:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayDinerName}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Diner Email:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayDinerEmail}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Chef Name:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayChefName}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Chef Email:</b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            defaultValue={this.state.displayChefEmail}
                            readOnly
                            name="menuName"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>Moderated</b>
                        <div className="form-group">
                          <input
                            name="moderationFlag"
                            type="radio"
                            id="vegRadio11"
                            className="radio-col-orange"
                            value="ACCEPTED"
                            onChange={this.handleFilterChange}
                            checked={this.state.moderationFlag === "ACCEPTED"}
                          />
                          <label for="vegRadio11" style={{ fontWeight: "100" }}>
                            Accepted
                          </label>
                          <input
                            name="moderationFlag"
                            type="radio"
                            id="nonVegRadio22"
                            className="radio-col-orange"
                            value="REJECTED"
                            onChange={this.handleFilterChange}
                            checked={this.state.moderationFlag === "REJECTED"}
                          />
                          <label
                            for="nonVegRadio22"
                            style={{ fontWeight: "100" }}
                          >
                            Rejected
                          </label>
                        </div>
                        {/* <div
                          className="demo-switch"
                          style={{ marginBottom: "20px", marginTop: "10px" }}
                        >
                          <div className="switch">
                            <label>
                              No
                              <input
                                type="checkbox"
                                value={true}
                                checked={this.state.isMenuEnabled}
                                onChange={this.checkMenuEnabled}
                              />
                              <span className="lever switch-col-deep-orange"></span>
                              Yes
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
					 <div className="col-md-6">
                      <div className="form-group">
                        <b>Avg Start Rating:</b>
                        <div className="form-line">
                          <span>{this.state.averageRating}</span>
                        </div>
                      </div>
                    </div>
				</div>
				<div className="row">
					<div className="col-md-6">
                      <div className="form-group">
                        <b>Food Quality Rating:</b>
                        <div className="form-line">
                          <span>{this.state.foodQualityRating}</span>
                        </div>
                      </div>
                    </div>
					<div className="col-md-6">
                      <div className="form-group">
                        <b>Portion Size Rating:</b>
                        <div className="form-line">
                          <span>{this.state.portionSizeRating}</span>
                        </div>
                      </div>
                    </div>
					<div className="col-md-6">
                      <div className="form-group">
                        <b>Packaging Rating:</b>
                        <div className="form-line">
                          <span>{this.state.packagingRating}</span>
                        </div>
                      </div>
                    </div>
					<div className="col-md-6">
                      <div className="form-group">
                        <b>Value for Money Rating:</b>
                        <div className="form-line">
                          <span>{this.state.valueForMoneyRating}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <b>Moderation Remarks</b>
                        <div className="form-line">
                          <textarea
                            className="form-control"
                            rows={3}
                            defaultValue={this.state.moderatedRemark === "null" ? "": this.state.moderatedRemark}
                            onChange={this.handleFilterChange}
                            name="moderatedRemark"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      data-dismiss="modal"
                      id="closeMenuFormButton"
                      aria-label="Close"
                      className="btn btn-default waves-effect"
                      style={{ color: "#000", marginRight: "10px" }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewList;
