import React, { Component } from 'react'
import Header from "../../components/Header";
import { addBannerAPI } from "../../core/services/APIServices";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { NotificationManager } from "react-notifications";

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === null && (valid = false)
    })

    return valid;
}

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class AddBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerName: '',
            bannerDescription: '',
            bannerLink: '',
            image: null,
            isPublished: false,
            formErrors: {
                bannerName: '',
                bannerDescription: '',
                bannerLink: '',
            },
            imageErr: '',
            buttonText: "Submit",
            buttonDisabled: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'bannerName':
                formErrors.bannerName = value.length <= 0 ? 'Banner name is required' : "";
                break;

            case 'bannerDescription':
                formErrors.bannerDescription = value.length <= 0 ? 'Description is required' : "";
                break;

            case 'bannerLink':
                formErrors.bannerLink = value.length <= 0 ? 'Link is required' : !value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/) ? "Please enter valid external URL" : "";
                break;

            default:
                break;
        }

        this.setState({ formErrors, [name]: value })
    }

    handleCheckboxInput = (e) => {
        this.setState({
            isPublished: e.target.checked
        })
    }


    onImageChange = (e) => {
        if (e.target.files[0]) {
            // this.setState({
            //     image: e.target.files[0]
            // })
            if (e.target.files[0].type.includes('jpg') ||
                e.target.files[0].type.includes('jpeg') ||
                e.target.files[0].type.includes('png') ||
                e.target.files[0].type.includes('svg')
            ) {
                if (e.target.files[0].size > 67108864) {
                    this.setState({
                        imageErr: "The allowed maximum upload size is 64 MB"
                    })
                    document.getElementById('uploadFile').value = "";
                } else {
                    this.setState({
                        imageErr: "",
                        image: e.target.files[0],
                        imageUrl: ""
                    })
                }
            } else {
                this.setState({
                    imageErr: "Select valid image. Allowed format are .jpg, .jpeg, .png and .svg"
                })
                document.getElementById('uploadFile').value = "";
            }
        } else {
            this.setState({
                image: null,
                imageErr: "Image is required"
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        const { bannerName, bannerDescription, bannerLink, image } = this.state;
        if (bannerName === "") {
            formErrors.bannerName = "Banner name is required";
        }
        if (bannerDescription === "") {
            formErrors.bannerDescription = "Description is required";
        }
        if (bannerLink === "") {
            formErrors.bannerLink = "Link is required";
        }
        if (image === null) {
            this.state.imageErr = "Image is required";
        }
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state) && this.state.imageErr === "") {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            let formData = {
                bannerName: this.state.bannerName,
                bannerLink: this.state.bannerLink,
                bannerDescription: this.state.bannerDescription,
                image: this.state.image,
                is_published: this.state.isPublished ? 1 : 0,
            }
            this.setState({
                buttonText: "Submitting...",
                buttonDisabled: true
            })
            return addBannerAPI(id, "", token, formData)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success(
                            "Banner added successfully",
                            "Success",
                            3000
                        );
                        this.setState({
                            buttonText: "Submit",
                        })
                        setTimeout(() => {
                            this.props.history.push('/banner-list');
                        }, 2000)
                    } else {
                        NotificationManager.error(response.data["message"], "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error, "Error!");
                    this.setState({
                        buttonText: "Submit",
                        buttonDisabled: false
                    })
                });
        } else {
            console.log('error');
        }
    }
    render() {
        const { formErrors } = this.state;
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Add Banner</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <b>Banner Name*</b>
                                                        <div className="form-line">
                                                            <input autoComplete="new-password" type="text" className="form-control" name="bannerName"
                                                                onChange={this.handleInputChange} />
                                                        </div>
                                                        {formErrors.bannerName.length > 0 && (
                                                            <span className="text-danger">{formErrors.bannerName}</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <b>Description*</b>
                                                        <div className="form-line">
                                                            <input type="text" className="form-control" name="bannerDescription"
                                                                onChange={this.handleInputChange} />
                                                        </div>
                                                        {formErrors.bannerDescription.length > 0 && (
                                                            <span className="text-danger">{formErrors.bannerDescription}</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <b>Link*</b>
                                                        <div className="form-line">
                                                            <input type="text" defaultValue={this.state.bannerLink} className="form-control" name="bannerLink"
                                                                onChange={this.handleInputChange} />
                                                        </div>
                                                        {formErrors.bannerLink.length > 0 && (
                                                            <span className="text-danger">{formErrors.bannerLink}</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <b>Banner Image*</b>
                                                        <div className="form-line">
                                                            <input id="uploadFile" type="file" accept=".jpg, .jpeg, .png, .svg" className="form-control" name="image"
                                                                onChange={this.onImageChange} />
                                                        </div>
                                                        <p>(Image to be uploaded: 1920 x 700 pixels)</p>
                                                        {this.state.imageErr.length > 0 && (
                                                            <span className="text-danger">{this.state.imageErr}</span>
                                                        )}
                                                        
                                                    </div>
                                                    <label>{this.state.isPublished}</label>
                                                    <div className="form-group">
                                                        <div style={{ marginLeft: "20px" }}>
                                                            <label for="isPublished">Is published?</label>
                                                            <input type="checkbox" id="isPublished" name="isPublished" className="filled-in" onChange={this.handleCheckboxInput} checked={this.state.isPublished} />
                                                        </div>
                                                    </div>
                                                    <Link to="/banner-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                    <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
