import React from 'react';
import Header from '../../components/Header';
import SideBarSection from '../../components/Sidebar';
import { NotificationManager } from 'react-notifications';
import Pagination from 'react-js-pagination';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import {
  getPartyOrderDetailAPI
} from '../../core/services/APIServices';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-json-pretty/themes/monikai.css';

class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: '',
      dinerName: '',
      dinerEmail: '',
      dinerMobile: '',
      locatios: '',
      locality: '',
      partyDateTime: '',
      numberOfGuests: '',
      preferredCuisine: '',
      doYouRequireService: '',
      liveCooking: '',
      placeTandoor: '',
      permitKitchenUsed	: '',
      specialRequest: '',
	  errors: {},
    };
  }

  componentDidMount() {
    this.getOrderDetailData();
  }
  

  getOrderDetailData = () => {
    var userId = localStorage.getItem('userID');
    var orderId = localStorage.getItem('partyID');
    var token = localStorage.getItem('accessToken');
    return getPartyOrderDetailAPI(userId, orderId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            orderId: response.data.data.party_order[0].id,
            dinerName: response.data.data.party_order[0].diner_name,
            dinerEmail: response.data.data.party_order[0].diner_email,
            dinerMobile: response.data.data.party_order[0].diner_mobile_number,
            locations: response.data.data.party_order[0].location,
            locality: response.data.data.party_order[0].locality,
            partyDateTime: response.data.data.party_order[0].party_date_time,
            numberOfGuests: response.data.data.party_order[0].number_of_guests,
            preferredCuisine: response.data.data.party_order[0].preferred_cuisine,
            doYouRequireService: response.data.data.party_order[0].do_you_require,
            liveCooking: response.data.data.party_order[0].live_cooking,
            placeTandoor: response.data.data.party_order[0].place_tandoor,
            permitKitchenUsed: response.data.data.party_order[0].permit_kitchen_used,
            specialRequest: response.data.data.party_order[0].special_request,
          });
        } else {
          this.setState({
            dataLoaded: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          dataLoaded: true
        });
      });
  };

  handleRadioInput = (e) => {
    if (e.target.value === 'Other') {
      this.setState({
        reasonTextDisabled: false
      });
    } else {
      this.setState({
        reasonTextDisabled: true,
        otherRejectReason: '',
        reasonFormErr: ''
      });
      if (document.getElementById('reason-textarea')) {
        document.getElementById('reason-textarea').value = '';
      }
    }
    this.setState({
      rejectReasonRadioInput: e.target.value
    });
  };

  render() {
    const {
      paymentApiResponse,
      deliveryApiResponse,
      refundApiResponse,
      deliveryWefastApiResponse,
      deliveryDunzoApiResponse
    } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='body'>
                    <ul className='nav nav-tabs tab-nav-right' role='tablist'>
                      <li role='presentation' className='active'>
                        <a href='#Profile' data-toggle='tab'>
                          <i className='material-icons'>face</i>Party Order Detail
                        </a>
                      </li>
                    </ul>
                    <div className='tab-content'>
                      <div
                        role='tabpanel'
                        className='tab-pane fade in active'
                        id='Profile'
                      >
                        <div className='row clearfix'>
                          <div className='col-xs-12 ol-sm-12 col-md-12 col-lg-12'>
                            <div
                              className='panel-group'
                              id='accordion_17'
                              role='tablist'
                              aria-multiselectable='true'
                            >
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingOne_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseOne_17'
                                      aria-expanded='true'
                                      aria-controls='collapseOne_17'
                                      className='collapsed'
                                    >
                                      Order Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseOne_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingOne_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Name</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerName
                                                  }
                                                  className='form-control'
                                                  name='dinerName'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Email ID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerEmail
                                                  }
                                                  className='form-control'
                                                  name='dinerEmail'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Mobile No</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerMobile
                                                  }
                                                  className='form-control'
                                                  name='dinerMobile'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Party Order Date/Time</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.partyDateTime
                                                  }
                                                  className='form-control'
                                                  name='partyDateTime'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Location</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.locations
                                                  }
                                                  className='form-control'
                                                  name='locations'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Locality</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                     this.state.locality
                                                  }
                                                  className='form-control'
                                                  name='locality'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Number of Guests</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .numberOfGuests
                                                  }
                                                  className='form-control'
                                                  name='numberOfGuests'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Preferred Cuisine</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.preferredCuisine
                                                  }
                                                  className='form-control'
                                                  name='preferredCuisine'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Do You Require Service</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.doYouRequireService
                                                  }
                                                  className='form-control'
                                                  name='doYouRequireService'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Live Cooking</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.liveCooking
                                                  }
                                                  className='form-control'
                                                  name='liveCooking'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Place Tandoor</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.placeTandoor
                                                  }
                                                  className='form-control'
                                                  name='placeTandoor'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Permit Kitchen Used</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={this.state.permitKitchenUsed}
                                                  className='form-control'
                                                  name='permitKitchenUsed'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Special Request</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={this.state.specialRequest}
                                                  className='form-control'
                                                  name='specialRequest'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default OrderDetailPage;
