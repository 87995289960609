/*
#############################################################################
# Name : siginin.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to verify the data by the admin whether it is correct or not
#############################################################################
*/
import React from "react";
import ReactFormInputValidation from "react-form-input-validation";
import { signinAPI } from "../../core/services/APIServices";

// React Notification
import { NotificationManager } from "react-notifications";

class SignInPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
        password: "",
      },
      buttonText: "Sign in",
      buttonDisabled: false,
      errors: {},
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({ email: "required|email", password: "required" });
    this.form.onformsubmit = (fields) => {
     /*  console.log(fields); */
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });
      return signinAPI(fields.email, fields.password)
        .then((response) => {
          /* console.log(response); */
          if (response.data["status"] === 200) {
            var token = response.data["data"]["api_access_token"];
            var userId = response.data["data"]["user"]["user_id"];
            var userEmail = response.data["data"]["user"]["email"];
            var userName = response.data["data"]["user"]["name"];
            var userMobile = response.data["data"]["user"]["mobile_number"];
            var image = response.data["data"]["user"]["profile_image"];
            var role = response.data["data"]["user"]["role"];
            localStorage.setItem("userID", userId);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userName", userName);
            localStorage.setItem("userImage", image);
            localStorage.setItem("accessToken", token);
            localStorage.setItem("userMobile", userMobile);
            localStorage.setItem("userRole", role);
            /* console.log(token);
            console.log(userId); */
            this.props.history.push("/report-data");
            NotificationManager.success(
              "Welcome back to CHEFPIN " + userName,
              "Successful!",
              3000
            );
            this.setState({
              buttonText: "Sign in",
            });
          } else {
            this.setState({
              buttonText: "Sign in",
              buttonDisabled: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Invalid User", "Error!");
          this.setState({
            buttonText: "Sign in",
            buttonDisabled: false,
          });
        });
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="login-page">
          <div className="login-box">
            <div className="logo">
              <a>
                <b className="text-uppercase">Chefpin</b>
              </a>
              <small>Admin</small>
            </div>
            <div className="card">
              <div className="body">
                <form id="sign_in" onSubmit={this.form.handleSubmit}>
                  <div className="msg">Sign in to start your session</div>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">person</i>
                    </span>
                    <div className="form-line">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email ID"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.email}
                        autoFocus
                      />
                    </div>
                    <small className="form-text error">
                      {this.state.errors.email ? "Email ID is required" : ""}
                    </small>
                  </div>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">lock</i>
                    </span>
                    <div className="form-line">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.password}
                      />
                    </div>
                    <small className="form-text error">
                      {this.state.errors.password ? "Password is required" : ""}
                    </small>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <button
                        className="btn btn-block waves-effect"
                        type="submit"
                        disabled={this.state.buttonDisabled}
                      >
                         {this.state.buttonText}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignInPage;
