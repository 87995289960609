/*
#############################################################################
# Name : AddHomepagePopup.js
# Created by : Tushar Gupta
# Date       : 7th Dec 2020
# Purpose    : This file is used to add new Homepage popup
#############################################################################
*/
import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { addHomepagePopupAPI, getCityListAPI } from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";
import Select from 'react-select';

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach((val) => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach((val) => {
        val === "form-control" && (valid = false);
    });

    return valid;
};
export default class AddHomepagePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupURL: "",
            popupName: "",
            marketingDescription: "",
            popupImage: null,
            popupImageName: "",
            resetFlag: false,
            errorBrandImage: "",
            isPublished: false,
            formErrors: {
                popupName: "",
                marketingDescription: "",
            },
            imageErr: '',
            buttonText: "Submit",
            buttonDisabled: false,
            chefArr: [],
            cuisineArr: [],
            menuArr: [],
            tagArr: [],
            menucategoryArr: [],
            externalUrl: '',
            selectedChefArr: [],
            selectedChef: null,
            selectedCuisineArr: [],
            selectedCuisine: null,
            selectedMenuArr: [],
            selectedMenu: null,
            selectedTagArr: [],
            selectedTag: null,
			selectedMenuCategoryArr: [],
            selectedMenuCategory: null,
            chefSelectErr: "",
            cuisineSelectErr: "",
            menuSelectErr: "",
            categorySelectErr: "",
            tagSelectErr: "",
            tableSectionError: "",
			citySelectErr: "",
			selectedCity: "0",
			cityList: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
		this.getCityListAPI();
    }
	
	getCityListAPI = () => {
		var id = localStorage.getItem("userID");
		var token = localStorage.getItem("accessToken");
		var currentPage = this.state.activePage;
		return getCityListAPI(id, currentPage, token)
		  .then((response) => {
			if (response.data["status"] === 200) {
			  if (response.data.data.data.length) {
				let array = [];
				response.data.data.data.map((item) => {
				  array.push({ value: item.id, label: item.name });
				});
				this.setState(
				  {
					cityList: array,
				  },
				  () => {
					console.log(this.state.cityList);
				  },
				  100
				);
			  }
			} else {
			  NotificationManager.error(response.data["message"], "Error!");
			}
		  })
		  .catch((error) => {
			NotificationManager.error("Something went wrong", "Error!");
		  });
	};

    onImageChange = (e) => {
        /* console.log(e.target.files[0]) */
        if (e.target.files[0]) {
            // this.setState({
            //     popupImage: e.target.files[0]
            // })
            if (e.target.files[0].type.includes('jpg') ||
                e.target.files[0].type.includes('jpeg') ||
                e.target.files[0].type.includes('png') ||
                e.target.files[0].type.includes('svg') ||
                e.target.files[0].type.includes('gif')
            ) {
                if (e.target.files[0].size > 67108864) {
                    this.setState({
                        imageErr: "The allowed maximum upload size is 64 MB"
                    })
                    document.getElementById('uploadFile').value = "";
                } else {
                    this.setState({
                        imageErr: "",
                        popupImage: e.target.files[0]
                    })
                }
            } else {
                this.setState({
                    imageErr: "Select valid image. Allowed format are .jpg, .jpeg, .png, .gif and .svg"
                })
                document.getElementById('uploadFile').value = "";
            }
        } else {
            this.setState({
                image: null,
                imageErr: "Image is required"
            })
        }
    }

    handleCheckboxInput = (e) => {
        this.setState({
            isPublished: e.target.checked
        })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        switch (name) {
            case "popupName":
                formErrors.popupName = value === "" ? "Name is required" : "";
                break;
        }
        this.setState({ formErrors, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let formErrors = this.state.formErrors;
        if (this.state.popupName === "") {
            formErrors.popupName = "Name is required";
        }

        if (this.state.popupImage === null) {
            this.state.imageErr = "Popup image is required";
        }

        this.setState({ formErrors, [name]: value });

        setTimeout(() => {
            if (formValid(this.state) && this.state.imageErr === "") {
                var id = localStorage.getItem("userID");
                var token = localStorage.getItem("accessToken");
                let formData = {
                    name: this.state.popupName,
                    image: this.state.popupImage,
                    published: this.state.isPublished ? 1 : 0,
					city_list: this.state.selectedCity,
					url: this.state.popupURL,
                }
                /* console.log(formData) */
                this.setState({
                    buttonText: "Submitting...",
                    buttonDisabled: true
                })
                return addHomepagePopupAPI(id, token, formData)
                    .then((response) => {
                        if (response.data["status"] === 200) {
                            NotificationManager.success(
                                "Homepage popup added successfully",
                                "Success",
                                3000
                            );
                            this.setState({
                                buttonText: "Submit",
                            })
                            setTimeout(() => {
                                this.props.history.push('/homepage-popup-list');
                            }, 2000)
                        } else {
                            NotificationManager.error(response.data["message"], "Error!");
                            this.setState({
                                buttonText: "Submit",
                                buttonDisabled: false
                            })
                        }
                    })
                    .catch((error) => {
                        NotificationManager.error(error, "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    });
            } else {
                console.log('error');
            }
        }, 100)
    }

    render() {
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        const { formErrors } = this.state;
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Add Homepage Popup Image</h2>
                                    </div>
                                    <div className="body">
                                        <div className="row clearfix">
                                            <div className="col-sm-12">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="row clearfix">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <b>Name*</b>
                                                                <div className="form-line">
                                                                    <input type="text" className="form-control" name="popupName"
                                                                        value={this.state.popupName}
                                                                        onChange={this.handleInputChange} />
                                                                </div>
                                                                {formErrors.popupName.length > 0 && (
                                                                    <span className="text-danger">{formErrors.popupName}</span>
                                                                )}
                                                            </div>
                                                        </div>
														
														<div className="col-sm-6">
															<div className="form-group">
																<b>City*</b>
															<div className="form-line">
															  <select
																className="form-control show-tick"
																value={this.state.selectedCity}
																onChange={(v) => {
																  const { value } = v.target;
																  if (
																	value !== "-- Any --" ||
																	value !== ""
																  ) {
																	this.setState({
																	  selectedCity: v.target.value,
																	});
																  } else {
																	this.setState({
																	  selectedCity: undefined,
																	});
																  }
																}}
															  >
																<option value={"0"}>Default</option>
																{this.state.cityList.map((el) => (
																
																  <option value={el.value}>
																	{el.label}
																  </option>
																))}
															  </select>
															</div>
														  </div>
														</div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <b>Popup Image*</b>
                                                                <div className="form-line">
                                                                    <input id="uploadFile" type="file" accept=".jpg, .jpeg, .png, .svg, .gif" className="form-control" name="image"
                                                                        onChange={this.onImageChange} />
                                                                </div>
                                                                {/*<p>(Image to be uploaded: 800 x 230 pixels)</p>*/}
                                                                {this.state.imageErr.length > 0 && (
                                                                    <span className="text-danger">{this.state.imageErr}</span>
                                                                )}
                                                            </div>
                                                        </div>
														<div className="col-sm-6">
															<div className="form-group">
																<b>Page URL</b>
																<div className="form-line">
																	<input type="text" className="form-control" name="popupURL"
																		value={this.state.popupURL}
																		onChange={this.handleInputChange} />
																</div>
																<span><b>Note:</b> Enter full URL with https:// protocol</span>
															</div>
														</div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <div style={{ marginLeft: "20px" }}>
                                                                    <label for="isPublished">Is published?</label>
                                                                    <input type="checkbox" id="isPublished" name="isPublished" className="filled-in" onChange={this.handleCheckboxInput} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <Link to="/homepage-popup-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                            <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}