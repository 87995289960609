import React, { Component } from "react";
import Header from "../../components/Header";
import {
  getDinerListAPI,
  deleteDinerAPI,
  addNewDinerAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const addFormValid = ({ addFormErrors, ...rest }) => {
  let valid = true;

  Object.values(addFormErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Object.values(addFormErrors).forEach((val) => {
  //   val === "form-control" || (valid = false);
  // });

  return valid;
};

export default class DinerListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: '',
      userEmailID: '',
      isLoading: true,
      userList: [],
      dinerExcelData: [],
      dinerFirstName: "",
      dinerLastName: "",
      dinerEmail: "",
      dinerMobileNo: "",
      dinerCity: "",
      dinerState: "",
      dinerOrderFromDate: "",
      dinerOrderToDate: "",
      formatStartDate: "",
      formatEndDate: "",
      resetFlag: false,
      errorDateMessage: "",
      activePage: 1,
      totalRecords: 10,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      addFormErrors: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
      },
      buttonText: "Submit",
      buttonDisabled: false,
      currentDate: "",
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }
  componentDidMount() {
    var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
    this.getDinerListAPI();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today,
    });
  }

  handleFilterChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }

  handleStartDateChange(event) {
    this.setState({
      dinerOrderFromDate: event,
      formatStartDate: Moment(event).format("YYYY-MM-DD"),
    });
  }

  handleEndDateChange(event) {
    this.setState({
      dinerOrderToDate: event,
      formatEndDate: Moment(event).format("YYYY-MM-DD"),
    });
  }

  submitFilter = (event) => {
    event.preventDefault();
    var statusDate = false;
    if (
      (this.state.formatEndDate == "" || this.state.dinerOrderToDate == "") &&
      this.state.formatStartDate
    ) {
      statusDate = true;
      this.setState({ errorDateMessage: "End Date not selected" });
    }

    if (this.state.formatStartDate == "" && this.state.formatEndDate) {
      statusDate = true;
      this.setState({ errorDateMessage: "Start Date not selected" });
    }

    if (this.state.formatStartDate && this.state.formatEndDate) {
      statusDate = false;
      this.setState({ errorDateMessage: "", resetFlag: true });
      /* console.log(this.state.dinerFirstName); */
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      var currentPage = "1";
      this.setState({
        activePage: 1,
      });
      return getDinerListAPI(
        id,
        currentPage,
        this.state.dinerFirstName,
        this.state.dinerLastName,
        this.state.dinerEmail,
        this.state.dinerMobileNo,
        this.state.dinerCity,
        this.state.dinerState,
        this.state.formatStartDate,
        this.state.formatEndDate,
        token
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            this.setState({
              userList: response.data.data.data,
              //dinerExcelData: response.data["data"]["records_found"],
			  dinerExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_100_days_records_found']),
              totalRecords: response.data.data.pager_count,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (statusDate === false && this.state.errorDateMessage === "") {
      this.setState({
        resetFlag: true,
      });
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      var currentPage = "1";
      this.setState({
        activePage: 1,
      });
      return getDinerListAPI(
        id,
        currentPage,
        this.state.dinerFirstName,
        this.state.dinerLastName,
        this.state.dinerEmail,
        this.state.dinerMobileNo,
        this.state.dinerCity,
        this.state.dinerState,
        this.state.formatStartDate,
        this.state.formatEndDate,
        token
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            this.setState({
              userList: response.data.data.data,
              dinerExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_100_days_records_found']),
              totalRecords: response.data.data.pager_count,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        dinerFirstName: "",
        dinerLastName: "",
        dinerEmail: "",
        dinerMobileNo: "",
        dinerCity: "",
        dinerState: "",
        dinerOrderFromDate: "",
        dinerOrderToDate: "",
        formatStartDate: "",
        formatEndDate: "",
      },
      () => {
        this.getDinerListAPI();
      }
    );
  };

  getDinerListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getDinerListAPI(
      id,
      currentPage,
      this.state.dinerFirstName,
      this.state.dinerLastName,
      this.state.dinerEmail,
      this.state.dinerMobileNo,
      this.state.dinerCity,
      this.state.dinerState,
      this.state.formatStartDate,
      this.state.formatEndDate,
      token
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            userList: response.data.data.data,
            dinerExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_100_days_records_found']),
            totalRecords: response.data.data.pager_count,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getDinerListAPI();
    });
  }
  handleClickDelete = (id) => {
    var userId = localStorage.getItem("userID");
    // var dinerID = localStorage.getItem('dinerID')
    var token = localStorage.getItem("accessToken");
    return deleteDinerAPI(userId, id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          // this.setState({
          //     isLoading: true
          // })
          this.getDinerListAPI();
          NotificationManager.success("Diner deleted successfully", "Success");
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteUser = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this diner?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  handleAddFormInputChange = (e) => {
    const { name, value } = e.target;
    let addFormErrors = this.state.addFormErrors;

    switch (name) {
      case "firstName":
        addFormErrors.firstName =
          value.length <= 0
            ? "First name is required"
            : !value.match(/^[a-zA-Z]+$/)
            ? "Enter valid first name"
            : "";
        break;

      case "lastName":
        addFormErrors.lastName =
          value.length <= 0
            ? "Last name is required"
            : !value.match(/^[a-zA-Z]+$/)
            ? "Enter valid last name"
            : "";
        break;

      case "email":
        addFormErrors.email =
          value.length <= 0
            ? "Email is required"
            : !value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
            ? "Enter valid email"
            : "";
        break;

      case "mobile":
        addFormErrors.mobile =
          value.length <= 0
            ? "Mobile number is required"
            : !value.match(/^[789]\d{9}$/)
            ? "Enter valid mobile number"
            : "";
        break;

      case "password":
        addFormErrors.password =
          value.length <= 0
            ? "Please enter new password"
            : value.length < 6
            ? "Password must be atleast 6 characters"
            : "";
        break;

      case "confirmPassword":
        addFormErrors.confirmPassword =
          value.length <= 0
            ? "Please confirm new password"
            : value !== this.state.password
            ? "Password is not matching!"
            : "";
        break;
    }

    this.setState({ addFormErrors, [name]: value });
  };
  submitAddChefForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let addFormErrors = this.state.addFormErrors;
    const {
      firstName,
      lastName,
      email,
      mobile,
      password,
      confirmPassword,
    } = this.state;

    if (firstName === "") {
      addFormErrors.firstName = "First name is required";
    }
    if (lastName === "") {
      addFormErrors.lastName = "Last name is required";
    }
    if (email === "") {
      addFormErrors.email = "Email is required";
    }
    if (mobile === "") {
      addFormErrors.mobile = "Mobile number is required";
    }
    if (password === "") {
      addFormErrors.password = "Please enter new password";
    }
    if (confirmPassword === "") {
      addFormErrors.confirmPassword = "Please confirm new password";
    }
    this.setState({ addFormErrors, [name]: value });
    if (addFormValid(this.state)) {
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });
      let data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        mobile: this.state.mobile,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      };
      return addNewDinerAPI(id, token, data)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Diner added successfully",
              "Success",
              3000
            );
            this.getDinerListAPI();
            this.setState(
              {
                buttonText: "Submit",
                buttonDisabled: false,
              },
              () => {
                document.getElementById("myModalAddDiner").click();
                localStorage.setItem("dinerID", response.data.data.user_id);
                this.props.history.push("/diner-data");
              }
            );
          } else {
            NotificationManager.error(response.data["message"], "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!");
          this.setState({
            buttonText: "Submit",
            buttonDisabled: false,
          });
        });
    }
  };

  addModalClosed = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      addFormErrors: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
      },
    });
    document.getElementById("dinerAddForm").reset();
  };

  getDinerView = (id) => {
    localStorage.setItem("dinerID", id);
    this.props.history.push("/diner-data");
  };
  render() {
    const { addFormErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Diner Listing</h2>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>First Name</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dinerFirstName"
                                  value={this.state.dinerFirstName}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Last Name</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dinerLastName"
                                  value={this.state.dinerLastName}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Email</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dinerEmail"
                                  value={this.state.dinerEmail}
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Mobile Number</b>
                              <div className="form-line">
                                <input
                                  type="tel"
                                  className="form-control"
                                  name="dinerMobileNo"
                                  value={this.state.dinerMobileNo}
                                  maxLength="10"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>City</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dinerCity"
                                  value={this.state.dinerCity}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>State</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dinerState"
                                  value={this.state.dinerState}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-sm-4">
                            <div className="form-group">
                              <b>Status</b>
                              <div className="form-line">
                                <select
                                  className="form-control show-tick"
                                  value={this.state.userStatus}
                                  onChange={this.handleStatusChange}
                                >
                                  <option value="">-- Any --</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Completed">Completed</option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-sm-4">
                            <div className="form-group">
                              <b>Date Range</b>
                              <div className="datePickerFlex">
                                <DatePicker
                                  selected={this.state.dinerOrderFromDate}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.dinerOrderToDate}
                                />
                                <p>&nbsp;&nbsp;to</p>
                                <DatePicker
                                  selected={this.state.dinerOrderToDate}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.dinerOrderFromDate}
                                />
                              </div>
                            </div>
                            {this.state.errorDateMessage ? (
                              <p className="errorMessage">
                                {this.state.errorDateMessage}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-sm-12 center_btn">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
					
					{/*  Admin and Ops menu */}
					{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
						<button
						  className="btn"
						  data-toggle="modal"
						  data-target="#myModalAddDiner"
						  style={{ marginBottom: "20px" }}
						>
						  Add Diner
						</button>
					) : null}
					
                    <div className="table-responsive">
					
					  {/*  Admin menu */}
					  {this.state.dinerExcelData.length && (this.state.userRole == 'Admin' || this.state.userRole == 'Marketing') ? (
                        <ExcelFile
                          filename={"Diner_Report_" + this.state.currentDate}
                          element={
							<button className='btn pull-right m-b-10'>
							{(this.state.userRole == 'Marketing') ? 'Last 100 Days Download Excel' : 'Download Excel'}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.dinerExcelData}
                            name="Diner Report"
                          >
                            <ExcelColumn label="Date" value="updated_at" />
                            <ExcelColumn label="ID" value="diner_user_id" />
                            <ExcelColumn
                              label="First Name"
                              value="first_name"
                            />
                            <ExcelColumn label="Last Name" value="last_name" />
                            <ExcelColumn label="Email" value="email" />
                            <ExcelColumn
                              label="Mobile Number"
                              value="mobile_number"
                            />
                            <ExcelColumn label="City" value="city" />
                            <ExcelColumn label="State" value="state" />
                            <ExcelColumn label="Pincode" value="pincode" />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}

                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="8">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.userList.length ? (
                          <tbody>
                            {this.state.userList.map((user, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{user.first_name}</td>
                                  <td>{user.last_name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.mobile_number}</td>
                                  <td>{user.created_at}</td>
                                  <td>{user.updated_at}</td>
                                  <td className="align-middle text-center">
                                    <a href="#" className="view_link">
                                      <i
                                        className="material-icons"
                                        onClick={this.getDinerView.bind(
                                          this,
                                          user.diner_user_id
                                        )}
                                      >
                                        remove_red_eye
                                      </i>
                                    </a>
									
									{/*  Admin menu */}
									{this.state.userRole == 'Admin' ? (
										<a
										  href="#"
										  className="view_link"
										  style={{ marginLeft: "5px" }}
										>
										  <i
											className="material-icons"
											onClick={this.deleteUser.bind(
											  this,
											  user.diner_user_id
											)}
										  >
											delete_forever
										  </i>
										</a>
									) : null}
                                    
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="8">
                                No record found.
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade menu_list_pop add_menu_list_pop"
          id="myModalAddDiner"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title color_C07" id="myModalLabel">
                  Add New Diner
                </h4>
              </div>
              <div className="modal-body">
                <form onSubmit={this.submitAddChefForm} id="dinerAddForm">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>
                          First Name<span style={{ color: "red" }}>*</span>
                        </b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            onChange={this.handleAddFormInputChange}
                            name="firstName"
                            className="form-control"
                          />
                        </div>
                        {addFormErrors.firstName.length > 0 && (
                          <small className="text-danger">
                            {addFormErrors.firstName}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>
                          Last Name<span style={{ color: "red" }}>*</span>
                        </b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            onChange={this.handleAddFormInputChange}
                            name="lastName"
                            className="form-control"
                          />
                        </div>
                        {addFormErrors.lastName.length > 0 && (
                          <small className="text-danger">
                            {addFormErrors.lastName}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>
                          Email<span style={{ color: "red" }}>*</span>
                        </b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            onChange={this.handleAddFormInputChange}
                            name="email"
                            className="form-control"
                          />
                        </div>
                        {addFormErrors.email.length > 0 && (
                          <small className="text-danger">
                            {addFormErrors.email}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>
                          Mobile Number<span style={{ color: "red" }}>*</span>
                        </b>
                        <div className="form-line">
                          <input
                            type="text"
                            placeholder=""
                            onChange={this.handleAddFormInputChange}
                            name="mobile"
                            className="form-control"
                          />
                        </div>
                        {addFormErrors.mobile.length > 0 && (
                          <small className="text-danger">
                            {addFormErrors.mobile}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>
                          Password<span style={{ color: "red" }}>*</span>
                        </b>
                        <div className="form-line">
                          <input
                            type="password"
                            placeholder=""
                            onChange={this.handleAddFormInputChange}
                            name="password"
                            className="form-control"
                          />
                        </div>
                        {addFormErrors.password.length > 0 && (
                          <small className="text-danger">
                            {addFormErrors.password}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <b>
                          Confirm Password
                          <span style={{ color: "red" }}>*</span>
                        </b>
                        <div className="form-line">
                          <input
                            type="password"
                            placeholder=""
                            onChange={this.handleAddFormInputChange}
                            name="confirmPassword"
                            className="form-control"
                          />
                        </div>
                        {addFormErrors.confirmPassword.length > 0 && (
                          <small className="text-danger">
                            {addFormErrors.confirmPassword}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      data-dismiss="modal"
                      id="closeAddFormButton"
                      aria-label="Close"
                      disabled={this.state.buttonDisabled}
                      className="btn btn-default waves-effect"
                      style={{ color: "#000", marginRight: "10px" }}
                      onClick={this.addModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      className="btn"
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
