/*
#############################################################################
# Name : stepWiseReport.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display step wise report
#############################################################################
*/
import React from 'react';
import Header from "../../components/Header";
import Pagination from "react-js-pagination";
import moment from 'moment';
import { getStepWiseReportAPI } from "../../core/services/APIServices";
import SideBarSection from '../../components/Sidebar';
import { Link, Redirect } from 'react-router-dom'

class StepWiseReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            userEmailID: "",
            totalRegistrated: "",
            registratedData: "",
            aboutYouData: "",
            aboutYourKitchenData: "",
            aboutYourBusinessData: "",
            aboutInWordsData: "",
            menuData: "",
            submitData: ""
        }
    }
    componentDidMount() {
        var name = localStorage.getItem("userName");
        var email = localStorage.getItem("userEmail");
        this.setState({
            userName: name,
            userEmailID: email,
        });
        this.getUserList();
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber }, () => {
            this.getUserList()
        });
    }

    getUserList() {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var currentPage = this.state.activePage
        return getStepWiseReportAPI(id, token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    this.setState({
                        totalRegistrated: response.data["data"]["total_records"],
                        registratedData: response.data["data"]["total_registered_records"],
                        aboutYouData: response.data["data"]["total_about_you_records"],
                        aboutYourKitchenData: response.data["data"]["total_about_your_kitchen_records"],
                        aboutYourBusinessData: response.data["data"]["total_about_your_business_records"],
                        aboutInWordsData: response.data["data"]["total_in_words_and_pictures_records"],
                        menuData: response.data["data"]["total_menu_records"],
                        submitData: response.data["data"]["total_submit_records"],
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getReport = (data) => {
        /* console.log(data); */
        localStorage.setItem('lastUpdated', data);  
    };

    render() {
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">State Wise Report</h2>
                                    </div>
                                    <div className="header details_wrap">
                                        <h2 className="fw_500">Total Registered: <span>{this.state.totalRegistrated}</span></h2>
                                    </div>
                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>Step</th>
                                                        <th>Registered</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>REGISTRATION</td>
                                                        <td>{this.state.registratedData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('registered')}>View Details</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>ABOUT YOU</td>
                                                        <td>{this.state.aboutYouData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('about_you')}>View Details</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>ABOUT YOUR KITCHEN</td>
                                                        <td>{this.state.aboutYourKitchenData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('about_your_kitchen')}>View Details</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>ABOUT YOUR BUSINESS</td>
                                                        <td>{this.state.aboutYourBusinessData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('about_your_business')}>View Details</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>MENU (Document)</td>
                                                        <td>{this.state.aboutInWordsData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('in_words_and_pictures')}>View Details</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>MENU (Manual)</td>
                                                        <td>{this.state.menuData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('menu')}>View Details</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>SUBMIT</td>
                                                        <td>{this.state.submitData}</td>
                                                        <td><a href="/report-data" className="btn small_btn" onClick={e => this.getReport('submit')}>View Details</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default StepWiseReport;