/*
#############################################################################
# Name :membershipList.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display memebership detail
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import {
  getMembershipListAPI,
  deleteMembershipAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactExport from "react-data-export";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class MembershipListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: "",
      userEmailID: "",
      userList: [],
      memberQueryExcelData: [],
      activePage: 1,
      totalRecords: 10,
      isLoading: true,
      currentDate: "",
    };
  }

  componentDidMount() {
    var name = localStorage.getItem("userName");
    var email = localStorage.getItem("userEmail");
	var role = localStorage.getItem('userRole');
    this.setState({
	  userRole: role,
      userName: name,
      userEmailID: email,
    });
    this.getUserList();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getUserList();
    });
  }

  getUserView = (id, event) => {
    event.preventDefault();
    localStorage.setItem("chefID", id);
    this.props.history.push("/chef-data");
  };

  /* deleteUser = (id, event) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        return deleteMembershipAPI(userId, id, token)
          .then((response) => {
            console.log(response.data);
            if (response.data["status"] === 200) {
              this.getUserList();
              swal("User has been deleted!", {
                icon: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }; */

  handleClickDelete = (id) => {
    var userId = localStorage.getItem("userID");
    // var dinerID = localStorage.getItem('dinerID')
    var token = localStorage.getItem("accessToken");
    return deleteMembershipAPI(userId, id, token)
      .then((response) => {
        /* console.log(response.data); */
        if (response.data["status"] === 200) {
          this.getUserList();
          swal("Membership has been deleted!", {
            icon: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteUser = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this membership?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class="btn"
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  getUserList() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getMembershipListAPI(id, currentPage, token)
      .then((response) => {
        /* console.log(response.data); */
        if (response.data["status"] === 200) {
          /* console.log(response.data.data.pager_count); */
          this.setState({
            userList: response.data["data"].data,
            memberQueryExcelData: response.data.data.records_found,
            totalRecords: response.data["data"]["pager_count"],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }
  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Membership Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      {this.state.userList.length && this.state.userRole == 'Admin' ? (
                        <ExcelFile
                          filename={
                            "Membership_Report_" + this.state.currentDate
                          }
                          element={
                            <button className="btn pull-right m-b-10">
                              Download Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.memberQueryExcelData}
                            name="Membership Report"
                          >
                            <ExcelColumn label="ID" value="chef_id" />
                            <ExcelColumn label="Name" value="chef_name" />
                            <ExcelColumn label="Email ID" value="chef_email" />
                            <ExcelColumn
                              label="Mobile Number"
                              value="chef_mobile_number"
                            />
                            <ExcelColumn
                              label="Membership Plan"
                              value="plan_assign"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Brand Name</th>
                            <th>Membership Plan</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="6">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.userList.length ? (
                          <tbody>
                            {this.state.userList.map((user, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{user.chef_id}</td>
                                  <td>{user.chef_name}</td>
                                  <td>{user.chef_email}</td>
                                  <td>{user.chef_mobile_number}</td>
                                  <td>{user.brand_name}</td>
                                  <td>{user.plan_assign}</td>
                                  <td className="align-middle text-center">
									{/*  Admin menu */}
									{this.state.userRole == 'Admin' ? (
                                    <a href="#" className="view_link">
                                      <i
                                        className="material-icons"
                                        onClick={this.deleteUser.bind(
                                          this,
                                          user.chef_id
                                        )}
                                      >
                                        delete_forever
                                      </i>
                                    </a>
									) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="8">No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MembershipListPage;
