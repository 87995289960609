import React, { Component } from "react";
import Header from "../../components/Header";
import {
  getDishTitleAPI,
  getDishDetailAPI,
  editDishTitleAPI,
  deleteDishImageAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class DishSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
	  userName: '',
	  userEmailID: '',
      dishTitle: "",
      dishImage: "",
      dishDesc: "",
      isLoading: true,
      dishDetailFlag: false,
      buttonDishDisabled: false,
      dishId: "",
      dishImageDetails: [],
      dishImageErr: "",
      activeImagePage: 1,
      totalImageRecords: 10,
      formErrors: {
        dishTitle: "",
        dishDesc: "",
      },
    };
  }

  componentDidMount() {
	var name = localStorage.getItem('userName');
	var email = localStorage.getItem('userEmail');
	var role = localStorage.getItem('userRole');
	this.setState({
	  userRole: role,
	  userName: name,
	  userEmailID: email
	});
	this.getDishDetailAPI();
  }

  getDishDetailAPI = () => {
    var id = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activeImagePage;
    let formData = {
      title: this.state.dishTitle,
      flag: "top",
    };
    return getDishTitleAPI(id, chefId, token, formData, currentPage)
      .then((response) => {
        if (response.data["status"] === 200) {
          console.log(response.data.data.slider_title);
          this.setState({
            dishId: response.data.data.slider_id,
            dishImageDetails: response.data.data.slider_data.slider_images,
            dishTitle: response.data.data.slider_title,
            totalImageRecords: response.data.data.slider_data.total_records,
            isLoading: false,
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "dishTitle":
        formErrors.dishTitle = value.length <= 0 ? "Title is required" : "";
        break;

      case "dishDesc":
        formErrors.dishDesc =
          value.length <= 0 ? "Description is required" : "";
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  submitTitle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { dishTitle } = this.state;
    if (dishTitle === "") {
      formErrors.dishTitle = "Title is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var id = localStorage.getItem("userID");
      var chefId = localStorage.getItem("chefID");
      var token = localStorage.getItem("accessToken");
      let formData = {
        title: this.state.dishTitle,
        flag: "top",
      };
      return getDishTitleAPI(id, chefId, token, formData)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Dish title added successfully",
              "Success",
              3000
            );
            console.log(response.data.data.slider_title);
            this.setState({
              dishId: response.data.data.slider_id,
            });
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  updateTitle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { dishTitle } = this.state;
    if (dishTitle === "") {
      formErrors.dishTitle = "Title is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var id = localStorage.getItem("userID");
      var chefId = localStorage.getItem("chefID");
      var token = localStorage.getItem("accessToken");
      var currentPage = this.state.activeImagePage;
      let formData = {
        title: this.state.dishTitle,
        flag: "top",
        id: this.state.dishId,
      };
      return editDishTitleAPI(id, chefId, token, formData, currentPage)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Dish title updated successfully",
              "Success",
              3000
          );
            this.setState({
              dishId: response.data.data.slider_id,
            });
            this.getDishDetailAPI();
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onDishImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //     image: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("svg")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            dishImageErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadFile").value = "";
        } else {
          this.setState({
            dishImageErr: "",
            dishImage: e.target.files[0],
          });
        }
      } else {
        this.setState({
          dishImageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png and .svg",
        });
        document.getElementById("uploadFile").value = "";
      }
    } else {
      this.setState({
        image: null,
        dishImageErr: "Image is required",
      });
    }
  };

  handleDishImageSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { dishImage, dishDesc } = this.state;
    if (dishImage === "") {
      this.state.dishImageErr = "Image is required";
    }
    if (dishDesc === "") {
      formErrors.dishDesc = "Description is required";
    }
    this.setState({ formErrors, [name]: value });
    console.log(this.state.dishImage);
    console.log(this.state.dishDesc);
    if (this.state.dishImageErr === "") {
      this.setState({
        buttonDishDisabled: false,
        isLoading: true,
      });
      var id = localStorage.getItem("userID");
      var chefId = localStorage.getItem("chefID");
      var token = localStorage.getItem("accessToken");
      let formData = {
        slider_id: this.state.dishId,
        slider_image: this.state.dishImage,
        slider_image_description: this.state.dishDesc,
      };
      return getDishDetailAPI(id, chefId, token, formData)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Dish Detail saved successfully",
              "Success",
              3000
            );
            this.setState(
              {
                dishImageDetails: response.data.data.slider_data.slider_images,
                totalImageRecords: response.data.data.slider_data.total_records,
                buttonDishDisabled: false,
                isLoading: false,
                dishDesc: "",
                dishImage: "",
              },
              () => {
                document.getElementById("uploadFile").value = "";
                document.getElementById("closeDishImgPopupButton").click();
              }
            );
            this.getDishDetailAPI();
          } else {
            NotificationManager.error(
              "Failed to save the dish details",
              "Error",
              1000
            );
            this.setState(
              {
                buttonDishDisabled: false,
                dishDesc: "",
                dishImage: "",
              },
              () => {
                document.getElementById("uploadFile").value = "";
                document.getElementById("closeDishImgPopupButton").click();
              }
            );
          }
        })
        .catch((error) => {
          console.log("Error");
          this.setState(
            {
              buttonDishDisabled: false,
              dishDesc: "",
              dishImage: "",
            },
            () => {
              document.getElementById("uploadFile").value = "";
              document.getElementById("closeDishImgPopupButton").click();
            }
          );
        });
    }
  };

  resetFile = () => {
    const file = document.querySelector(".imageDish");
    file.value = "";
    this.setState({
      image: null,
      dishImage: "",
      dishDesc: "",
    });
  };

  deleteDishImage = (imageId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui text-center">
            <h2 style={{ marginTop: "20px" }}>Are you sure?</h2>
            <p style={{ marginTop: "20px", marginBottom: "40px" }}>
              You want to delete this dish image detail?
            </p>
            <button
              className="btn btn-default waves-effect"
              style={{ color: "#000", marginRight: "10px" }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className="btn"
              onClick={() => {
                this.handleDishImageDelete(imageId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  handleDishImageDelete = (imageId) => {
    this.setState({
      isLoading: true,
    });
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteDishImageAPI(id, token, imageId)
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            "Dish detail deleted successfully",
            "Success"
          );
          this.setState({
            isLoading: false,
          });
          this.getDishDetailAPI();
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong", "Error!");
      });
  };

  handleDishImagePagination(pageNumber) {
    this.setState({ activeImagePage: pageNumber, isLoading: true }, () => {
      this.getDishDetailAPI();
    });
  }

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Dish Slider Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <b>Dish Title*</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  placeholder=""
                                  onChange={this.handleMenuInputChange}
                                  name="dishTitle"
                                  className="form-control"
                                  value={this.state.dishTitle}
                                  maxLength="50"
                                />
                              </div>
                              {formErrors.dishTitle.length > 0 && (
                                <small className="text-danger">
                                  {formErrors.dishTitle}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <button
                              type="submit"
                              className="btn"
                              onClick={
                                this.state.dishId
                                  ? this.updateTitle
                                  : this.submitTitle
                              }
                            >
                              Submit
                            </button>
                          </div>
                          <div className="col-sm-12">
						  {/*  Admin and Marketing menu */}
						  {this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                            <button
                              className="btn"
                              data-toggle="modal"
                              data-target="#myModalDishImage"
                              disabled={this.state.dishDetailFlag}
                              onClick={() => this.resetFile()}
                            >
                              Add Dishes
                            </button>
							 ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="align-middle text-center">
                              Sr. No.
                            </th>
                            <th className="align-middle text-center">
                              Dish Image
                            </th>
                            <th className="align-middle text-center">
                              Dish Image Description
                            </th>
                            <th className="align-middle text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="4">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.dishImageDetails.length ? (
                          <tbody>
                            {this.state.dishImageDetails.map((image, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activeImagePage > 1 &&
                                    i + 1 != 10
                                      ? this.state.activeImagePage -
                                        1 +
                                        "" +
                                        (i + 1)
                                      : this.state.activeImagePage > 1 &&
                                        i + 1 == 10
                                      ? this.state.activeImagePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="30%"
                                  >
                                    <a href={image.image_path} target="_blank">
                                      <img
                                        src={image.image_path}
                                        style={{ width: "100px" }}
                                      />
                                    </a>
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="30%"
                                  >
                                    <p>{image.description}</p>
                                  </td>
                                  <td
                                    width="10%"
                                    className="align-middle text-center"
                                  >
                                    {/* <i className="material-icons view_link">
                                      create
                                    </i> */}
									{/*  Admin and Marketing menu */}
									{this.state.userRole == 'Admin' || this.state.userRole == 'Marketing' ? (
                                    <i
                                      className="material-icons view_link"
                                      onClick={() =>
                                        this.deleteDishImage(image.id)
                                      }
                                    >
                                      delete
                                    </i>
									 ) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="4">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activeImagePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalImageRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handleDishImagePagination.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade menu_list_pop add_menu_list_pop"
          id="myModalDishImage"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h4>Add Dish Details</h4>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={this.handleDishImageSubmit}
                  id="featuredImageForm"
                >
                  <div className="form-group">
                    <b>Dish Image*</b>
                    <div className="form-line">
                      <input
                        id="uploadFile"
                        type="file"
                        accept=".jpg, .jpeg, .png, .svg"
                        className="form-control imageDish"
                        name="image"
                        onChange={this.onDishImageChange}
                      />
                    </div>
                      <p>(Image to be uploaded: 590 x 420 pixels)</p> 
                    {this.state.dishImageErr.length > 0 && (
                      <span className="text-danger">
                        {this.state.dishImageErr}
                      </span>
                    )}
                    {this.state.buttonDishDisabled ? (
                      <span className="text-danger">
                        Dish image getting uploading ...
                      </span>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <b>Dish Description*</b>
                    <div className="form-line">
                      <textarea
                        className="form-control imageDish"
                        rows={2}
                        onChange={this.handleMenuInputChange}
                        name="dishDesc"
                        value={this.state.dishDesc}
                        maxLength="50"
                      ></textarea>
                    </div>
                    {formErrors.dishDesc.length > 0 && (
                      <small className="text-danger">
                        {formErrors.dishDesc}
                      </small>
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      data-dismiss="modal"
                      id="closeDishImgPopupButton"
                      aria-label="Close"
                      disabled={this.state.buttonDishDisabled}
                      className="btn btn-default waves-effect"
                      style={{ color: "#000", marginRight: "10px" }}
                      onClick={this.menuModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={this.state.buttonDishDisabled}
                      className="btn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DishSlider;
