import React from 'react';
import Header from '../../components/Header';
import {
  getChefSubscriptionPackageAPI
} from '../../core/services/APIServices';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import SideBarSection from '../../components/Sidebar';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactExport from 'react-data-export';
import { NotificationManager } from 'react-notifications';

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

const couponCodePattern = /^[a-zA-Z\d-_]+$/;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class chefSubscribedListing extends React.Component {	
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userEmailID: '',
      orderData: [],
      orderExcelData: [],
      userName: '',
      userToken: '',
      isLoading: true,
      activePage: 1,
      totalRecords: 10,
      chefName: '',
      Packagess: '',
      packageStatus: '',
      packageFromDate: '',
      packageToDate: '',
      formatStartDate: '',
      formatEndDate: '',
      resetFlag: false,
      errorDateMessage: '',
      currentDate: '',
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    var name = localStorage.getItem('userName');
    var token = localStorage.getItem('accessToken');
    this.setState({
      userName: name,
      userToken: token
    });
	
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userEmailID: email
    });
	
    this.getChefSubscriptionListing();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today
    });
  }

  handleFilterChange(key) {
    const { name, value } = key.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'couponFilter':
        formErrors.couponFilter = !couponCodePattern.test(key.target.value)
          ? 'Enter valid Coupon Code.'
          : '';
        break;
    }
    if (name === 'chefName' || name === 'packageStatus') {
      this.setState({
        ordersMarkedForPayment: [],
        paymentAmount: '',
        paymentUtrNumber: ''
      });
    }
    this.setState({
      [key.target.name]: key.target.value
    });
  }

  handleStartDateChange(event) {
    this.setState({
      packageFromDate: event,
      formatStartDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleEndDateChange(event) {
    this.setState({
      packageToDate: event,
      formatEndDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  getChefSubscriptionListing = () => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getChefSubscriptionPackageAPI(
      id,
      currentPage,
	  this.state.Packagess,
      this.state.chefName,
      this.state.packageStatus,
	  this.state.formatStartDate,
	  this.state.formatEndDate,
      token
    )
      .then((response) => {
        if (response.data['status'] == 200) {
          /*  console.log(response); */
          this.setState({
            orderData: response.data.data.data,
            orderExcelData: (response.data['data']['records_found']),
            totalRecords: response.data.data.pager_count,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getChefSubscriptionListing();
    });
  }

  getOrderView = (id) => {
    localStorage.setItem('orderID', id);
    this.props.history.push('/diner-packagev-detail');
  };

  submitFilter = (event) => {
    event.preventDefault();
    var statusDate = false;
    if (
      (this.state.formatEndDate == '' || this.state.packageToDate == '') &&
      this.state.formatStartDate
    ) {
      statusDate = true;
      this.setState({ errorDateMessage: 'Join End Date not selected' });
    }

    if (this.state.formatStartDate == '' && this.state.formatEndDate) {
      statusDate = true;
      this.setState({ errorDateMessage: 'Join Start Date not selected' });
    }

    if (this.state.formatStartDate && this.state.formatEndDate) {
      statusDate = false;
      this.setState({ errorDateMessage: '', resetFlag: true });
      /* console.log(this.state.packageStatus); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getChefSubscriptionPackageAPI(
        id,
        currentPage,
		this.state.Packagess,
        this.state.chefName,
        this.state.packageStatus,
        this.state.formatStartDate,
        this.state.formatEndDate,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }

    if (statusDate === false && this.state.errorDateMessage === '') {
      this.setState({
        resetFlag: true
      });
      /* console.log(this.state.packageStatus); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getChefSubscriptionPackageAPI(
        id,
        currentPage,
        this.state.Packagess,
        this.state.chefName,
        this.state.packageStatus,
        this.state.formatStartDate,
        this.state.formatEndDate,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (response.data['data']['records_found']),
              totalRecords: response.data.data.pager_count,
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        chefName: '',
        Packagess: '',
        packageStatus: '',
        packageFromDate: '',
        packageToDate: '',
        formatStartDate: '',
        formatEndDate: '',
      },
      () => {
        this.getChefSubscriptionListing();
      }
    );
  };

  render() {
    if (!localStorage.getItem('accessToken')) {
      return <Redirect to='/' />;
    }

    const { formErrors } = this.state;

    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row clearfix'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='header'>
                    <h2 className='fw_500'>Chef Subscription Package List</h2>
                    <ul className='header-dropdown m-r--5'>
                      <li className='dropdown'>
                        <a
                          href='#'
                          className='dropdown-toggle'
                          data-toggle='dropdown'
                          role='button'
                          aria-haspopup='true'
                          aria-expanded='false'
                        ></a>
                        <ul className='dropdown-menu pull-right'>
                          <li>
                            <a href='#'>Action</a>
                          </li>
                          <li>
                            <a href='#'>Another action</a>
                          </li>
                          <li>
                            <a href='#'>Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className='body'>
                    <div className='row clearfix'>
                      <div className='col-sm-12'>
                        <div className='row clearfix'>
                          <div className='col-sm-4'>
                            <div className='form-group'>
                              <b>Chef Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='chefName'
                                  value={this.state.chefName}
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
						   <div className='col-sm-4'>
                            <div className='form-group'>
                              <b>Package</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='Packagess'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select</option>
                                  <option
                                    value='Registration Package'
                                    selected={
                                      this.state.is_same_day_delivery == 'Registration Package'
                                    }
                                  >
                                    Registration Package
                                  </option>
                                  <option
                                    value='Marketing Package'
                                    selected={
                                      this.state.is_same_day_delivery == 'Marketing Package'
                                    }
                                  >
                                    Marketing Package
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Status</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='packageStatus'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Status</option>
                                  <option
                                    value='1'
                                    selected={
                                      this.state.packageStatus == '1'
                                    }
                                  >
                                    Active
                                  </option>
                                  <option
                                    value='0'
                                    selected={
                                      this.state.packageStatus == '0'
                                    }
                                  >
                                    Inactive
                                  </option>
								  <option
                                    value='2'
                                    selected={
                                      this.state.packageStatus == '2'
                                    }
                                  >
                                    Upcoming
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <b>Join Date Range</b>
                              <div className='datePickerFlex'>
                                <DatePicker
                                  selected={this.state.packageFromDate}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.packageToDate}
                                />
                                <p>&nbsp;&nbsp;to</p>
                                <DatePicker
                                  selected={this.state.packageToDate}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.packageFromDate}
                                />
                              </div>
                            </div>
                            {this.state.errorDateMessage ? (
                              <p className='errorMessage'>
                                {this.state.errorDateMessage}
                              </p>
                            ) : null}
                          </div>
                          <div className='col-sm-12 center_btn'>
                            <button
                              type='submit'
                              className='btn'
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type='submit'
                                className='btn'
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className='table-responsive'>
                      {this.state.orderExcelData.length  && (this.state.userRole == 'Admin' || this.state.userRole == 'Finance' || this.state.userRole == 'Ops') ? (
                        <ExcelFile
                          filename={'chef_subscription_list_' + this.state.currentDate}
                          element={
                            <button className='btn pull-right m-b-10'>
							{'Download Excel'}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.orderExcelData}
                            name='Chef Subscription Report'
                          >
                            <ExcelColumn
                              label='Chef Name'
                              value='chef_name'
                            />
                            <ExcelColumn
                              label='Package'
                              value='package'
                            />
                            <ExcelColumn
                              label='Join Date'
                              value='join_date'
                            />
                            <ExcelColumn
                              label='Expire Date'
                              value='expire_date'
                            />
							<ExcelColumn
                              label='Payment Date'
                              value='payment_date'
                            />
                            <ExcelColumn
                              label='Paid Amount'
                              value='paid_amount'
                            />
							 <ExcelColumn
                              label='Package Amount'
                              value='package_amount'
                            />
                            <ExcelColumn
                              label='Package GST'
                              value='package_gst'
                            />
                            <ExcelColumn
                              label='Payment Remark'
                              value='payment_remark'
                            />
                            <ExcelColumn
                              label='Status'
                              value='status'
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className='table table-bordered table-striped table-hover tbl_responsive packagev-table'>
                        <thead>
                          <tr>
							<th>Sr. No.</th>
                            <th>Chef Name</th>
                            <th>Package</th>
                            <th>Join Date</th>
                            <th>Expire Date</th>
                            <th>Payment Date</th>
                            <th>Paid Amount</th>
                            <th>Package Amount</th>
                            <th>Package GST</th>
                            <th>Payment Remark</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className='text-center' colSpan='11'>
                                <Loader
                                  type='Oval'
                                  color='#F07C07'
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.orderData.length ? (
                          <tbody>
                            {this.state.orderData.map((packagev, i) => {
                              return (
                                <tr key={i} className={( packagev.status == 'row-gray' )}>
                                  <td
                                    className='align-middle text-center'
                                    width='10%'
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + '' + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{packagev.chef_name}</td>
                                  <td>{packagev.package}</td>
                                  <td>{packagev.join_date}</td>
                                  <td>{packagev.expire_date}</td>
                                  <td>{packagev.payment_date}</td>
                                  <td>₹ {Math.round(packagev.paid_amount)}</td>
                                  <td>₹ {Math.round(packagev.package_amount)}</td>
                                  <td>₹ {Math.round(packagev.package_gst)}</td>
                                  <td>{packagev.payment_remark}</td>
                                  <td>{packagev.status}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan='11'>No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className='text-right'>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default chefSubscribedListing;
