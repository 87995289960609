import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import {
  addCollectionAPI,
  getChefListAPI,
  getMenuListAPI,
  getMarketingTagListAPI,
  getCuisinesListAPI,
  getMenuCategoriesListAPI,
  getCityListAPI
} from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";
import Select from "react-select";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};
export default class AddCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionName: "",
      ordering: "",
      collectionDescription: "",
      collectionImage: null,
      collectionImageName: "",
      resetFlag: false,
      errorBrandImage: "",
      isPublished: false,
      formErrors: {
        collectionName: "",
        collectionDescription: "",
      },
      imageErr: "",
      buttonText: "Submit",
      buttonDisabled: false,
      chefArr: [],
      cuisineArr: [],
      menuArr: [],
      tagArr: [],
	  menucategoryArr: [],
      externalUrl: "",
      selectedChefArr: [],
      selectedChef: null,
      selectedCuisineArr: [],
      selectedCuisine: null,
      selectedMenuArr: [],
      selectedMenu: null,
      selectedTagArr: [],
      selectedTag: null,
	  selectedMenuCategoryArr: [],
	  selectedMenuCategory: null,
      chefSelectErr: "",
      cuisineSelectErr: "",
      menuSelectErr: "",
      tagSelectErr: "",
	  categorySelectErr: "",
      tableSectionError: "",
      selectedTab: "",
	  selectedCity: '0',
      cityList: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getChefListAPI();
    this.getCuisinesListAPI();
    // this.getMenuListAPI()
    this.getMarketingTagListAPI();
	this.getMenuCategoriesListAPI();
	this.getCityListAPI();
  }

  getChefListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getChefListAPI(id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.length) {
            let array = [];
            response.data.data.map((item) => {
              array.push({ value: item.id, label: item.chef_name });
            });
            this.setState({
              chefArr: array,
            });
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
      });
  };
  
  getCityListAPI = () => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getCityListAPI(id, currentPage, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          if (response.data.data.data.length) {
            let array = [];
            response.data.data.data.map((item) => {
              array.push({ value: item.id, label: item.name });
            });
            this.setState(
              {
                cityList: array
              },
              () => {
                console.log(this.state.cityList);
              },
              100
            );
          }
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error('Something went wrong', 'Error!');
      });
  };

  getCuisinesListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getCuisinesListAPI(id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.length) {
            let array = [];
            response.data.data.map((item) => {
              array.push({ value: item.id, label: item.cuisine_name });
            });
            this.setState({
              cuisineArr: array,
            });
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
      });
  };

  // getMenuListAPI = () => {
  //     var id = localStorage.getItem("userID");
  //     var token = localStorage.getItem("accessToken");
  //     return getMenuListAPI(id, token)
  //         .then((response) => {
  //             if (response.data["status"] === 200) {
  //                 if (response.data.data.length) {
  //                     let array = []
  //                     response.data.data.map(item => {
  //                         array.push({ value: item.id, label: item.item_name })
  //                     })
  //                     this.setState({
  //                         menuArr: array
  //                     })
  //                 }
  //             } else {
  //                 NotificationManager.error(response.data["message"], "Error!");
  //             }
  //         })
  //         .catch((error) => {
  //             NotificationManager.error(error, "Error!");
  //         });
  // }

  getMarketingTagListAPI = () => {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getMarketingTagListAPI(id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data.data.length) {
            let array = [];
            response.data.data.map((item) => {
              array.push({ value: item.id, label: item.tag_name });
            });
            this.setState({
              tagArr: array,
            });
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!");
      });
  };
  
  getMenuCategoriesListAPI = () => {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        return getMenuCategoriesListAPI(id, token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    if (response.data.data.length) {
                        let array = []
                        response.data.data.map(item => {
                            array.push({ value: item.id, label: item.category_name })
                        })
                        this.setState({
                            menucategoryArr: array
                        })
                    }
                } else {
                    NotificationManager.error(response.data["message"], "Error!");
                }
            })
            .catch((error) => {
                NotificationManager.error(error, "Error!");
            });
    }

  onImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //     collectionImage: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("svg") ||
        e.target.files[0].type.includes("gif")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            imageErr: "The allowed maximum upload size is 64 MB",
          });
        } else {
          this.setState({
            imageErr: "",
            collectionImage: e.target.files[0],
          });
        }
      } else {
        this.setState({
          imageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png, .gif and .svg",
        });
      }
    } else {
      this.setState({
        image: null,
        imageErr: "Image is required",
      });
    }
  };

  handleCheckboxInput = (e) => {
    this.setState({
      isPublished: e.target.checked,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "collectionName":
        formErrors.collectionName =
          value === "" ? "Collection Name is required" : "";
        break;

      case "collectionDescription":
        formErrors.collectionDescription =
          value === "" ? "Collection description is required" : "";
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.collectionName === "") {
      formErrors.collectionName = "Title is required";
    }
    if (this.state.collectionDescription === "") {
      formErrors.collectionDescription = "Description is required";
    }
    if (this.state.collectionImage === null) {
      this.state.imageErr = "Image is required";
    }
    if (
      !this.state.selectedChefArr.length &&
      !this.state.selectedCuisineArr.length &&
	  !this.state.selectedMenuCategoryArr.length &&
      !this.state.selectedTagArr.length
    ) {
      this.setState({
        tableSectionError:
          "Please select at least one from chef / cuisine / tag / menu category",
      });
    }

    this.setState({ formErrors, [name]: value });
    setTimeout(() => {
      if (
        formValid(this.state) &&
        this.state.imageErr === "" &&
        this.state.tableSectionError === ""
      ) {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        let finalChefArray = [];
        let finalCuisineArray = [];
        let finalMenuArray = [];
        let finalTagArray = [];
        let finalCategoryArray = [];
        if (this.state.selectedChefArr.length) {
          this.state.selectedChefArr.map((item) => {
            finalChefArray.push(item.value);
          });
        }

        if (this.state.selectedCuisineArr.length) {
          this.state.selectedCuisineArr.map((item) => {
            finalCuisineArray.push(item.value);
          });
        }

        // if (this.state.selectedMenuArr.length) {
        //     this.state.selectedMenuArr.map(item => {
        //         finalMenuArray.push(item.value)
        //     })
        // }

        if (this.state.selectedTagArr.length) {
          this.state.selectedTagArr.map((item) => {
            finalTagArray.push(item.value);
          });
        }
		
		if (this.state.selectedMenuCategoryArr.length) {
			this.state.selectedMenuCategoryArr.map(item => {
				finalCategoryArray.push(item.value)
			})
		}

        let formData = {
          title: this.state.collectionName,
          ordering: this.state.ordering,
          description: this.state.collectionDescription,
          image: this.state.collectionImage,
          is_published: this.state.isPublished ? 1 : 0,
          selected_chef: finalChefArray.toString(),
          selected_cuisine: finalCuisineArray.toString(),
          // selected_menu: finalMenuArray.toString(),
          selected_tag: finalTagArray.toString(),
          selected_category: finalCategoryArray.toString(),
		  city_list: this.state.selectedCity
        };
        /* console.log(formData) */
        this.setState({
          buttonText: "Submitting...",
          buttonDisabled: true,
        });
        return addCollectionAPI(id, token, formData)
          .then((response) => {
            if (response.data["status"] === 200) {
              NotificationManager.success(
                "Collection added successfully",
                "Success",
                3000
              );
              this.setState({
                buttonText: "Submit",
              });
              setTimeout(() => {
                this.props.history.push("/collection-list");
              }, 2000);
            } else {
              NotificationManager.error(response.data["message"], "Error!");
              this.setState({
                buttonText: "Submit",
                buttonDisabled: false,
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          });
      } else {
        console.log("error");
      }
    }, 100);
  };

  handleChefChange = (e) => {
    this.setState({
      selectedChef: e ? e : null,
    });
    if (e) {
      this.setState({
        chefSelectErr: "",
      });
    }
  };

  handleAddChef = () => {
    if (!this.state.selectedChef) {
      this.setState({
        chefSelectErr: "Please select chef",
      });
    } else {
      this.setState((state) => {
        const { selectedChefArr, selectedChef } = this.state;
        let itemAlreadyInArray = false;
        selectedChefArr.forEach((item) => {
          if (item.value === selectedChef.value) {
            itemAlreadyInArray = true;
            NotificationManager.error(
              selectedChef.label + " is already added in the list",
              "Error!"
            );
          }
        });
        if (!itemAlreadyInArray) {
          selectedChefArr.push({ ...selectedChef });
          this.setState({
            selectedChef: null,
            selectedCuisineArr: [],
            selectedMenuArr: [],
            selectedTagArr: [],
			selectedMenuCategoryArr: [],
          });
        }
        return selectedChefArr;
      });
      this.setState({
        tableSectionError: "",
      });
    }
  };

  deleteChefFromList = (value) => {
    let array = this.state.selectedChefArr.filter((elm) => elm.value !== value);
    this.setState({
      selectedChefArr: array,
    });
    if (!array.length) {
      this.setState({
        tableSectionError:
          "Please select at least one from chef / cuisine / tag / menu category.",
      });
    }
  };

  handleCuisineChange = (e) => {
    this.setState({
      selectedCuisine: e ? e : null,
    });
    if (e) {
      this.setState({
        cuisineSelectErr: "",
      });
    }
  };

  handleAddCuisine = () => {
    if (!this.state.selectedCuisine) {
      this.setState({
        cuisineSelectErr: "Please select cuisine",
      });
    } else {
      this.setState((state) => {
        const { selectedCuisineArr, selectedCuisine } = this.state;
        let itemAlreadyInArray = false;
        selectedCuisineArr.forEach((item) => {
          if (item.value === selectedCuisine.value) {
            itemAlreadyInArray = true;
            NotificationManager.error(
              selectedCuisine.label + " is already added in the list",
              "Error!"
            );
          }
        });
        if (!itemAlreadyInArray) {
          selectedCuisineArr.push({ ...selectedCuisine });
          this.setState({
            selectedCuisine: null,
            selectedChefArr: [],
            selectedMenuArr: [],
			selectedMenuCategoryArr: [],
            selectedTagArr: [],
          });
        }
        return selectedCuisineArr;
      });
      this.setState({
        tableSectionError: "",
      });
    }
  };

  deleteCuisineFromList = (value) => {
    let array = this.state.selectedCuisineArr.filter(
      (elm) => elm.value !== value
    );
    this.setState({
      selectedCuisineArr: array,
    });
    if (!array.length) {
      this.setState({
        tableSectionError:
          "Please select at least one from chef / cuisine / tag / menu category.",
      });
    }
  };

  // handleMenuChange = (e) => {
  //     this.setState({
  //         selectedMenu: e ? e : null
  //     })
  //     if (e) {
  //         this.setState({
  //             menuSelectErr: ""
  //         })
  //     }
  // }

  // handleAddMenu = () => {
  //     if (!this.state.selectedMenu) {
  //         this.setState({
  //             menuSelectErr: "Please select menu"
  //         })
  //     } else {
  //         this.setState(state => {
  //             const { selectedMenuArr, selectedMenu } = this.state;
  //             let itemAlreadyInArray = false;
  //             selectedMenuArr.forEach(item => {
  //                 if (item.value === selectedMenu.value) {
  //                     itemAlreadyInArray = true;
  //                     NotificationManager.error(selectedMenu.label + " is already added in the list", "Error!");
  //                 }
  //             });
  //             if (!itemAlreadyInArray) {
  //                 selectedMenuArr.push({ ...selectedMenu })
  //                 this.setState({
  //                     selectedMenu: null,
  //                     selectedCuisineArr: [],
  //                     selectedChefArr: [],
  //                     selectedTagArr: []
  //                 })
  //             }
  //             return selectedMenuArr;
  //         })
  //         this.setState({
  //             tableSectionError: ""
  //         })
  //     }
  // }

  // deleteMenuFromList = (value) => {
  //     let array = this.state.selectedMenuArr.filter(elm => elm.value !== value)
  //     this.setState({
  //         selectedMenuArr: array
  //     })
  //     if (!array.length) {
  //         this.setState({
  //             tableSectionError: "Please select at least one from chef / cuisine / tag."
  //         })
  //     }
  // }

  handleTagChange = (e) => {
    this.setState({
      selectedTag: e ? e : null,
    });
    if (e) {
      this.setState({
        tagSelectErr: "",
      });
    }
  };

  handleAddTag = () => {
    if (!this.state.selectedTag) {
      this.setState({
        tagSelectErr: "Please select tag",
      });
    } else {
      this.setState((state) => {
        const { selectedTagArr, selectedTag } = this.state;
        let itemAlreadyInArray = false;
        selectedTagArr.forEach((item) => {
          if (item.value === selectedTag.value) {
            itemAlreadyInArray = true;
            NotificationManager.error(
              selectedTag.label + " is already added in the list",
              "Error!"
            );
          }
        });
        if (!itemAlreadyInArray) {
          selectedTagArr.push({ ...selectedTag });
          this.setState({
            selectedTag: null,
            selectedChefArr: [],
            selectedCuisineArr: [],
			selectedMenuCategoryArr: [],
            selectedMenuArr: [],
          });
        }
        return selectedTagArr;
      });
      this.setState({
        tableSectionError: "",
      });
    }
  };

  deleteTagFromList = (value) => {
    let array = this.state.selectedTagArr.filter((elm) => elm.value !== value);
    this.setState({
      selectedTagArr: array,
    });
    if (!array.length) {
      this.setState({
        tableSectionError:
          "Please select at least one from chef / cuisine / tag / menu category.",
      });
    }
  };
  
	handleMenuCategoryChange = (e) => {
        this.setState({
            selectedMenuCategory: e ? e : null
        })
        if (e) {
            this.setState({
                categorySelectErr: ""
            })
        }
    }

    handleAddMenuCategory = () => {
        if (!this.state.selectedMenuCategory) {
            this.setState({
                categorySelectErr: "Please select menu category"
            })
        } else {
            this.setState(state => {
                const { selectedMenuCategoryArr, selectedMenuCategory } = this.state;
                let itemAlreadyInArray = false;
                selectedMenuCategoryArr.forEach(item => {
                    if (item.value === selectedMenuCategory.value) {
                        itemAlreadyInArray = true;
                        NotificationManager.error(selectedMenuCategory.label + " is already added in the list", "Error!");
                    }
                });
                if (!itemAlreadyInArray) {
                    selectedMenuCategoryArr.push({ ...selectedMenuCategory })
                    this.setState({
						selectedMenuCategory: null,
						selectedChefArr: [],
						selectedCuisineArr: [],
						selectedTagArr: [],
						selectedMenuArr: [],
                    })
                }
                return selectedMenuCategoryArr;
            })
            this.setState({
                tableSectionError: ""
            })
        }
    }

    deleteMenuCategoryFromList = (value) => {
        let array = this.state.selectedMenuCategoryArr.filter(elm => elm.value !== value)
        this.setState({
            selectedMenuCategoryArr: array
        })
        if (!array.length && !this.state.externalUrl) {
            this.setState({
				tableSectionError: "Please select at least one from chef / cuisine / tag / menu category.",
            })
        }
    }

  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Add Collection</h2>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <form onSubmit={this.handleSubmit}>
                          <div className="row clearfix">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <b>Title*</b>
                                <div className="form-line">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="collectionName"
                                    value={this.state.collectionName}
                                    maxLength="20"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                {formErrors.collectionName.length > 0 && (
                                  <span className="text-danger">
                                    {formErrors.collectionName}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <b>Description*</b>
                                <div className="form-line">
                                  <textarea
                                    className="form-control"
                                    rows={3}
                                    name="collectionDescription"
                                    onChange={this.handleInputChange}
                                  ></textarea>
                                </div>
                                {formErrors.collectionDescription.length >
                                  0 && (
                                  <span className="text-danger">
                                    {formErrors.collectionDescription}
                                  </span>
                                )}
                              </div>
                            </div>
							
							<div className='col-sm-6'>
                              <div className='form-group'>
                                <b>Image*</b>
                                <div className='form-line'>
                                  <input
                                    id='uploadFile'
                                    type='file'
                                    accept='.jpg, .jpeg, .png, .svg, .gif'
                                    className='form-control'
                                    name='image'
                                    onChange={this.onImageChange}
                                  />
                                </div>
                                <p>(Image to be uploaded: 241 x 325 pixels)</p>
                                {this.state.imageErr.length > 0 && (
                                  <span className='text-danger'>
                                    {this.state.imageErr}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='form-group'>
                                <b>City</b>
                                <div className='form-line'>
                                  <select
                                    className='form-control show-tick'
                                    value={this.state.selectedCity}
                                    onChange={(v) => {
                                      const { value } = v.target;
                                      if (
                                        value !== '-- Any --' ||
                                        value !== '0'
                                      ) {
                                        this.setState({
                                          selectedCity: v.target.value
                                        });
                                      } else {
                                        this.setState({
                                          selectedCity: undefined
                                        });
                                      }
                                    }}
                                  >
                                    <option value={'0'}>-- Any --</option>
                                    {this.state.cityList.map((el) => (
                                      <option value={el.value}>
                                        {el.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
							
							
							<div className="col-sm-6">
                              <div className="form-group">
                                <b>Ordering (Highest come on the top)</b>
                                <div className="form-line">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="ordering"
                                    value={this.state.ordering}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
							
							<div className="col-sm-6">
                              <div className="form-group">
                                <div style={{ marginLeft: "20px" }}>
                                  <label htmlFor="isPublished">
                                    Is published?
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="isPublished"
                                    name="isPublished"
                                    className="filled-in"
                                    onChange={this.handleCheckboxInput}
                                  />
                                </div>
                              </div>
                            </div>
							

                            <div className="col-sm-12">
                              <ul
                                className="nav nav-tabs tab-nav-right"
                                role="tablist"
                              >
                                <li role="presentation" className="active">
                                  <a href="#chef-tab" data-toggle="tab">
                                    <i className="material-icons">face</i>Chef
                                  </a>
                                </li>
                                <li role="presentation">
                                  <a href="#cuisine-tab" data-toggle="tab">
                                    <i className="material-icons">business</i>
                                    Cuisine
                                  </a>
                                </li>
                                {/* <li role="presentation">
                                                                    <a href="#menu-tab" data-toggle="tab">
                                                                        <i className="material-icons">kitchen</i>Menu
                                                                    </a>
                                                                </li> */}
                                <li role="presentation">
                                  <a href="#tag-tab" data-toggle="tab">
                                    <i className="material-icons">kitchen</i>Tag
                                  </a>
                                </li>
								<li role="presentation">
									<a href="#category-tab" data-toggle="tab">
										<i className="material-icons">category</i>Menu Category
									</a>
								</li>
                              </ul>

                              <div className="tab-content">
                                <div
                                  role="tabpanel"
                                  className="tab-pane fade in active"
                                  id="chef-tab"
                                >
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                      {/* <div className="form-line"> */}
                                      <Select
                                        value={this.state.selectedChef}
                                        placeholder="Select Chef"
                                        onChange={this.handleChefChange}
                                        options={this.state.chefArr}
                                      />
                                      {/* </div> */}
                                      {this.state.chefSelectErr.length > 0 && (
                                        <span className="text-danger">
                                          {this.state.chefSelectErr}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.handleAddChef}
                                    >
                                      Add Chef
                                    </button>
                                  </div>
                                  <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="text-center" width="20%">
                                          Sr. No.
                                        </th>
                                        <th className="text-center">
                                          Chef Name
                                        </th>
                                        <th className="text-center" width="10%">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    {this.state.selectedChefArr.length ? (
                                      <tbody>
                                        {this.state.selectedChefArr.map(
                                          (item, i) => {
                                            return (
                                              <tr key={"a" + i}>
                                                <td className="text-center">
                                                  {i + 1}
                                                </td>
                                                <td>{item.label}</td>
                                                <td className="text-center">
                                                  <i
                                                    className="material-icons view_link"
                                                    onClick={() =>
                                                      this.deleteChefFromList(
                                                        item.value
                                                      )
                                                    }
                                                  >
                                                    delete
                                                  </i>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan="3"
                                            className="text-center"
                                          >
                                            No chef added.
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </div>

                                <div
                                  role="tabpanel"
                                  className="tab-pane fade in"
                                  id="cuisine-tab"
                                >
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                      {/* <div className="form-line"> */}
                                      <Select
                                        value={this.state.selectedCuisine}
                                        placeholder="Select Cuisine"
                                        onChange={this.handleCuisineChange}
                                        options={this.state.cuisineArr}
                                      />
                                      {/* </div> */}
                                      {this.state.cuisineSelectErr.length >
                                        0 && (
                                        <span className="text-danger">
                                          {this.state.cuisineSelectErr}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.handleAddCuisine}
                                    >
                                      Add Cuisine
                                    </button>
                                  </div>
                                  <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="text-center" width="20%">
                                          Sr. No.
                                        </th>
                                        <th className="text-center">
                                          Cuisine Name
                                        </th>
                                        <th className="text-center" width="10%">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    {this.state.selectedCuisineArr.length ? (
                                      <tbody>
                                        {this.state.selectedCuisineArr.map(
                                          (item, i) => {
                                            return (
                                              <tr key={"a" + i}>
                                                <td className="text-center">
                                                  {i + 1}
                                                </td>
                                                <td>{item.label}</td>
                                                <td className="text-center">
                                                  <i
                                                    className="material-icons view_link"
                                                    onClick={() =>
                                                      this.deleteCuisineFromList(
                                                        item.value
                                                      )
                                                    }
                                                  >
                                                    delete
                                                  </i>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan="3"
                                            className="text-center"
                                          >
                                            No cuisine added.
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </div>

                                {/* <div
                                                                    role="tabpanel"
                                                                    className="tab-pane fade in"
                                                                    id="menu-tab"
                                                                >
                                                                    <div className="col-sm-8">
                                                                        <div className="form-group">
                                                                            <Select
                                                                                value={this.state.selectedMenu}
                                                                                placeholder="Select Menu"
                                                                                onChange={this.handleMenuChange}
                                                                                options={this.state.menuArr}
                                                                            />
                                                                            {this.state.menuSelectErr.length > 0 && (
                                                                                <span className="text-danger">{this.state.menuSelectErr}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <button type="button" className="btn" onClick={this.handleAddMenu}>Add Menu</button>
                                                                    </div>
                                                                    <table className="table table-bordered table-striped table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-center" width="20%">Sr. No.</th>
                                                                                <th className="text-center">Menu Name</th>
                                                                                <th className="text-center" width="10%">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {
                                                                            this.state.selectedMenuArr.length ?
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.selectedMenuArr.map((item, i) => {
                                                                                            return <tr key={'a' + i}>
                                                                                                <td className="text-center">{i + 1}</td>
                                                                                                <td>{item.label}</td>
                                                                                                <td className="text-center">
                                                                                                    <i
                                                                                                        className="material-icons view_link"
                                                                                                        onClick={() => this.deleteMenuFromList(item.value)}
                                                                                                    >
                                                                                                        delete
                                                                                                    </i>
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                                :
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td colSpan="3" className="text-center">No menu added.</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                        }
                                                                    </table>
                                                                </div> */}
                                <div
                                  role="tabpanel"
                                  className="tab-pane fade in"
                                  id="tag-tab"
                                >
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                      {/* <div className="form-line"> */}
                                      <Select
                                        value={this.state.selectedTag}
                                        placeholder="Select Tag"
                                        onChange={this.handleTagChange}
                                        options={this.state.tagArr}
                                      />
                                      {/* </div> */}
                                      {this.state.tagSelectErr.length > 0 && (
                                        <span className="text-danger">
                                          {this.state.tagSelectErr}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={this.handleAddTag}
                                    >
                                      Add Tag
                                    </button>
                                  </div>
                                  <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="text-center" width="20%">
                                          Sr. No.
                                        </th>
                                        <th className="text-center">
                                          Tag Name
                                        </th>
                                        <th className="text-center" width="10%">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    {this.state.selectedTagArr.length ? (
                                      <tbody>
                                        {this.state.selectedTagArr.map(
                                          (item, i) => {
                                            return (
                                              <tr key={"a" + i}>
                                                <td className="text-center">
                                                  {i + 1}
                                                </td>
                                                <td>{item.label}</td>
                                                <td className="text-center">
                                                  <i
                                                    className="material-icons view_link"
                                                    onClick={() =>
                                                      this.deleteTagFromList(
                                                        item.value
                                                      )
                                                    }
                                                  >
                                                    delete
                                                  </i>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan="3"
                                            className="text-center"
                                          >
                                            No Tag added.
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </div>
								
								<div
									role="tabpanel"
									className="tab-pane fade in"
									id="category-tab"
								>
									<div className="col-sm-8">
										<div className="form-group">
											{/* <div className="form-line"> */}
											<Select
												value={this.state.selectedMenuCategory}
												placeholder="Select Menu Category"
												onChange={this.handleMenuCategoryChange}
												options={this.state.menucategoryArr}
											/>
											{/* </div> */}
											{this.state.categorySelectErr.length > 0 && (
												<span className="text-danger">{this.state.categorySelectErr}</span>
											)}
										</div>
									</div>
									<div className="col-sm-4">
										<button type="button" className="btn" onClick={this.handleAddMenuCategory}>Add Menu Category</button>
									</div>
									<table className="table table-bordered table-striped table-hover">
										<thead>
											<tr>
												<th className="text-center" width="20%">Sr. No.</th>
												<th className="text-center">Menu Category Name</th>
												<th className="text-center" width="10%">Action</th>
											</tr>
										</thead>
										{
											this.state.selectedMenuCategoryArr.length ?
												<tbody>
													{
														this.state.selectedMenuCategoryArr.map((item, i) => {
															return <tr key={'a' + i}>
																<td className="text-center">{i + 1}</td>
																<td>{item.label}</td>
																<td className="text-center">
																	<i
																		className="material-icons view_link"
																		onClick={() => this.deleteMenuCategoryFromList(item.value)}
																	>
																		delete
																	</i>
																</td>
															</tr>
														})
													}
												</tbody>
												:
												<tbody>
													<tr>
														<td colSpan="3" className="text-center">No menu category added.</td>
													</tr>
												</tbody>
										}
									</table>
								</div>
                              </div>
                              {this.state.tableSectionError.length > 0 && (
                                <span className="text-danger">
                                  {this.state.tableSectionError}
                                </span>
                              )}
                            </div>
                            <div className="col-sm-12">
                              <Link
                                to="/collection-list"
                                className="btn btn-default waves-effect"
                                style={{ color: "#000", marginRight: "10px" }}
                              >
                                Cancel
                              </Link>
                              <button
                                type="submit"
                                disabled={this.state.buttonDisabled}
                                className="btn"
                              >
                                {this.state.buttonText}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
