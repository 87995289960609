/*
#############################################################################
# Name : filterReport.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display the chef detail and user can add filter
#############################################################################
*/
import React from 'react';
import Header from '../../components/Header';
import Pagination from 'react-js-pagination';
import Moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import { getReportAPI, deleteUserAPI } from '../../core/services/APIServices';
import ReactExport from 'react-data-export';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SideBarSection from '../../components/Sidebar';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { addNewChefAPI } from '../../core/services/APIServices';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const profileFormValid = ({ addFormErrors, ...rest }) => {
  let valid = true;

  Object.values(addFormErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Object.values(addFormErrors).forEach((val) => {
  //   val === "form-control" || (valid = false);
  // });

  return valid;
};

class FilterReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userRole: '',
      userName: '',
      userEmailID: '',
      userFirstName: '',
      userLastName: '',
      userBrandName: '',
      userEmail: '',
      userMobileNo: '',
      userCity: '',
      userLastUpdated: '',
      userStatus: '',
      marketingFees: '',
      registrationFees: '',
      userSameDayDelivery: '',
      userFSSAI: '',
      resetFlag: false,
      userList: [],
      userExcelData: [],
      activePage: 1,
      totalRecords: 10,
      chefAboutYou: '',
      chefAboutBusiness: '',
      chefAboutKitchen: '',
      chefMenu: '',
      formStartDate: '',
      formStartEnd: '',
      totalData: '',
      completedData: '',
      registeredData: '',
      formatStartDate: '',
      formatEndDate: '',
      errorDateMessage: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      password: '',
      confirmPassword: '',
      addFormErrors: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: ''
      },
      buttonText: 'Submit',
      buttonDisabled: false
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLastUpdateChange = this.handleLastUpdateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleRegistrationFeesChange = this.handleRegistrationFeesChange.bind(this);
    this.handleMarketingFeesChange = this.handleMarketingFeesChange.bind(this);
    this.handleSameDayDelivery = this.handleSameDayDelivery.bind(this);
    this.handleFSSAI = this.handleFSSAI.bind(this);
  }

  componentDidMount() {
    var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userName: name,
      userEmailID: email
    });
    if (
      !localStorage.getItem('lastUpdated') ||
      localStorage.getItem('lastUpdated') == undefined
    ) {
      this.setState({ resetFlag: false });
      this.getReport();
    } else {
      var updatedData = localStorage.getItem('lastUpdated');
      this.setState({ userLastUpdated: updatedData, resetFlag: true });

      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = this.state.activePage;
      return getReportAPI(
        id,
        currentPage,
        this.state.userFirstName,
        this.state.userLastName,
        this.state.userBrandName,
        this.state.userEmail,
        this.state.userMobileNo,
        this.state.userCity,
        updatedData,
        this.state.userStatus,
        this.state.userSameDayDelivery,
        this.state.userFSSAI,
        this.state.formatStartDate,
        this.state.formatEndDate,
        this.state.marketingFees,
        this.state.registrationFees,
        token
      )
        .then((response) => {
          if (response.data['status'] === 200) {
            this.setState({
              userList: response.data['data']['records'],
              userExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data['data']['total_records'],
              totalData: response.data['data']['total_records'],
              completedData: response.data['data']['total_completed'],
              registeredData: response.data['data']['total_registered'],
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getReport();
    });
  }

  getReport() {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getReportAPI(
      id,
      currentPage,
      this.state.userFirstName,
      this.state.userLastName,
      this.state.userBrandName,
      this.state.userEmail,
      this.state.userMobileNo,
      this.state.userCity,
      this.state.userLastUpdated,
      this.state.userStatus,
      this.state.userSameDayDelivery,
      this.state.userFSSAI,
      this.state.formatStartDate,
      this.state.formatEndDate,
      this.state.marketingFees,
      this.state.registrationFees,
      token
    )
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            userList: response.data['data']['records'],
            totalRecords: response.data['data']['total_records'],
            userExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
            totalData: response.data['data']['total_records'],
            completedData: response.data['data']['total_completed'],
            registeredData: response.data['data']['total_registered'],
            isLoading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleFilterChange(key) {
    this.setState({
      [key.target.name]: key.target.value
    });
  }

  handleStartDateChange(event) {
    this.setState({
      formStartDate: event,
      formatStartDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleEndDateChange(event) {
    this.setState({
      formStartEnd: event,
      formatEndDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleLastUpdateChange(event) {
    this.setState({ userLastUpdated: event.target.value });
  }

  handleStatusChange(event) {
    this.setState({ userStatus: event.target.value });
  }
  
  handleRegistrationFeesChange(event) {
    this.setState({ registrationFees: event.target.value });
  }
  
  handleMarketingFeesChange(event) {
    this.setState({ marketingFees: event.target.value });
  }
  
  handleSameDayDelivery(event) {
    this.setState({ userSameDayDelivery: event.target.value });
  }
  
   handleFSSAI(event) {
    this.setState({ userFSSAI: event.target.value });
  }

  submitFilter = (event) => {
    event.preventDefault();
    var statusDate = false;

    /*  if (this.state.formatStartDate && this.state.formatEndDate == "") {
       console.log('in');
       statusDate = true;
       this.setState({errorDateMessage: "End Date not selected" })
     }else{
       statusDate = false;
       this.setState({errorDateMessage:''})
     } */

    if (
      (this.state.formatEndDate == '' || this.state.formStartEnd == '') &&
      this.state.formatStartDate
    ) {
      statusDate = true;
      this.setState({ errorDateMessage: 'End Date not selected' });
    }

    if (this.state.formatStartDate == '' && this.state.formatEndDate) {
      statusDate = true;
      this.setState({ errorDateMessage: 'Start Date not selected' });
    }

    if (this.state.formatStartDate && this.state.formatEndDate) {
      statusDate = false;
      this.setState({ errorDateMessage: '', resetFlag: true });
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getReportAPI(
        id,
        currentPage,
        this.state.userFirstName,
        this.state.userLastName,
        this.state.userBrandName,
        this.state.userEmail,
        this.state.userMobileNo,
        this.state.userCity,
        this.state.userLastUpdated,
        this.state.userStatus,
        this.state.userSameDayDelivery,
        this.state.userFSSAI,
        this.state.formatStartDate,
        this.state.formatEndDate,
        this.state.marketingFees,
        this.state.registrationFees,
        token
      )
        .then((response) => {
          if (response.data['status'] === 200) {
            this.setState({
              userList: response.data['data']['records'],
              totalRecords: response.data['data']['total_records'],
              userExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalData: response.data['data']['total_records'],
              completedData: response.data['data']['total_completed'],
              registeredData: response.data['data']['total_registered'],
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (statusDate === false && this.state.errorDateMessage === '') {
      this.setState({
        resetFlag: true
      });
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getReportAPI(
        id,
        currentPage,
        this.state.userFirstName,
        this.state.userLastName,
        this.state.userBrandName,
        this.state.userEmail,
        this.state.userMobileNo,
        this.state.userCity,
        this.state.userLastUpdated,
        this.state.userStatus,
        this.state.userSameDayDelivery,
        this.state.userFSSAI,
        this.state.formatStartDate,
        this.state.formatEndDate,
        this.state.marketingFees,
        this.state.registrationFees,
        token
      )
        .then((response) => {
          if (response.data['status'] === 200) {
            this.setState({
              userList: response.data['data']['records'],
              totalRecords: response.data['data']['total_records'],
              userExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalData: response.data['data']['total_records'],
              completedData: response.data['data']['total_completed'],
              registeredData: response.data['data']['total_registered'],
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        userFirstName: '',
        userLastName: '',
        userBrandName: '',
        userEmail: '',
        userMobileNo: '',
        userCity: '',
        userLastUpdated: '',
        userStatus: '',
        userSameDayDelivery: '',
        userFSSAI: '',
        formatStartDate: '',
        formatEndDate: '',
        formStartDate: '',
        marketingFees: '',
        registrationFees: '',
        formStartEnd: ''
      },
      () => {
        localStorage.removeItem('lastUpdated');
        this.getReport();
      }
    );
  };

  getPercentageStatus = (aboutYou, aboutBusiness, aboutKitchen, aboutMenu) => {
    this.setState({
      chefAboutYou: aboutYou,
      chefAboutBusiness: aboutBusiness,
      chefAboutKitchen: aboutKitchen,
      chefMenu: aboutMenu
    });
  };

  getUserView = (id, chefId) => {
    localStorage.setItem('chefID', id);
    localStorage.setItem('chefMainID', chefId);
    /*  this.props.history.push("/chef-data"); */
  };

  handleClickDelete = (id) => {
    var userId = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    return deleteUserAPI(userId, id, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            isLoading: true
          });
          this.getReport();
          NotificationManager.success('User deleted successfully', 'Success');
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteUser = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui text-center'>
            <h2 style={{ marginTop: '20px' }}>Are you sure?</h2>
            <p style={{ marginTop: '20px', marginBottom: '40px' }}>
              You want to delete this user?
            </p>
            <button
              className='btn btn-default waves-effect'
              style={{ color: '#000', marginRight: '10px' }}
              onClick={onClose}
            >
              No
            </button>
            <button
              class='btn'
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  };

  handleAddFormInputChange = (e) => {
    const { name, value } = e.target;
    let addFormErrors = this.state.addFormErrors;

    switch (name) {
      case 'firstName':
        addFormErrors.firstName =
          value.length <= 0
            ? 'First name is required'
            : !value.match(/^[a-zA-Z]+$/)
            ? 'Enter valid first name'
            : '';
        break;

      case 'lastName':
        addFormErrors.lastName =
          value.length <= 0
            ? 'Last name is required'
            : !value.match(/^[a-zA-Z]+$/)
            ? 'Enter valid last name'
            : '';
        break;

      case 'email':
        addFormErrors.email =
          value.length <= 0
            ? 'Email is required'
            : !value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
            ? 'Enter valid email'
            : '';
        break;

      case 'mobile':
        addFormErrors.mobile =
          value.length <= 0
            ? 'Mobile number is required'
            : !value.match(/^[789]\d{9}$/)
            ? 'Enter valid mobile number'
            : '';
        break;

      case 'password':
        addFormErrors.password =
          value.length <= 0
            ? 'Please enter new password'
            : value.length < 6
            ? 'Password must be atleast 6 characters'
            : '';
        break;

      case 'confirmPassword':
        addFormErrors.confirmPassword =
          value.length <= 0
            ? 'Please confirm new password'
            : value !== this.state.password
            ? 'Password is not matching!'
            : '';
        break;
    }

    this.setState({ addFormErrors, [name]: value });
  };
  submitAddChefForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let addFormErrors = this.state.addFormErrors;
    const { firstName, lastName, email, mobile, password, confirmPassword } =
      this.state;

    if (firstName === '') {
      addFormErrors.firstName = 'First name is required';
    }
    if (lastName === '') {
      addFormErrors.lastName = 'Last name is required';
    }
    if (email === '') {
      addFormErrors.email = 'Email is required';
    }
    if (mobile === '') {
      addFormErrors.mobile = 'Mobile number is required';
    }
    if (password === '') {
      addFormErrors.password = 'Please enter new password';
    }
    if (confirmPassword === '') {
      addFormErrors.confirmPassword = 'Please confirm new password';
    }
    this.setState({ addFormErrors, [name]: value });
    if (profileFormValid(this.state)) {
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      this.setState({
        buttonText: 'Submitting...',
        buttonDisabled: true
      });
      let data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        mobile: this.state.mobile,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword
      };
      return addNewChefAPI(id, token, data)
        .then((response) => {
          if (response.data['status'] === 200) {
            NotificationManager.success(
              'Chef added successfully',
              'Success',
              3000
            );
            this.getReport();
            this.setState(
              {
                buttonText: 'Submit',
                buttonDisabled: false
              },
              () => {
                document.getElementById('myAddChefModal').click();
                localStorage.setItem('chefID', response.data.data.chef_id);
                this.props.history.push('/chef-data');
              }
            );
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, 'Error!');
          this.setState({
            buttonText: 'Submit',
            buttonDisabled: false
          });
        });
    }
  };

  addModalClosed = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      password: '',
      confirmPassword: '',
      addFormErrors: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: ''
      }
    });
    document.getElementById('chefAddForm').reset();
  };

  render() {
    if (!localStorage.getItem('accessToken')) {
      return <Redirect to='/' />;
    }
    const { addFormErrors } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row clearfix'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='header'>
                    <h2 className='fw_500'>Chef Form List</h2>
                  </div>
                  <div className='header details_wrap'>
                    <h2 className='fw_500'>
                      Registered: <span>{this.state.registeredData}</span>
                    </h2>
                    <h2 className='fw_500'>
                      Completed: <span>{this.state.completedData}</span>
                    </h2>
                    <h2 className='fw_500'>
                      Total: <span>{this.state.totalData}</span>
                    </h2>
                  </div>
                  <div className='body'>
                    <div className='row clearfix'>
                      <div className='col-sm-12'>
                        <div className='row clearfix'>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>First Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='userFirstName'
                                  value={this.state.userFirstName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Last Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='userLastName'
                                  value={this.state.userLastName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Brand Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='userBrandName'
                                  value={this.state.userBrandName}
                                  maxLength='50'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Email</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='userEmail'
                                  value={this.state.userEmail}
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Mobile Number</b>
                              <div className='form-line'>
                                <input
                                  type='tel'
                                  className='form-control'
                                  name='userMobileNo'
                                  value={this.state.userMobileNo}
                                  maxLength='10'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>City</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='userCity'
                                  value={this.state.userCity}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Last Updated</b>
                              <div className='form-line'>
                                <select
                                  className='form-control show-tick'
                                  value={this.state.userLastUpdated}
                                  onChange={this.handleLastUpdateChange}
                                >
                                  <option value=''>-- Any --</option>
                                  <option value='registered'>REGISTERED</option>
                                  <option value='about_you'>ABOUT YOU</option>
                                  <option value='about_your_kitchen'>
                                    ABOUT YOUR KITCHEN
                                  </option>
                                  <option value='about_your_business'>
                                    ABOUT YOUR BUSINESS
                                  </option>
                                  <option value='in_words_and_pictures'>
                                    MENU (Document)
                                  </option>
                                  <option value='menu'>MENU (Manual) </option>
                                  <option value='submit'>SUBMIT</option>
								  <option value='Subscribed Package'>Subscribed Package </option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
						   <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Is FSSAI Registration</b>
                              <div className='form-line'>
                                <select
                                  className='form-control show-tick'
                                  value={this.state.userFSSAI}
                                  onChange={this.handleFSSAI}
                                >
                                  <option value=''>-- Any --</option>
                                  <option value='Yes'>Yes</option>
                                  <option value='No'>No</option>
                                  <option value='Applied'>Applied</option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Status</b>
                              <div className='form-line'>
                                <select
                                  className='form-control show-tick'
                                  value={this.state.userStatus}
                                  onChange={this.handleStatusChange}
                                >
                                  <option value=''>-- Any --</option>
                                  <option value='Pending'>Pending</option>
                                  <option value='Completed'>Completed</option>
                                  <option value='Published'>Published</option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
						   <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Is same-day delivery</b>
                              <div className='form-line'>
                                <select
                                  className='form-control show-tick'
                                  value={this.state.userSameDayDelivery}
                                  onChange={this.handleSameDayDelivery}
                                >
                                  <option value=''>-- Any --</option>
                                  <option value='1'>Yes</option>
                                  <option value='0'>No</option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
						  <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Registration Fees</b>
                              <div className='form-line'>
                                <select
                                  className='form-control show-tick'
                                  value={this.state.registrationFees}
                                  onChange={this.handleRegistrationFeesChange}
                                >
                                  <option value=''>-- Any --</option>
                                  <option value='Unpaid'>Unpaid</option>
                                  <option value='Paid'>Paid</option>
                                  <option value='To be expired'>To be expired</option>
                                  <option value='Expired'>Expired</option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
						  <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Marketing Fees</b>
                              <div className='form-line'>
                                <select
                                  className='form-control show-tick'
                                  value={this.state.marketingFees}
                                  onChange={this.handleMarketingFeesChange}
                                >
                                  <option value=''>-- Any --</option>
                                  <option value='Unpaid'>Unpaid</option>
                                  <option value='Paid'>Paid</option>
                                  <option value='To be expired'>To be expired</option>
                                  <option value='Expired'>Expired</option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
                          <div className='col-sm-4'>
                            <div className='form-group'>
                              <b>Date Range</b>
                              <div className='datePickerFlex'>
                                <DatePicker
                                  selected={this.state.formStartDate}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.formStartEnd}
                                />
                                <p>&nbsp;&nbsp;to</p>
                                <DatePicker
                                  selected={this.state.formStartEnd}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.formStartDate}
                                />
                              </div>
                            </div>
                            {this.state.errorDateMessage ? (
                              <p className='errorMessage'>
                                {this.state.errorDateMessage}
                              </p>
                            ) : null}
                          </div>
                          <div className='col-sm-12 center_btn'>
                            <button
                              type='submit'
                              className='btn'
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type='submit'
                                className='btn'
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
					  {/*  Admin and Ops menu */}
					  {this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
						<button
						className='btn'
						data-toggle='modal'
						data-target='#myAddChefModal'
					  >
						Add New Chef
					  </button>
					  ) : null}
                      
					  {/*  Admin menu */}
                      {this.state.userExcelData.length && (this.state.userRole == 'Admin' || this.state.userRole == 'Ops' || this.state.userRole == 'Finance') ? (
                        <ExcelFile
                          filename='Chef_Detail'
                          element={
                            <button className='btn pull-right m-b-10'>
							{(this.state.userRole == 'Finance' || this.state.userRole == 'Ops') ? 'Last 30 Days Download Excel' : 'Download Excel'}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.userExcelData}
                            name='Users'
                          >
                            <ExcelColumn
                              label='First Name'
                              value='first_name'
                            />
                            <ExcelColumn label='Last Name' value='last_name' />
                            <ExcelColumn
                              label='Brand Name'
                              value='brand_name'
                            />
                            <ExcelColumn label='EmailID' value='email' />
                            <ExcelColumn
                              label='Mobile Number'
                              value='mobile_number'
                            />
                            <ExcelColumn label='City' value='city' />
                            <ExcelColumn
                              label='Registered at'
                              value='registered_at'
                            />
                            <ExcelColumn
                              label='Last Updated'
                              value='last_updated'
                            />
                            <ExcelColumn label='Status' value='status' />
                            <ExcelColumn
                              label='Final Submitted at'
                              value='final_submitted_at'
                            />
                            <ExcelColumn label='Address' value='address' />
                            <ExcelColumn
                              label='Is MOU Submitted'
                              value='is_mou_submitted'
                            />
							<ExcelColumn
                              label='Is Same-day delivery'
                              value='is_same_day_delivery'
                            />
							<ExcelColumn
                              label='Is Fssai Registration'
                              value='fssai_registration'
                            />
							<ExcelColumn
                              label='Registration Fees'
                              value='registration_package'
                            />
							<ExcelColumn
                              label='Marketing Fees'
                              value='marketing_package'
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className='table table-bordered table-striped table-hover'>
                        <thead>
                          <tr>
                            <th className='text-center'>Sr. No</th>
                            <th className='text-center'>First Name</th>
                            <th className='text-center'>Last Name</th>
                            <th className='text-center'>Brand Name</th>
                            <th className='text-center'>Mobile Number</th>
                            <th className='text-center'>City</th>
                            <th className='text-center'>Reg. at</th>
                            <th className='text-center'>Last Updated</th>
                            {/*<th className='text-center'>Percentage Status</th>*/}
                            <th className='text-center'>Status</th>
                            <th className='text-center'>Final Sub. at</th>
                            <th className='text-center'>MOU Sub.</th>
                            <th className='text-center'>Is Same-day delivery</th>
                            <th className='text-center'>Is Fssai</th>
                            <th className='text-center'>Reg. Fees</th>
                            <th className='text-center'>Mar. Fees</th>
                            <th className='text-center'>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className='text-center' colSpan='16'>
                                <Loader
                                  type='Oval'
                                  color='#F07C07'
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.userList.length ? (
                          <tbody>
                            {this.state.userList.map((user, i) => {
                              return (
                                <tr key={i}>
                                  {/* <td>{this.state.activePage == 1 ? i+1 : (this.state.activePage - 1)+""+(i+1)}</td> */}
                                  <td>
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + '' + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{user.first_name}</td>
                                  <td>{user.last_name}</td>
                                  <td>{user.brand_name}</td>
                                  <td>{user.mobile_number}</td>
                                  <td>{user.city}</td>
                                  <td>{user.registered_at}</td>
                                  <td>{user.last_updated}</td>
                                  {/*<td className='align-middle' align='center'>
                                    <i
                                      type='button'
                                      data-toggle='modal'
                                      data-target='#myModal'
                                      className='material-icons view_link'
                                      onClick={() =>
                                        this.getPercentageStatus(
                                          user.profile_completion_percentage
                                            .about_average,
                                          user.profile_completion_percentage
                                            .business_average,
                                          user.profile_completion_percentage
                                            .kitchen_average,
                                          user.profile_completion_percentage
                                            .number_of_uploaded_menu
                                        )
                                      }
                                    >
                                      remove_red_eye
                                    </i>
                                  </td>*/}
                                  <td>
                                    {user.status == 'Pending' ? (
                                      <span className='statusFontPending'>
                                        {user.status}
                                      </span>
                                    ) : (
                                      <span className='statusFontCompleted'>
                                        {user.status}
                                      </span>
                                    )}
                                  </td>
                                  <td>{user.final_submitted_at}</td>
                                  <td>{user.is_mou_submitted}</td>
                                  <td>{user.is_same_day_delivery}</td>
                                  <td>{user.fssai_registration}</td>
                                  <td>{user.registration_package}</td>
                                  <td>{user.marketing_package}</td>

                                  <td>
                                    <a
                                      className='view_link'
                                      href='/chef-data'
                                      target='blank'
                                    >
                                      <i
                                        className='material-icons'
                                        onClick={() =>
                                          this.getUserView(
                                            user.user_id,
                                            user.id
                                          )
                                        }
                                      >
                                        remove_red_eye
                                      </i>
                                    </a>
									{/*  Admin menu */}
									{this.state.userRole == 'Admin' ? (
										<a className='view_link'>
										  <i
											className='material-icons'
											onClick={() =>
											  this.deleteUser(user.user_id)
											}
										  >
											delete_forever
										  </i>
										</a>
									) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className='text-center' colSpan='17'>
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className='text-right'>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className='modal fade menu_list_pop'
          id='myModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='add_another_btn'>
                  <button
                    type='button'
                    className='close plus_icon'
                    data-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <h4 className='modal-title color_C07' id='myModalLabel'>
                  Percentage Status
                </h4>
              </div>
              <div className='modal-body'>
                <ul>
                  <li>
                    <b>About You Page</b>
                    <span>{this.state.chefAboutYou}%</span>
                  </li>
                  <li>
                    <b>About Business Page</b>
                    <span>{this.state.chefAboutBusiness}%</span>
                  </li>
                  <li>
                    <b>About Kitchen Page</b>
                    <span>{this.state.chefAboutKitchen}%</span>
                  </li>
                  <li>
                    <b>Menu</b>
                    <span>{this.state.chefMenu} item added</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='myAddChefModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title color_C07' id='myModalLabel'>
                  Add New Chef
                </h4>
              </div>
              <div className='modal-body'>
                <form onSubmit={this.submitAddChefForm} id='chefAddForm'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          First Name<span style={{ color: 'red' }}>*</span>
                        </b>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder=''
                            onChange={this.handleAddFormInputChange}
                            name='firstName'
                            className='form-control'
                          />
                        </div>
                        {addFormErrors.firstName.length > 0 && (
                          <small className='text-danger'>
                            {addFormErrors.firstName}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          Last Name<span style={{ color: 'red' }}>*</span>
                        </b>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder=''
                            onChange={this.handleAddFormInputChange}
                            name='lastName'
                            className='form-control'
                          />
                        </div>
                        {addFormErrors.lastName.length > 0 && (
                          <small className='text-danger'>
                            {addFormErrors.lastName}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          Email<span style={{ color: 'red' }}>*</span>
                        </b>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder=''
                            onChange={this.handleAddFormInputChange}
                            name='email'
                            className='form-control'
                          />
                        </div>
                        {addFormErrors.email.length > 0 && (
                          <small className='text-danger'>
                            {addFormErrors.email}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          Mobile Number<span style={{ color: 'red' }}>*</span>
                        </b>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder=''
                            onChange={this.handleAddFormInputChange}
                            name='mobile'
                            className='form-control'
                          />
                        </div>
                        {addFormErrors.mobile.length > 0 && (
                          <small className='text-danger'>
                            {addFormErrors.mobile}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          Password<span style={{ color: 'red' }}>*</span>
                        </b>
                        <div className='form-line'>
                          <input
                            type='password'
                            placeholder=''
                            onChange={this.handleAddFormInputChange}
                            name='password'
                            className='form-control'
                          />
                        </div>
                        {addFormErrors.password.length > 0 && (
                          <small className='text-danger'>
                            {addFormErrors.password}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          Confirm Password
                          <span style={{ color: 'red' }}>*</span>
                        </b>
                        <div className='form-line'>
                          <input
                            type='password'
                            placeholder=''
                            onChange={this.handleAddFormInputChange}
                            name='confirmPassword'
                            className='form-control'
                          />
                        </div>
                        {addFormErrors.confirmPassword.length > 0 && (
                          <small className='text-danger'>
                            {addFormErrors.confirmPassword}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <button
                      type='button'
                      data-dismiss='modal'
                      id='closeAddFormButton'
                      aria-label='Close'
                      disabled={this.state.buttonDisabled}
                      className='btn btn-default waves-effect'
                      style={{ color: '#000', marginRight: '10px' }}
                      onClick={this.addModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={this.state.buttonDisabled}
                      className='btn'
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterReport;
