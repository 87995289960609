import React, { Component } from 'react'
import {
    getDinerProfileDetailAPI,
    updateDinerProfileDetailsAPI
} from "../../core/services/APIServices";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import Moment from 'moment'

const profileFormValid = ({ profileFormErrors, ...rest }) => {
    let valid = true;
  
    Object.values(profileFormErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
  
    Object.values(profileFormErrors).forEach((val) => {
      val === null && (valid = false);
    });
  
    return valid;
  };

export default class ProfileTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
			userRole: '',
            date: new Date(),
            userName: "",
            userFirstName: "",
            userLastName: "",
            userEmailId: "",
            userMobileNo: "",
            userProfileImage: "",
            userProfileImageUrl: "",
            userPincode: "",
            userCity: "",
            userState: "",
            userLocation: [],
            profileFormErrors: {
                userFirstName: "",
                userLastName: "",
                userEmailId: "",
                userMobileNo: "",
                dataLoaded: false,
                userPincode: "",
                userCity: "",
                userState: "",
            },
            profileImgError: "",
            buttonText: "Update Profile Info",
            passwordButtonText: "Change Password",
            buttonDisabled: false
        }
    }
    componentDidMount() {
		var name = localStorage.getItem('userName');
		var email = localStorage.getItem('userEmail');
		var role = localStorage.getItem('userRole');
		this.setState({
		  userRole: role,
		  userName: name,
		  userEmailID: email
		});
        this.getProfileData()
    }
    getProfileData() {
        var userId = localStorage.getItem("userID");
        var dinerId = localStorage.getItem("dinerID");
        var token = localStorage.getItem("accessToken");
        return getDinerProfileDetailAPI(userId, dinerId, token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    let dataValue = {
                        fName: response.data.data["first_name"],
                        lName: response.data.data["last_name"],
                    }
                    this.props.onUserdataChanges(dataValue);
                    this.setState({
                        userFirstName: response.data.data["first_name"],
                        userLastName: response.data.data["last_name"],
                        userEmailId: response.data.data["email"],
                        userMobileNo: response.data.data["mobile_number"],
                        userProfileImageUrl: response.data.data["profile_image"] == "null" ? null : response.data.data["profile_image"],
                        userPincode: response.data.data["pincode"] == null ? "" : response.data.data["pincode"],
                        userCity: response.data.data["city"] == null ? "" : response.data.data["city"],
                        userState: response.data.data["state"] == null ? "" : response.data.data["state"],
                        userLocation: response.data.data["locations"] == null ? [] : response.data.data["locations"],
                    });
                } else {
                    this.setState({
                        dataLoaded: true,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    dataLoaded: true,
                })
            });
    }
    onProfileImageChange = (e) => {
        if (e.target.files[0]) {
            this.setState({
                userProfileImage: e.target.files[0]
            })
            if (e.target.files[0].type.includes('jpg') ||
                e.target.files[0].type.includes('jpeg') ||
                e.target.files[0].type.includes('png') ||
                e.target.files[0].type.includes('gif')
            ) {
                if (e.target.files[0].size > 67108864) {
                    this.setState({
                        profileImgError: "The allowed maximum upload size is 64 MB"
                    })
                } else {
                    this.setState({
                        profileImgError: ""
                    })
                }
            } else {
                this.setState({
                    profileImgError: "Select valid image. Allowed format are .jpg, .jpeg, .png and .gif"
                })
            }
        }
    }
    onBrandImageChange = (e) => {
        if (e.target.files[0]) {
            this.setState({
                userBrandImage: e.target.files[0]
            })
            if (e.target.files[0].type.includes('jpg') ||
                e.target.files[0].type.includes('jpeg') ||
                e.target.files[0].type.includes('png') ||
                e.target.files[0].type.includes('gif')
            ) {
                if (e.target.files[0].size > 67108864) {
                    this.setState({
                        brandImgError: "The allowed maximum upload size is 64 MB"
                    })
                } else {
                    this.setState({
                        brandImgError: ""
                    })
                }
            } else {
                this.setState({
                    brandImgError: "Select valid image. Allowed format are .jpg, .jpeg, .png and .gif"
                })
            }
        }
    }
    handleProfileInputChange = (e) => {
        const { name, value } = e.target;
        let profileFormErrors = this.state.profileFormErrors;

        switch (name) {
            case 'userFirstName':
                profileFormErrors.userFirstName = value.length <= 0 ? 'First name is required' : !value.match(/^[a-zA-Z]+$/) ? "Enter valid first name" : "";
                break;

            case 'userLastName':
                profileFormErrors.userLastName = value.length <= 0 ? 'Last name is required' : !value.match(/^[a-zA-Z]+$/) ? "Enter valid last name" : "";
                break;

            case 'userEmailId':
                profileFormErrors.userEmailId = value.length <= 0 ? 'Email is required' : !value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? "Enter valid email" : "";
                break;

            case 'userMobileNo':
                profileFormErrors.userMobileNo = value.length <= 0 ? 'Mobile number is required' : !value.match(/^[789]\d{9}$/) ? "Enter valid mobile number" : "";
                break;

            case 'userPincode':
                // profileFormErrors.userPincode = value.length <= 0 ? 'Pincode is required' : !value.match(/^[1-9][0-9]{5}$/) ? "Enter valid pincode" : "";
                if (value.length === 6) {
                    axios
                        .get(
                            "https://maps.googleapis.com/maps/api/geocode/json?address=+" +
                            value +
                            "+&sensor=true&key=AIzaSyAM9tXQe4ikJvpt4PwbWVR9gVOaYPoWuT8",
                            {
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.status === "OK") {
                                var cityCnt = 0;
                                var stateCnt = 0;
                                response.data.results[0].address_components.forEach((item) => {
                                    item.types.forEach((type) => {
                                        if (
                                            type === "administrative_area_level_2" ||
                                            type === "locality"
                                        ) {
                                            if (item.long_name) {
                                                profileFormErrors.userCity = "";
                                            }
                                            this.setState({
                                                userCity: item.long_name,
                                            });
                                            cityCnt++;
                                        }
                                        if (type === "administrative_area_level_1") {
                                            if (item.long_name) {
                                                profileFormErrors.userState = "";
                                            }
                                            this.setState({
                                                userState: item.long_name,
                                            });
                                            stateCnt++;
                                        }
                                    });
                                });

                                if (cityCnt === 0) {
                                    this.setState({
                                        userCity: "",
                                    });
                                }
                                if (stateCnt === 0) {
                                    this.setState({
                                        userState: "",
                                    });
                                }
                            } else {
                                this.setState({
                                    userCity: "",
                                    userState: "",
                                });
                                profileFormErrors.userPincode = "Pincode is invalid"
                            }
                        })
                        .catch((error) => {
                            NotificationManager.error(error);
                        });
                }
                break;

            // case 'userCity':
            //     profileFormErrors.userCity = value.length <= 0 ? 'City is required' : "";
            //     break;

            // case 'userState':
            //     profileFormErrors.userState = value.length <= 0 ? 'State is required' : "";
            //     break;
        }

        this.setState({ profileFormErrors, [name]: value })
    }
    submitProfileForm = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let profileFormErrors = this.state.profileFormErrors;
        const { userFirstName,
            userLastName,
            userEmailId,
            userMobileNo,
            userPincode,
            userCity,
            userProfileImage,
            userProfileImageUrl,
            profileImgError,
            userState } = this.state;

        if (userFirstName === "") {
            profileFormErrors.userFirstName = "First name is required";
        }
        if (userLastName === "") {
            profileFormErrors.userLastName = "Last name is required";
        }
        if (userEmailId === "") {
            profileFormErrors.userEmailId = "Email is required";
        }
        if (userMobileNo === "") {
            profileFormErrors.userMobileNo = "Mobile number is required";
        }
        // if (userPincode === "") {
        //     profileFormErrors.userPincode = "Pincode is required";
        // }
        // if (userCity === "") {
        //     profileFormErrors.userCity = "City is required";
        // }
        // if (userState === "") {
        //     profileFormErrors.userState = "State is required";
        // }
        this.setState({ profileFormErrors, [name]: value });
        setTimeout(() => {
            if (profileFormValid(this.state)) {
                var id = localStorage.getItem("userID");
                var dinerId = localStorage.getItem("dinerID");
                var token = localStorage.getItem("accessToken");
                this.setState({
                    buttonText: "Updating...",
                    buttonDisabled: true
                })
                let data = {
                    userFirstName: this.state.userFirstName,
                    userLastName: this.state.userLastName,
                    userEmailId: this.state.userEmailId,
                    userMobileNo: this.state.userMobileNo,
                    mobileNo: this.state.mobileNo,
                    userProfileImage: this.state.userProfileImage,
                    userPincode: this.state.userPincode,
                    userCity: this.state.userCity,
                    userState: this.state.userState,
                }
                return updateDinerProfileDetailsAPI(id, dinerId, token, data)
                    .then((response) => {
                        if (response.data["status"] === 200) {
                            NotificationManager.success(
                                "Profile data updated successfully",
                                "Success",
                                3000
                            );
                            this.getProfileData()
                            this.setState({
                                buttonText: "Update Profile Info",
                                buttonDisabled: false
                            })
                        } else {
                            NotificationManager.error(response.data["message"], "Error!");
                            this.setState({
                                buttonText: "Update Profile Info",
                                buttonDisabled: false
                            })
                        }
                    })
                    .catch((error) => {
                        NotificationManager.error(error, "Error!");
                        this.setState({
                            buttonText: "Update Profile Info",
                            buttonDisabled: false
                        })
                    });
            } else {
                NotificationManager.error(
                    "Please see if the required fields are entered",
                    "Error",
                    3000
                );
            }
        }, 200)
    }
    render() {
        const { profileFormErrors } = this.state;
        return (
            <form onSubmit={this.submitProfileForm}>
                <div className="row clearfix">
                    <div className="col-xs-12 ol-sm-12 col-md-12 col-lg-12">
                        <div
                            className="panel-group"
                            id="accordion_17"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne_17"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#accordion_17"
                                            href="#collapseOne_17"
                                            aria-expanded="false"
                                            aria-controls="collapseOne_17"
                                            className="collapsed"
                                        >
                                            Profile{" "}
                                            <i className="material-icons float-right">
                                                arrow_drop_down
                                      </i>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="collapseOne_17"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="headingOne_17"
                                    aria-expanded="false"
                                    style={{ height: "0px" }}
                                >
                                    <div className="panel-body aniimated-thumbnials">
                                        <div className="card">
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>First Name*</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userFirstName} className="form-control" name="userFirstName"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userFirstName.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userFirstName}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>Last Name*</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userLastName} className="form-control" name="userLastName"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userLastName.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userLastName}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>Mobile Number*</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userMobileNo} className="form-control" name="userMobileNo"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userMobileNo.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userMobileNo}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>Email ID*</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userEmailId} className="form-control" name="userEmailId"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userEmailId.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userEmailId}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-group upload_img_one mb-0">
                                                            {!this.state.userProfileImageUrl ? (
                                                                <div className="form-group">
                                                                    <b>Upload your profile picture</b>
                                                                    <div className="form-line">
                                                                        <input type="file" accept=".jpg, .jpeg, .png, .gif" className="form-control" name="userProfileImage"
                                                                            onChange={this.onProfileImageChange} />
                                                                    </div>
                                                                    {this.state.profileImgError.length > 0 && (
                                                                        <span className="text-danger">{this.state.profileImgError}</span>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                    <div>
                                                                        <b>Profile picture*</b>
                                                                        <div>
                                                                            <a
                                                                                href={
                                                                                    this.state.userProfileImageUrl
                                                                                }
                                                                                data-sub-html="Profile Image"
                                                                                target="_blank"
                                                                            >
                                                                                <img
                                                                                    className="img-responsive thumbnail"
                                                                                    src={
                                                                                        this.state
                                                                                            .userProfileImageUrl
                                                                                    }
                                                                                />
                                                                            </a>
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: -5,
                                                                                right: 0,
                                                                                height: "20px",
                                                                                width: "20px",
                                                                                backgroundColor: 'red',
                                                                                borderRadius: "20px",
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                cursor: "pointer"
                                                                            }} onClick={() => this.setState({
                                                                                userProfileImageUrl: "",
                                                                                profileImgError: "Profile image is required"
                                                                            })}>
                                                                                <i className="material-icons" style={{
                                                                                    color: 'white',
                                                                                    fontSize: '13px',
                                                                                    marginLeft: '3px',
                                                                                    marginTop: '2px',
                                                                                }}>clear</i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingTwo_17"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            className="collapsed"
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#accordion_17"
                                            href="#collapseTwo_17"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo_17"
                                        >
                                            Address
                                      <i className="material-icons float-right">
                                                arrow_drop_down
                                      </i>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="collapseTwo_17"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="headingTwo_17"
                                    aria-expanded="false"
                                    style={{ height: "0px" }}
                                >
                                    <div className="panel-body">
                                        <div className="card">
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>Pincode</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userPincode} className="form-control" name="userPincode"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userPincode.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userPincode}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>City</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userCity} className="form-control" name="userCity"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userCity.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userCity}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <b>State</b>
                                                            <div className="form-line">
                                                                <input type="text" defaultValue={this.state.userState} className="form-control" name="userState"
                                                                    onChange={this.handleProfileInputChange} onBlur={this.handleProfileInputChange} />
                                                            </div>
                                                            {profileFormErrors.userState.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.userState}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
												
												<div className="row clearfix">
                                                    <div className="col-md-12">
														<div className="table-responsive">
															<table className="table table-bordered table-striped table-hover">
																<thead>
																	<tr>
																		<th>#</th>
																		<th>Type</th>
																		<th>Latitude</th>
																		<th>Longitude</th>
																		<th>Location</th>
																		<th>Address line1</th>
																		<th>Address line2</th>
																		<th>City</th>
																		<th>Pincode</th>
																	</tr>
																</thead>
																{this.state.userLocation.length ? (
																  <tbody>
																	{this.state.userLocation.map((loc, i) => {
																	  return (
																		<tr key={i}>
																		  <td
																			className="align-middle text-center"
																			width="2%"
																		  >
																			{i + 1}
																		  </td>
																		  <td>{loc.type}</td>
																		  <td>{loc.latitude}</td>
																		  <td>{loc.longitude}</td>
																		  <td>{loc.location}</td>
																		  <td>{loc.address_line1}</td>
																		  <td>{loc.address_line2}</td>
																		  <td>{loc.city}</td>
																		  <td>{loc.pincode}</td>
																		</tr>
																	  );
																	})}
																  </tbody>
																) : (
																  <tbody>
																	<tr>
																	  <td colSpan="9">No Records Found</td>
																	</tr>
																  </tbody>
																)}
															</table>
														</div>
                                                    </div>
												</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
				{/*  Admin and Ops menu */}
				{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
				<button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
				) : null}
                
				{/*  Admin and Ops menu */}
				{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
				<button type="button" disabled={this.state.buttonDisabled} className="btn" style={{ marginLeft: "10px" }} data-toggle="modal"
                    data-target="#myModalDinerPasswordChange">{this.state.passwordButtonText}</button>
				) : null}
                
            </form>
        )
    }
}