import React, { Component } from "react";
import Header from "../../components/Header";
import { addCityAPI } from "../../core/services/APIServices";
import Geocode from "react-geocode";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import cities from "../../config/cities";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class AddCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tag: "",
      cityName: "",
      country: "India",
      state: "",
      pincode: "",
      latitude: "",
      longitude: "",
      primaryType: "",
      secondaryType: "",
      image: null,
      isPublished: false,
      formErrors: {
        cityName: "",
        country: "",
        state: "",
        pincode: "",
        latitude: "",
        longitude: "",
      },
      imageErr: "",
      buttonText: "Submit",
      buttonDisabled: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "cityName":
        if (value) {
          Geocode.setApiKey(config.placesKey);
          Geocode.fromAddress(value).then(
            (response) => {
              /* console.log(response.results[0].address_components); */
              const { lat, lng } = response.results[0].geometry.location;
              this.setState({
                latitude: lat,
                longitude: lng,
              });
              formErrors.latitude = "";
              formErrors.longitude = "";
              var stateCnt = 0;
              var pinCnt = 0;
              response.results[0].address_components.forEach((item) => {
                item.types.forEach((type) => {
                  if (type === "administrative_area_level_1") {
                    if (item.long_name) {
                      formErrors.state = "";
                    }
                    this.setState({
                      state: item.long_name,
                    });
                    stateCnt++;
                  }
                  if (type === "postal_code") {
                    if (item.long_name) {
                      formErrors.pincode = "";
                    }
                    this.setState({
                      pincode: item.long_name,
                    });
                    pinCnt++;
                  }
                });
              });
              if (stateCnt === 0) {
                this.setState({
                  state: "",
                });
              }
              if (pinCnt === 0) {
                this.setState({
                  pincode: "",
                });
              }
            },
            (error) => {
              console.error(error);
            }
          );
        } else {
          this.setState({
            state: "",
            pincode: "",
            latitude: "",
            longitude: "",
          });
          formErrors.state = "State is required";
          formErrors.pincode = "Pincode is required";
          formErrors.latitude = "Latitude is required";
          formErrors.longitude = "Longitude is required";
        }
        formErrors.cityName = value.length <= 0 ? "City name is required" : "";
        break;

      case "country":
        formErrors.country =
          value.length <= 0
            ? "Country is required"
            : !value.match(/^[a-zA-Z ]+$/)
            ? "Enter valid country name"
            : "";
        break;

      case "state":
        formErrors.state =
          value.length <= 0
            ? "State is required"
            : !value.match(/^[a-zA-Z ]+$/)
            ? "Enter valid state name"
            : "";
        break;

      case "pincode":
        formErrors.pincode =
          value.length <= 0
            ? "Pincode is required"
            : !value.match(/^[1-9][0-9]{5}$/)
            ? "Enter valid pincode"
            : "";
        break;

      case "latitude":
        formErrors.latitude = value.length <= 0 ? "Latitude is required" : "";
        break;

      case "longitude":
        formErrors.longitude = value.length <= 0 ? "Longitude is required" : "";
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleCheckboxInput = (e) => {
    this.setState({
      isPublished: e.target.checked,
    });
  };

  onImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //     image: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes("jpg") ||
        e.target.files[0].type.includes("jpeg") ||
        e.target.files[0].type.includes("png") ||
        e.target.files[0].type.includes("svg") ||
        e.target.files[0].type.includes("gif")
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            imageErr: "The allowed maximum upload size is 64 MB",
          });
          document.getElementById("uploadFile").value = "";
        } else {
          this.setState({
            imageErr: "",
            image: e.target.files[0],
          });
        }
      } else {
        this.setState({
          imageErr:
            "Select valid image. Allowed format are .jpg, .jpeg, .png, .gif and .svg",
        });
        document.getElementById("uploadFile").value = "";
      }
    } else {
      this.setState({
        image: null,
        imageErr: "Image is required",
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const {
      cityName,
      country,
      state,
      pincode,
      latitude,
      longitude,
      image,
      primaryDelivery,
      secondaryDelivery,
    } = this.state;
    if (cityName === "") {
      formErrors.cityName = "City name is required";
    }
    if (country === "") {
      formErrors.country = "Country is required";
    }
    if (state === "") {
      formErrors.state = "State is required";
    }
    if (pincode === "") {
      formErrors.pincode = "Pincode is required";
    }
    if (latitude === "") {
      formErrors.latitude = "Latitude is required";
    }
    if (longitude === "") {
      formErrors.longitude = "Longitude is required";
    }
    if (image === null) {
      this.state.imageErr = "Image is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state) && this.state.imageErr === "") {
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      let formData = {
        cityName: this.state.cityName,
        state: this.state.state,
        pincode: this.state.pincode,
        country: this.state.country,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        image: this.state.image,
        is_published: this.state.isPublished ? 1 : 0,
        primary_delivery_type: this.state.primaryType,
        secondary_delivery_type: this.state.secondaryType,
        region: this.state.tags.toString(),
      };
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });
      return addCityAPI(id, token, formData)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "City added successfully",
              "Success",
              3000
            );
            this.setState({
              buttonText: "Submit",
            });
            setTimeout(() => {
              this.props.history.push("/city-list");
            }, 2000);
          } else {
            NotificationManager.error(response.data["message"], "Error!");
            this.setState({
              buttonText: "Submit",
              buttonDisabled: false,
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!");
          this.setState({
            buttonText: "Submit",
            buttonDisabled: false,
          });
        });
    } else {
      console.log("error");
    }
  };

  handleChange = (tags) => {
    this.setState({ tags }, () => {
      console.log(this.state.tags);
    });
  };

  

  render() {
    const { formErrors } = this.state;
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Add City</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row">
                      <div className="col-md-8">
                        <form onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            <b>City Name*</b>
                            <div className="form-line">
                              {/* <input autoComplete="new-password" type="text" className="form-control" name="cityName"
                                                                onChange={this.handleInputChange} /> */}

                              <select
                                className="form-control"
                                name="cityName"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select City</option>
                                {cities.map((item, i) => {
                                  return (
                                    <option value={item} key={"a" + i}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {formErrors.cityName.length > 0 && (
                              <span className="text-danger">
                                {formErrors.cityName}
                              </span>
                            )}
                          </div>

                          <div className="form-group ">
                            <div className="form-line">
                              <TagsInput
                                inputProps={{ placeholder: 'Add Region' }}
                                value={this.state.tags}
                                onChange={this.handleChange}
                                placeholder="Add Region"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <b>State*</b>
                            <div className="form-line">
                              <input
                                type="text"
                                defaultValue={this.state.state}
                                className="form-control"
                                name="state"
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.state.length > 0 && (
                              <span className="text-danger">
                                {formErrors.state}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>Country*</b>
                            <div className="form-line">
                              <input
                                disabled={true}
                                defaultValue="India"
                                type="text"
                                className="form-control"
                                name="country"
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.country.length > 0 && (
                              <span className="text-danger">
                                {formErrors.country}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>Pincode*</b>
                            <div className="form-line">
                              <input
                                type="text"
                                defaultValue={this.state.pincode}
                                className="form-control"
                                name="pincode"
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.pincode.length > 0 && (
                              <span className="text-danger">
                                {formErrors.pincode}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>Latitude*</b>
                            <div className="form-line">
                              <input
                                disabled={true}
                                defaultValue={this.state.latitude}
                                type="text"
                                className="form-control"
                                name="latitude"
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.latitude.length > 0 && (
                              <span className="text-danger">
                                {formErrors.latitude}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>Longitude*</b>
                            <div className="form-line">
                              <input
                                disabled={true}
                                defaultValue={this.state.longitude}
                                type="text"
                                className="form-control"
                                name="longitude"
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.longitude.length > 0 && (
                              <span className="text-danger">
                                {formErrors.longitude}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>City Image*</b>
                            <div className="form-line">
                              <input
                                id="uploadFile"
                                type="file"
                                accept=".jpg, .jpeg, .png, .svg, .gif"
                                className="form-control"
                                name="image"
                                onChange={this.onImageChange}
                              />
                            </div>
                            {this.state.imageErr.length > 0 && (
                              <span className="text-danger">
                                {this.state.imageErr}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>Primary Delivery</b>
                            <div className="form-line">
                              <select
                                className="form-control"
                                name="primaryType"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputChange}
                              >
                                <option value="">
                                  Select Primary Delivery
                                </option>
                                <option
                                  value="Dunzo"
                                  selected={this.state.primaryType == "Dunzo"}
                                >
                                  Dunzo
                                </option>
                                <option
                                  value="WeFast"
                                  selected={this.state.primaryType == "WeFast"}
                                >
                                  WeFast
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <b>Secondary Delivery</b>
                            <div className="form-line">
                              <select
                                className="form-control"
                                name="secondaryType"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputChange}
                              >
                                <option value="">
                                  Select Secondary Delivery
                                </option>
                                <option
                                  value="Dunzo"
                                  selected={this.state.secondaryType == "Dunzo"}
                                >
                                  Dunzo
                                </option>
                                <option
                                  value="Wefast"
                                  selected={
                                    this.state.secondaryType == "Wefast"
                                  }
                                >
                                  Wefast
                                </option>
                              </select>
                            </div>
                          </div>
                          <hr />
                          <label>{this.state.isPublished}</label>
                          <div className="form-group">
                            <div style={{ marginLeft: "20px" }}>
                              <label htmlFor="isPublished">Is published?</label>
                              <input
                                type="checkbox"
                                id="isPublished"
                                name="isPublished"
                                className="filled-in"
                                onChange={this.handleCheckboxInput}
                                checked={this.state.isPublished}
                              />
                            </div>
                          </div>
                          <Link
                            to="/city-list"
                            className="btn btn-default waves-effect"
                            style={{ color: "#000", marginRight: "10px" }}
                          >
                            Cancel
                          </Link>
                          <button
                            type="submit"
                            disabled={this.state.buttonDisabled}
                            className="btn"
                          >
                            {this.state.buttonText}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
