/*
#############################################################################
# Name : userInfo.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display the chef detail information added from the frontend
#############################################################################
*/
import React from 'react';
import Header from '../../components/Header';
import SideBarSection from '../../components/Sidebar';
import { NotificationManager } from 'react-notifications';
import Pagination from 'react-js-pagination';
import { Link, Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import {
  getUserKitchenDetailAPI,
  getMenuDocumentsAPI,
  getKitchenMenuListAPI,
  getMenuDetailsAPI,
  getKitchenMenuAPI,
  getKycDetailsAPI,
  submitKycDetailsAPI,
  getMenuCategoriesAPI,
  getKitchenMatListAPI,
  storeKitchenChefMenuAPI,
  getChefMenuListAPI,
  getChefMenuDataAPI,
  updateKitchenChefMenuAPI,
  uploadMenuCSVAPI,
  deleteMenuItemAPI,
  updateUserPasswordAPI,
  getFeaturedImageListAPI,
  deleteFeaturedImageAPI,
  addFeaturedImageAPI,
  getFeaturedImageDataAPI,
  updateFeaturedImageAPI,
  getTagsListAPI,
  deleteMenuImageAPI,
  getChefMembershipLog,
  getAuditImageDataAPI,
  addAuditImageAPI,
  deleteAuditImageAPI,
  getUserBusinessDetailAPI,
} from '../../core/services/APIServices';
import ProfileTab from './ProfileTab';
import BusinessTab from './BusinessTab';
import KitchenTab from './KitchenTab';
import FeaturedImageTab from './FeaturedImageTab';
import Loader from 'react-loader-spinner';

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Object.values(formErrors).forEach((val) => {
  //   val === "form-control" || (valid = false);
  // });

  return valid;
};

class UserInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: '',
      userEmailID: '',
      fName: '',
      lName: '',
      isLoading: true,
      userID: '',
      chefID: '',
      menuListingItems: [],
      uploadDocumentItems: [],
      excelItems: [],
      menuItemCategory: '',
      menuItemName: '',
      menuItemDescription: '',
      menuItemType: '',
      menuStandardQuantity: '',
      menuQuantityUnit: '',
      menuItemPrice: '',
      menuAdvanceNotice: '',
      menuUploadPhoto: [],
      menuItemContain: [],
      menuFestiveTags: [],
      jainSelected: '',
      pureSelected: '',
      nonVegSelected: '',
      vegSelected: '',
      maximumPeopleCarter: '',
      telephoneNo: '',
      chefMembershipType: 'basic',
      chefVerified: '0',
      chefPromoted: '0',
      verifyMou: '0',
      verifyChefDetails: '0',
      verifyKyc: '0',
      buttonText: 'Submit',
      buttonDisabled: false,
      buttonAuditDisabled: false,
      isPublished: false,
      commissionRate: '0.00',
      menuCategories: [],
      kitchenMatArr: [],
      menuMatArr: [],
      cookingMatArr: [],
      tagsArr: [],
      menuCategory: '',
      menuCategoryName: '',
      menuName: '',
      menuDescription: '',
      menuType: '',
      menuQuantityMeasure: 'kg',
      menuQuantity: '',
      menuPrice: '',
      menuNotice: '',
      menuImage: '',
      menuItemContainsMaterial: [],
      formErrors: {
        menuCategory: '',
        menuName: '',
        menuDescription: '',
        menuType: '',
        menuNotice: '',
        menuQuantity: '',
        menuPrice: ''
      },
      isMenuEnabled: true,
      isMenuSpecial: false,
      menuImageErr: '',
      chefMenuListItems: [],
      activePage: 1,
      totalRecords: 10,
      activeFeaturePage: 1,
      totalFeatureRecords: 10,
      isLoadingMenuTable: true,
      menuModalTitle: '',
      menuImageUrl: '',
      editMenuId: '',
      menuCSV: '',
      menuCSVErr: '',
      csvUploadErrors: [],
      menuItemNone: '',
      menuContainError: '',
      userPassword: '',
      userConfirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      featuredImageList: [],
      auditImageList: [],
      activeImagePage: 1,
      totalImageRecords: 10,
      totalAuditImageRecords: 10,
      featuredImagePopupTitle: 'Add Featured Image',
      featuredImage: null,
      imageAudit: null,
      auditImageArr: [],
      xOrder: '',
      featuredImageErr: '',
      auditImageErr: '',
      orderErr: '',
      editFeaturedImageId: '',
      featuredImageUrl: '',
      isDataLoaded: false,
      formStartDate: '',
      formEndDate: '',
      currentMembershipTablePage: 1,
	  checksamedaydelivery: '',
      sameDayDelivery: '',
      packagess: 'Registration Package',
      joinDate: '',
      expireDate: '',
      paymentDate: '',
      paymentdateerror: '',
      paidAmount: '',
      paidamounterror: '',
      paymentRemark: '',
      paymentremarkerror: '',
      membershipLogs: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  getChefMembershipLog = () => {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefMainID');
    var token = localStorage.getItem('accessToken');
    getChefMembershipLog(
      userId,
      chefId,
      token,
      this.state.currentMembershipTablePage
    )
      .then((response) => {
        this.setState({
          membershipLogs: response.data.data
        });
      })
      .catch((error) => {});
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'menuQuantityMeasure') {
      this.setState({});
    }
    this.setState({ [name]: value });
  };

  handleCheckboxInput = (e) => {
    this.setState({
      isPublished: e.target.checked
    });
  };

  handleStartDateChange(event) {
    this.setState({
      formStartDate: event,
      formatStartDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleEndDateChange(event) {
    this.setState({
      formEndDate: event,
      formatEndDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  kycRadioBtnChanged = (e, type) => {
    switch (type) {
      case 'memberType':
        if (e.target.value == 'premium') {
          this.setState({
            commissionRate: '0.00'
          });
        } else if (e.target.value == 'vip') {
          this.setState({
            commissionRate: '0.00'
          });
        } else {
          this.setState({
            commissionRate: '0.00'
          });
        }
        this.setState({
          chefMembershipType: e.currentTarget.value
        });
        break;

      case 'verified':
        this.setState({
          chefVerified: e.currentTarget.value
        });
        break;

      case 'promotion':
        this.setState({
          chefPromoted: e.currentTarget.value
        });
        break;

      case 'mou':
        this.setState({
          verifyMou: e.currentTarget.value
        });
        break;

      case 'details':
        this.setState({
          verifyChefDetails: e.currentTarget.value
        });
        break;

      case 'kyc':
        this.setState({
          verifyKyc: e.currentTarget.value
        });
        break;
    }
  };

  submitKycDetails = () => {
    this.setState({
      paymentdateerror: '',
      paymentremarkerror: '',
      paidamounterror: '',
    });
	
	// check validation Error
	if(this.state.isPublished == '1'){
		
		if(this.state.paymentDate == ""){
			this.setState({
				 paymentdateerror: "Please select payment date."
			});
			return false;
		}
		if(this.state.paidAmount == "" || this.state.paidAmount <= 0){
			this.setState({
				 paidamounterror: "Please enter valid paid amount."
			});
			return false;
		}
		if(this.state.paymentRemark == ""){
			this.setState({
				 paymentremarkerror: "Please enter payment remark."
			});
			return false;
		}
	}
	
	this.setState({
      buttonText: 'Submitting...',
      buttonDisabled: true
    });
	
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    let data = {
      membership_assignment: this.state.chefMembershipType,
      verified: this.state.chefVerified,
      promoted: this.state.chefPromoted,
      verify_mou_order: this.state.verifyMou,
      verify_chef_details_menu_order: this.state.verifyChefDetails,
      kyc_verification_completed: this.state.verifyKyc,
      commission_rate: this.state.commissionRate,
      is_published: this.state.isPublished ? 1 : 0,
	  packagess: this.state.packagess,
	  join_date: this.state.joinDate,
	  expire_date: this.state.expireDate,
	  payment_date: this.state.paymentDate,
	  paid_amount: this.state.paidAmount,
	  payment_remark: this.state.paymentRemark,
    };
    return submitKycDetailsAPI(userId, chefId, token, data).then((response) => {
      if (response.data['status'] === 200) {
        this.setState({
          buttonText: 'Submit',
          buttonDisabled: false
        });
        NotificationManager.success(
          'Kyc details submitted successfully',
          'Success',
          3000
        );
        this.getKycDetailsAPI();
      }else {
		this.setState({
          buttonText: 'Submit',
          buttonDisabled: false
        });
		NotificationManager.error(response.data['error'], 'Error!');
	  }
    });
  };

  componentDidMount() {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var name = localStorage.getItem('userName');
    var email = localStorage.getItem('userEmail');
	var role = localStorage.getItem('userRole');
    this.setState({
      userID: userId,
	  userRole: role,
      chefID: chefId,
      userName: name,
      userEmailID: email
    });
    this.getMenuListData();
    this.getKycDetailsAPI();
    this.getMenuCategory();
    this.getKitchenMatCategory();
    this.getChefMenuList();
    this.getFeaturedImageListAPI();
    this.getAuditImageListAPI();
    this.getTagsListAPI();
    this.getChefMembershipLog();
	this.getBusinessData();
  }

  async  getBusinessData() {
    var userId = localStorage.getItem("userID");
    var chefId = localStorage.getItem("chefID");
    var token = localStorage.getItem("accessToken");
    return await  getUserBusinessDetailAPI(userId, chefId, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            checksamedaydelivery:response.data["data"]["is_same_day_delivery"],
            sameDayDelivery:response.data["data"]["is_all_items_same_day_delivery"],
          });
        }
		this.setState(
		  {
			menuModalTitle: 'Add Menu Item',
			menuCategory: '',
			isDataLoaded: true,
			isMenuEnabled: true,
			isMenuSpecial: false,
			menuName: '',
			formStartDate: '',
			formEndDate: '',
			menuDescription: '',
			menuType: '',
			menuQuantityMeasure: 'kg',
			menuQuantity: '',
			menuPrice: '',
			menuNotice: '',
			menuImage: '',
			menuItemContainsMaterial: [],
			menuFestiveTags: [],
			formErrors: {
			  menuCategory: '',
			  menuName: '',
			  menuDescription: '',
			  menuType: '',
			  menuQuantity: '',
			  menuNotice: '',
			  menuPrice: '',
			  menuImage: ''
			},
			menuImageUrl: '',
			editMenuId: '',
			menuItemNone: '0',
			menuContainError: ''
		  },
		  () => {
			document
			  .getElementById('chefMenuForm')
			  .reset();
		  }
		)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  getFeaturedImageData = (imageId) => {
    this.setState({
      featuredImagePopupTitle: 'Edit Featured Image',
      featuredImage: null,
      featuredImageUrl: '',
      xOrder: '',
      featuredImageErr: '',
      orderErr: ''
    });
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return getFeaturedImageDataAPI(userId, chefId, token, imageId)
      .then((response) => {
        if (response.data['status'] == 200) {
          this.setState({
            editFeaturedImageId: response.data.data.id,
            featuredImageUrl: response.data.data.image,
            xOrder: response.data.data.x_order
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getFeaturedImageListAPI() {
    var id = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activeImagePage;
    return getFeaturedImageListAPI(id, chefId, currentPage, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            featuredImageList: response.data['data'].data,
            totalImageRecords: response.data['data']['pager_count'],
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
        NotificationManager.error('Something went wrong', 'Error!');
      });
  }

  getAuditImageListAPI() {
    var id = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activeImagePage;
    return getAuditImageDataAPI(id, chefId, currentPage, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          console.log(response.data.data);
          this.setState({
            auditImageList: response.data.data,
            totalAuditImageRecords: response.data['pager_count'],
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
        NotificationManager.error('Something went wrong', 'Error!');
      });
  }

  handleImagePagination(pageNumber) {
    this.setState({ activeImagePage: pageNumber, isLoading: true }, () => {
      this.getFeaturedImageListAPI();
    });
  }

  handleAuditImagePagination(pageNumber) {
    this.setState({ activeImagePage: pageNumber, isLoading: true }, () => {
      this.getAuditImageListAPI();
    });
  }

  handleFeaturedImageDelete = (imageId) => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    return deleteFeaturedImageAPI(id, token, imageId)
      .then((response) => {
        if (response.data['status'] === 200) {
          NotificationManager.success(
            'Featured image deleted successfully',
            'Success'
          );
          this.getFeaturedImageListAPI();
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error('Something went wrong', 'Error!');
      });
  };

  deleteFeaturedImage = (imageId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui text-center'>
            <h2 style={{ marginTop: '20px' }}>Are you sure?</h2>
            <p style={{ marginTop: '20px', marginBottom: '40px' }}>
              You want to delete this featured image?
            </p>
            <button
              className='btn btn-default waves-effect'
              style={{ color: '#000', marginRight: '10px' }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className='btn'
              onClick={() => {
                this.handleFeaturedImageDelete(imageId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  };

  handleAuditImageDelete = (imageId) => {
    this.setState({
      isLoading: true
    });
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    return deleteAuditImageAPI(id, token, imageId)
      .then((response) => {
        if (response.data['status'] === 200) {
          NotificationManager.success(
            'Audit image deleted successfully',
            'Success'
          );
          this.setState({
            isLoading: false
          });
          this.getAuditImageListAPI();
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error('Something went wrong', 'Error!');
      });
  };

  deleteAuditImage = (imageId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui text-center'>
            <h2 style={{ marginTop: '20px' }}>Are you sure?</h2>
            <p style={{ marginTop: '20px', marginBottom: '40px' }}>
              You want to delete this audit image?
            </p>
            <button
              className='btn btn-default waves-effect'
              style={{ color: '#000', marginRight: '10px' }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className='btn'
              onClick={() => {
                this.handleAuditImageDelete(imageId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  };

  onFeaturedImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //     image: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes('jpg') ||
        e.target.files[0].type.includes('jpeg') ||
        e.target.files[0].type.includes('png') ||
        e.target.files[0].type.includes('svg')
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            featuredImageErr: 'The allowed maximum upload size is 64 MB'
          });
          document.getElementById('uploadFile').value = '';
        } else {
          this.setState({
            featuredImageErr: '',
            featuredImage: e.target.files[0]
          });
        }
      } else {
        this.setState({
          featuredImageErr:
            'Select valid image. Allowed format are .jpg, .jpeg, .png and .svg'
        });
        document.getElementById('uploadFile').value = '';
      }
    } else {
      this.setState({
        image: null,
        featuredImageErr: 'Image is required'
      });
    }
  };

  onAuditImageChange = (e) => {
    if (e.target.files[0]) {
      // this.setState({
      //     image: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes('jpg') ||
        e.target.files[0].type.includes('jpeg') ||
        e.target.files[0].type.includes('png') ||
        e.target.files[0].type.includes('svg')
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            auditImageErr: 'The allowed maximum upload size is 64 MB'
          });
          document.getElementById('uploadFile').value = '';
        } else {
          this.setState({
            auditImageErr: ''
          });
          this.state.auditImageArr.push(...e.target.files);
          document.getElementById('uploadFile').value = '';
        }
      } else {
        this.setState({
          auditImageErr:
            'Select valid image. Allowed format are .jpg, .jpeg, .png and .svg'
        });
        document.getElementById('uploadFile').value = '';
      }
    } else {
      this.setState({
        imageAudit: null,
        auditImageErr: 'Image is required'
      });
    }
  };

  resetFile = () => {
    const file = document.querySelector('.imageAudit');
    file.value = '';
    this.setState({
      auditImageArr: [],
      imageAudit: null
    });
  };

  handleAuditImageSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { auditImageArr } = this.state;
    if (auditImageArr.length === 0) {
      this.state.auditImageErr = 'Image is required';
    }
    this.setState({ formErrors, [name]: value });
    if (this.state.auditImageErr === '') {
      this.setState({
        buttonAuditDisabled: true
      });
      console.log(this.state.auditImageArr);
      var id = localStorage.getItem('userID');
      var chefId = localStorage.getItem('chefID');
      var token = localStorage.getItem('accessToken');
      let formData = {
        auditImage: this.state.auditImageArr
      };
      return addAuditImageAPI(id, chefId, token, formData)
        .then((response) => {
          if (response.data['status'] === 200) {
            NotificationManager.success(
              'Audit image uploaded successfully',
              'Success',
              3000
            );
            this.setState(
              {
                buttonAuditDisabled: false,
                isLoading: true,
                auditImageArr: [],
                imageAudit: null
              },
              () => {
                document.getElementById('uploadFile').value = '';
                document.getElementById('closeAuditImgPopupButton').click();
              }
            );
            this.getAuditImageListAPI();
          } else {
            NotificationManager.error(
              'Failed to upload Audit Image',
              'Error',
              1000
            );
            this.setState(
              {
                buttonAuditDisabled: false
              },
              () => {
                document.getElementById('uploadFile').value = '';
                document.getElementById('closeAuditImgPopupButton').click();
              }
            );
          }
        })
        .catch((error) => {
          console.log('Error');
          this.setState(
            {
              buttonAuditDisabled: false
            },
            () => {
              document.getElementById('uploadFile').value = '';
              document.getElementById('closeAuditImgPopupButton').click();
            }
          );
        });
    }
  };

  handleFeaturedImageSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { featuredImage } = this.state;
    if (featuredImage === null && this.state.featuredImageUrl === '') {
      this.state.featuredImageErr = 'Image is required';
    }
    this.setState({ formErrors, [name]: value });
    if (this.state.featuredImageErr === '') {
      var id = localStorage.getItem('userID');
      var chefId = localStorage.getItem('chefID');
      var token = localStorage.getItem('accessToken');
      this.setState({
        buttonText: 'Submitting...',
        buttonDisabled: true
      });
      if (this.state.editFeaturedImageId) {
        let formData = {
          image_id: this.state.editFeaturedImageId,
          featuredImage: this.state.featuredImage,
          x_order: this.state.xOrder
        };
        return updateFeaturedImageAPI(id, chefId, token, formData)
          .then((response) => {
            if (response.data['status'] === 200) {
              NotificationManager.success(
                'Featured image edited successfully',
                'Success',
                3000
              );
              this.setState(
                {
                  buttonText: 'Submit',
                  buttonDisabled: false,
                  isLoading: true
                },
                () => {
                  document
                    .getElementById('closeFeaturedImgPopupButton')
                    .click();
                }
              );
              this.getFeaturedImageListAPI();
            } else {
              NotificationManager.error(response.data['message'], 'Error!');
              this.setState({
                buttonText: 'Submit',
                buttonDisabled: false
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
          });
      } else {
        let formData = {
          featuredImage: this.state.featuredImage,
          x_order: this.state.xOrder
        };
        return addFeaturedImageAPI(id, chefId, token, formData)
          .then((response) => {
            if (response.data['status'] === 200) {
              NotificationManager.success(
                'Featured image added successfully',
                'Success',
                3000
              );
              this.setState(
                {
                  buttonText: 'Submit',
                  buttonDisabled: false,
                  isLoading: true
                },
                () => {
                  document
                    .getElementById('closeFeaturedImgPopupButton')
                    .click();
                }
              );
              this.getFeaturedImageListAPI();
            } else {
              NotificationManager.error(response.data['message'], 'Error!');
              this.setState({
                buttonText: 'Submit',
                buttonDisabled: false
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
          });
      }
    } else {
      console.log('error');
    }
  };
  checkOrder = (e) => {
    if (e.target.value <= 0 && e.target.value !== '') {
      this.setState({
        orderErr: 'Order should be greater than 0'
      });
    } else {
      this.setState({
        xOrder: e.target.value,
        orderErr: ''
      });
    }
  };

  handleUserdata = (dataValue) => {
    this.setState({
      fName: dataValue.fName,
      lName: dataValue.lName
    });
  };

  getChefMenuData(menuId) {
    document.getElementById('chefMenuForm').reset();
    this.setState({
      menuModalTitle: 'Edit Menu Item',
      menuCategory: '',
      isMenuEnabled: true,
      isMenuSpecial: false,
      isDataLoaded: false,
      menuName: '',
      menuDescription: '',
      menuType: '',
      menuQuantityMeasure: 'kg',
      menuQuantity: '',
      menuQuantityMeasure: 'kg',
      menuPrice: '',
      menuNotice: '',
      menuImage: '',
      menuItemContainsMaterial: [],
      menuFestiveTags: [],
      formErrors: {
        menuCategory: '',
        menuName: '',
        menuDescription: '',
        menuType: '',
        menuQuantity: '',
        menuNotice: '',
        menuPrice: '',
        menuImage: ''
      },
      menuImageUrl: '',
      editMenuId: '',
      menuItemNone: '0',
      menuContainError: '',
      formStartDate: '',
      formEndDate: ''
    });
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getChefMenuDataAPI(userId, chefId, token, menuId)
      .then((response) => {
        if (response.data['status'] == 200) {
          /* console.log(response); */
          var startDate = Moment(response.data.data.disabled_from).format(
            'YYYY-MM-DD HH:mm:ssZ'
          );
          var endDate = Moment(response.data.data.disabled_to).format(
            'YYYY-MM-DD HH:mm:ssZ'
          );
          /* console.log(startDate); */
          if (response.data.data.menu_uses_material.length) {
            let array = [];
            response.data.data.menu_uses_material.forEach((item) => {
              array.push(item.material_id);
            });
            this.setState({
              menuItemContainsMaterial: array
            });
          }
          /* console.log(response.data.data.disabled_from); */
          this.setState({
            editMenuId: response.data.data.id,
            menuCategory: response.data.data.category_id,
            menuCategoryName: response.data.data.category_name,
            menuName: response.data.data.item_name,
            menuDescription: response.data.data.item_description,
            menuType: response.data.data.diet_type,
            menuQuantityMeasure: response.data.data.unit,
            menuQuantity: response.data.data.standard_quantity_or_portion_size,
            menuPrice: response.data.data.item_price,
            menuNotice: response.data.data.advanced_preparation_note,
            menuImageUrl: response.data.data.menu_images.length
              ? response.data.data.menu_images[0].image_path
              : '',
            menuItemNone: response.data.data.menu_item_none,
            isMenuEnabled: response.data.data.enabled == 1 ? true : false,
            isMenuSpecial:
              response.data.data.is_chef_special == 1 ? true : false,
            menuFestiveTags: response.data.data.tags,
            formStartDate: response.data.data.disabled_from,
            formEndDate: response.data.data.disabled_to,
            checksamedaydelivery: response.data.data.check_same_day_delivery,
            sameDayDelivery: response.data.data.is_same_day_delivery,
            isDataLoaded: true
          });
		  
		   if (response.data.data.check_same_day_delivery == '0') {
            this.setState({
              sameDayDelivery: ''
            });
			
			let array = this.state.menuFestiveTags.filter(
				(elm) => elm !== 'Same-day Delivery'
			);
			this.setState({
				menuFestiveTags: array
			});
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getChefMenuList() {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getChefMenuListAPI(userId, chefId, token, currentPage)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            chefMenuListItems: response.data.data.data,
            totalRecords: response.data.data.pager_count
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMenuCategory() {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return getMenuCategoriesAPI(userId, chefId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            menuCategories: response.data.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getKitchenMatCategory() {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return getKitchenMatListAPI(userId, chefId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            menuMatArr: response.data.data.menu_material,
            kitchenMatArr: response.data.data.kitchen_material,
            cookingMatArr: response.data.data.kitchen_cooking_Material
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getTagsListAPI = () => {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return getTagsListAPI(userId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            tagsArr: response.data.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMenuListData() {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return getMenuDocumentsAPI(userId, chefId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          response.data['data']['menu_list'].forEach((item) => {
            if (item.menu.length) {
              item.menu.forEach((menu) => {
                this.state.menuListingItems.push(menu);
              });
            }
          });
          this.setState({
            uploadDocumentItems: response.data['data']['document'],
            excelItems: response.data['data']['excel']
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getKycDetailsAPI = () => {
    var userId = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');

    return getKycDetailsAPI(userId, chefId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          /* console.log(response.data.chef_published.is_published); */
          this.setState({
            chefMembershipType: response.data.data.membership_assignment == undefined ? 'basic' : response.data.data.membership_assignment,
            chefVerified: response.data.data.verified == undefined ? '0' : response.data.data.verified,
            chefPromoted: response.data.data.promoted == undefined ? '0' : response.data.data.promoted,
            verifyMou: response.data.data.verify_mou_order == undefined ? '0' : response.data.data.verify_mou_order,
            verifyChefDetails:
              response.data.data.verify_chef_details_menu_order == undefined ? '0' : response.data.data.verify_chef_details_menu_order,
            verifyKyc: response.data.data.kyc_verification_completed == undefined ? '0' : response.data.data.kyc_verification_completed,
            commissionRate: response.data.data.commission_rate,
            packagess: response.data.data.package,
            joinDate: response.data.data.join_date,
            expireDate: response.data.data.expire_date,
            paymentDate: response.data.data.payment_date,
            paidAmount: response.data.data.paid_amount,
            paymentRemark: response.data.data.payment_remark,
            isPublished:
              response.data.chef_published.is_published == 1 ? true : false
          });
          /*   setTimeout(() => {
              console.log(this.state.isPublished);
            }, 1000); */
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error(error, 'Error!');
      });
  };

  onImageChange = (e) => {
    if (e.target.files[0]) {
      /* console.log(e.target.files[0]); */
      // this.setState({
      //   menuImage: e.target.files[0]
      // })
      if (
        e.target.files[0].type.includes('jpg') ||
        e.target.files[0].type.includes('jpeg') ||
        e.target.files[0].type.includes('png') ||
        e.target.files[0].type.includes('svg')
      ) {
        if (e.target.files[0].size > 67108864) {
          this.setState({
            menuImageErr: 'The allowed maximum upload size is 64 MB'
          });
          document.getElementById('uploadFile').value = '';
        } else {
          this.setState({
            menuImageErr: '',
            menuImage: e.target.files[0]
          });
        }
      } else {
        this.setState({
          menuImageErr:
            'Select valid image. Allowed format are .jpg, .jpeg, .png and .svg'
        });
        document.getElementById('uploadFile').value = '';
      }
    } else {
      if (this.state.menuModalTitle == 'Add Menu Item') {
        this.setState({
          menuImage: null,
          menuImageErr: 'Image is required'
        });
      } else {
        this.setState({
          menuImage: null,
          menuImageErr: ''
        });
      }
    }
  };

  clearPrevImage = () => {
    this.setState({
      menuImageUrl: '',
      menuImageErr: '',
      featuredImageUrl: '',
      featuredImageErr: 'Image is required'
    });
  };

  handleCheckboxInputChange = (e) => {
    if (e.target.checked) {
      let array = this.state.menuItemContainsMaterial;
      array.push(e.target.value);
      this.setState({
        menuItemContainsMaterial: array,
        menuItemNone: '0',
        menuContainError: ''
      });
    } else {
      let array = this.state.menuItemContainsMaterial.filter(
        (elm) => elm !== e.target.value
      );
      this.setState({
        menuItemContainsMaterial: array
      });
    }
    // setTimeout(() => {
    //   console.log(this.state.menuItemContainsMaterial)
    // }, 100)
  };

  handleTagCheckboxInputChange = (e) => {
    if (e.target.checked) {
      let array = this.state.menuFestiveTags;
      array.push(e.target.value);
      this.setState({
        menuFestiveTags: array
      });
    } else {
      let array = this.state.menuFestiveTags.filter(
        (elm) => elm !== e.target.value
      );
      this.setState({
        menuFestiveTags: array
      });
    }
    // setTimeout(() => {
    //   console.log(this.state.menuContainsFestiveTags)
    // }, 100)
  };

  onPasswordChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    switch (name) {
      case 'userPassword':
        this.setState({
          passwordError:
            value.length <= 0
              ? 'Please enter new password'
              : value.length < 6
              ? 'Password must be atleast 6 characters'
              : ''
        });
        break;

      case 'userConfirmPassword':
        this.setState({
          confirmPasswordError:
            value.length <= 0
              ? 'Please confirm new password'
              : value !== this.state.userPassword
              ? 'Password is not matching!'
              : ''
        });
        break;
    }
    this.setState({ [name]: value });
  };
  changePasswordSubmit = (e) => {
    e.preventDefault();
    if (this.state.userPassword === '') {
      this.setState({
        passwordError: 'Please enter new password'
      });
    }
    if (this.state.userConfirmPassword === '') {
      this.setState({
        confirmPasswordError: 'Please confirm new password'
      });
    }
    setTimeout(() => {
      if (
        this.state.passwordError === '' &&
        this.state.confirmPasswordError === ''
      ) {
        this.setState({
          buttonText: 'Submitting...',
          buttonDisabled: true
        });

        var id = localStorage.getItem('userID');
        var chefId = localStorage.getItem('chefID');
        var token = localStorage.getItem('accessToken');
        let data = {
          password: this.state.userPassword,
          password_confirm: this.state.userConfirmPassword
        };
        return updateUserPasswordAPI(id, chefId, token, data)
          .then((response) => {
            if (response.data['status'] === 200) {
              NotificationManager.success(
                'Password changed successfully',
                'Success',
                3000
              );
              document.getElementById('changePasswordForm').reset();
              this.setState({
                userPassword: '',
                userConfirmPassword: '',
                passwordError: '',
                confirmPasswordError: ''
              });
              this.getChefMenuList();
              this.setState(
                {
                  buttonText: 'Submit',
                  buttonDisabled: false
                },
                () => {
                  document.getElementById('closePasswordButton').click();
                }
              );
            } else {
              NotificationManager.error(response.data['message'], 'Error!');
              this.setState({
                buttonText: 'Submit',
                buttonDisabled: false
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
          });
      }
    }, 100);
  };

  checkMenuEnabled = (e) => {
    this.setState({
      isMenuEnabled: e.target.checked
    });
  };

  checkMenuSpecial = (e) => {
    this.setState({
      isMenuSpecial: e.target.checked
    });
  };

  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { menuModalTitle } = this.state;

    switch (name) {
      case 'menuCategory':
        formErrors.menuCategory =
          value.length <= 0 ? 'Category is required' : '';
        break;

      case 'menuName':
        formErrors.menuName =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'Name is required'
            : '';
        // formErrors.menuName = value.length <= 0 ? 'Name is required' : !value.match(/^[a-zA-Z0-9 ]+$/) ? "Enter valid menu name" : "";
        break;

      case 'menuDescription':
        formErrors.menuDescription =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'Description is required'
            : '';
        break;
		
	  case 'sameDayDelivery':
		if(value == '1'){
			let array = this.state.menuFestiveTags;
			array.push('Same-day Delivery');
			this.setState({
				menuFestiveTags: array
			});
		}else{
			let array = this.state.menuFestiveTags.filter(
				(elm) => elm !== 'Same-day Delivery'
			);
			this.setState({
				menuFestiveTags: array
			});
		}

      case 'menuType':
        formErrors.menuType =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'Type is required'
            : '';
        break;

      /*case 'menuNotice':
        formErrors.menuNotice =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'Notice is required'
            : '';
        break;*/

      case 'menuQuantityMeasure':
        this.setState({
          menuQuantity: ''
        });
        formErrors.menuQuantityMeasure =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'Quantity unit is required'
            : '';
        break;

      case 'menuQuantity':
        formErrors.menuQuantity =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'This is required'
            : value.length &&
              !value.match(/^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$/)
            ? 'Enter valid quantity'
            : '';
        break;

      case 'menuPrice':
        formErrors.menuPrice =
          menuModalTitle == 'Add Menu Item' && value.length <= 0
            ? 'Price is required'
            : value.length &&
              !value.match(/^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$/)
            ? 'Enter valid price'
            : '';
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  submitMenuForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const {
      menuModalTitle,
      menuCategory,
      menuName,
      menuDescription,
      menuType,
      menuQuantity,
      menuPrice,
      menuNotice,
      menuImage,
      menuImageUrl
    } = this.state;
    if (menuModalTitle == 'Add Menu Item' && menuCategory === '') {
      formErrors.menuCategory = 'Category is required';
    }
    if (menuModalTitle == 'Add Menu Item' && menuName === '') {
      formErrors.menuName = 'Name is required';
    }
    if (menuModalTitle == 'Add Menu Item' && menuDescription === '') {
      formErrors.menuDescription = 'Description is required';
    }
    if (menuModalTitle == 'Add Menu Item' && menuType === '') {
      formErrors.menuType = 'Type is required';
    }
    if (menuModalTitle == 'Add Menu Item' && menuQuantity === '') {
      formErrors.menuQuantity = 'Quantity is required';
    }
    if (menuModalTitle == 'Add Menu Item' && menuPrice === '') {
      formErrors.menuPrice = 'Price is required';
    }
   /* if (menuModalTitle == 'Add Menu Item' && menuNotice === '') {
      formErrors.menuNotice = 'Notice is required'; 
    }*/
    if (
      menuModalTitle == 'Add Menu Item' &&
      menuImage === '' &&
      menuImageUrl === ''
    ) {
      this.state.menuImageErr = 'Image is required';
    }
    if (
      menuModalTitle == 'Add Menu Item' &&
      !this.state.menuItemContainsMaterial.length &&
      this.state.menuItemNone === '0'
    ) {
      this.state.menuContainError = 'This is required';
    }
    this.setState({ formErrors, [name]: value });
    if (
      formValid(this.state) &&
      this.state.menuImageErr === '' &&
      this.state.menuContainError === ''
    ) {
      this.setState({
        buttonText: 'Submitting...',
        buttonDisabled: true
      });

      var id = localStorage.getItem('userID');
      var chefId = localStorage.getItem('chefID');
      var token = localStorage.getItem('accessToken');
      if (this.state.editMenuId) {
        let formData = {
          menu_id: this.state.editMenuId,
          category_id: this.state.menuCategory,
          item_name: this.state.menuName,
          item_description: this.state.menuDescription,
          diet_type: this.state.menuType,
          advanced_preparation_note: this.state.menuNotice,
          unit: this.state.menuQuantityMeasure,
          standard_quantity_or_portion_size: this.state.menuQuantity,
          item_price: this.state.menuPrice,
          advanced_preparation_note: this.state.menuNotice,
          menu_images: this.state.menuImage,
          menu_uses_material: this.state.menuItemContainsMaterial.toString(),
          menu_item_none: this.state.menuItemNone,
          tags: this.state.menuFestiveTags.toString(),
          enabled: this.state.isMenuEnabled == true ? 1 : 0,
          is_chef_special: this.state.isMenuSpecial == true ? 1 : 0,
          disabled_from: this.state.formStartDate,
          disabled_to: this.state.formEndDate,
          menuImageUrl: this.state.menuImageUrl,
          sameDayDelivery: this.state.sameDayDelivery
        };

        return updateKitchenChefMenuAPI(id, chefId, token, formData)
          .then((response) => {
            if (response.data['status'] === 200) {
              NotificationManager.success(
                'Menu edited successfully',
                'Success',
                3000
              );
              document.getElementById('chefMenuForm').reset();
              this.setState({
                menuCategory: '',
                isMenuEnabled: true,
                isMenuSpecial: false,
                menuName: '',
                formStartDate: '',
                formEndDate: '',
                menuDescription: '',
                menuType: '',
                menuQuantityMeasure: 'kg',
                menuQuantity: '',
                menuPrice: '',
                menuNotice: '',
                menuImage: '',
                menuItemContainsMaterial: [],
                menuFestiveTags: [],
                formErrors: {
                  menuCategory: '',
                  menuName: '',
                  menuDescription: '',
                  menuType: '',
                  menuQuantity: '',
                  menuNotice: '',
                  menuPrice: '',
                  menuImage: ''
                },
                menuImageUrl: '',
                editMenuId: ''
              });
              this.getChefMenuList();
              this.setState(
                {
                  buttonText: 'Submit',
                  buttonDisabled: false
                },
                () => {
                  document.getElementById('closeMenuFormButton').click();
                }
              );
            } else {
              NotificationManager.error(response.data['message'], 'Error!');
              this.setState({
                buttonText: 'Submit',
                buttonDisabled: false
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
          });
      } else {
        let formData = {
          category_id: this.state.menuCategory,
          item_name: this.state.menuName,
          item_description: this.state.menuDescription,
          diet_type: this.state.menuType,
          advanced_preparation_note: this.state.menuNotice,
          unit: this.state.menuQuantityMeasure,
          standard_quantity_or_portion_size: this.state.menuQuantity,
          item_price: this.state.menuPrice,
          advanced_preparation_note: this.state.menuNotice,
          menu_images: this.state.menuImage,
          menu_uses_material: this.state.menuItemContainsMaterial.toString(),
          menu_item_none: this.state.menuItemNone,
          tags: this.state.menuFestiveTags.toString(),
          enabled: this.state.isMenuEnabled == true ? 1 : 0,
          is_chef_special: this.state.isMenuSpecial == true ? 1 : 0,
          disabled_from: this.state.formStartDate,
          disabled_to: this.state.formEndDate,
          menuImageUrl: this.state.menuImageUrl,
          sameDayDelivery: this.state.sameDayDelivery
        };
        return storeKitchenChefMenuAPI(id, chefId, token, formData)
          .then((response) => {
            if (response.data['status'] === 200) {
              NotificationManager.success(
                'Menu added successfully',
                'Success',
                3000
              );
              document.getElementById('chefMenuForm').reset();
              this.setState({
                menuCategory: '',
                isMenuEnabled: true,
                isMenuSpecial: false,
                menuName: '',
                formStartDate: '',
                formEndDate: '',
                menuDescription: '',
                menuType: '',
                menuQuantityMeasure: 'kg',
                menuQuantity: '',
                menuPrice: '',
                menuNotice: '',
                menuImage: '',
                menuItemContainsMaterial: [],
                menuFestiveTags: [],
                formErrors: {
                  menuCategory: '',
                  menuName: '',
                  menuDescription: '',
                  menuType: '',
                  menuQuantity: '',
                  menuNotice: '',
                  menuPrice: '',
                  menuImage: ''
                },
                menuImageUrl: '',
                editMenuId: ''
              });
              document.getElementById('closeMenuFormButton').click();
              this.getChefMenuList();
              this.setState(
                {
                  buttonText: 'Submit',
                  buttonDisabled: false
                },
                () => {
                  document.getElementById('closeMenuFormButton').click();
                }
              );
            } else {
              NotificationManager.error(response.data['message'], 'Error!');
              this.setState({
                buttonText: 'Submit',
                buttonDisabled: false
              });
            }
          })
          .catch((error) => {
            NotificationManager.error(error, 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
          });
      }
    }
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoadingMenuTable: true }, () => {
      this.getChefMenuList();
    });
  }

  menuModalClosed = () => {
    document.getElementById('chefMenuForm').reset();
    document.getElementById('menuCSVForm').reset();
    document.getElementById('changePasswordForm').reset();
    document.getElementById('featuredImageForm').reset();
    this.setState({
      menuCategory: '',
      isMenuEnabled: true,
      isMenuSpecial: false,
      menuName: '',
      formStartDate: '',
      formEndDate: '',
      menuDescription: '',
      menuType: '',
      menuQuantityMeasure: 'kg',
      menuQuantity: '',
      menuPrice: '',
      menuNotice: '',
      menuImage: '',
      menuFestiveTags: [],
      menuItemContainsMaterial: [],
      formErrors: {
        menuCategory: '',
        menuName: '',
        menuDescription: '',
        menuType: '',
        menuQuantity: '',
        menuNotice: '',
        menuPrice: '',
        menuImage: ''
      },
      menuImageUrl: '',
      editMenuId: '',
      menuCSV: '',
      menuCSVErr: '',
      menuImageErr: '',
      csvUploadErrors: [],
      menuItemNone: '0',
      menuContainError: '',
      userPassword: '',
      userConfirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      featuredImage: null,
      imageAudit: null,
      xOrder: '',
      featuredImageErr: '',
      auditImageErr: '',
      orderErr: '',
      editFeaturedImageId: ''
    });
  };

  onCSVChange = (e) => {
    /*  console.log(e.target.files[0]); */
    if (e.target.files[0]) {
      this.setState({
        menuCSV: e.target.files[0]
      });
      if (
        e.target.files[0].type.includes('csv') ||
        e.target.files[0].type.includes('excel')
      ) {
        // if (e.target.files[0].size > 64000) {
        //   this.setState({
        //     menuImageErr: "The allowed maximum upload size is 64 KB"
        //   })
        // } else {
        this.setState({
          menuCSVErr: ''
        });
        // }
      } else {
        this.setState({
          menuCSVErr: 'Select valid csv file.'
        });
      }
    } else {
      this.setState({
        menuCSV: null,
        menuCSVErr: 'CSV file is required'
      });
    }
  };

  csvFormSubmit = (e) => {
    e.preventDefault();

    if (!this.state.menuCSV) {
      this.setState({
        menuCSVErr: 'CSV file is required'
      });
    }
    if (this.state.menuCSV && !this.state.menuCSVErr) {
      this.setState({
        buttonText: 'Submitting...',
        buttonDisabled: true
      });

      var id = localStorage.getItem('userID');
      var chefId = localStorage.getItem('chefID');
      var token = localStorage.getItem('accessToken');

      return uploadMenuCSVAPI(id, chefId, token, this.state.menuCSV)
        .then((response) => {
          if (response.data['status'] === 200) {
            NotificationManager.success(
              'Menu CSV uploaded successfully',
              'Success',
              3000
            );
            document.getElementById('menuCSVForm').reset();
            this.setState({
              menuCSV: '',
              menuCSVErr: ''
            });
            if (response.data.error.length) {
              this.setState({
                csvUploadErrors: response.data.error
              });
            }
            this.getChefMenuList();
            this.setState(
              {
                buttonText: 'Submit',
                buttonDisabled: false
              },
              () => {
                // document.getElementById('closeCSVFormButton').click()
              }
            );
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
            this.setState({
              buttonText: 'Submit',
              buttonDisabled: false
            });
            if (response.data.error.length) {
              this.setState({
                csvUploadErrors: response.data.error
              });
            }
          }
        })
        .catch((error) => {
          NotificationManager.error(error, 'Error!');
          this.setState({
            buttonText: 'Submit',
            buttonDisabled: false
          });
        });
    }
  };

  handleClickDelete = (menuId) => {
    var id = localStorage.getItem('userID');
    var chefId = localStorage.getItem('chefID');
    var token = localStorage.getItem('accessToken');
    return deleteMenuItemAPI(id, chefId, token, menuId)
      .then((response) => {
        if (response.data['status'] === 200) {
          NotificationManager.success(
            'Menu item deleted successfully',
            'Success'
          );
          this.getChefMenuList();
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error('Something went wrong', 'Error!');
      });
  };

  deleteMenuItem = (menuId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui text-center'>
            <h2 style={{ marginTop: '20px' }}>Are you sure?</h2>
            <p style={{ marginTop: '20px', marginBottom: '40px' }}>
              You want to delete this menu item?
            </p>
            <button
              className='btn btn-default waves-effect'
              style={{ color: '#000', marginRight: '10px' }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className='btn'
              onClick={() => {
                this.handleClickDelete(menuId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  };

  deleteMenuImage = (imageId, chefId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui text-center'>
            <h2 style={{ marginTop: '20px' }}>Are you sure?</h2>
            <p style={{ marginTop: '20px', marginBottom: '40px' }}>
              You want to delete this menu image?
            </p>
            <button
              className='btn btn-default waves-effect'
              style={{ color: '#000', marginRight: '10px' }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className='btn'
              onClick={() => {
                this.handleMenuImageDelete(imageId, chefId);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  };

  handleMenuImageDelete = (imageId, chefId) => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    return deleteMenuImageAPI(id, token, imageId, chefId)
      .then((response) => {
        if (response.data['status'] === 200) {
          NotificationManager.success(
            'Featured image deleted successfully',
            'Success'
          );
          this.getMenuListData();
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error('Something went wrong', 'Error!');
      });
  };

  handleNoneCheckbxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        menuItemNone: '1',
        menuItemContainsMaterial: [],
        menuContainError: ''
      });
    } else {
      this.setState({
        menuItemNone: '0'
      });
    }
  };

  ucfirst = (str) => {
    var firstLetter = str.substr(0, 1);
    return firstLetter.toUpperCase() + str.substr(1);
  };

  render() {
    const { formErrors, menuModalTitle } = this.state;
	const { kycFormErrors } = this.state;
    if (!localStorage.getItem('accessToken')) {
      return <Redirect to='/' />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='body'>
                    <div className='text-right'>
                      <h6 className='user_name'>
                        {this.state.fName + ' ' + this.state.lName}
                      </h6>
                    </div>
                    <ul className='nav nav-tabs tab-nav-right' role='tablist'>
                      <li role='presentation' className='active'>
                        <a href='#Profile' data-toggle='tab'>
                          <i className='material-icons'>face</i>Profile
                        </a>
                      </li>
                      <li role='presentation'>
                        <a href='#Business' data-toggle='tab'>
                          <i className='material-icons'>business</i>Business
                        </a>
                      </li>
                      <li role='presentation'>
                        <a href='#Kitchen' data-toggle='tab'>
                          <i className='material-icons'>kitchen</i>Kitchen
                        </a>
                      </li>
                      <li role='presentation'>
                        <a href='#Menu' data-toggle='tab'>
                          <i className='material-icons'>menu</i>Menu
                        </a>
                      </li>
                      <li role='presentation'>
                        <a href='#kyc' data-toggle='tab'>
                          <i className='material-icons'>menu</i>KYC
                        </a>
                      </li>
                      <li role='presentation'>
                        <a href='#featuredImage' data-toggle='tab'>
                          <i className='material-icons'>menu</i>Featured Image
                        </a>
                      </li>
                      <li role='presentation'>
                        <a href='#auditImage' data-toggle='tab'>
                          <i className='material-icons'>menu</i>Audit Image
                        </a>
                      </li>
                      {/*  <li role="presentation">
                        <a href="#extras" data-toggle="tab">
                          <i className="material-icons">menu</i>Extras
                        </a>
                      </li>  */}
                    </ul>

                    <div className='tab-content'>
                      <div
                        role='tabpanel'
                        className='tab-pane fade in active'
                        id='Profile'
                      >
                        <ProfileTab onUserdataChanges={this.handleUserdata} />
                      </div>
                      <div
                        role='tabpanel'
                        className='tab-pane fade'
                        id='Business'
                      >
                        <BusinessTab />
                      </div>
                      <div
                        role='tabpanel'
                        className='tab-pane fade'
                        id='Kitchen'
                      >
                        <KitchenTab
                          kitchenMatArr={this.state.kitchenMatArr}
                          cookingMatArr={this.state.cookingMatArr}
                        />
                      </div>
                      <div role='tabpanel' className='tab-pane fade' id='Menu'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='wrapper_download'>
                              <b className='d-block'>Fill an excel sheet</b>
                              {this.state.excelItems.length ? (
                                this.state.excelItems.map((item, i) => {
                                  return (
                                    <div className='removeDocAlign'>
                                      <a
                                        href={item.document_path}
                                        target='_blank'
                                        className='view_link'
                                        key={'k' + i}
                                      >
                                        Download File
                                      </a>
                                      <p
                                        className='removeDocText'
                                        onClick={() =>
                                          this.deleteMenuImage(
                                            item.id,
                                            item.chef_id
                                          )
                                        }
                                      >
                                        Remove
                                      </p>
                                    </div>
                                  );
                                })
                              ) : (
                                <span>-</span>
                              )}
                            </div>
                            <hr className='mb-0' />
                          </div>
                          <div className='col-md-12'>
                            <div className='wrapper_download'>
                              <div className='input-group upload_img_one two aniimated-thumbnials'>
                                <b className='d-block'>Upload Menu</b>
                                {this.state.uploadDocumentItems.length ? (
                                  this.state.uploadDocumentItems.map((item) => {
                                    return item.document_name.indexOf(
                                      '.jpg'
                                    ) !== -1 ||
                                      item.document_name.indexOf('.png') !==
                                        -1 ||
                                      item.document_name.indexOf('.jpeg') !==
                                        -1 ||
                                      item.document_name.indexOf('.JPEG') !==
                                        -1 ||
                                      item.document_name.indexOf('.PNG') !==
                                        -1 ||
                                      item.document_name.indexOf('.jpg') !==
                                        -1 ||
                                      item.document_name.indexOf('.JPG') !==
                                        -1 ? (
                                      <div className='imageDisplay'>
                                        <a
                                          className='mx-1 '
                                          href={item.document_path}
                                          data-sub-html='Profile Image'
                                          target='_blank'
                                        >
                                          <img
                                            className='img-responsive thumbnail'
                                            src={item.document_path}
                                            target='_blank'
                                          />
                                        </a>
                                        <div
                                          className='removeImage'
                                          onClick={() =>
                                            this.deleteMenuImage(
                                              item.id,
                                              item.chef_id
                                            )
                                          }
                                        >
                                          <i className='material-icons imageIconDisplay'>
                                            clear
                                          </i>
                                        </div>
                                      </div>
                                    ) : item.document_name.indexOf('.pdf') !==
                                        -1 ||
                                      item.document_name.indexOf('.PDF') !==
                                        -1 ? (
                                      <div className='removeDocAlign'>
                                        <a
                                          className='mx-1'
                                          href={item.document_path}
                                          data-sub-html='Profile Image'
                                          target='_blank'
                                        >
                                          <p>View PDF File</p>
                                        </a>
                                        <p
                                          className='removeDocText'
                                          onClick={() =>
                                            this.deleteMenuImage(
                                              item.id,
                                              item.chef_id
                                            )
                                          }
                                        >
                                          Remove
                                        </p>
                                      </div>
                                    ) : item.document_name.indexOf('.txt') !==
                                        -1 ||
                                      item.document_name.indexOf('.TXT') !==
                                        -1 ? (
                                      <div className='removeDocAlign'>
                                        <a
                                          className='mx-1'
                                          href={item.document_path}
                                          data-sub-html='Profile Image'
                                          target='_blank'
                                        >
                                          <p>View Text File</p>
                                        </a>
                                        <p
                                          className='removeDocText'
                                          onClick={() =>
                                            this.deleteMenuImage(
                                              item.id,
                                              item.chef_id
                                            )
                                          }
                                        >
                                          Remove
                                        </p>
                                      </div>
                                    ) : item.document_name.indexOf('.doc') !==
                                        -1 ||
                                      item.document_name.indexOf('.DOC') !==
                                        -1 ? (
                                      <div className='removeDocAlign'>
                                        <a
                                          className='mx-1'
                                          href={item.document_path}
                                          data-sub-html='Profile Image'
                                          target='_blank'
                                        >
                                          <p>View Doc File</p>
                                        </a>
                                        <p
                                          className='removeDocText'
                                          onClick={() =>
                                            this.deleteMenuImage(
                                              item.id,
                                              item.chef_id
                                            )
                                          }
                                        >
                                          Remove
                                        </p>
                                      </div>
                                    ) : item.document_name.indexOf('.docx') !==
                                        -1 ||
                                      item.document_name.indexOf('.DOCX') !==
                                        -1 ? (
                                      <div className='removeDocAlign'>
                                        <a
                                          className='mx-1'
                                          href={item.document_path}
                                          data-sub-html='Profile Image'
                                          target='_blank'
                                        >
                                          <p>View Docx File</p>
                                        </a>
                                        <p
                                          className='removeDocText'
                                          onClick={() =>
                                            this.deleteMenuImage(
                                              item.id,
                                              item.chef_id
                                            )
                                          }
                                        >
                                          Remove
                                        </p>
                                      </div>
                                    ) : null;
                                  })
                                ) : (
                                  <span>-</span>
                                )}
                              </div>
                            </div>
                            <hr className='mb-0' />
                          </div>
                          <div className='col-md-12'>
                            <b className='d-block'>Menu Items</b>
							{/*  Admin and Ops menu */}
							{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
								<button
								  data-toggle='modal'
								  data-target='#myModalAddMenu'
								  className='btn'
								  onClick={() =>
									this.getBusinessData()
								  }
								>
								  Add Menu
								</button>
							) : null}
							{/*  Admin and Ops menu */}
							{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
								<button
								  data-toggle='modal'
								  data-target='#myModalAddCSVMenu'
								  className='btn'
								  style={{ marginLeft: '10px' }}
								  onClick={() =>
									this.setState({
									  menuCSV: '',
									  menuCSVErr: '',
									  csvUploadErrors: []
									})
								  }
								>
								  Import Menu CSV
								</button>
							) : null}
							{/*  Admin and Ops menu */}
							{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
								<button
								  className='btn'
								  style={{ marginLeft: '10px' }}
								  onClick={() => {
									window.location.href =
									  'https://api.chefp.in/uploads/menu_csv_format.csv';
								  }}
								>
								  Download Sample Menu CSV
								</button>
							  ) : null}
                            
                            <div
                              className='table-responsive'
                              style={{ marginTop: '20px' }}
                            >
                              <table className='table table-bordered table-striped table-hover'>
                                <thead>
                                  <tr>
                                    <th className='text-center'>Sr. No.</th>
                                    <th className='text-center'>Item Name</th>
                                    <th className='text-center'>Description</th>
                                    <th className='text-center'>Price (INR)</th>
                                    <th className='text-center'>
                                      Quantity/Size
                                    </th>
                                    <th className='text-center'>Thumbnail</th>
                                    <th className='text-center'>View</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.chefMenuListItems.length ? (
                                    this.state.chefMenuListItems.map(
                                      (item, i) => {
                                        return (
                                          <tr key={'l' + i}>
                                            <td className='align-middle text-center'>
                                              {this.state.activePage > 1 &&
                                              i + 1 != 10
                                                ? this.state.activePage -
                                                  1 +
                                                  '' +
                                                  (i + 1)
                                                : this.state.activePage > 1 &&
                                                  i + 1 == 10
                                                ? this.state.activePage *
                                                  (i + 1)
                                                : i + 1}
                                            </td>
                                            <td className='align-middle'>
                                              {item.item_name}
                                            </td>
                                            <td className='align-middle'>
                                              {item.item_description.length > 30
                                                ? item.item_description.substring(
                                                    0,
                                                    30
                                                  ) + '...'
                                                : item.item_description}
                                            </td>
                                            <td className='align-middle'>
                                              ₹ {item.item_price}
                                            </td>
                                            <td className='align-middle'>
                                              {item.standard_quantity_or_portion_size +
                                                ' ' +
                                                item.unit}
                                            </td>
                                            <td className='align-middle text-center'>
                                              <img
                                                src={
                                                  item.image_path
                                                    ? item.image_path
                                                    : 'assets/images/noimage.png'
                                                }
                                                height='50px'
                                                width='50px'
                                              />
                                            </td>
                                            <td className='align-middle text-center'>
                                              <i
                                                type='button'
                                                data-toggle='modal'
                                                data-target='#myModal'
                                                className='material-icons view_link'
                                                onClick={() =>
                                                  this.getChefMenuData(item.id)
                                                }
                                              >
                                                remove_red_eye
                                              </i>
											  {/*  Admin and Ops menu */}
											  {this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                              <i
                                                data-toggle='modal'
                                                data-target='#myModalAddMenu'
                                                onClick={() =>
                                                  this.getChefMenuData(item.id)
                                                }
                                                style={{ marginLeft: '10px' }}
                                                className='material-icons view_link'
                                              >
                                                create
                                              </i>
											  ) : null}
											  {/*  Admin and Ops menu */}
											  {this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                              <i
                                                className='material-icons view_link'
                                                onClick={() =>
                                                  this.deleteMenuItem(item.id)
                                                }
                                                style={{ marginLeft: '10px' }}
                                              >
                                                delete
                                              </i>
											  ) : null}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        className='align-middle text-center'
                                        colSpan='7'
                                      >
                                        No Records Found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              <div className='text-right'>
                                <Pagination
                                  activePage={this.state.activePage}
                                  itemsCountPerPage={10}
                                  totalItemsCount={this.state.totalRecords}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div role='tabpanel' className='tab-pane fade' id='kyc'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <b>Membership assingment</b>
                            <div>
                              <span>Select the membership of this chef.</span>
                              <br />
                              <input
                                name='memberType'
                                type='radio'
                                id='mt_1'
                                className='radio-col-orange'
                                value='basic'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'memberType')
                                }
                                checked={
                                  this.state.chefMembershipType === 'basic'
                                }
                              />
                              <label htmlFor='mt_1'>Basic</label>
                              <input
                                name='memberType'
                                type='radio'
                                id='mt_2'
                                className='radio-col-orange'
                                value='premium'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'memberType')
                                }
                                checked={
                                  this.state.chefMembershipType === 'premium'
                                }
                              />
                              <label htmlFor='mt_2'>Premium</label>
                              <input
                                name='memberType'
                                type='radio'
                                id='mt_3'
                                className='radio-col-orange'
                                value='vip'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'memberType')
                                }
                                checked={
                                  this.state.chefMembershipType === 'vip'
                                }
                              />
                              <label htmlFor='mt_3'>VIP</label>
                              {this.state.chefMembershipType === 'vip' ||
                              this.state.chefMembershipType === 'premium' ? (
                                <div className='form-group'>
                                  <b>Commission Rate</b>
                                  <div className='form-line'>
                                    <input
                                      type='text'
                                      className='form-control'
                                      name='commissionRate'
                                      value={this.state.commissionRate}
                                      onChange={this.handleMenuInputChange}
                                      onBlur={this.handleMenuInputChange}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <hr />
                            <b>Verification</b>
                            <div>
                              <span>Is chef verified?</span>
                              <br />
                              <input
                                name='verified'
                                type='radio'
                                id='veri_1'
                                className='radio-col-orange'
                                value='1'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'verified')
                                }
                                checked={this.state.chefVerified === '1'}
                              />
                              <label htmlFor='veri_1'>Yes</label>
                              <input
                                name='verified'
                                type='radio'
                                id='veri_2'
                                className='radio-col-orange'
                                value='0'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'verified')
                                }
                                checked={this.state.chefVerified === '0'}
                              />
                              <label htmlFor='veri_2'>No</label>
                            </div>
                            <hr />
                            <b>Promotion</b>
                            <div>
                              <span>Is chef Promoted?</span>
                              <br />
                              <input
                                name='promotion'
                                type='radio'
                                id='Prom_1'
                                className='radio-col-orange'
                                value='1'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'promotion')
                                }
                                checked={this.state.chefPromoted === '1'}
                              />
                              <label htmlFor='Prom_1'>Yes</label>
                              <input
                                name='promotion'
                                type='radio'
                                id='Prom_2'
                                className='radio-col-orange'
                                value='0'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'promotion')
                                }
                                checked={this.state.chefPromoted === '0'}
                              />
                              <label htmlFor='Prom_2'>No</label>
                            </div>
                            <hr />
                            <b>Verify MOU</b>
                            <div>
                              <span>Verify MOU are in order</span>
                              <br />
                              <input
                                name='mou'
                                type='radio'
                                id='mou_1'
                                className='radio-col-orange'
                                value='1'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'mou')
                                }
                                checked={this.state.verifyMou === '1'}
                              />
                              <label htmlFor='mou_1'>Yes</label>
                              <input
                                name='mou'
                                type='radio'
                                id='mou_2'
                                className='radio-col-orange'
                                value='0'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'mou')
                                }
                                checked={this.state.verifyMou === '0'}
                              />
                              <label htmlFor='mou_2'>No</label>
                            </div>
                            <hr />
                            <b>Verify Chef details</b>
                            <div>
                              <span>
                                Verify Chef details and menu are in order
                              </span>
                              <br />
                              <input
                                name='details'
                                type='radio'
                                id='chdet_1'
                                className='radio-col-orange'
                                value='1'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'details')
                                }
                                checked={this.state.verifyChefDetails === '1'}
                              />
                              <label htmlFor='chdet_1'>Yes</label>
                              <input
                                name='details'
                                type='radio'
                                id='chdet_2'
                                className='radio-col-orange'
                                value='0'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'details')
                                }
                                checked={this.state.verifyChefDetails === '0'}
                              />
                              <label htmlFor='chdet_2'>No</label>
                            </div>
                            <hr />
                            <b>KYC verification</b>
                            <div>
                              <span>Chef KYC verification completed?</span>
                              <br />
                              <input
                                name='kyc'
                                type='radio'
                                id='kycv_1'
                                className='radio-col-orange'
                                value='1'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'kyc')
                                }
                                checked={this.state.verifyKyc === '1'}
                              />
                              <label htmlFor='kycv_1'>Yes</label>
                              <input
                                name='kyc'
                                type='radio'
                                id='kycv_2'
                                className='radio-col-orange'
                                value='0'
                                onChange={(e) =>
                                  this.kycRadioBtnChanged(e, 'kyc')
                                }
                                checked={this.state.verifyKyc === '0'}
                              />
                              <label htmlFor='kycv_2'>No</label>
                            </div>
                            <hr />
                            <label>{this.state.isPublished}</label>
                            <div className='form-group'>
                              <div style={{ marginLeft: '20px' }}>
                                <label htmlFor='isPublished'>Is published?</label>
                                <input
                                  type='checkbox'
                                  id='isPublished'
                                  name='isPublished'
                                  className='filled-in'
                                  onChange={this.handleCheckboxInput}
                                  checked={this.state.isPublished}
                                />
                              </div>
                            </div>
							
							{(this.state.isPublished === true || this.state.isPublished === false) && (
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<b>Package*</b>
											<div className="form-line">
												<input
												  type="text"
												  placeholder=""
												  defaultValue={this.state.packagess}
												  onChange={this.handleMenuInputChange}
												  name="packagess"
												  readonly="readonly"
												  className="form-control"
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<b>Join Date</b>
											<div className="form-line">
												<input
													type='date'
													defaultValue={this.state.joinDate}
													className='form-control'
													name='joinDate'
													readonly="readonly"
													onChange={this.handleMenuInputChange}
													onBlur={this.handleMenuInputChange}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<b>Expire Date</b>
											<div className="form-line">
												<input
													type='date'
													defaultValue={this.state.expireDate}
													className='form-control'
													name='expireDate'
													readonly="readonly"
													onChange={this.handleMenuInputChange}
													onBlur={this.handleMenuInputChange}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<b>Payment Date*</b>
											<div className="form-line">
												<input
													type='date'
													defaultValue={this.state.paymentDate}
													className='form-control'
													name='paymentDate'
													onChange={this.handleMenuInputChange}
													onBlur={this.handleMenuInputChange}
												/>
											</div>
											{this.state.paymentdateerror != '' && (
											  <small className="text-danger">
												{this.state.paymentdateerror}
											  </small>
											)}
										</div>
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<b>Paid Amount*</b>
											<div className="form-line">
												<input
												  type="number"
												  step="any"
												  placeholder=""
												  defaultValue={this.state.paidAmount}
												  onChange={this.handleMenuInputChange}
												  name="paidAmount"
												  className="form-control"
												/>
											</div>
											{this.state.paidamounterror != '' && (
											  <small className="text-danger">
												{this.state.paidamounterror}
											  </small>
											)}
										</div>
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<b>Payment Remark*</b>
											<div className="form-line">
												<input
												  type="text"
												  placeholder=""
												  defaultValue={this.state.paymentRemark}
												  onChange={this.handleMenuInputChange}
												  name="paymentRemark"
												  className="form-control"
												/>
											</div>
											{this.state.paymentremarkerror != '' && (
											  <small className="text-danger">
												{this.state.paymentremarkerror}
											  </small>
											)}
										</div>
									</div>
								</div>
							)}

                            {/* <Link to="/category-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link> */}
							{/*  Admin and Ops menu */}
							{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                            <button
                              type='submit'
                              disabled={this.state.buttonDisabled}
                              className='btn'
                              onClick={this.submitKycDetails}
                            >
                              {this.state.buttonText}
                            </button>
							) : null}
                            <h5 className='membership-log-heading'>
                              Membership Logs
                            </h5>
                            <table className='table table-bordered text-center'>
                              <thead>
                                <tr>
                                  <th>Sr.No.</th>
                                  <th>Membership Assigned</th>
                                  <th>Log Description</th>
                                  {/* <th>Modified Date</th> */}
                                </tr>
                              </thead>
                              {this.state.membershipLogs.length ? (
                                <tbody>
                                  {this.state.membershipLogs.map((log, i) => {
                                    return (
                                      <tr key={'ujh' + i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          {log.membership_assigned
                                            ? this.ucfirst(
                                                log.membership_assigned
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          <ul>
                                            {log.description ? (
                                              log.description.indexOf('| ') !==
                                              -1 ? (
                                                log.description
                                                  .split('| ')
                                                  .map((item, i) => {
                                                    return (
                                                      <li key={'ghd' + i}>
                                                        {item}
                                                      </li>
                                                    );
                                                  })
                                              ) : (
                                                <li>{log.description}</li>
                                              )
                                            ) : (
                                              '-'
                                            )}
                                          </ul>
                                        </td>
                                        {/* <td>{log.modified_date}</td> */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan='3'>No records found.</td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>

                      <div
                        role='tabpanel'
                        className='tab-pane fade'
                        id='featuredImage'
                      >
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='card'>
                              <div className='body'>
								{/*  Admin and Ops menu */}
								{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                <button
                                  className='btn'
                                  data-toggle='modal'
                                  data-target='#myModalFeaturedImage'
                                  onClick={() =>
                                    this.setState({
                                      featuredImage: null,
                                      featuredImageUrl: '',
                                      featuredImageErr: '',
                                      xOrder: '',
                                      orderErr: ''
                                    })
                                  }
                                >
                                  Add Featured Image
                                </button>
								) : null}
                                <div
                                  className='table-responsive'
                                  style={{ marginTop: '20px' }}
                                >
                                  <table className='table table-bordered table-striped table-hover'>
                                    <thead>
                                      <tr>
                                        <th className='align-middle text-center'>
                                          Sr. No.
                                        </th>
                                        <th className='align-middle text-center'>
                                          Featured Image
                                        </th>
                                        <th className='align-middle text-center'>
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    {this.state.isLoading ? (
                                      <tbody>
                                        <tr>
                                          <td
                                            className='text-center'
                                            colSpan='4'
                                          >
                                            <Loader
                                              type='Oval'
                                              color='#F07C07'
                                              height={30}
                                              width={30}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    ) : this.state.featuredImageList.length ? (
                                      <tbody>
                                        {this.state.featuredImageList.map(
                                          (image, i) => {
                                            return (
                                              <tr key={i}>
                                                <td
                                                  className='align-middle text-center'
                                                  width='10%'
                                                >
                                                  {this.state.activeImagePage >
                                                    1 && i + 1 != 10
                                                    ? this.state
                                                        .activeImagePage -
                                                      1 +
                                                      '' +
                                                      (i + 1)
                                                    : this.state
                                                        .activeImagePage > 1 &&
                                                      i + 1 == 10
                                                    ? this.state
                                                        .activeImagePage *
                                                      (i + 1)
                                                    : i + 1}
                                                </td>
                                                <td
                                                  className='align-middle text-center'
                                                  width='30%'
                                                >
                                                  <img
                                                    src={image.image}
                                                    style={{ width: '100px' }}
                                                  />
                                                </td>
                                                <td
                                                  width='10%'
                                                  className='align-middle text-center'
                                                >
												{/*  Admin and Ops menu */}
												{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                                  <i
                                                    data-toggle='modal'
                                                    data-target='#myModalFeaturedImage'
                                                    onClick={() =>
                                                      this.getFeaturedImageData(
                                                        image.id
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: '10px'
                                                    }}
                                                    className='material-icons view_link'
                                                  >
                                                    create
                                                  </i>
												  ) : null}
												  {/*  Admin and Ops menu */}
												  {this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                                  <i
                                                    className='material-icons view_link'
                                                    onClick={() =>
                                                      this.deleteFeaturedImage(
                                                        image.id
                                                      )
                                                    }
                                                  >
                                                    delete
                                                  </i>
												  ) : null}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td
                                            className='text-center'
                                            colSpan='4'
                                          >
                                            No record found
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                  <div className='text-right'>
                                    <Pagination
                                      activePage={this.state.activeImagePage}
                                      itemsCountPerPage={10}
                                      totalItemsCount={
                                        this.state.totalImageRecords
                                      }
                                      pageRangeDisplayed={5}
                                      onChange={this.handleImagePagination.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          role='tabpanel'
                          className='tab-pane fade'
                          id='extras'
                        >
                          <div className='row'>
                            <span>Commissions</span>
                            <div className='col-md-6'>
                              <b>VIP Chef</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='marketingName'
                                  value={this.state.vipChefCommission}
                                  onChange={this.handleMenuInputChange}
                                  onBlur={this.handleMenuInputChange}
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='col-md-6'>
                                <b>VIP Chef</b>
                                <div className='form-line'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    name='marketingName'
                                    value={this.state.marketingName}
                                    onChange={this.handleMenuInputChange}
                                    onBlur={this.handleMenuInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        role='tabpanel'
                        className='tab-pane fade'
                        id='auditImage'
                      >
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='card'>
                              <div className='body'>
								{/*  Admin and Ops menu */}
								{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                <button
                                  className='btn'
                                  data-toggle='modal'
                                  data-target='#myModalAuditImage'
                                  onClick={() => this.resetFile()}
                                >
                                  Add Audit Image
                                </button>
								) : null}
                                <div
                                  className='table-responsive'
                                  style={{ marginTop: '20px' }}
                                >
                                  <table className='table table-bordered table-striped table-hover'>
                                    <thead>
                                      <tr>
                                        <th className='align-middle text-center'>
                                          Sr. No.
                                        </th>
                                        <th className='align-middle text-center'>
                                          Audit Image
                                        </th>
                                        <th className='align-middle text-center'>
                                          Audit Image Name
                                        </th>
                                        <th className='align-middle text-center'>
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    {this.state.isLoading ? (
                                      <tbody>
                                        <tr>
                                          <td
                                            className='text-center'
                                            colSpan='4'
                                          >
                                            <Loader
                                              type='Oval'
                                              color='#F07C07'
                                              height={30}
                                              width={30}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    ) : this.state.auditImageList.length ? (
                                      <tbody>
                                        {this.state.auditImageList.map(
                                          (image, i) => {
                                            return (
                                              <tr key={i}>
                                                <td
                                                  className='align-middle text-center'
                                                  width='10%'
                                                >
                                                  {this.state.activeImagePage >
                                                    1 && i + 1 != 10
                                                    ? this.state
                                                        .activeImagePage -
                                                      1 +
                                                      '' +
                                                      (i + 1)
                                                    : this.state
                                                        .activeImagePage > 1 &&
                                                      i + 1 == 10
                                                    ? this.state
                                                        .activeImagePage *
                                                      (i + 1)
                                                    : i + 1}
                                                </td>
                                                <td
                                                  className='align-middle text-center'
                                                  width='30%'
                                                >
                                                  <a
                                                    href={image.image}
                                                    target='_blank'
                                                  >
                                                    <img
                                                      src={image.image}
                                                      style={{ width: '100px' }}
                                                    />
                                                  </a>
                                                </td>
                                                <td
                                                  className='align-middle text-center'
                                                  width='30%'
                                                >
                                                  <p>{image.kitchen_image}</p>
                                                </td>
                                                <td
                                                  width='10%'
                                                  className='align-middle text-center'
                                                >
												{/*  Admin and Ops menu */}
												{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                                  <i
                                                    className='material-icons view_link'
                                                    onClick={() =>
                                                      this.deleteAuditImage(
                                                        image.id
                                                      )
                                                    }
                                                  >
                                                    delete
                                                  </i>
												  ) : null}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td
                                            className='text-center'
                                            colSpan='4'
                                          >
                                            No record found
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                  <div className='text-right'>
                                    <Pagination
                                      activePage={this.state.activeImagePage}
                                      itemsCountPerPage={10}
                                      totalItemsCount={
                                        this.state.totalAuditImageRecords
                                      }
                                      pageRangeDisplayed={5}
                                      onChange={this.handleAuditImagePagination.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className='modal fade menu_list_pop'
          id='myModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='add_another_btn'>
                  <button
                    type='button'
                    className='close plus_icon'
                    data-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <h4 className='modal-title color_C07' id='myModalLabel'>
                  Menu Detail
                </h4>
              </div>
              <div className='modal-body'>
                <ul>
                  <li>
                    <b>Item Category</b>
                    <span>{this.state.menuCategoryName}</span>
                  </li>
                  <li>
                    <b>Item Name</b>
                    <span>{this.state.menuName}</span>
                  </li>
                  <li>
                    <b>Item description</b>
                    <span>{this.state.menuDescription}</span>
                  </li>
                  <li>
                    <b>This item is</b>
                    <span>{this.state.menuType}</span>
                  </li>
                  <li>
                    <b>Standard Quantity or portion size</b>
                    <span>
                      {this.state.menuQuantity}
                      <span> {this.state.menuQuantityMeasure}</span>
                    </span>
                  </li>
                  <li>
                    <b>Item Price</b>
                    <span>
                      INR<span> {this.state.menuPrice}</span>
                    </span>
                  </li>
				  {this.state.checksamedaydelivery == "1" && (
				  <li>
                    <b>Do you offer same-day delivery on this item?</b>
                    <span>{(this.state.sameDayDelivery == '0' ? 'No' : 'Yes')}</span>
                  </li>
				  )}
                  {/*<li>
                    <b>Advance notice required to prepare the item</b>
                    <span>{this.state.menuNotice}</span>
                  </li>*/}
                  <li>
                    <b>Item Image</b>
                    <div className='upload_img_wrapper aniimated-thumbnials'>
                      <div className='upload_img_one'>
                        <img
                          width='150px'
                          src={
                            this.state.menuImageUrl.length
                              ? this.state.menuImageUrl
                              : '/assets/images/noimage.png'
                          }
                          alt='Menu URL'
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <b>Does this item contain</b>
                    {this.state.menuMatArr.length ? (
                      this.state.menuMatArr
                        .filter((elm) =>
                          this.state.menuItemContainsMaterial.includes(elm.id)
                        )
                        .map((item, i) => {
                          return (
                            <span key={'oss' + i}>
                              {i + 1}. {item.material_name}
                              <br />
                            </span>
                          );
                        })
                    ) : (
                      <span>None</span>
                    )}
                  </li>
				  
                  {/* <li>
                    <b>Festive Special Tags</b>
                    {this.state.menuMatArr.length ? (
                      this.state.menuMatArr.filter(elm => this.state.menuItemContainsMaterial.includes(elm.id)).map((item, i) => {
                        return (
                          <span key={'oss' + i}>
                            {i + 1}. {item.material_name}
                            <br />
                          </span>
                        );
                      })
                    ) : (
                        <span>None</span>
                      )}
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className='modal fade menu_list_pop add_menu_list_pop'
          id='myModalAddMenu'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='row'>
                  <div className='col-md-6'>
                    <h4>{this.state.menuModalTitle}</h4>
                  </div>
                  <div className='col-md-6 text-right'>
                    <div className='demo-switch' style={{ marginTop: '10px' }}>
                      <div className='switch'>
                        <label>
                          Disabled
                          <input
                            type='checkbox'
                            value={true}
                            checked={this.state.isMenuEnabled}
                            onChange={this.checkMenuEnabled}
                          />
                          <span className='lever switch-col-deep-orange'></span>
                          Enabled
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-body'>
                <form onSubmit={this.submitMenuForm} id='chefMenuForm'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>Menu Category*</b>
                        <div className='form-line'>
                          {this.state.menuCategories.length && (
                            <select
                              className='form-control show-tick'
                              name='menuCategory'
                              onChange={this.handleMenuInputChange}
                            >
                              <option value=''>Select Category</option>
                              {this.state.menuCategories.map((item, i) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={'v' + i}
                                    selected={
                                      this.state.menuCategory === item.id
                                    }
                                  >
                                    {item.category_name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                        {formErrors.menuCategory.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuCategory}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <b>
                          Item Name
                          {menuModalTitle == 'Add Menu Item' ? '*' : null}
                        </b>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder=''
                            defaultValue={this.state.menuName}
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                            name='menuName'
                            className='form-control'
                          />
                        </div>
                        {formErrors.menuName.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuName}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <b>
                          Item Description
                          {menuModalTitle == 'Add Menu Item' ? '*' : null}
                        </b>
                        <div className='form-line'>
                          <textarea
                            className='form-control'
                            rows={3}
                            defaultValue={this.state.menuDescription}
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                            name='menuDescription'
                          ></textarea>
                        </div>
                        {formErrors.menuDescription.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuDescription}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <label className='d-block'>
                        This item is:
                        {menuModalTitle == 'Add Menu Item' ? '*' : null}
                      </label>
                      <div>
                        <div className='form-group'>
                          <input
                            name='menuType'
                            type='radio'
                            id='vegRadio11'
                            className='radio-col-orange'
                            value='Veg'
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                            checked={this.state.menuType === 'Veg'}
                          />
                          <label htmlFor='vegRadio11' style={{ fontWeight: '100' }}>
                            Veg
                          </label>
                          <input
                            name='menuType'
                            type='radio'
                            id='nonVegRadio22'
                            className='radio-col-orange'
                            value='Non Veg'
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                            checked={this.state.menuType === 'Non Veg'}
                          />
                          <label
                            htmlFor='nonVegRadio22'
                            style={{ fontWeight: '100' }}
                          >
                            Non Veg
                          </label>
                          <input
                            name='menuType'
                            type='radio'
                            id='containEggRadio33'
                            className='radio-col-orange'
                            value='Contains Egg'
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                            checked={this.state.menuType === 'Contains Egg'}
                          />
                          <label
                            htmlFor='containEggRadio33'
                            style={{ fontWeight: '100' }}
                          >
                            Contains Egg
                          </label>
                        </div>
                        {formErrors.menuType.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuType}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      {this.state.isDataLoaded ? (
                        <div className='form-group'>
                          <div className='form-line'>
                            <select
                              className='form-control'
                              name='menuQuantityMeasure'
                              onChange={this.handleMenuInputChange}
                              onBlur={this.handleMenuInputChange}
                              defaultValue={this.state.menuQuantityMeasure}
                            >
                              <option
                                value='kg'
                                selected={
                                  this.state.menuQuantityMeasure === 'kg'
                                }
                              >
                                KG
                              </option>
                              <option
                                value='gm'
                                selected={
                                  this.state.menuQuantityMeasure === 'gm'
                                }
                              >
                                GM
                              </option>
                              <option
                                value='ltr'
                                selected={
                                  this.state.menuQuantityMeasure === 'ltr'
                                }
                              >
                                LTR
                              </option>
                              <option
                                value='pc'
                                selected={
                                  this.state.menuQuantityMeasure === 'pc'
                                }
                              >
                                PC
                              </option>
                              <option
                                value='portion'
                                selected={
                                  this.state.menuQuantityMeasure === 'portion'
                                }
                              >
                                PORTION
                              </option>
                            </select>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder={
                              this.state.menuQuantityMeasure === 'portion'
                                ? 'Serves how many people'
                                : menuModalTitle == 'Add Menu Item'
                                ? 'Item Quantity*'
                                : 'Item Quantity'
                            }
                            name='menuQuantity'
                            value={this.state.menuQuantity}
                            className='form-control'
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                          />
                        </div>
                        {formErrors.menuQuantity.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuQuantity}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          disabled={true}
                          value='INR'
                          className='form-control'
                          onChange={this.handleMenuInputChange}
                          onBlur={this.handleMenuInputChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <div className='form-line'>
                          <input
                            type='text'
                            placeholder={
                              menuModalTitle == 'Add Menu Item'
                                ? 'Item Price (₹)*'
                                : 'Item Price (₹)'
                            }
                            name='menuPrice'
                            defaultValue={this.state.menuPrice}
                            className='form-control'
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                          />
                        </div>
                        {formErrors.menuPrice.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuPrice}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
				  
				  <div class="row">
					 <div className="col-md-12">
						<b>Do you offer same-day delivery on this item?</b>
						<div className="input-group">
						  <input
							name="sameDayDelivery"
							type="radio"
							id="sdd11"
							className="radio-col-orange"
							value="1"
							onChange={this.handleMenuInputChange}
							onBlur={this.handleMenuInputChange}
							disabled={this.state.checksamedaydelivery == "1" ? false: true }
							checked={
							  this.state.sameDayDelivery === "1"
							}
						  />
						  <label htmlFor="sdd11" style={{ fontWeight: '100' }}>Yes</label>
						  <input
							name="sameDayDelivery"
							type="radio"
							id="sdd22"
							className="radio-col-orange"
							value="0"
							onChange={this.handleMenuInputChange}
							onBlur={this.handleMenuInputChange}
							disabled={this.state.checksamedaydelivery == "1" ? false: true }
							checked={
							  this.state.sameDayDelivery === "0"
							}
						  />
						  <label htmlFor="sdd22" style={{ fontWeight: '100' }}>No</label>
						</div>
						{this.state.checksamedaydelivery == "0" ?
						<p style={{margin: '-25px 0px 10px 0px'}}>To enable same-day delivery for this dish please go to the Your Business section</p>
						:
						<p style={{margin: '-25px 0px 10px 0px'}}>Your chosen advance notice for same-day delivery items will be applied</p>
						}
					  </div>
				  </div>

                  <div className='row'>
                    {/*<div className='col-md-12'>
                      <div className='form-group'>
                        <b>
                          Advance notice required to prepare the item
                          {menuModalTitle == 'Add Menu Item' ? '*' : null}
                        </b>
                        <div className='form-line'>
                          <textarea
                            className='form-control'
                            rows={3}
                            name='menuNotice'
                            onChange={this.handleMenuInputChange}
                            onBlur={this.handleMenuInputChange}
                            defaultValue={this.state.menuNotice}
                          ></textarea>
                        </div>
                        {formErrors.menuNotice.length > 0 && (
                          <small className='text-danger'>
                            {formErrors.menuNotice}
                          </small>
                        )}
                      </div>
                    </div>*/}

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <b>
                          Upload a photo of the item
                          {menuModalTitle == 'Add Menu Item' ? '*' : null}
                        </b>
                        <div className='form-line'>
                          <input
                            id='uploadFile'
                            type='file'
                            accept='.jpg, .jpeg, .png, .svg'
                            className='form-control'
                            name='menuImage'
                            onChange={this.onImageChange}
                          />
                        </div>
                        <p>(Image to be uploaded: 130 x 140 pixels)</p>
                        {this.state.menuImageErr.length > 0 && (
                          <small className='text-danger'>
                            {this.state.menuImageErr}
                          </small>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: '20px',
                          marginBottom: '20px',
                          position: 'relative',
                          width: '160px'
                        }}
                      >
                        {this.state.menuImageUrl ? (
                          <div>
                            <img width='150px' src={this.state.menuImageUrl} />
                            <div
                              style={{
                                position: 'absolute',
                                top: -5,
                                right: 0,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'red',
                                borderRadius: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={this.clearPrevImage}
                            >
                              <i
                                className='material-icons'
                                style={{
                                  color: 'white',
                                  fontSize: '13px',
                                  marginLeft: '3px',
                                  marginTop: '2px'
                                }}
                              >
                                clear
                              </i>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <b>
                          Does this item contain:
                          {menuModalTitle == 'Add Menu Item' ? '*' : null}
                        </b>
                        <div className='item_wrap'>
                          {this.state.menuMatArr.length &&
                            this.state.menuMatArr.map((item, i) => {
                              return (
                                <div key={'sd' + i}>
                                  <label
                                    htmlFor={item.material_name}
                                    style={{ fontWeight: '100' }}
                                  >
                                    {item.material_name}
                                  </label>
                                  <input
                                    type='checkbox'
                                    id={item.material_name}
                                    value={item.id}
                                    className='filled-in'
                                    onChange={this.handleCheckboxInputChange}
                                    checked={this.state.menuItemContainsMaterial.includes(
                                      item.id
                                    )}
                                  />
                                </div>
                              );
                            })}
                          <div>
                            <label
                              htmlFor='none_checkbx'
                              style={{ fontWeight: '100' }}
                            >
                              None of the above
                            </label>
                            <input
                              type='checkbox'
                              id='none_checkbx'
                              value='1'
                              className='filled-in'
                              onChange={this.handleNoneCheckbxChange}
                              checked={
                                this.state.menuItemNone.toString() === '1'
                              }
                            />
                          </div>
                        </div>
                        {this.state.menuContainError.length > 0 && (
                          <small className='text-danger'>
                            {this.state.menuContainError}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <b>Festive Special Tags</b>
                        <div className='item_wrap'>
                          {this.state.tagsArr.length &&
                            this.state.tagsArr.map((item, i) => {
                              return (
                                <div key={'sd' + i}>
                                  <label
                                    htmlFor={item.tag_name}
                                    style={{ fontWeight: '100' }}
                                  >
                                    {item.tag_name}
                                  </label>
                                  <input
                                    type='checkbox'
                                    id={item.tag_name}
                                    value={item.tag_name}
                                    className='filled-in'
                                    onChange={this.handleTagCheckboxInputChange}
                                    disabled={!this.state.menuFestiveTags.includes(
                                      item.tag_name
                                    ) && item.tag_name == "Same-day Delivery"}
                                    checked={this.state.menuFestiveTags.includes(
                                      item.tag_name
                                    )}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        {this.state.menuContainError.length > 0 && (
                          <small className='text-danger'>
                            {this.state.menuContainError}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <b>Is chef special?</b>
                      <div
                        className='demo-switch'
                        style={{ marginTop: '10px', marginBottom: '30px' }}
                      >
                        <div className='switch'>
                          <label>
                            No
                            <input
                              type='checkbox'
                              value={true}
                              checked={this.state.isMenuSpecial}
                              onChange={this.checkMenuSpecial}
                            />
                            <span className='lever switch-col-deep-orange'></span>
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <b>Menu Enabled/Disabled Date Range</b>
                      <div className='form-group'>
                        <div className='datePickerFlex'>
                          <p>from</p>
                          <div className='form-group'>
                            {/* <b>From*</b> */}
                            <div className='form-line'>
                              <input
                                type='date'
                                defaultValue={this.state.formStartDate}
                                className='form-control'
                                name='formStartDate'
                                onChange={this.handleMenuInputChange}
                                onBlur={this.handleMenuInputChange}
                              />
                            </div>

                            {/* {profileFormErrors.formStartDate.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.formStartDate}</span>
                                                            )} */}
                          </div>
                          {/* <DatePicker selected={this.state.formStartDate} onChange={this.handleStartDateChange} maxDate={this.state.formEndDate} /> */}
                          <p>to</p>
                          <div className='form-group'>
                            {/* <b>To*</b> */}
                            <div className='form-line'>
                              <input
                                type='date'
                                defaultValue={this.state.formEndDate}
                                className='form-control'
                                name='formEndDate'
                                onChange={this.handleMenuInputChange}
                                onBlur={this.handleMenuInputChange}
                              />
                            </div>

                            {/* {profileFormErrors.formEndDate.length > 0 && (
                                                                <span className="text-danger">{profileFormErrors.formEndDate}</span>
                                                            )} */}
                          </div>
                          {/* <DatePicker selected={this.state.formEndDate} onChange={this.handleEndDateChange} minDate={this.state.formStartDate} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <button
                      type='button'
                      data-dismiss='modal'
                      id='closeMenuFormButton'
                      aria-label='Close'
                      disabled={this.state.buttonDisabled}
                      className='btn btn-default waves-effect'
                      style={{ color: '#000', marginRight: '10px' }}
                      onClick={this.menuModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={this.state.buttonDisabled}
                      className='btn'
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade menu_list_pop add_menu_list_pop'
          id='myModalAddCSVMenu'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header text-center'>
                <h4>Upload Menu CSV</h4>
              </div>
              <div className='modal-body'>
                <form onSubmit={this.csvFormSubmit} id='menuCSVForm'>
                  <div className='form-line'>
                    <input
                      type='file'
                      accept='.csv'
                      className='form-control'
                      name='menuCSV'
                      onChange={this.onCSVChange}
                    />
                  </div>
                  {this.state.menuCSVErr.length > 0 && (
                    <small className='text-danger'>
                      {this.state.menuCSVErr}
                    </small>
                  )}
                  <div className='text-center' style={{ marginTop: '30px' }}>
                    <button
                      type='button'
                      data-dismiss='modal'
                      id='closeCSVFormButton'
                      aria-label='Close'
                      disabled={this.state.buttonDisabled}
                      className='btn btn-default waves-effect'
                      style={{ color: '#000', marginRight: '10px' }}
                      onClick={this.menuModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={this.state.buttonDisabled}
                      className='btn'
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
                {this.state.csvUploadErrors.length ? (
                  <div
                    className='alert alert-danger error-message-div'
                    style={{ marginTop: '20px' }}
                  >
                    {this.state.csvUploadErrors.map((item, i) => {
                      return (
                        <div key={'dfd' + i}>
                          <b>{item.errFor}</b>
                          <br />
                          {item.errors.map((err, i) => {
                            return (
                              <span key={'yut' + i}>
                                {err}
                                <br />
                              </span>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade menu_list_pop add_menu_list_pop'
          id='myModalPasswordChange'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header text-center'>
                <h4>Change Password</h4>
              </div>
              <div className='modal-body'>
                <form
                  onSubmit={this.changePasswordSubmit}
                  id='changePasswordForm'
                >
                  <div className='form-group'>
                    <b>New Password</b>
                    <div className='form-line'>
                      <input
                        type='password'
                        className='form-control'
                        name='userPassword'
                        onChange={this.onPasswordChange}
                      />
                    </div>
                    {this.state.passwordError.length > 0 && (
                      <small className='text-danger'>
                        {this.state.passwordError}
                      </small>
                    )}
                  </div>
                  <div className='form-group'>
                    <div className='form-line'>
                      <b>Confirm Password</b>
                      <input
                        type='password'
                        className='form-control'
                        name='userConfirmPassword'
                        onChange={this.onPasswordChange}
                      />
                    </div>
                    {this.state.confirmPasswordError.length > 0 && (
                      <small className='text-danger'>
                        {this.state.confirmPasswordError}
                      </small>
                    )}
                  </div>
                  <div className='text-center' style={{ marginTop: '30px' }}>
                    <button
                      type='button'
                      data-dismiss='modal'
                      id='closePasswordButton'
                      aria-label='Close'
                      disabled={this.state.buttonDisabled}
                      className='btn btn-default waves-effect'
                      style={{ color: '#000', marginRight: '10px' }}
                      onClick={this.menuModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={this.state.buttonDisabled}
                      className='btn'
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className='modal fade menu_list_pop add_menu_list_pop'
          id='myModalFeaturedImage'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header text-center'>
                <h4>{this.state.featuredImagePopupTitle}</h4>
              </div>
              <div className='modal-body'>
                <form
                  onSubmit={this.handleFeaturedImageSubmit}
                  id='featuredImageForm'
                >
                  <div className='form-group'>
                    <b>Featured Image*</b>
                    <div className='form-line'>
                      <input
                        id='uploadFile'
                        type='file'
                        accept='.jpg, .jpeg, .png, .svg'
                        className='form-control'
                        name='image'
                        onChange={this.onFeaturedImageChange}
                      />
                    </div>
                    <p>(Image to be uploaded: 805 x 450 pixels)</p>
                    {this.state.featuredImageErr.length > 0 && (
                      <span className='text-danger'>
                        {this.state.featuredImageErr}
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      position: 'relative',
                      width: '160px'
                    }}
                  >
                    {this.state.featuredImageUrl ? (
                      <div>
                        <img width='150px' src={this.state.featuredImageUrl} />
                        <div
                          style={{
                            position: 'absolute',
                            top: -5,
                            right: 0,
                            height: '20px',
                            width: '20px',
                            backgroundColor: 'red',
                            borderRadius: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                          }}
                          onClick={this.clearPrevImage}
                        >
                          <i
                            className='material-icons'
                            style={{
                              color: 'white',
                              fontSize: '13px',
                              marginLeft: '3px',
                              marginTop: '2px'
                            }}
                          >
                            clear
                          </i>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group'>
                    <b>Order</b>
                    <div className='form-line'>
                      <input
                        defaultValue={this.state.xOrder}
                        type='number'
                        min='1'
                        className='form-control'
                        name='xOrder'
                        onChange={this.checkOrder}
                      />
                    </div>
                    {this.state.orderErr.length > 0 && (
                      <span className='text-danger'>{this.state.orderErr}</span>
                    )}
                  </div>
                  <div className='text-center'>
                    <button
                      type='button'
                      data-dismiss='modal'
                      id='closeFeaturedImgPopupButton'
                      aria-label='Close'
                      disabled={this.state.buttonDisabled}
                      className='btn btn-default waves-effect'
                      style={{ color: '#000', marginRight: '10px' }}
                      onClick={this.menuModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={this.state.buttonDisabled}
                      className='btn'
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className='modal fade menu_list_pop add_menu_list_pop'
          id='myModalAuditImage'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header text-center'>
                <h4>Add Audit Image</h4>
              </div>
              <div className='modal-body'>
                <form
                  onSubmit={this.handleAuditImageSubmit}
                  id='featuredImageForm'
                >
                  <div className='form-group'>
                    <b>Audit Image*</b>
                    <div className='form-line'>
                      <input
                        id='uploadFile'
                        type='file'
                        accept='.jpg, .jpeg, .png, .svg'
                        className='form-control imageAudit'
                        name='imageAudit'
                        onChange={this.onAuditImageChange}
                        multiple
                      />
                    </div>
                    {/*  <p>(Image to be uploaded: 805 x 450 pixels)</p> */}
                    {this.state.auditImageErr.length > 0 && (
                      <span className='text-danger'>
                        {this.state.auditImageErr}
                      </span>
                    )}
                    {this.state.buttonAuditDisabled ? (
                      <span className='text-danger'>
                        Audit image getting uploading ...
                      </span>
                    ) : null}
                  </div>
                  <div className='text-center'>
                    <button
                      type='button'
                      data-dismiss='modal'
                      id='closeAuditImgPopupButton'
                      aria-label='Close'
                      disabled={this.state.buttonAuditDisabled}
                      className='btn btn-default waves-effect'
                      style={{ color: '#000', marginRight: '10px' }}
                      onClick={this.menuModalClosed}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={this.state.buttonAuditDisabled}
                      className='btn'
                    >
                      {this.state.buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserInfoPage;
