import React, { Component } from "react";
import Header from "../../components/Header";
import { getBlogAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class BlogListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      blogArr: [],
      blogExcelData: [],
      activePage: 1,
      totalRecords: 10,
      resetFlag: false,
      currentDate: "",
      chefEmail: "",
      chefName: "",
      chefMobile: "",
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.getBlogListData();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today,
    });
  }

  handleFilterChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getBlogListData();
    });
  }

  getBlogListData() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getBlogAPI(
      id,
      this.state.chefEmail,
      this.state.chefName,
      this.state.chefMobile,
      currentPage,
      token
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            blogArr: response.data.data,
            blogExcelData: response.data["all_records"],
            totalRecords: response.data["total_records"],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  }

  submitFilter = (event) => {
    console.log("in");
    event.preventDefault();
    this.setState({ resetFlag: true });
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = "1";
    this.setState({
      activePage: 1,
    });
    return getBlogAPI(
        id,
        this.state.chefEmail,
        this.state.chefName,
        this.state.chefMobile,
        currentPage,
        token
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            this.setState({
              blogArr: response.data.data,
              blogExcelData: response.data["all_records"],
              totalRecords: response.data["total_records"],
              isLoading: false,
            });
          } else {
            this.setState({
              isLoading: false,
            });
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error("Something went wrong", "Error!");
        });
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        chefName: "",
        chefEmail: "",
        chefMobile:""
      },
      () => {
        this.getBlogListData();
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Blog Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="row clearfix">
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Name</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="chefName"
                                  value={this.state.chefName}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Email</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="chefEmail"
                                  value={this.state.chefEmail}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <b>Mobile</b>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="chefMobile"
                                  value={this.state.chefMobile}
                                  maxLength="20"
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 center_btn">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      {this.state.blogArr.length ? (
                        <ExcelFile
                          filename={"Blog_Report_" + this.state.currentDate}
                          element={
                            <button className="btn pull-right m-b-10">
                              Download Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.blogExcelData}
                            name="Blog Report"
                          >
                            <ExcelColumn
                              label="Blog Date"
                              value="created_at"
                            />
                            <ExcelColumn
                              label="Chef Name"
                              value="chef_name"
                            />
                            <ExcelColumn
                              label="Chef Email ID"
                              value="email"
                            />
                            <ExcelColumn
                              label="Chef Mobile Number"
                              value="mobile_number"
                            />
                            <ExcelColumn label="Blog Title" value="document_title" />
                            <ExcelColumn
                              label="Blog Document"
                              value="document_name"
                            />
                            <ExcelColumn
                              label="Blog Document Path"
                              value="document_path"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="align-middle text-center">
                              Sr. No.
                            </th>
                            <th className="align-middle text-center">
                              Chef Name
                            </th>
                            <th className="align-middle text-center">
                              Chef Email
                            </th>
                            <th className="align-middle text-center">
                              Chef Mobile No.
                            </th>
                            <th className="align-middle text-center">
                              Blog Title
                            </th>
                            <th className="align-middle text-center">
                              Blog Document Name
                            </th>
                            <th className="align-middle text-center">
                              Created On
                            </th>
                            {/* <th className="align-middle text-center">
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="7">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.blogArr.length ? (
                          <tbody>
                            {this.state.blogArr.map((blog, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="5%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td className="align-middle" width="25%">
                                    {blog.chef_name}
                                  </td>
                                  <td className="align-middle" width="25%">
                                    {blog.email}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {blog.mobile_number}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    {blog.document_title}
                                  </td>
                                  <td
                                    className="align-middle text-center"
                                    width="25%"
                                  >
                                    <a href={blog.document_path} download target="_blank">{blog.document_name}</a>
                                  </td>
                                  <td className="align-middle" width="20%">
                                    {blog.created_at}
                                  </td>
                                  {/* <td
                                    width="10%"
                                    className="align-middle text-center"
                                    style={styles.th}
                                  >
                                    <i
                                      className="material-icons view_link"
                                      onClick={() =>
                                        this.deleteCouponCode(couponCode.id)
                                      } 
                                    >
                                      delete
                                    </i>
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="7">
                                No record found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
    th: {
      textAlign: "center",
    },
  };