import React, { Component } from "react";
import Header from "../../components/Header";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import { getFAQAPI, getFAQAEDITPI } from "../../core/services/APIServices";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

export default class EditFAQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqQuestion: "",
      faqAnswer: "",
      faqType: "",
      formErrors: {
        faqQuestion: "",
        faqAnswer: "",
        faqType: "",
      },
      buttonText: "Submit",
      buttonDisabled: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getFAQAPI();
  }

  getFAQAPI() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getFAQAPI(id, this.props.location.search.substr(4, 100), token)
      .then((response) => {
        if (response.data["status"] === 200) {
          /* console.log(response.data.data.question); */
          this.setState({
            faqQuestion: response.data.data.question,
            faqAnswer: response.data.data.answer,
            faqType: response.data.data.user_type,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        NotificationManager.error("Something went wrong", "Error!");
      });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "faqQuestion":
        formErrors.faqQuestion =
          value.length <= 0 ? "FAQ Question is required" : "";
        break;

      case "faqAnswer":
        formErrors.faqAnswer =
          value.length <= 0 ? "FAQ Answer is required" : "";
        break;

      case "faqType":
        formErrors.faqType = value === "" ? "FAQ Type is required" : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { faqQuestion, faqAnswer, faqType } = this.state;
    if (faqQuestion === "") {
      formErrors.faqQuestion = "FAQ Question is required";
    }
    if (faqAnswer === "") {
      formErrors.faqAnswer = "FAQ Answer is required";
    }
    if (faqType === "") {
      formErrors.faqType = "FAQ Type is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var id = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      this.setState({
        buttonText: "Submitting...",
        buttonDisabled: true,
      });
      try {
        const response = await getFAQAEDITPI(
          id,
          this.props.location.search.substr(4, 100),
          token,
          this.state.faqQuestion,
          this.state.faqAnswer,
          this.state.faqType
        );
        if (response.data["status"] === 200) {
          NotificationManager.success(
            "FAQ edited successfully",
            "Success",
            3000
          );
          this.setState({
            buttonText: "Submit",
          });
          setTimeout(() => {
            if (this.state.faqType == "Chef") {
              this.props.history.push("/chef-faq-list");
            } else {
              this.props.history.push("/diner-faq-list");
            }
          }, 2000);
        } else {
          NotificationManager.error(response.data["message"], "Error!");
          this.setState({
            buttonText: "Submit",
            buttonDisabled: false,
          });
        }
      } catch (error) {
        NotificationManager.error(error, "Error!");
        this.setState({
          buttonText: "Submit",
          buttonDisabled: false,
        });
      }
    } else {
      console.log("error");
    }
  };
  render() {
    const { formErrors } = this.state;
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Edit FAQ's</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="row">
                      <div className="col-md-8">
                        <form onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            <b>Question*</b>
                            <div className="form-line">
                              <input
                                autoComplete="new-password"
                                type="text"
                                className="form-control"
                                name="faqQuestion"
                                value={this.state.faqQuestion}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.faqQuestion.length > 0 && (
                              <span className="text-danger">
                                {formErrors.faqQuestion}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <b>Answer*</b>
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control"
                                name="faqAnswer"
                                value={this.state.faqAnswer}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {formErrors.faqAnswer.length > 0 && (
                              <span className="text-danger">
                                {formErrors.faqAnswer}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <b>Type</b>
                            <div className="form-line">
                              <select
                                className="form-control"
                                name="faqType"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputChange}
                              >
                                <option value="">Select FAQ's Type</option>
                                <option
                                  value="Chef"
                                  selected={this.state.faqType == "Chef"}
                                >
                                  Chef
                                </option>
                                <option
                                  value="Diner"
                                  selected={this.state.faqType == "Diner"}
                                >
                                  Diner
                                </option>
                              </select>
                            </div>
                            {formErrors.faqType.length > 0 && (
                              <span className="text-danger">
                                {formErrors.faqType}
                              </span>
                            )}
                          </div>
                          <Link
                            to="/chef-faq-list"
                            className="btn btn-default waves-effect"
                            style={{ color: "#000", marginRight: "10px" }}
                          >
                            Cancel
                          </Link>
                          <button
                            type="submit"
                            disabled={this.state.buttonDisabled}
                            className="btn"
                          >
                            {this.state.buttonText}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
