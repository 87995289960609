/*
#############################################################################
# Name : cityReport.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display the cites detail
#############################################################################
*/
import React from 'react';
import Header from "../../components/Header";
import Pagination from "react-js-pagination";
import { Link, Redirect } from 'react-router-dom'
import Moment from 'moment';
import { getCityWiseReportAPI } from "../../core/services/APIServices";
import SideBarSection from '../../components/Sidebar';

class CityReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            userEmailID: "",
            userList: [],
            activePage: 1,
            totalRecords: 10,
            totalData: '',
            completedData: '',
            registeredData: ''
        }
    }

    componentDidMount() {
        var name = localStorage.getItem("userName");
        var email = localStorage.getItem("userEmail");
        this.setState({
            userName: name,
            userEmailID: email,
        });
        this.getCityReport();
    }


    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber }, () => {
            this.getCityReport()
        });
    }

    getCityReport() {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var currentPage = this.state.activePage
        return getCityWiseReportAPI(id, currentPage, token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    /* console.log(response.data); */
                    this.setState({
                        userList: response.data["data"]["records"],
                        totalRecords: response.data["data"]["pager_count"],
                        totalData: response.data["data"]["total_records"],
                        completedData: response.data["data"]["completed"],
                        registeredData: response.data["data"]["registered"],
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">City Wise Report</h2>
                                    </div>
                                    <div className="header details_wrap">
                                        <h2 className="fw_500">Registered: <span>{this.state.registeredData}</span></h2>
                                        <h2 className="fw_500">Completed: <span>{this.state.completedData}</span></h2>
                                        <h2 className="fw_500">Total: <span>{this.state.totalData}</span></h2>
                                    </div>
                                    <div className="body">
                                        <div className="table-responsive">
                                            {this.state.userList.length !== 0 ? <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>City Name</th>
                                                        <th>Registered</th>
                                                        <th>Completed</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.userList.map((user, i) => {
                                                        return (

                                                            <tr key={i}>
                                                                <td>{user.city_name == null ? "-":user.city_name}</td>
                                                                <td>{user.total_registrations}</td>
                                                                <td>{user.completed}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table> : null}
                                            <div className="text-right">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.totalRecords}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default CityReport;