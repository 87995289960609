import React, { Component } from 'react'
import Header from "../../components/Header";
import { getSubscriptionPackageAPI, editSubscriptionPackageAPI } from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import Moment from 'moment';
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from 'react-router-dom'
import { NotificationManager } from "react-notifications";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === null && (valid = false)
    })

    return valid;
}

const nameRegx = RegExp(/([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*/);

export default class EditSubscriptionPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packagess: '',
            duration: '',
            basic_price: '',
            basic_strike_price: '',
            gst_percentage: '',
            formErrors: {
                gstPercentage: '',
                basicStrickPrice: '',
                basicPrice: '',
                duration: '',
                packagess: '',
            },
            buttonText: "Submit",
            buttonDisabled: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getSubscriptionPackageAPI();
    }

    getSubscriptionPackageAPI() {
        var id = localStorage.getItem("userID");
        var token = localStorage.getItem("accessToken");
        var currentPage = this.state.activePage
        return getSubscriptionPackageAPI(id, this.props.location.search.substr(4, 100), token)
            .then((response) => {
                if (response.data["status"] === 200) {
                    this.setState({
                        packagess: response.data.data.package,
                        duration: response.data.data.duration,
                        basic_price: response.data.data.basic_price,
                        basic_strike_price: response.data.data.basic_strike_price,
                        gst_percentage: response.data.data.gst_percentage,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                    NotificationManager.error("Something went wrong", "Error!");
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false
                })
                NotificationManager.error("Something went wrong", "Error!");
            });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'basic_price':
                formErrors.basicPrice = (value == '' || value < 0 ? 'Basic Price is required' : "");
                break;

            case 'packagess':
                formErrors.packagess = (value == '' ? 'Package is required' : "");
                break;
				
			case 'duration':
                formErrors.duration = (value == '' ? 'Duration is required' : "");
                break;
				
			case 'gst_percentage':
                formErrors.gstPercentage = (value == '' ? 'Gst percentage is required' : "");
                break;

            default:
                break;
        }

        this.setState({ formErrors, [name]: value })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        const { packagess, duration, basic_price, basic_strike_price, gst_percentage } = this.state;
        if (packagess === "") {
            formErrors.packagess = "Package is required";
        }
        if (duration === "") {
            formErrors.duration = "Duration is required";
        }
		if (gst_percentage === "") {
            formErrors.gstPercentage = "Gst percentage is required";
        }
        if (basic_price === "" || parseFloat(basic_price) < 0) {
            formErrors.basicPrice = "Basic Price is required";
        }
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state)) {
            var id = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            this.setState({
                buttonText: "Submitting...",
                buttonDisabled: true
            })
            return editSubscriptionPackageAPI(id, token, this.props.location.search.substr(4, 100), packagess, duration, basic_price, basic_strike_price, gst_percentage)
                .then((response) => {
                    if (response.data["status"] === 200) {
                        NotificationManager.success(
                            "Subscription package updated successfully",
                            "Success",
                            3000
                        );
                        this.setState({
                            buttonText: "Submit",
                        })
                        setTimeout(() => {
                            this.props.history.push('/subscription-package-list');
                        }, 2000)
                    } else {
                        NotificationManager.error("Something went wrong", "Error!");
                        this.setState({
                            buttonText: "Submit",
                            buttonDisabled: false
                        })
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error, "Error!");
                    this.setState({
                        buttonText: "Submit",
                        buttonDisabled: false
                    })
                });
        } else {
            console.log('error');
        }
    }
    render() {
        const { formErrors } = this.state;
        if (!localStorage.getItem("accessToken")) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Header />
                <SideBarSection />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div className="header">
                                        <h2 className="fw_500">Edit Subscription Package</h2>
                                        <ul className="header-dropdown m-r--5">
                                            <li className="dropdown">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                    <li>
                                                        <a href="#">Action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Another action</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Something else here</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    {
                                        this.state.isLoading ?
                                            <center>
                                                <Loader
                                                    style={{ marginTop: 50, paddingBottom: 50 }}
                                                    type="Oval"
                                                    color="#F07C07"
                                                    height={50}
                                                    width={50}
                                                /></center> :
                                            <div className="body">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <form onSubmit={this.handleSubmit}>
														
															<div className="form-group">
																<b>Package*</b>
																<div className="form-line">
																	<select
																		className='form-control show-tick'
																		value={this.state.packagess}
																		onChange={(v) => {
																			  formErrors.packagess = '';
																			  const { value } = v.target;
																			  if (
																				value !== '-- Any --' ||
																				value !== '0'
																			  ) {
																				this.setState({
																				  packagess: v.target.value
																				});
																			  } else {
																				this.setState({
																				  packagess: '0'
																				});
																			  }
																			}}
																		>
																		<option value={'0'}>-- Any --</option>
																		<option value='Registration Package'>Registration Package</option>
																		<option value='Marketing Package'>Marketing Package</option>
																	</select>
																</div>
															   {formErrors.packagess.length > 0 && (
																	<span className="text-danger">{formErrors.packagess}</span>
																)}
															</div>
															
															<div className="form-group">
																<b>Duration*</b>
																<div className="form-line">
																	<select
																		className='form-control show-tick'
																		value={this.state.duration}
																		onChange={(v) => {
																			  formErrors.duration = '';
																			  const { value } = v.target;
																			  if (
																				value !== '-- Any --' ||
																				value !== '0'
																			  ) {
																				this.setState({
																				  duration: v.target.value
																				});
																			  } else {
																				this.setState({
																				  duration: '0'
																				});
																			  }
																			}}
																		>
																		<option value={'0'}>-- Any --</option>
																		<option value='1 Month'>1 Month</option>
																		<option value='2 Month'>2 Month</option>
																		<option value='3 Month'>3 Month</option>
																		<option value='4 Month'>4 Month</option>
																		<option value='5 Month'>5 Month</option>
																		<option value='6 Month'>6 Month</option>
																		<option value='7 Month'>7 Month</option>
																		<option value='8 Month'>8 Month</option>
																		<option value='9 Month'>9 Month</option>
																		<option value='10 Month'>10 Month</option>
																		<option value='11 Month'>11 Month</option>
																		<option value='12 Month'>12 Month</option>
																	</select>
																</div>
																{formErrors.duration.length > 0 && (
																	<span className="text-danger">{formErrors.duration}</span>
																)}
															</div>
													
                                                            <div className="form-group">
                                                                <b>Basic Price*</b>
                                                                <div className="form-line">
                                                                    <input type="number" step="any" className="form-control" name="basic_price"
                                                                        value={this.state.basic_price}
                                                                        onChange={this.handleInputChange}
                                                                        onBlur={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                {formErrors.basicPrice.length > 0 && (
                                                                    <span className="text-danger">{formErrors.basicPrice}</span>
                                                                )}
                                                            </div>

                                                            <div className="form-group">
                                                                <b>Basic Strick Price</b>
                                                                <div className="form-line">
                                                                    <input type="number" step="any" className="form-control" name="basic_strike_price"
                                                                        value={this.state.basic_strike_price}
                                                                        onChange={this.handleInputChange}
                                                                        onBlur={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                {formErrors.basicStrickPrice.length > 0 && (
                                                                    <span className="text-danger">{formErrors.basicStrickPrice}</span>
                                                                )}
                                                            </div>
															
															<div className="form-group">
																<b>GST(%)*</b>
																<div className="form-line">
																	<select
																		className='form-control show-tick'
																		value={this.state.gst_percentage}
																		onChange={(v) => {
																			  formErrors.gstPercentage = '';
																			  const { value } = v.target;
																			  if (
																				value !== '-- Any --' ||
																				value !== '0'
																			  ) {
																				this.setState({
																				  gst_percentage: v.target.value
																				});
																			  } else {
																				this.setState({
																				  gst_percentage: '0'
																				});
																			  }
																			}}
																		>
																		<option value={'0'}>-- Any --</option>
																		<option value='5'>5</option>
																		<option value='12'>12</option>
																		<option value='18'>18</option>
																		<option value='28'>28</option>
																	</select>
																</div>
																{formErrors.gstPercentage.length > 0 && (
																	<span className="text-danger">{formErrors.gstPercentage}</span>
																)}
															</div>
													
                                                            <Link to="/subscription-package-list" className="btn btn-default waves-effect" style={{ color: "#000", marginRight: "10px" }}>Cancel</Link>
                                                            <button type="submit" disabled={this.state.buttonDisabled} className="btn">{this.state.buttonText}</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
