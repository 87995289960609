/*
#############################################################################
# Name : enquiresList.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display enquires data raised by the frontend users
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import {
  getEnquireListAPI,
  deleteUserAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class EnquiresListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userName: "",
      userEmailID: "",
      userList: [],
      dinerQueryExcelData: [],
      activePage: 1,
      totalRecords: 10,
      isLoading: true,
      currentDate: "",
    };
  }

  componentDidMount() {
    var name = localStorage.getItem("userName");
    var email = localStorage.getItem("userEmail");
	var role = localStorage.getItem('userRole');
    this.setState({
	  userRole: role,
      userName: name,
      userEmailID: email,
    });
    this.getUserList();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getUserList();
    });
  }

  getUserView = (id, event) => {
    event.preventDefault();
    localStorage.setItem("chefID", id);
    this.props.history.push("/chef-data");
  };

  deleteUser = (id, event) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        return deleteUserAPI(userId, id, token)
          .then((response) => {
            /* console.log(response.data); */
            if (response.data["status"] === 200) {
              this.getUserList();
              swal("User has been deleted!", {
                icon: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  getUserList() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getEnquireListAPI(id, currentPage, token)
      .then((response) => {
        /* console.log(response.data); */
        if (response.data["status"] === 200) {
          /* console.log(response.data.data.pager_count); */
          this.setState({
            userList: response.data["data"].data,
            dinerQueryExcelData: response.data.data.records_found,
            totalRecords: response.data["data"]["pager_count"],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }
  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Party Order Enquiry Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      {this.state.userList.length && this.state.userRole == 'Admin' ? (
                        <ExcelFile
                          filename={"Enquiry_Report_" + this.state.currentDate}
                          element={
                            <button className="btn pull-right m-b-10">
                              Download Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.dinerQueryExcelData}
                            name="Enquiry Report"
                          >
                            <ExcelColumn label="Date" value="created_at" />
                            <ExcelColumn label="Diner Name" value="dinner_name" />
                            <ExcelColumn label="Diner Email ID" value="dinner_email" />
                            <ExcelColumn label="Dner Mobile Number" value="dinner_mobile_number" />
                            <ExcelColumn label="Chef Name" value="chef_name" />
                            <ExcelColumn label="Chef Email ID" value="email" />
                            <ExcelColumn
                              label="Chef Mobile Number"
                              value="mobile_number"
                            />
                            <ExcelColumn
                              label="Message"
                              value="message"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Date</th>
                             <th>Diner Name</th>
                            <th>Diner Email</th>
                            <th>Diner Mobile No.</th> 
                            <th>Chef Name</th>
                            <th>Chef Email</th>
                            <th>Chef Mobile No.</th>
                            <th>Message</th>
                            
                            {/*  <th>Actions</th> */}
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="6">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.userList.length ? (
                          <tbody>
                            {this.state.userList.map((user, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>
                                    {Moment(user.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                   <td>{user.dinner_name}</td>
                                  <td>{user.dinner_email}</td>
                                  <td>{user.dinner_mobile_number}</td> 
                                  <td>
                                    {user.first_name} {user.last_name}
                                  </td>
                                  <td>{user.email}</td>
                                  <td>{user.mobile_number}</td>
                                  <td>{user.message}</td>
                                  
                                  {/* <td>
                                  <a href="#" className="view_link">
                                    <i className="material-icons" onClick={this.getUserView.bind(this, user.user_id)}>
                                      remove_red_eye
                                    </i>
                                  </a>
                                  <a href="#" className="view_link">
                                    <i className="material-icons" onClick={this.deleteUser.bind(this, user.user_id)}>
                                      delete_forever
                                    </i>
                                  </a>
                                </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="6">No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default EnquiresListPage;
