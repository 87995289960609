import React from 'react';
import Header from '../../components/Header';
import {
  getDeleteAccountRequestAPI
} from '../../core/services/APIServices';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import SideBarSection from '../../components/Sidebar';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactExport from 'react-data-export';
import { NotificationManager } from 'react-notifications';

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

const couponCodePattern = /^[a-zA-Z\d-_]+$/;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class deleteAccountListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userEmailID: '',
      orderData: [],
      orderExcelData: [],
      userName: '',
      userToken: '',
      isLoading: true,
      activePage: 1,
      totalRecords: 10,
      dinerName: '',
      dinerEmailID: '',
      dinerMobileNo: '',
      dinerOrderFromDate: '',
      dinerOrderToDate: '',
      formatStartDate: '',
      formatEndDate: '',
      resetFlag: false,
      errorDateMessage: '',
      currentDate: '',
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    var name = localStorage.getItem('userName');
    var token = localStorage.getItem('accessToken');
    this.setState({
      userName: name,
      userToken: token
    });
	
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userEmailID: email
    });
	
    this.getOrderListing();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today
    });
  }
	
  handleFilterChange(key) {
    const { name, value } = key.target;
    let formErrors = this.state.formErrors;
    this.setState({
      [key.target.name]: key.target.value
    });
  }
  
  handleStartDateChange(event) {
    this.setState({
      dinerOrderFromDate: event,
      formatStartDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleEndDateChange(event) {
    this.setState({
      dinerOrderToDate: event,
      formatEndDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  getOrderListing = () => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getDeleteAccountRequestAPI(
      id,
      currentPage,
      this.state.dinerName,
      this.state.dinerEmailID,
      this.state.dinerMobileNo,
      this.state.formatStartDate,
      this.state.formatEndDate,
      token
    )
      .then((response) => {
        if (response.data['status'] == 200) {
          /*  console.log(response); */
          this.setState({
            orderData: response.data.data.data,
            orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
            totalRecords: response.data.data.pager_count,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getOrderListing();
    });
  }

  submitFilter = (event) => {
    event.preventDefault();
    var statusDate = false;
    if (
      (this.state.formatEndDate == '' || this.state.dinerOrderToDate == '') &&
      this.state.formatStartDate
    ) {
      statusDate = true;
      this.setState({ errorDateMessage: 'End Date not selected' });
    }

    if (this.state.formatStartDate == '' && this.state.formatEndDate) {
      statusDate = true;
      this.setState({ errorDateMessage: 'Start Date not selected' });
    }

    if (this.state.formatStartDate && this.state.formatEndDate) {
      statusDate = false;
      this.setState({ errorDateMessage: '', resetFlag: true });
      /* console.log(this.state.orderStatus); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getDeleteAccountRequestAPI(
        id,
        currentPage,
		this.state.dinerName,
		this.state.dinerEmailID,
		this.state.dinerMobileNo,
		this.state.formatStartDate,
		this.state.formatEndDate,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }

    if (statusDate === false && this.state.errorDateMessage === '') {
      this.setState({
        resetFlag: true
      });
      /* console.log(this.state.orderStatus); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getDeleteAccountRequestAPI(
        id,
        currentPage,
		this.state.dinerName,
		this.state.dinerEmailID,
		this.state.dinerMobileNo,
		this.state.formatStartDate,
		this.state.formatEndDate,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        dinerName: '',
        dinerEmailID: '',
        dinerMobileNo: '',
        dinerOrderFromDate: '',
        dinerOrderToDate: '',
        formatStartDate: '',
        formatEndDate: ''
      },
      () => {
        this.getOrderListing();
      }
    );
  };

  render() {
    if (!localStorage.getItem('accessToken')) {
      return <Redirect to='/' />;
    }

    const { formErrors } = this.state;

    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row clearfix'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='header'>
                    <h2 className='fw_500'>Delete Account Request Report</h2>
                    <ul className='header-dropdown m-r--5'>
                      <li className='dropdown'>
                        <a
                          href='#'
                          className='dropdown-toggle'
                          data-toggle='dropdown'
                          role='button'
                          aria-haspopup='true'
                          aria-expanded='false'
                        ></a>
                        <ul className='dropdown-menu pull-right'>
                          <li>
                            <a href='#'>Action</a>
                          </li>
                          <li>
                            <a href='#'>Another action</a>
                          </li>
                          <li>
                            <a href='#'>Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className='body'>
                    <div className='row clearfix'>
                      <div className='col-sm-12'>
                        <div className='row clearfix'>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='dinerName'
                                  value={this.state.dinerName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Email ID</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='dinerEmailID'
                                  value={this.state.dinerEmailID}
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Mobile No</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='dinerMobileNo'
                                  value={this.state.dinerMobileNo}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>						  
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <b>Created Date Range</b>
                              <div className='datePickerFlex'>
                                <DatePicker
                                  selected={this.state.dinerOrderFromDate}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.dinerOrderToDate}
                                />
                                <p>&nbsp;&nbsp;to</p>
                                <DatePicker
                                  selected={this.state.dinerOrderToDate}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.dinerOrderFromDate}
                                />
                              </div>
                            </div>
                            {this.state.errorDateMessage ? (
                              <p className='errorMessage'>
                                {this.state.errorDateMessage}
                              </p>
                            ) : null}
                          </div>
                          <div className='col-sm-12 center_btn'>
                            <button
                              type='submit'
                              className='btn'
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type='submit'
                                className='btn'
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='table-responsive'>
                      {this.state.orderExcelData.length  && (this.state.userRole == 'Admin' || this.state.userRole == 'Finance' || this.state.userRole == 'Ops') ? (
                        <ExcelFile
                          filename={'Delete_Account_Request_Report_' + this.state.currentDate}
                          element={
                            <button className='btn pull-right m-b-10'>
							{(this.state.userRole == 'Finance' || this.state.userRole == 'Ops') ? 'Last 30 Days Download Excel' : 'Download Excel'}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.orderExcelData}
                            name='Delete Account Request Report'
                          >
							<ExcelColumn
                              label='Created At'
                              value='created_at'
                            />
                            <ExcelColumn
                              label='Diner Name'
                              value='name'
                            />
                            <ExcelColumn
                              label='Diner Email ID'
                              value='email'
                            />
                            <ExcelColumn
                              label='Diner Mobile No'
                              value='mobile_number'
                            />
							<ExcelColumn
                              label='Delete At'
                              value='deleted_at'
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className='table table-bordered table-striped table-hover tbl_responsive'>
                        <thead>
                          <tr>
							<th>Sr. No.</th>
                            <th>Created At</th>
                            <th>Name</th>
                            <th>Email ID</th>
                            <th>Mobile No</th>
                            <th>Deleted At</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className='text-center' colSpan='6'>
                                <Loader
                                  type='Oval'
                                  color='#F07C07'
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.orderData.length ? (
                          <tbody>
                            {this.state.orderData.map((order, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className='align-middle text-center'
                                    width='10%'
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + '' + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{order.created_at}</td>
                                  <td>{order.name}</td>
                                  <td>{order.email}</td>
                                  <td>{order.mobile_number}</td>
                                  <td>{order.deleted_at}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan='6'>No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className='text-right'>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default deleteAccountListing;