import React from 'react';
import Header from '../../components/Header';
import {
  getDinerOrderAPI,
  markPaymentComplete
} from '../../core/services/APIServices';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import SideBarSection from '../../components/Sidebar';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactExport from 'react-data-export';
import { NotificationManager } from 'react-notifications';

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

const couponCodePattern = /^[a-zA-Z\d-_]+$/;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class OrderListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      userEmailID: '',
      orderData: [],
      orderExcelData: [],
      userName: '',
      userToken: '',
      isLoading: true,
      activePage: 1,
      totalRecords: 10,
      dinerName: '',
      chefName: '',
      orderId: '',
      orderNo: '',
      payID: '',
      orderStatus: '',
      orderType: '',
      is_same_day_delivery: '',
      orderCity: '',
      dinerOrderFromDate: '',
      dinerOrderToDate: '',
      formatStartDate: '',
      formatEndDate: '',
      resetFlag: false,
      errorDateMessage: '',
      currentDate: '',
      couponFilter: '',
      ordersMarkedForPayment: [],
      paymentAmount: '',
      paymentUtrNumber: '',
      paymentRemark: '',
      isOffline: '',
	  isClick: '0',
      formErrors: {
        couponFilter: ''
      }
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    var name = localStorage.getItem('userName');
    var token = localStorage.getItem('accessToken');
    this.setState({
      userName: name,
      userToken: token
    });
	
    var email = localStorage.getItem('userEmail');
    var role = localStorage.getItem('userRole');
    this.setState({
      userRole: role,
      userEmailID: email
    });
	
    this.getOrderListing();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today
    });
  }

  handleFilterChange(key) {
    const { name, value } = key.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'couponFilter':
        formErrors.couponFilter = !couponCodePattern.test(key.target.value)
          ? 'Enter valid Coupon Code.'
          : '';
        break;
    }
    if (name === 'chefName' || name === 'orderStatus') {
      this.setState({
        ordersMarkedForPayment: [],
        paymentAmount: '',
        paymentRemark: '',
        isOffline: '',
        isClick: '0',
        paymentUtrNumber: ''
      });
    }
    this.setState({
      [key.target.name]: key.target.value
    });
  }
  
  handleCheckboxInput = (e) => {
    this.setState({
      isOffline: e.target.checked
    });
  };

  handleStartDateChange(event) {
    this.setState({
      dinerOrderFromDate: event,
      formatStartDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  handleEndDateChange(event) {
    this.setState({
      dinerOrderToDate: event,
      formatEndDate: Moment(event).format('YYYY-MM-DD')
    });
  }

  getOrderListing = () => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getDinerOrderAPI(
      id,
      currentPage,
      this.state.orderId,
      this.state.dinerName,
      this.state.chefName,
      this.state.orderNo,
      this.state.orderStatus,
      this.state.is_same_day_delivery,
      this.state.orderCity,
      this.state.formatStartDate,
      this.state.formatEndDate,
      this.state.payID,
      this.state.couponFilter,
      this.state.orderType,
      token
    )
      .then((response) => {
        if (response.data['status'] == 200) {
          /*  console.log(response); */
          this.setState({
            orderData: response.data.data.data,
            orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
            totalRecords: response.data.data.pager_count,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getOrderListing();
    });
  }

  getOrderView = (id) => {
    localStorage.setItem('orderID', id);
    this.props.history.push('/diner-order-detail');
  };

  submitFilter = (event) => {
    event.preventDefault();
    var statusDate = false;
    if (
      (this.state.formatEndDate == '' || this.state.dinerOrderToDate == '') &&
      this.state.formatStartDate
    ) {
      statusDate = true;
      this.setState({ errorDateMessage: 'End Date not selected' });
    }

    if (this.state.formatStartDate == '' && this.state.formatEndDate) {
      statusDate = true;
      this.setState({ errorDateMessage: 'Start Date not selected' });
    }

    if (this.state.formatStartDate && this.state.formatEndDate) {
      statusDate = false;
      this.setState({ errorDateMessage: '', resetFlag: true });
      /* console.log(this.state.orderStatus); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getDinerOrderAPI(
        id,
        currentPage,
        this.state.orderId,
        this.state.dinerName,
        this.state.chefName,
        this.state.orderNo,
        this.state.orderStatus,
        this.state.is_same_day_delivery,
        this.state.orderCity,
        this.state.formatStartDate,
        this.state.formatEndDate,
        this.state.payID,
        this.state.couponFilter,
        this.state.orderType,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
			  isClick: '1',
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }

    if (statusDate === false && this.state.errorDateMessage === '') {
      this.setState({
        resetFlag: true
      });
      /* console.log(this.state.orderStatus); */
      var id = localStorage.getItem('userID');
      var token = localStorage.getItem('accessToken');
      var currentPage = '1';
      this.setState({
        activePage: 1
      });
      return getDinerOrderAPI(
        id,
        currentPage,
        this.state.orderId,
        this.state.dinerName,
        this.state.chefName,
        this.state.orderNo,
        this.state.orderStatus,
        this.state.is_same_day_delivery,
        this.state.orderCity,
        this.state.formatStartDate,
        this.state.formatEndDate,
        this.state.payID,
        this.state.couponFilter,
        this.state.orderType,
        token
      )
        .then((response) => {
          if (response.data['status'] == 200) {
            /* console.log(response); */
            this.setState({
              orderData: response.data.data.data,
              orderExcelData: (this.state.userRole == 'Admin' ? response.data['data']['records_found'] : response.data['data']['last_30_days_records_found']),
              totalRecords: response.data.data.pager_count,
			  isClick: '1',
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false
          });
        });
    }
  };

  resetFilter = (event) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true,
        resetFlag: false,
        dinerName: '',
        chefName: '',
        orderId: '',
        orderNo: '',
        payID: '',
        orderStatus: '',
        orderType: '',
        ordersMarkedForPayment: [],
        is_same_day_delivery: '',
        orderCity: '',
        dinerOrderFromDate: '',
        dinerOrderToDate: '',
        formatStartDate: '',
        formatEndDate: '',
		isClick: '0',
        couponFilter: ''
      },
      () => {
        this.getOrderListing();
      }
    );
  };

  render() {
    if (!localStorage.getItem('accessToken')) {
      return <Redirect to='/' />;
    }

    const { formErrors } = this.state;

    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row clearfix'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='header'>
                    <h2 className='fw_500'>Order Report</h2>
                    <ul className='header-dropdown m-r--5'>
                      <li className='dropdown'>
                        <a
                          href='#'
                          className='dropdown-toggle'
                          data-toggle='dropdown'
                          role='button'
                          aria-haspopup='true'
                          aria-expanded='false'
                        ></a>
                        <ul className='dropdown-menu pull-right'>
                          <li>
                            <a href='#'>Action</a>
                          </li>
                          <li>
                            <a href='#'>Another action</a>
                          </li>
                          <li>
                            <a href='#'>Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className='body'>
                    <div className='row clearfix'>
                      <div className='col-sm-12'>
                        <div className='row clearfix'>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Order ID</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='orderId'
                                  value={this.state.orderId}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Order Number</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='orderNo'
                                  value={this.state.orderNo}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Pay ID</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='payID'
                                  value={this.state.payID}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Diner Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='dinerName'
                                  value={this.state.dinerName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Chef Name</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='chefName'
                                  value={this.state.chefName}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>City</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='orderCity'
                                  value={this.state.orderCity}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Search Coupon</b>
                              <div className='form-line'>
                                <input
                                  type='text'
                                  className='form-control'
                                  name='couponFilter'
                                  value={this.state.couponFilter}
                                  maxLength='20'
                                  onChange={this.handleFilterChange}
                                  style={{ textTransform: 'uppercase' }}
                                />
                              </div>
                              <span className='text-danger'>
                                {formErrors.couponFilter}
                              </span>
                            </div>
                          </div>
						   <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Is Same-day Delivery</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='is_same_day_delivery'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select</option>
                                  <option
                                    value='1'
                                    selected={
                                      this.state.is_same_day_delivery == 'Yes'
                                    }
                                  >
                                    Yes
                                  </option>
                                  <option
                                    value='0'
                                    selected={
                                      this.state.is_same_day_delivery == 'No'
                                    }
                                  >
                                    No
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Order Status</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='orderStatus'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select Order Status</option>
                                  <option
                                    value='Pending'
                                    selected={
                                      this.state.orderStatus == 'Pending'
                                    }
                                  >
                                    Pending
                                  </option>
                                  <option
                                    value='Cancelled'
                                    selected={
                                      this.state.orderStatus == 'Cancelled'
                                    }
                                  >
                                    Cancelled
                                  </option>
                                  <option
                                    value='Ordered'
                                    selected={
                                      this.state.orderStatus == 'Ordered'
                                    }
                                  >
                                    Ordered
                                  </option>
                                  <option
                                    value='Prepared'
                                    selected={
                                      this.state.orderStatus == 'Prepared'
                                    }
                                  >
                                    Prepared
                                  </option>
                                  <option
                                    value='Accepted'
                                    selected={
                                      this.state.orderStatus == 'Accepted'
                                    }
                                  >
                                    Accepted
                                  </option>
                                  <option
                                    value='Rejected'
                                    selected={
                                      this.state.orderStatus == 'Rejected'
                                    }
                                  >
                                    Rejected
                                  </option>
                                  <option
                                    value='Delivered'
                                    selected={
                                      this.state.orderStatus == 'Delivered'
                                    }
                                  >
                                    Delivered
                                  </option>
                                  <option
                                    value='Failed'
                                    selected={
                                      this.state.orderStatus == 'Failed'
                                    }
                                  >
                                    Failed
                                  </option>
                                  <option
                                    value='Timed out'
                                    selected={
                                      this.state.orderStatus == 'Timed out'
                                    }
                                  >
                                    Timed out
                                  </option>
                                  <option
                                    value='Hold'
                                    selected={this.state.orderStatus == 'Hold'}
                                  >
                                    Hold
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
						  
						 <div className='col-sm-3'>
                            <div className='form-group'>
                              <b>Order Type</b>
                              <div className='form-line'>
                                <select
                                  className='form-control'
                                  name='orderType'
                                  onChange={this.handleFilterChange}
                                  onBlur={this.handleFilterChange}
                                >
                                  <option value=''>Select Order Type</option>
                                  <option
                                    value='App'
                                    selected={
                                      this.state.orderType == 'App'
                                    }
                                  >
                                    App
                                  </option>
								  <option
                                    value='Browser'
                                    selected={
                                      this.state.orderType == 'Browser'
                                    }
                                  >
                                    Browser
                                  </option>
								</select>
								</div>
							</div>
						  </div>
						  
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <b>Date Range</b>
                              <div className='datePickerFlex'>
                                <DatePicker
                                  selected={this.state.dinerOrderFromDate}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.dinerOrderToDate}
                                />
                                <p>&nbsp;&nbsp;to</p>
                                <DatePicker
                                  selected={this.state.dinerOrderToDate}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.dinerOrderFromDate}
                                />
                              </div>
                            </div>
                            {this.state.errorDateMessage ? (
                              <p className='errorMessage'>
                                {this.state.errorDateMessage}
                              </p>
                            ) : null}
                          </div>
                          <div className='col-sm-12 center_btn'>
                            <button
                              type='submit'
                              className='btn'
                              onClick={this.submitFilter}
                            >
                              Submit
                            </button>
                            {this.state.resetFlag ? (
                              <button
                                type='submit'
                                className='btn'
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {this.state.ordersMarkedForPayment &&
                        this.state.ordersMarkedForPayment.length > 0 && (
                          <div style={{ marginRight: '1.6rem' }}>
                            <button
                              className='btn pull-right m-b-10'
                              data-toggle='modal'
                              data-target='#myModalPayChefMenu'
                            >
                              Pay
                            </button>
                          </div>
                        )}
                    </div>

                    <div className='table-responsive'>
                      {this.state.orderExcelData.length  && (this.state.userRole == 'Admin' || this.state.userRole == 'Finance' || this.state.userRole == 'Ops' || this.state.userRole == 'Marketing') ? (
                        <ExcelFile
                          filename={'Order_Report_' + this.state.currentDate}
                          element={
                            <button className='btn pull-right m-b-10'>
							{(this.state.userRole == 'Finance' || this.state.userRole == 'Ops' || this.state.userRole == 'Marketing') ? 'Last 100 Days Download Excel' : 'Download Excel'}
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.orderExcelData}
                            name='Order Report'
                          >
                            <ExcelColumn
                              label='Order Date'
                              value='created_at'
                            />
                            <ExcelColumn
                              label='Order Number'
                              value='order_number'
                            />
                            <ExcelColumn
                              label='Order ID(RazorPay)'
                              value='order_id'
                            />
                            <ExcelColumn
                              label='Payment ID(Razorpay)'
                              value='transaction_id'
                            />
                            <ExcelColumn
                              label='Order Selected Slot'
                              value='selected_time_slot'
                            />
                            <ExcelColumn label='Order Status' value='status' />
                            <ExcelColumn
                              label='Order Takeaway'
                              value='is_takeaway_selected'
                            />
                            <ExcelColumn label='Chef Name' value='chef_name' />
                            <ExcelColumn
                              label='Chef EmailID'
                              value='chef_email'
                            />
                            <ExcelColumn
                              label='Chef Mobile'
                              value='chef_mobile_number'
                            />
                            <ExcelColumn
                              label='Chef Address'
                              value='pickup_location'
                            />
                            <ExcelColumn
                              label='Diner Name'
                              value='diner_name'
                            />
                            <ExcelColumn
                              label='Diner EmailID'
                              value='diner_email'
                            />
                            <ExcelColumn
                              label='Diner Mobile'
                              value='diner_mobile_number'
                            />
                            <ExcelColumn
                              label='Diner Address'
                              value='drop_location'
                            />
                            <ExcelColumn
                              label='Delivery Partner'
                              value='delivery_partner'
                            />
                            <ExcelColumn
                              label='Delivery Tracking ID'
                              value='order_tracking_id'
                            />
                            <ExcelColumn
                              label='Delivery Charges'
                              value='delivery_charges'
                            />
                            <ExcelColumn
                              label='Actual Delivery Charges'
                              value='actual_delivery_charges'
                            />
                            <ExcelColumn
                              label='Tax Amount'
                              value='tax_amount'
                            />
                            <ExcelColumn
                              label='Discount Amount'
                              value='discount_amount'
                            />
                            <ExcelColumn
                              label='Discount Description'
                              value='discount_description'
                            />
							<ExcelColumn
                              label='Commission Rate %'
                              value='commission_rate'
                            />
                            <ExcelColumn
                              label='Commission + GST Amount'
                              value='commission_and_gst_amount'
                            />
                            <ExcelColumn
                              label='Dish Amount'
                              value='dish_amount'
                            />
                            <ExcelColumn
                              label='Amount Paid'
                              value='amount_paid'
                            />
                            <ExcelColumn
                              label='Coupon Applied'
                              value='coupon_applied'
                            />
                            <ExcelColumn
                              label='Coupon Discount'
                              value='coupon_discount_amount'
                            />
                            <ExcelColumn
                              label='GST Number'
                              value='gst_registration_number'
                            />
							<ExcelColumn
                              label='Same-day Delivery'
                              value='is_same_day_delivery'
                            />
							<ExcelColumn
                              label='UTR Number'
                              value='chef_paid_utr_number'
                            />
							<ExcelColumn
                              label='Order Type'
                              value='order_from'
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className='table table-bordered table-striped table-hover tbl_responsive order-table'>
                        <thead>
                          <tr>
                            {this.state.chefName !== '' &&
                              this.state.orderStatus === 'Delivered' &&  this.state.isClick == '1' && (this.state.userRole == 'Admin' || this.state.userRole == 'Ops' || this.state.userRole == 'Finance') && (
                                <th>Mark</th>
                              )}

                            <th>Sr. No.</th>
                            <th>Order Date</th>
                            <th>Order No.</th>
                            <th>Pay ID</th>
                            <th>Tracking ID</th>
                            <th>Diner Name</th>
                            <th>Chef Name</th>
                            <th>City</th>
                            <th>Checkout Amount</th>
                            <th>Dish Amount</th>
                            <th>Commission + GST Amount</th>
                            <th>Delivery Fee</th>
                            <th>Tax & Charges</th>
                            <th>Discount</th>
                            <th>Coupon Applied</th>
                            <th>Coupon Discount</th>
                            <th>Same-day Delivery</th>
                            <th>Status</th>
                            <th>Order Type</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className='text-center' colSpan='20'>
                                <Loader
                                  type='Oval'
                                  color='#F07C07'
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.orderData.length ? (
                          <tbody>
                            {this.state.orderData.map((order, i) => {
                              return (
                                <tr key={i} className={(
									order.status == 'Rejected' ? 'row-red' :
								order.is_same_day_delivery == 'Yes' ? 'row-gray' : '')}>
                                  {this.state.chefName !== '' &&
                                    this.state.orderStatus === 'Delivered' &&  this.state.isClick == '1' && (this.state.userRole == 'Admin' || this.state.userRole == 'Ops' || this.state.userRole == 'Finance') && (
                                      <td>
                                        {order.chef_payment_status === null ? (
                                          <div
                                            className='form-group'
                                            style={{ position: 'relative' }}
                                          >
                                            <input
                                              type='checkbox'
                                              id='isPublished'
                                              name='isPublished'
                                              className='filled-in position-relative'
                                              onChange={() => {
                                                const indexOfOrderNumber =
                                                  this.state.ordersMarkedForPayment.indexOf(
                                                    order.order_number
                                                  );
                                                var newMarked = [
                                                  ...this.state
                                                    .ordersMarkedForPayment
                                                ];
                                                if (indexOfOrderNumber > -1) {
                                                  newMarked = newMarked.filter(
                                                    (el) =>
                                                      el !== order.order_number
                                                  );
                                                } else {
                                                  newMarked.push(
                                                    order.order_number
                                                  );
                                                }

                                                this.setState({
                                                  ordersMarkedForPayment:
                                                    newMarked
                                                });
                                              }}
                                              checked={
                                                this.state.ordersMarkedForPayment.indexOf(
                                                  order.order_number
                                                ) > -1
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <p
                                            style={{
                                              marginTop: '1.5rem',
                                              textAlign: 'center'
                                            }}
                                          >
                                            Paid
                                          </p>
                                        )}
                                      </td>
                                    )}
                                  <td
                                    className='align-middle text-center'
                                    width='10%'
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + '' + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{order.created_at}</td>
                                  <td>{order.order_number}</td>
                                  <td>{order.transaction_id}</td>
                                  {/* <td className="textStatusView">
                                    {order.delivery_partner}
                                  </td> */}
                                  <td className='textStatusView'>
                                    {order.order_tracking_id != null
                                      ? order.order_tracking_id +
                                        ' (' +
                                        order.delivery_partner +
                                        ')'
                                      : '-'}
                                  </td>
                                  <td>
                                    {order.diner_info
                                      ? order.diner_info.split(', ')[0]
                                      : '-'}
                                  </td>
                                  <td>
                                    {order.chef_info
                                      ? order.chef_info.split(', ')[0]
                                      : '-'}
                                  </td>
                                  <td>
                                    {order.city != null ? order.city : '-'}
                                  </td>
                                  <td>₹ {Math.round(order.amount_paid)}</td>
                                  <td>₹ {Math.round(order.dish_amount)}</td>
                                  <td>
                                    ₹{' '}
                                    {Math.round(
                                      order.commission_and_gst_amount
                                    )}
                                  </td>
                                  <td>
                                    ₹ {Math.round(order.delivery_charges)}
                                  </td>
                                  <td>₹ {Math.round(order.tax_amount)}</td>
                                  <td>₹ {Math.round(order.discount_amount)}</td>
                                  <td>
                                    {order.coupon_applied
                                      ? order.coupon_applied
                                      : '-'}
                                  </td>
                                  <td>
                                    {order.coupon_discount_amount
                                      ? order.coupon_discount_amount
                                      : '-'}
                                  </td>
								  <td>
                                    {order.is_same_day_delivery}
                                  </td>
                                  <td className='textStatusView'>
                                    {order.status == 'created'
                                      ? 'pending'
                                      : order.status}
                                  </td>
								  <td>
                                    {order.order_from}
                                  </td>
                                  <td className='text-center'>
									{/*  Admin menu */}
									{this.state.userRole == 'Admin' || this.state.userRole == 'Ops' ? (
                                    <a className='view_link'>
                                      <i
                                        className='material-icons'
                                        onClick={() =>
                                          this.getOrderView(order.id)
                                        }
                                      >
                                        remove_red_eye
                                      </i>
                                    </a>
									) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan='20'>No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className='text-right'>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className='modal fade'
          id='myModalPayChefMenu'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header justify-content-start flex-column'>
                <h5
                  className='modal-title ml-0 text-uppercase'
                  id='pins_achievedTitle'
                >
                  Pay home chef details
                  <button
                    type='button'
                    className='close'
                    id='closeMenuFormButton'
                    data-dismiss='modal'
                    onClick={() =>
                      this.setState({
                        showRejectReasonAlert: false
                      })
                    }
                    aria-label='Close'
                  >
                    <img
                      src='assets/images/icons/black-close.svg'
                      alt='Close'
                    />
                  </button>
                </h5>
              </div>
              <div className='modal-body'>
                <div className='form-group'>
                  <b>Enter Amount</b>
                  <div className='form-line'>
                    <input
                      type='number'
                      className='form-control'
                      value={this.state.paymentAmount}
                      onChange={(e) =>
                        this.setState({ paymentAmount: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <b>Enter UTR Number</b>
                  <div className='form-line'>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.paymentUtrNumber}
                      onChange={(e) =>
                        this.setState({ paymentUtrNumber: e.target.value })
                      }
                    />
                  </div>
                </div>
				{/*<div className='form-group'>
                   <div style={{ marginLeft: '20px' }}>
					<label htmlFor='isOffline'>Is offline?</label>
					<input
					  type='checkbox'
					  id='isOffline'
					  name='isOffline'
					  className='filled-in'
					  onChange={this.handleCheckboxInput}
					  checked={this.state.isOffline}
					/>
				  </div>
                </div>*/}
				<div className='form-group'>
                  <b>Enter Remark</b>
                  <div className='form-line'>
                   <textarea
					className='form-control'
					rows={3}
					defaultValue={this.state.paymentRemark}
					onChange={(e) =>
						this.setState({ paymentRemark: e.target.value })
					}
					name='paymentRemark'
				  ></textarea>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '3rem',
                    display: 'flex',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <button
                    type='button'
                    data-dismiss='modal'
                    id='closePasswordButton'
                    aria-label='Close'
                    disabled={this.state.buttonDisabled}
                    className='btn btn-default waves-effect'
                    style={{ color: '#000', marginRight: '10px' }}
                    onClick={this.menuModalClosed}
                  >
                    Cancel
                  </button>
                  <button
                    data-toggle='modal'
                    data-target='#myModalPayChefMenu'
                    className='btn'
                    disabled={
                      this.state.paymentAmount === '' ||
                      this.state.paymentUtrNumber === '' ||
                      !this.state.paymentAmount
                    }
                    onClick={async () => {
                      console.log(
                        'OrderNumbers: ',
                        this.state.ordersMarkedForPayment
                      );
                      console.log('OrderAmount: ', this.state.paymentAmount);
                      console.log('OrderUtrNumber: ', this.state.paymentUtrNumber);
                      var userId = localStorage.getItem('userID');
                      var token = localStorage.getItem('accessToken');
                      const result = await markPaymentComplete(
                        this.state.ordersMarkedForPayment,
                        this.state.paymentUtrNumber,
                        this.state.paymentAmount,
                        this.state.isOffline,
                        this.state.paymentRemark,
                        userId,
                        token
                      );
                      console.log('Result: ', result.response);
                      if (result.status === 200) {
                        NotificationManager.success(
                          'Payment to home chef recorded successfully',
                          'Success'
                        );
                        this.setState({ isLoading: true }, () => {
                          this.getOrderListing();
                          document.getElementById('myModalPayChefMenu').click();
                        });
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderListingPage;
