/*
#############################################################################
# Name : queryList.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display query list data
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import {
  getQueryListAPI,
  deleteUserAPI,
} from "../../core/services/APIServices";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Moment from "moment";
import SideBarSection from "../../components/Sidebar";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class QueryListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  userRole: '',
      isLoading: true,
      userName: "",
      userEmailID: "",
      queryList: [],
      queryExcelData: [],
      activePage: 1,
      totalRecords: 10,
      name: "",
      email: "",
      mobileNo: "",
      message: "",
      date: "",
      currentDate: "",
    };
  }

  componentDidMount() {
    var name = localStorage.getItem("userName");
    var email = localStorage.getItem("userEmail");
	var role = localStorage.getItem('userRole');
    this.setState({
	  userRole: role,
      userName: name,
      userEmailID: email,
    });
    this.getQueryList();
    let today = new Date().toLocaleDateString();
    this.setState({
      currentDate: today,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getQueryList();
    });
  }

  getUserView = (id, event) => {
    event.preventDefault();
    localStorage.setItem("chefID", id);
    this.props.history.push("/chef-data");
  };

  deleteUser = (id, event) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        return deleteUserAPI(userId, id, token)
          .then((response) => {
            /* console.log(response.data); */
            if (response.data["status"] === 200) {
              this.getQueryList();
              swal("User has been deleted!", {
                icon: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  getQueryList() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getQueryListAPI(id, currentPage, token)
      .then((response) => {
        if (response.data["status"] == 200) {
          /* console.log(response); */
          this.setState({
            queryList: response.data.data.enquiries_messages,
            queryExcelData: response.data.data.records_found,
            totalRecords: response.data.data.pager_count,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }

  getQueryData = (query) => {
    this.setState({
      name: query.name,
      email: query.email,
      mobileNo: query.mobile_number,
      message: query.message,
      date: query.created_at,
    });
  };
  render() {
    if (!localStorage.getItem("accessToken")) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className="content">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2 className="fw_500">Query Listing</h2>
                    <ul className="header-dropdown m-r--5">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <ul className="dropdown-menu pull-right">
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Another action</a>
                          </li>
                          <li>
                            <a href="#">Something else here</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      {this.state.queryList.length && this.state.userRole == 'Admin' ? (
                        <ExcelFile
                          filename={"Query_Report_" + this.state.currentDate}
                          element={
                            <button className="btn pull-right m-b-10">
                              Download Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.queryExcelData}
                            name="Query Report"
                          >
                            <ExcelColumn label="Date" value="created_at" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Email ID" value="email" />
                            <ExcelColumn
                              label="Mobile Number"
                              value="mobile_number"
                            />
                            <ExcelColumn label="Message" value="message" />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Message</th>
                            <th>Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {this.state.isLoading ? (
                          <tbody>
                            <tr>
                              <td className="text-center" colSpan="6">
                                <Loader
                                  type="Oval"
                                  color="#F07C07"
                                  height={30}
                                  width={30}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ) : this.state.queryList.length ? (
                          <tbody>
                            {this.state.queryList.map((query, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="align-middle text-center"
                                    width="10%"
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + "" + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{query.name}</td>
                                  <td>{query.email}</td>
                                  <td>{query.mobile_number}</td>
                                  <td>
                                    {query.message.length > 50
                                      ? query.message.substr(0, 50) + "..."
                                      : query.message}
                                  </td>
                                  <td>
                                    {Moment(query.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="align-middle text-center">
                                    <i
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#myModal"
                                      className="material-icons view_link"
                                      onClick={() => this.getQueryData(query)}
                                    >
                                      remove_red_eye
                                    </i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="6">No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div className="text-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade menu_list_pop"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="add_another_btn">
                  <button
                    type="button"
                    className="close plus_icon"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <h4 className="modal-title color_C07" id="myModalLabel">
                  Query Detail
                </h4>
              </div>
              <div className="modal-body">
                <ul>
                  <li>
                    <b>Name</b>
                    <span>{this.state.name}</span>
                  </li>
                  <li>
                    <b>Email</b>
                    <span>{this.state.email}</span>
                  </li>
                  <li>
                    <b>Mobile Number</b>
                    <span>{this.state.mobileNo}</span>
                  </li>
                  <li>
                    <b>Message</b>
                    <span>{this.state.message}</span>
                  </li>
                  <li>
                    <b>Date</b>
                    <span>{Moment(this.state.date).format("DD/MM/YYYY")}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QueryListPage;
