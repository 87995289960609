import React from 'react';
import Header from '../../components/Header';
import SideBarSection from '../../components/Sidebar';
import { NotificationManager } from 'react-notifications';
import Pagination from 'react-js-pagination';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import {
  getDinerOrderDetailAPI,
  cancelDelivery,
  rejectHoldOrder,
  orderDelivered,
  rescheduleOrder,
  reactivateOrder
} from '../../core/services/APIServices';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-json-pretty/themes/monikai.css';

class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: '',
      orderNumber: '',
      deliveryCharges: '',
      amountPaid: '',
      payID: '',
      chefName: '',
      chefEmail: '',
      chefMobile: '',
      chefAddress: '',
      dinerName: '',
      dinerEmail: '',
      dinerMobile: '',
      dinerAddress: '',
      orderDate: '',
      taxAmount: '',
      deliveryPartner: '',
      trackingId: '',
      orderStatus: '',
      reason_for_chef_rejection: '',
      orderAcceptedAt: '',
      orderPreparedAt: '',
      orderPickedAt: '',
      orderTakeaway: '',
      orderSameDayDelivery: '',
      orderSelectedSlot: '',
      orderCancelledAt: '',
      orderRejectedAt: '',
      reasonOfRejection: '',
      requestCustomization: '',
      chefTrackingURL: '',
      dinerTrackingURL: '',
      refundId: '',
      refundAmount: '',
      refundDate: '',
      productDetail: [],
      commissionAmount: '',
      dishAmount: '',
      paymentApiResponse: '',
      deliveryApiResponse: '',
      refundApiResponse: '',
      displayPaymentApiLogPopup: false,
      discountAmount: '',
      deliveryWefastApiResponse: '',
      deliveryDunzoApiResponse: '',
      couponApplied: '',
      couponDiscount: '',
      rescheduleDate: new Date(new Date().setDate(new Date().getDate())),
      currentDate: new Date(new Date().setDate(new Date().getDate())),
      currenHour: new Date().getHours(),
      timeOption: false,
      rescheduleTimeSlot: '',
      deliveryRemark: '',
      deliveryStatus: '',
      delivery_api_status: '',
      refund_api_response: '',
      payment_api_status: '',
      otherRejectReason: '',
      rejectReasonRadioInput: 'Ingredients not available',
      reasonTextDisabled: true,
      reasonSubmitBtnDisabled: false,
	  errors: {},
    };
  }

  componentDidMount() {
	this.checkCurrentDate();
    this.getOrderDetailData();
  }
  
  checkCurrentDate = () => {
	if(this.state.rescheduleDate.toDateString() === this.state.currentDate.toDateString()){
		this.setState({timeOption: true});
	}
  };

  getOrderDetailData = () => {
    var userId = localStorage.getItem('userID');
    var orderId = localStorage.getItem('orderID');
    var token = localStorage.getItem('accessToken');
    return getDinerOrderDetailAPI(userId, orderId, token)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            orderId: response.data.data.order[0].order_id,
            orderNumber: response.data.data.order[0].order_number ?? '-',
            productDetail: response.data.data.order[0].order_details,
            amountPaid: response.data.data.order[0].amount_paid,
            deliveryCharges: response.data.data.order[0].delivery_charges,
            payID: response.data.data.order[0].transaction_id
              ? response.data.data.order[0].transaction_id
              : '-',
            chefName: response.data.data.order[0].chef_info.split(', ')[0],
            chefEmail: response.data.data.order[0].chef_info.split(', ')[1],
            chefMobile: response.data.data.order[0].chef_info.split(', ')[2],
            chefAddress: response.data.data.order[0].pickup_location,
            dinerName: response.data.data.order[0].diner_info
              ? response.data.data.order[0].diner_info.split(', ')[0]
              : '-',
            dinerEmail: response.data.data.order[0].diner_info
              ? response.data.data.order[0].diner_info.split(', ')[1]
              : '-',
            dinerMobile: response.data.data.order[0].diner_info
              ? response.data.data.order[0].diner_info.split(', ')[2]
              : '-',
            dinerAddress: response.data.data.order[0].drop_location,
            orderDate: response.data.data.order[0].created_at,
            taxAmount: response.data.data.order[0].tax_amount,
            deliveryPartner: response.data.data.order[0].delivery_partner,
            requestCustomization:
              response.data.data.order[0].special_instructions,
            trackingId: response.data.data.order[0].order_tracking_id
              ? response.data.data.order[0].order_tracking_id
              : '-',
            orderStatus: response.data.data.order[0].status,
            reason_for_chef_rejection: response.data.data.order[0].reason_for_chef_rejection,
            orderAcceptedAt: response.data.data.order[0].accepted_at,
            orderPreparedAt: response.data.data.order[0].prepared_at,
            orderPickedAt: response.data.data.order[0].pickedup_at,
            discountAmount: response.data.data.order[0].discount_amount,
            discountDescription:
              response.data.data.order[0].discount_description,
            orderSameDayDelivery: response.data.data.order[0].is_same_day_delivery,
            orderTakeaway: response.data.data.order[0].is_takeaway_selected,
            orderSelectedSlot: response.data.data.order[0].selected_time_slot,
            orderCancelledAt: response.data.data.order[0].order_cancelled_at,
            orderRejectedAt: response.data.data.order[0].order_rejected_at,
            chefTrackingURL: response.data.data.order[0].chef_tracking_url,
            dinerTrackingURL: response.data.data.order[0].diner_tracking_url,
            deliveryStatus: response.data.data.order[0].delivery_status,
            delivery_api_status: response.data.data.order[0].delivery_api_status,
            refund_api_response: response.data.data.order[0].refund_api_response,
            payment_api_status: response.data.data.order[0].payment_api_status,
            deliveryRemark: response.data.data.order[0].delivery_remark == null ? '' : response.data.data.order[0].delivery_remark,
            commissionAmount:
              response.data.data.order[0].commission_and_gst_amount,
            dishAmount: response.data.data.order[0].dish_amount,
            couponApplied: response.data.data.order[0].coupon_applied,
            couponDiscount: response.data.data.order[0].coupon_discount_amount,
            //paymentApiResponse: JSON.parse(response.data.data.order[0].payment_api_response),
            //refundApiResponse: JSON.parse(response.data.data.order[0].refund_api_response),
            actual_delivery_charges:
              response.data.data.order[0].actual_delivery_charges
          });

          if (response.data.data.order[0].refund_api_response != null && response.data.data.order[0].refund_api_response != "") {
            let refundResponse = JSON.parse(
              response.data.data.order[0].refund_api_response
            );
            let refundLogArr = {};
            if (refundResponse.error) {
              refundLogArr.status = 'Failed';
              refundLogArr.description = refundResponse.error.description;
            } else {
              refundLogArr.status = 'Sucess';
              refundLogArr.refund_id = refundResponse.id;
              refundLogArr.refund_amount = refundResponse.amount;
              refundLogArr.refund_currency = refundResponse.currency;
              refundLogArr.payment_id = refundResponse.payment_id;
              refundLogArr.refund_date = Moment.unix(
                refundResponse.created_at
              ).format('DD/MM/YYYY');
            }
            this.setState({
              refundApiResponse: refundLogArr
            });
          }

          if (response.data.data.order[0].payment_api_response != null && response.data.data.order[0].payment_api_response != "") {
            let paymentResponse = JSON.parse(
              response.data.data.order[0].payment_api_response
            );
            let paymentLogArr = {};
            if (paymentResponse.error) {
              paymentLogArr.status = 'Failed';
              paymentLogArr.description = paymentResponse.error.description;
            } else {
              paymentLogArr.status = 'Sucess';
              paymentLogArr.razor_payment_id =
                paymentResponse.razorpay_payment_id;
              paymentLogArr.razor_order_id = paymentResponse.razorpay_order_id;
              paymentLogArr.razor_signature =
                paymentResponse.razorpay_signature;
            }
            this.setState({
              paymentApiResponse: paymentLogArr
            });
          }

          if (response.data.data.order[0].delivery_api_response != null && response.data.data.order[0].delivery_api_response != "") {
            let deliveryResponse = JSON.parse(
              response.data.data.order[0].delivery_api_response
            );
            let deliveryLogArr = {};
            if (
              response.data.data.order[0].delivery_status == 'completed' ||
              response.data.data.order[0].delivery_status == null
            ) {
              if (deliveryResponse.order) {
                deliveryLogArr.status = deliveryResponse.is_successful
                  ? 'Success'
                  : 'Failed';
                deliveryLogArr.description =
                  deliveryResponse.order.status_description;
              }
              if (deliveryResponse.delivery_api_response) {
                deliveryLogArr.status = deliveryResponse.api_status;
                deliveryLogArr.description = JSON.parse(
                  deliveryResponse.delivery_api_response
                ).message;
              }
            } else {
              deliveryLogArr.status =
                response.data.data.order[0].delivery_status
                  .charAt(0)
                  .toUpperCase() +
                response.data.data.order[0].delivery_status.slice(1);
              deliveryLogArr.description =
                response.data.data.order[0].delivery_status_description;
            }
            this.setState({
              deliveryApiResponse: deliveryLogArr
            });
          }

          if (response.data.data.order[0].delivery_log) {
            let deliveryLogArr = {};
            let deliveryDunzoLogArr = {};
            console.log(response.data.data.order[0].delivery_log);
            if (
              response.data.data.order[0].delivery_log[0]
                .delivery_partner_name === 'wefast'
            ) {
              let deliveryResponse = JSON.parse(
                response.data.data.order[0].delivery_log[0]
                  .delivery_api_response
              );
              deliveryLogArr.partner =
                response.data.data.order[0].delivery_log[0].delivery_partner_name
                  .charAt(0)
                  .toUpperCase() +
                response.data.data.order[0].delivery_log[0].delivery_partner_name.slice(
                  1
                );
              deliveryLogArr.status = deliveryResponse.is_successful
                ? 'Success'
                : 'Failed';
              deliveryLogArr.description = deliveryResponse.errors
                ? deliveryResponse.errors.toString()
                : '-';
              deliveryLogArr.date = Moment(
                response.data.data.order[0].delivery_log[0].created_at
              ).format('DD/MM/YYYY');
              this.setState({
                deliveryWefastApiResponse: deliveryLogArr
              });
            } else {
              let deliveryResponse = JSON.parse(
                response.data.data.order[0].delivery_log[0]
                  .delivery_api_response
              );
              deliveryDunzoLogArr.partner =
                response.data.data.order[0].delivery_log[0].delivery_partner_name
                  .charAt(0)
                  .toUpperCase() +
                response.data.data.order[0].delivery_log[0].delivery_partner_name.slice(
                  1
                );
              deliveryDunzoLogArr.status = deliveryResponse.code
                ? 'Failed'
                : '';
              deliveryDunzoLogArr.code = deliveryResponse.code;
              deliveryDunzoLogArr.description =
                deliveryResponse.message.toString();
              deliveryDunzoLogArr.date = Moment(
                response.data.data.order[0].delivery_log[0].created_at
              ).format('DD/MM/YYYY');
              this.setState({
                deliveryDunzoApiResponse: deliveryDunzoLogArr
              });
            }
            console.log(
              response.data.data.order[0].delivery_log[1].delivery_partner_name
            );
            if (
              response.data.data.order[0].delivery_log[1]
                .delivery_partner_name === 'dunzo'
            ) {
              console.log('in');
              let deliveryResponse = JSON.parse(
                response.data.data.order[0].delivery_log[1]
                  .delivery_api_response
              );
              deliveryDunzoLogArr.partner =
                response.data.data.order[0].delivery_log[1].delivery_partner_name
                  .charAt(0)
                  .toUpperCase() +
                response.data.data.order[0].delivery_log[1].delivery_partner_name.slice(
                  1
                );
              deliveryDunzoLogArr.status = deliveryResponse.code
                ? 'Failed'
                : '';
              deliveryDunzoLogArr.code = deliveryResponse.code;
              deliveryDunzoLogArr.description =
                deliveryResponse.message.toString();
              deliveryDunzoLogArr.date = Moment(
                response.data.data.order[0].delivery_log[1].created_at
              ).format('DD/MM/YYYY');
              this.setState(
                {
                  deliveryDunzoApiResponse: deliveryDunzoLogArr
                },
                () => {
                  console.log(this.state.deliveryDunzoApiResponse);
                }
              );
            } else {
              try {
                let deliveryResponse = JSON.parse(
                  response.data.data.order[0].delivery_log[1]
                    .delivery_api_response
                );
                deliveryLogArr.partner =
                  response.data.data.order[0].delivery_log[1].delivery_partner_name
                    .charAt(0)
                    .toUpperCase() +
                  response.data.data.order[0].delivery_log[1].delivery_partner_name.slice(
                    1
                  );
                deliveryLogArr.status = deliveryResponse.is_successful
                  ? 'Success'
                  : 'Failed';
                deliveryLogArr.description = deliveryResponse.errors.toString();
                deliveryLogArr.date = Moment(
                  response.data.data.order[0].delivery_log[1].created_at
                ).format('DD/MM/YYYY');
                this.setState({
                  deliveryWefastApiResponse: deliveryLogArr
                });
              } catch (error) {
                console.error(error);
              }
            }

            /* console.log(deliveryDunzoLogArr.length);
            this.setState({
              deliveryWefastApiResponse: deliveryLogArr,
              deliveryDunzoApiResponse: deliveryDunzoLogArr
            }, ()=> {
              console.log(this.state.deliveryWefastApiResponse);
              console.log(this.state.deliveryDunzoApiResponse.length);
            }) */
          }

          if (response.data.data.order[0].refund_api != null && response.data.data.order[0].refund_api != "") {
            var dateRefund = Moment.unix(
              response.data.data.order[0].refund_api.created_at
            ).format('YYYY-MM-DD hh:mm:ss A');
            this.setState({
              refundDate: dateRefund,
              refundId: response.data.data.order[0].refund_api.id,
              refundAmount: response.data.data.order[0].refund_api.amount
            });
          }
          if (response.data.data.order[0].status == 'Cancelled') {
            this.setState({
              reasonOfRejection: 'Order is cancelled by Diner.'
            });
          } else if (response.data.data.order[0].status === 'Rejected') {
            console.log('Rejected');
            this.setState({
              reasonOfRejection:
                'Order is Rejected by Chef, ' +
                response.data.data.order[0].reason_for_chef_rejection
            });
          }else if (response.data.data.order[0].status === 'Hold') {
            console.log('Rejected');
            this.setState({
              reasonOfRejection:
                'Order is Hold by Chef, ' +
                response.data.data.order[0].reason_for_chef_rejection
            });
          } else if (
            response.data.data.order[0].status == 'Failed' &&
            response.data.data.order[0].reason_for_chef_rejection == 'Timed Out' && response.data.data.order[0].is_same_day_delivery == '1'
          ) {
            this.setState({
              reasonOfRejection:
                'Order is not accepted by Chef within 15min Time frame hence Timed out.'
            });
          }else if (
            response.data.data.order[0].status == 'Failed' &&
            response.data.data.order[0].reason_for_chef_rejection == 'Timed Out'
          ) {
			  
            this.setState({
              reasonOfRejection:
                'Order is not accepted by Chef within 2hr Time frame hence Timed out.'
            });
          } else {
            this.setState({
              reasonOfRejection: '-'
            });
          }
        } else {
          this.setState({
            dataLoaded: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          dataLoaded: true
        });
      });
  };

  handleRadioInput = (e) => {
    if (e.target.value === 'Other') {
      this.setState({
        reasonTextDisabled: false
      });
    } else {
      this.setState({
        reasonTextDisabled: true,
        otherRejectReason: '',
        reasonFormErr: ''
      });
      if (document.getElementById('reason-textarea')) {
        document.getElementById('reason-textarea').value = '';
      }
    }
    this.setState({
      rejectReasonRadioInput: e.target.value
    });
  };

  render() {
    const {
      paymentApiResponse,
      deliveryApiResponse,
      refundApiResponse,
      deliveryWefastApiResponse,
      deliveryDunzoApiResponse
    } = this.state;
    return (
      <div>
        <Header />
        <SideBarSection />
        <section className='content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='card'>
                  <div className='body'>
                    <ul className='nav nav-tabs tab-nav-right' role='tablist'>
                      <li role='presentation' className='active'>
                        <a href='#Profile' data-toggle='tab'>
                          <i className='material-icons'>face</i>Order Detail
                        </a>
                      </li>
                    </ul>
                    <div className='tab-content'>
                      <div
                        role='tabpanel'
                        className='tab-pane fade in active'
                        id='Profile'
                      >
                        <div className='row clearfix'>
                          <div className='col-xs-12 ol-sm-12 col-md-12 col-lg-12'>
                            <div
                              className='panel-group'
                              id='accordion_17'
                              role='tablist'
                              aria-multiselectable='true'
                            >
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingOne_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseOne_17'
                                      aria-expanded='true'
                                      aria-controls='collapseOne_17'
                                      className='collapsed'
                                    >
                                      Order Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseOne_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingOne_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Number</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.orderNumber
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order ID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.orderId
                                                  }
                                                  className='form-control'
                                                  name='orderId'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Pay ID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.payID
                                                  }
                                                  className='form-control'
                                                  name='orderId'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Date</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.orderDate
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Selected Slot</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.orderSelectedSlot
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Accepted At</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .orderAcceptedAt == null
                                                      ? '-'
                                                      : this.state
                                                          .orderAcceptedAt
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Prepared At</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .orderPreparedAt == null
                                                      ? '-'
                                                      : this.state
                                                          .orderPreparedAt
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Picked At</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.orderPickedAt ==
                                                    null
                                                      ? '-'
                                                      : this.state.orderPickedAt
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
										  
										  <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Same-day Delivery</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.orderSameDayDelivery ==
                                                    '1'
                                                      ? 'Yes'
                                                      : 'No'
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Takeaway</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.orderTakeaway ==
                                                    1
                                                      ? 'Yes'
                                                      : 'No'
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Order Status</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    (this.state.reason_for_chef_rejection == 'Timed Out' && this.state.orderStatus == 'Failed') ? this.state.reason_for_chef_rejection: this.state.orderStatus
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Commission + GST Amount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.commissionAmount
                                                  )}
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Dish Amount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.dishAmount
                                                  )}
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Delivery Charges</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.deliveryCharges
                                                  )}
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Tax Amount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.taxAmount
                                                  )}
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Discount Amount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.discountAmount
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Amount Paid</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={Math.round(
                                                    this.state.amountPaid
                                                  )}
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Coupon Applied</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.couponApplied
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Coupon Discount</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.couponDiscount
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          {/* <div className="col-md-8">
                                            <div className="form-group">
                                              <b>Discount Description</b>
                                              <div className="form-line">
                                                <input
                                                  type="text"
                                                  value={
                                                    this.state.description ? this.state.description : "-"
                                                  }
                                                  className="form-control"
                                                  name="orderNumber"
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div> */}
                                          {this.state.orderCancelledAt !=
                                          null ? (
                                            <div className='col-md-3'>
                                              <div className='form-group'>
                                                <b>Order Cancelled At</b>
                                                <div className='form-line'>
                                                  <input
                                                    type='text'
                                                    value={
                                                      this.state
                                                        .orderCancelledAt ==
                                                      null
                                                        ? '-'
                                                        : this.state
                                                            .orderCancelledAt
                                                    }
                                                    className='form-control'
                                                    name='orderNumber'
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className='col-md-3'>
                                              <div className='form-group'>
                                                <b>
                                                  Order Rejected/Cancelled At
                                                </b>
                                                <div className='form-line'>
                                                  <input
                                                    type='text'
                                                    value={
                                                      this.state
                                                        .orderRejectedAt == null
                                                        ? '-'
                                                        : this.state
                                                            .orderRejectedAt
                                                    }
                                                    className='form-control'
                                                    name='orderNumber'
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <div className='col-md-6'>
                                            <div className='form-group'>
                                              <b>
                                                Hold/Rejection/Cancellation Reason
                                              </b>
                                              <div className='form-line'>
                                                <textarea
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .reasonOfRejection == null
                                                      ? '-'
                                                      : this.state
                                                          .reasonOfRejection
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                ></textarea>
                                              </div>
                                            </div>
                                          </div>
										  
										  {(this.state.deliveryStatus == 'Cancelled' || this.state.deliveryStatus == 'Canceled') && (
                                            <div className='col-md-6'>
                                              <div className='form-group'>
                                                <b>Delivery Remark</b>
                                                <div className='form-line'>
                                                  <textarea
													type='text'
                                                    value={
                                                    this.state
                                                      .deliveryRemark == null
                                                      ? '-'
                                                      : this.state
                                                          .deliveryRemark
                                                  }
                                                    className='form-control'
                                                    name='orderNumber'
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
										<div class="row clearfix">
											<div class="col-md-12">
												{this.state.orderStatus in
												  {
													Ordered: 'Ordered',
													Accepted: 'Accepted',
													Prepared: 'Prepared',
													Hold: 'Hold'
												  }  && this.state.deliveryStatus != 'Cancelled' && this.state.deliveryStatus != 'Canceled' && (
													<button
													  className='btn'
													  data-toggle='modal'
													  data-target='#myModalAddMenu'
													>
													  Reschedule
													</button>
												  )}
												  
												  {this.state.orderStatus in
												  {
													Failed: 'Failed',
												  }  && this.state.delivery_api_status == 'Failed' && this.state.payment_api_status == 'Success' && this.state.refund_api_response == null && (
													<button
													  className='btn'
													  data-toggle='modal'
													  data-target='#myModalReschedule'
													>
													  Re-activate
													</button>
												  )}
												  
												  {this.state.orderStatus in
												  {
													Ordered: 'Ordered',
													Accepted: 'Accepted',
													Prepared: 'Prepared',
													Hold: 'Hold'
												  } && this.state.orderTakeaway == '0' && this.state.deliveryStatus != 'Cancelled' && this.state.deliveryStatus != 'Canceled' && (
													<button
													  className='btn'
													  data-toggle='modal'
													  data-target='#myModalCancelDelivery'
													  style={{ marginLeft: '4rem' }}
													>
													  Cancel Delivery
													</button>
												  )}
												  
												{this.state.orderStatus in
												  {
													Ordered: 'Ordered',
													Accepted: 'Accepted',
													Prepared: 'Prepared',
													PickedUp: 'PickedUp',
													Hold: 'Hold'
												  }  && (
													<button
													  className='btn'
													  data-toggle='modal'
													  data-target='#myModalConfirmDeliver'
													  style={{ marginLeft: '4rem' }}
													>
													  Delivered
													</button>
												)}
												  
												{this.state.orderStatus === 'Hold' && (
												  <button
													className='btn'
													data-toggle='modal'
													data-target='#myModalRejectMenu'
													style={{ marginLeft: '4rem' }}
												  >
													Reject
												  </button>
												)}
											</div>
										</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingTwo_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseTwo_17'
                                      aria-expanded='true'
                                      aria-controls='collapseTwo_17'
                                      className='collapsed'
                                    >
                                      Chef Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseTwo_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingTwo_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Chef Name</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.chefName
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-2'>
                                            <div className='form-group'>
                                              <b>Chef Email</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.chefEmail
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-2'>
                                            <div className='form-group'>
                                              <b>Chef Mobile No.</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.chefMobile
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-5'>
                                            <div className='form-group'>
                                              <b>Chef Address</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.chefAddress
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingThree_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseThree_17'
                                      aria-expanded='true'
                                      aria-controls='collapseThree_17'
                                      className='collapsed'
                                    >
                                      Diner Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseThree_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingThree_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Diner Name</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerName
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-2'>
                                            <div className='form-group'>
                                              <b>Diner Email</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerEmail
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-2'>
                                            <div className='form-group'>
                                              <b>Diner Mobile No.</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerMobile
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-5'>
                                            <div className='form-group'>
                                              <b>Diner Address</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.dinerAddress
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingFour_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseFour_17'
                                      aria-expanded='true'
                                      aria-controls='collapseFour_17'
                                      className='collapsed'
                                    >
                                      Delivery Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseFour_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingFour_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Delivery Partner</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state.deliveryPartner
                                                  }
                                                  className='form-control textStatusView'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Tracking ID</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state.trackingId
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Request Customization</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  value={
                                                    this.state
                                                      .requestCustomization ==
                                                      '' ||
                                                    this.state
                                                      .requestCustomization ==
                                                      null
                                                      ? '-'
                                                      : this.state
                                                          .requestCustomization
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-md-3'>
                                            <div className='form-group'>
                                              <b>Actual Delivery Charge</b>
                                              <div className='form-line'>
                                                <input
                                                  type='text'
                                                  defaultValue={
                                                    this.state
                                                      .actual_delivery_charges
                                                  }
                                                  className='form-control'
                                                  name='orderNumber'
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="col-md-3">
                                            <div className="form-group">
                                              <b>Rejection Reason</b>
                                              <div className="form-line">
                                                <input
                                                  type="text"
                                                  value={
                                                    this.state
                                                      .reasonOfRejection ==
                                                      "" ||
                                                      this.state
                                                        .reasonOfRejection == null
                                                      ? "-"
                                                      : this.state
                                                        .reasonOfRejection
                                                  }
                                                  className="form-control"
                                                  name="orderNumber"
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div> */}
                                          {(this.state.orderStatus ==
                                            'Failed' ||
                                            this.state.orderStatus ==
                                              'Rejected') &&
                                          this.state.orderTakeaway ==
                                            1 ? null : (
                                            <div className='col-md-4'>
                                              <div className='form-group'>
                                                <a
                                                  className='form-control'
                                                  href={
                                                    this.state.chefTrackingURL
                                                  }
                                                  target='_blank'
                                                >
                                                  Chef Tracking Url
                                                </a>
                                              </div>
                                            </div>
                                          )}{' '}
                                          {(this.state.orderStatus ==
                                            'Failed' ||
                                            this.state.orderStatus ==
                                              'Rejected') &&
                                          this.state.orderTakeaway ==
                                            1 ? null : (
                                            <div className='col-md-4'>
                                              <div className='form-group'>
                                                <a
                                                  className='form-control'
                                                  href={
                                                    this.state.dinerTrackingURL
                                                  }
                                                  target='_blank'
                                                >
                                                  Diner Tracking Url
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.orderStatus == 'Cancelled' ? (
                                <div className='panel'>
                                  <div
                                    className='panel-heading'
                                    role='tab'
                                    id='headingFive_17'
                                  >
                                    <h4 className='panel-title'>
                                      <a
                                        role='button'
                                        data-toggle='collapse'
                                        data-parent='#accordion_17'
                                        href='#collapseFive_17'
                                        aria-expanded='true'
                                        aria-controls='collapseFive_17'
                                        className='collapsed'
                                      >
                                        Refund Info{' '}
                                        <i className='material-icons float-right'>
                                          arrow_drop_down
                                        </i>
                                      </a>
                                    </h4>
                                  </div>
                                  <div
                                    id='collapseFive_17'
                                    className='panel-collapse collapse in'
                                    role='tabpanel'
                                    aria-labelledby='headingFive_17'
                                    aria-expanded='true'
                                    style={{ height: 'auto' }}
                                  >
                                    <div className='panel-body aniimated-thumbnials'>
                                      <div className='card'>
                                        <div className='body'>
                                          <div className='row clearfix'>
                                            <div className='col-md-3'>
                                              <div className='form-group'>
                                                <b>Refund ID</b>
                                                <div className='form-line'>
                                                  <input
                                                    type='text'
                                                    value={this.state.refundId}
                                                    className='form-control'
                                                    name='orderNumber'
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-md-3'>
                                              <div className='form-group'>
                                                <b>Refund Amount</b>
                                                <div className='form-line'>
                                                  <input
                                                    type='text'
                                                    value={Math.round(
                                                      this.state.refundAmount /
                                                        100
                                                    )}
                                                    className='form-control'
                                                    name='orderNumber'
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-md-3'>
                                              <div className='form-group'>
                                                <b>Amount Date</b>
                                                <div className='form-line'>
                                                  <input
                                                    type='text'
                                                    value={
                                                      this.state.refundDate
                                                    }
                                                    className='form-control'
                                                    name='orderNumber'
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingSix_17'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseSix_17'
                                      aria-expanded='true'
                                      aria-controls='collapseSix_17'
                                      className='collapsed'
                                    >
                                      Ordered Item Info{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseSix_17'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingSix_17'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          <div
                                            className='table-responsive'
                                            style={{ marginTop: '20px' }}
                                          >
                                            <table className='table table-bordered table-striped table-hover'>
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>
                                                    Sr. No.
                                                  </th>
                                                  <th className='text-center'>
                                                    Item Name
                                                  </th>
                                                  <th className='text-center'>
                                                    Item Quantity
                                                  </th>
                                                  <th className='text-center'>
                                                    Price (INR)
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.productDetail
                                                  .length ? (
                                                  this.state.productDetail.map(
                                                    (item, i) => {
                                                      return (
                                                        <tr key={'l' + i}>
                                                          <td className='align-middle text-center'>
                                                            {this.state
                                                              .activePage > 1 &&
                                                            i + 1 != 10
                                                              ? this.state
                                                                  .activePage -
                                                                1 +
                                                                '' +
                                                                (i + 1)
                                                              : this.state
                                                                  .activePage >
                                                                  1 &&
                                                                i + 1 == 10
                                                              ? this.state
                                                                  .activePage *
                                                                (i + 1)
                                                              : i + 1}
                                                          </td>
                                                          <td className='align-middle'>
                                                            {item.item_name}
                                                          </td>
                                                          {/*  <td className="align-middle">
                                                          {item.item_description
                                                            .length > 30
                                                            ? item.item_description.substring(
                                                                0,
                                                                30
                                                              ) + "..."
                                                            : item.item_description}
                                                        </td> */}
                                                          <td className='align-middle'>
                                                            {item.itemQuantity}
                                                          </td>
                                                          <td className='align-middle'>
                                                            ₹{' '}
                                                            {Math.round(
                                                              parseFloat(
                                                                item.item_price
                                                              ) *
                                                                parseFloat(
                                                                  item.itemQuantity
                                                                )
                                                            )}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td
                                                      className='align-middle text-center'
                                                      colSpan='4'
                                                    >
                                                      No Records Found
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='panel'>
                                <div
                                  className='panel-heading'
                                  role='tab'
                                  id='headingSix_18'
                                >
                                  <h4 className='panel-title'>
                                    <a
                                      role='button'
                                      data-toggle='collapse'
                                      data-parent='#accordion_17'
                                      href='#collapseSix_18'
                                      aria-expanded='true'
                                      aria-controls='collapseSix_18'
                                      className='collapsed'
                                    >
                                      API Log{' '}
                                      <i className='material-icons float-right'>
                                        arrow_drop_down
                                      </i>
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id='collapseSix_18'
                                  className='panel-collapse collapse in'
                                  role='tabpanel'
                                  aria-labelledby='headingSix_18'
                                  aria-expanded='true'
                                  style={{ height: 'auto' }}
                                >
                                  <div className='panel-body aniimated-thumbnials'>
                                    {!paymentApiResponse &&
                                      !deliveryApiResponse &&
                                      !refundApiResponse && (
                                        <div className='text-center'>
                                          <p>No API log available.</p>
                                        </div>
                                      )}
                                    <div className='card'>
                                      <div className='body'>
                                        <div className='row clearfix'>
                                          {paymentApiResponse && (
                                            <div>
                                              <h4>Razorpay Log</h4>
                                              <JSONPretty
                                                id='json-pretty'
                                                data={paymentApiResponse}
                                              ></JSONPretty>
                                            </div>
                                          )}
                                          {deliveryApiResponse && (
                                            <div>
                                              <hr />
                                              <h4>Delivery Log</h4>
                                              <JSONPretty
                                                id='json-pretty'
                                                data={deliveryApiResponse}
                                              ></JSONPretty>
                                            </div>
                                          )}
                                          {deliveryWefastApiResponse && (
                                            <div>
                                              <hr />
                                              <h4>Wefast Log</h4>
                                              <JSONPretty
                                                id='json-pretty'
                                                data={deliveryWefastApiResponse}
                                              ></JSONPretty>
                                            </div>
                                          )}
                                          {deliveryDunzoApiResponse &&
                                            (deliveryDunzoApiResponse !=
                                            'undefined' ? (
                                              <div>
                                                <hr />
                                                <h4> Dunzo Log</h4>
                                                <JSONPretty
                                                  id='json-pretty'
                                                  data={
                                                    deliveryDunzoApiResponse
                                                  }
                                                ></JSONPretty>
                                              </div>
                                            ) : null)}
                                          {refundApiResponse && (
                                            <div>
                                              <hr />
                                              <h4>Refund Log</h4>
                                              <JSONPretty
                                                id='json-pretty'
                                                data={refundApiResponse}
                                              ></JSONPretty>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.orderStatus in
          {
            Ordered: 'Ordered',
            Accepted: 'Accepted',
            Prepared: 'Prepared',
            Hold: 'Hold'
          }  && this.state.deliveryStatus != 'Cancelled' && this.state.deliveryStatus != 'Canceled' && (
            <div
              className='modal fade'
              id='myModalAddMenu'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='myModalLabel'
            >
              <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                  <div className='modal-header justify-content-start flex-column'>
                    <h5
                      className='modal-title ml-0 text-uppercase'
                      id='pins_achievedTitle'
                    >
                      Reschedule Order Date
                      <button
                        type='button'
                        className='close'
                        id='closeRescheduleFormButton'
                        data-dismiss='modal'
                        onClick={() =>
                          this.setState({
                            showRejectReasonAlert: false
                          })
                        }
                        aria-label='Close'
                      >
                        <img
                          src='assets/images/icons/black-close.svg'
                          alt='Close'
                        />
                      </button>
                    </h5>
                  </div>
                  <div className='modal-body'>
                    <h5>Select Date</h5>
                    <DatePicker
                      selected={this.state.rescheduleDate}
                      onChange={(date) => {
                        this.setState({ rescheduleDate: date });
						this.setState({timeOption: false});
						if(date.toDateString() === this.state.currentDate.toDateString()){
							this.setState({timeOption: true});
						}
                      }}
                      minDate={
                        new Date(new Date().setDate(new Date().getDate()))
                      }
                    />
                    <div style={{ marginTop: '20px' }}>
                      <div className='form-group'>
                        <b>Select Slot</b>
                        <div
                          className='form-line'
                          style={{ marginTop: '10px' }}
                        >
                          <select
                            className='form-control show-tick'
                            value={this.state.rescheduleTimeSlot}
                            onChange={(v) => {
                              const { value } = v.target;
                              this.setState({ rescheduleTimeSlot: value });
                            }}
                          >
                            <option value=''>Select time</option>
                            {[
                              '06:00 AM - 07:00 AM',
                              '07:00 AM - 08:00 AM',
                              '08:00 AM - 09:00 AM',
                              '09:00 AM - 10:00 AM',
                              '10:00 AM - 11:00 AM',
                              '11:00 AM - 12:00 PM',
                              '12:00 PM - 01:00 PM',
                              '01:00 PM - 02:00 PM',
                              '02:00 PM - 03:00 PM',
                              '03:00 PM - 04:00 PM',
                              '04:00 PM - 05:00 PM',
                              '05:00 PM - 06:00 PM',
                              '06:00 PM - 07:00 PM',
                              '07:00 PM - 08:00 PM',
                              '08:00 PM - 09:00 PM',
                              '09:00 PM - 10:00 PM',
                              '10:00 PM - 11:00 PM'
                            ].map((time, i) => {
								
								let timesplit  = time.split(' - ');
								let optionval  = false;
								
								let optdate = this.state.rescheduleDate.toISOString().split('T')[0] + ' ' + timesplit[0];
								timesplit[0] = new Date(optdate).getHours();
								
								if(this.state.timeOption == true && (timesplit[0] <= this.state.currenHour)){
									optionval = true;
								}
								if(optionval === false){
									return (
										<option
										  value={time}
										  key={'ttt' + i}
										  selected={
											this.state.userBusinessStartTime === time
										  }
										>
										  {time}
										</option>
									);
								}
                            })}
                          </select>
                        </div>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <button
                            className='btn'
                            style={{ marginTop: '30px' }}
                            disabled={this.state.rescheduleTimeSlot === ''}
                            data-toggle='modal'
                            onClick={async () => {
                              console.log(
                                'Reschedule delivery date: ',
                                this.state.rescheduleDate
                              );
                              console.log(
                                'Reschedule delivery time slot: ',
                                this.state.rescheduleTimeSlot
                              );
                              var userId = localStorage.getItem('userID');
                              var token = localStorage.getItem('accessToken');
                              const rescheduleOrderResult =
                                await rescheduleOrder(
                                  this.state.orderId,
                                  `${this.state.rescheduleDate.getFullYear()}-${
                                    this.state.rescheduleDate.getMonth() + 1
                                  }-${this.state.rescheduleDate.getDate()}`,
                                  this.state.rescheduleTimeSlot,
                                  userId,
                                  this.state.orderNumber,
                                  token
                                );
                              console.log(
                                'Reschedule order result; ',
                                rescheduleOrderResult
                              );
                              if (rescheduleOrderResult.status === 200) {
                                NotificationManager.success(
                                  'Order date rescheduled successfully',
                                  'Success'
                                );
                              } else if (
                                rescheduleOrderResult &&
                                rescheduleOrderResult.data &&
                                rescheduleOrderResult.data.error
                              ) {
                                NotificationManager.error(
                                  rescheduleOrderResult.data.error,
                                  'Error'
                                );
                              }

                              this.setState(
                                {
                                  rescheduleDate: new Date(
                                    new Date().setDate(new Date().getDate())
                                  ),
                                  rescheduleTimeSlot: ''
                                },
                                () => {}
                              );
                              this.getOrderDetailData();
                              document
                                .getElementById('closeRescheduleFormButton')
                                .click();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
		)}
		
		{this.state.orderStatus in
          {
            Failed: 'Failed',
          }  && this.state.delivery_api_status == 'Failed' && this.state.payment_api_status == 'Success' && this.state.refund_api_response == null && (
            <div
              className='modal fade'
              id='myModalReschedule'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='myModalLabel'
            >
              <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                  <div className='modal-header justify-content-start flex-column'>
                    <h5
                      className='modal-title ml-0 text-uppercase'
                      id='pins_achievedTitle'
                    >
                      Re-activate Order
                      <button
                        type='button'
                        className='close'
                        id='closeRescheduleFormButton'
                        data-dismiss='modal'
                        onClick={() =>
                          this.setState({
                            showRejectReasonAlert: false
                          })
                        }
                        aria-label='Close'
                      >
                        <img
                          src='assets/images/icons/black-close.svg'
                          alt='Close'
                        />
                      </button>
                    </h5>
                  </div>
                  <div className='modal-body'>
                    <h5>Select Date</h5>
                    <DatePicker
                      selected={this.state.rescheduleDate}
                      onChange={(date) => {
                        this.setState({ rescheduleDate: date });
						this.setState({timeOption: false});
						if(date.toDateString() === this.state.currentDate.toDateString()){
							this.setState({timeOption: true});
						}
                      }}
                      minDate={
                        new Date(new Date().setDate(new Date().getDate()))
                      }
                    />
                    <div style={{ marginTop: '20px' }}>
                      <div className='form-group'>
                        <b>Select Slot</b>
                        <div
                          className='form-line'
                          style={{ marginTop: '10px' }}
                        >
                          <select
                            className='form-control show-tick'
                            value={this.state.rescheduleTimeSlot}
                            onChange={(v) => {
                              const { value } = v.target;
                              this.setState({ rescheduleTimeSlot: value });
                            }}
                          >
                            <option value=''>Select time</option>
                            {[
                              '06:00 AM - 07:00 AM',
                              '07:00 AM - 08:00 AM',
                              '08:00 AM - 09:00 AM',
                              '09:00 AM - 10:00 AM',
                              '10:00 AM - 11:00 AM',
                              '11:00 AM - 12:00 PM',
                              '12:00 PM - 01:00 PM',
                              '01:00 PM - 02:00 PM',
                              '02:00 PM - 03:00 PM',
                              '03:00 PM - 04:00 PM',
                              '04:00 PM - 05:00 PM',
                              '05:00 PM - 06:00 PM',
                              '06:00 PM - 07:00 PM',
                              '07:00 PM - 08:00 PM',
                              '08:00 PM - 09:00 PM',
                              '09:00 PM - 10:00 PM',
                              '10:00 PM - 11:00 PM'
                            ].map((time, i) => {
								
								let timesplit  = time.split(' - ');
								let optionval  = false;
								
								let optdate = this.state.rescheduleDate.toISOString().split('T')[0] + ' ' + timesplit[0];
								timesplit[0] = new Date(optdate).getHours();
								
								if(this.state.timeOption == true && (timesplit[0] <= this.state.currenHour)){
									optionval = true;
								}
								if(optionval === false){
									return (
										<option
										  value={time}
										  key={'ttt' + i}
										  selected={
											this.state.userBusinessStartTime === time
										  }
										>
										  {time}
										</option>
									);
								}
                            })}
                          </select>
                        </div>
						
						<div style={{ marginTop: '20px' }}>
							<div className='form-group'>
							<b>Delivery remark</b>
							<textarea
								id='remark-textarea'
								className='form-control'
								placeholder='Enter remark here'
								defaultValue={this.state.deliveryRemark}
								onChange={(v) => {
									const { value } = v.target;
									this.setState({ deliveryRemark: value });
								}}
							></textarea>
							<p style={{ color: "red" }}>{this.state.errors["deliveryRemark"]}</p>
							</div>
						</div>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <button
                            className='btn'
                            style={{ marginTop: '30px' }}
                            disabled={this.state.rescheduleTimeSlot === ''}
                            data-toggle='modal'
                            onClick={async () => {
                              console.log(
                                'Reschedule delivery date: ',
                                this.state.rescheduleDate
                              );
                              console.log(
                                'Reschedule delivery time slot: ',
                                this.state.rescheduleTimeSlot
                              );
                              var userId = localStorage.getItem('userID');
                              var token = localStorage.getItem('accessToken');

							  if (this.state.deliveryRemark == "") {
								
								let errors = {};
								errors["deliveryRemark"] = "Please enter delivery remark.";
								this.setState({
								  errors: errors,
								});
								return false;
							  }
						  
                              const rescheduleOrderResult =
                                await reactivateOrder(
                                  this.state.orderId,
                                  `${this.state.rescheduleDate.getFullYear()}-${
                                    this.state.rescheduleDate.getMonth() + 1
                                  }-${this.state.rescheduleDate.getDate()}`,
                                  this.state.rescheduleTimeSlot,
                                  userId,
                                  this.state.orderNumber,
                                  this.state.deliveryRemark,
                                  token
                                );
                              console.log(
                                'Re-activated order result; ',
                                rescheduleOrderResult
                              );
                              if (rescheduleOrderResult.status === 200) {
                                NotificationManager.success(
                                  'Order re-activated successfully',
                                  'Success'
                                );
                              } else if (
                                rescheduleOrderResult &&
                                rescheduleOrderResult.data &&
                                rescheduleOrderResult.data.error
                              ) {
                                NotificationManager.error(
                                  rescheduleOrderResult.data.error,
                                  'Error'
                                );
                              }

                              this.setState(
                                {
                                  rescheduleDate: new Date(
                                    new Date().setDate(new Date().getDate())
                                  ),
                                  rescheduleTimeSlot: ''
                                },
                                () => {}
                              );
                              this.getOrderDetailData();
                              document
                                .getElementById('closeRescheduleFormButton')
                                .click();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
		)}
		
		{this.state.orderStatus in
          {
            Ordered: 'Ordered',
            Accepted: 'Accepted',
            Prepared: 'Prepared',
            Hold: 'Hold'
          } && this.state.orderTakeaway == '0' && this.state.deliveryStatus != 'Cancelled' && this.state.deliveryStatus != 'Canceled' && (
            <div
              className='modal'
              id='myModalCancelDelivery'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='myModalLabel'
            >
              <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                  <div className='modal-header justify-content-start flex-column'>
                    <h5
                      className='modal-title ml-0 text-uppercase'
                      id='pins_achievedTitle'
                    >
                      New Delivery Details
                      <button
                        type='button'
                        className='close'
                        id='closeCancelDeliveryFormButton'
                        data-dismiss='modal'
                        onClick={() =>
                          this.setState({
                            showRejectReasonAlert: false
                          })
                        }
                        aria-label='Close'
                      >
                        <img
                          src='assets/images/icons/black-close.svg'
                          alt='Close'
                        />
                      </button>
                    </h5>
                  </div>
                  <div className='modal-body'>
                    <textarea
						id='remark-textarea'
						className='form-control'
						placeholder='Enter remark here'
						defaultValue={this.state.deliveryRemark}
						onChange={(v) => {
							const { value } = v.target;
							this.setState({ deliveryRemark: value });
						}}
					></textarea>
					<p style={{ color: "red" }}>{this.state.errors["deliveryRemark"]}</p>
					<button
						type='button'
						className='btn'
						style={{ marginTop: '10px' }}
						disabled={this.state.deliveryRemark === ''}
						data-toggle='modal'
						onClick={async () => {
						  console.log(
							'Delivery Remark: ',
							this.state.deliveryRemark
						  );
						  var userId = localStorage.getItem('userID');
						  var token = localStorage.getItem('accessToken');
						  
						  if (this.state.deliveryRemark == "") {
							let errors = {};
							errors["deliveryRemark"] = "Please enter remark.";
							this.setState({
							  errors: errors,
							});
							return false;
						  }
	
						  const cancelDeliveryResult =
							await cancelDelivery(
							  this.state.orderId,
							  this.state.deliveryRemark,
							  userId,
							  this.state.orderNumber,
							  token
							);
						  console.log(
							'Cancel delivery result; ',
							cancelDeliveryResult
						  );
						  
						  if (cancelDeliveryResult.status === 200) {
							NotificationManager.success(
							  'Delivery cancelled successfully',
							  'Success'
							);
						  } else if (
							cancelDeliveryResult &&
							cancelDeliveryResult.data &&
							cancelDeliveryResult.data.error
						  ) {
							NotificationManager.error(
							  cancelDeliveryResult.data.error,
							  'Error'
							);
						  }
							
						this.setState({
						  deliveryRemark: "",
						  errors: {},
						});
						this.getOrderDetailData();
						  document
							.getElementById('closeCancelDeliveryFormButton')
							.click();
						}}
					  >
						Submit
					</button>
                  </div>
                </div>
              </div>
            </div>
		)}
		
		{this.state.orderStatus in
          {
            Ordered: 'Ordered',
            Accepted: 'Accepted',
            Prepared: 'Prepared',
            Hold: 'Hold',
            PickedUp: 'PickedUp'
          } && (
            <div
              className='modal'
              id='myModalConfirmDeliver'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='myModalLabel'
            >
              <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                  <div className='modal-header justify-content-start flex-column'>
                    
                      <button
                        type='button'
                        className='close'
                        id='closeDeliverFormButton'
                        data-dismiss='modal'
                        onClick={() =>
                          this.setState({
                            showRejectReasonAlert: false
                          })
                        }
                        aria-label='Close'
                      >
                        <img
                          src='assets/images/icons/black-close.svg'
                          alt='Close'
                        />
                      </button>
                    
                  </div>
				  <div className='modal-body'>
					<h5
                      className='modal-title ml-0 text-uppercase'
                      id='pins_achievedTitle'
					  style={{ fontSize: '20px' }}
                    >Are You sure, the order: ({this.state.orderNumber}) is delivered?</h5>
				  </div>
                  <div className='modal-footer'>
					<button
						type='button'
						className='btn'
						style={{ marginTop: '10px' }}
						data-dismiss='modal'
                        onClick={() =>
                          this.setState({
                            showRejectReasonAlert: false
                          })
                        }
                        aria-label='Close'
					>
						Cancel
					</button>
					<button
						type='button'
						className='btn'
						style={{ marginTop: '10px' }}
						data-toggle='modal'
						onClick={async () => {
						  var userId = localStorage.getItem('userID');
						  var token = localStorage.getItem('accessToken');	
						  const orderDeliveryResult =
							await orderDelivered(
							  this.state.orderId,
							  userId,
							  this.state.orderNumber,
							  token
							);
						  if (orderDeliveryResult.status === 200) {
							NotificationManager.success(
							  'Order status: delivered successfully',
							  'Success'
							);
						  } else if (
							orderDeliveryResult &&
							orderDeliveryResult.data &&
							orderDeliveryResult.data.error
						  ) {
							NotificationManager.error(
							  orderDeliveryResult.data.error,
							  'Error'
							);
						  }
						this.getOrderDetailData();
						  document
							.getElementById('closeDeliverFormButton')
							.click();
						}}
					  >
						Delivered
					</button>
                  </div>
                </div>
              </div>
            </div>
		)}
		
        {this.state.orderStatus === 'Hold' && (
          <div
            className='modal fade'
            id='myModalRejectMenu'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='myModalLabel'
          >
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header justify-content-start flex-column'>
                  <h5
                    className='modal-title ml-0 text-uppercase'
                    id='pins_achievedTitle'
                  >
                    select reason
                    <button
                      type='button'
                      className='close'
                      id='closeMenuFormButton'
                      data-dismiss='modal'
                      onClick={() =>
                        this.setState({
                          showRejectReasonAlert: false
                        })
                      }
                      aria-label='Close'
                    >
                      <img
                        src='assets/images/icons/black-close.svg'
                        alt='Close'
                      />
                    </button>
                  </h5>
                  <p>
                    Please select a reason for rejecting/cancelling the order.
                  </p>
                </div>
                <div className='modal-body'>
                  <div
                    className='input-group'
                    style={{ marginTop: '1rem !important' }}
                  >
                    <div className='mt-0'>
                      <input
                        id='reason_1'
                        onChange={this.handleRadioInput}
                        className='radio-col-orange'
                        type='radio'
                        name='rejectReason'
                        value='Ingredients not available'
                        checked={
                          this.state.rejectReasonRadioInput ===
                          'Ingredients not available'
                        }
                      />
                      <label for='reason_1'>Ingredients not available</label>
                    </div>

                    <div className='custome_check'>
                      <input
                        id='reason_2'
                        onChange={this.handleRadioInput}
                        type='radio'
                        className='radio-col-orange'
                        name='rejectReason'
                        value='Not adequate advance notice'
                        checked={
                          this.state.rejectReasonRadioInput ===
                          'Not adequate advance notice'
                        }
                      />
                      <label for='reason_2'>Not adequate advance notice</label>
                    </div>

                    <div className='custome_check'>
                      <input
                        id='radio_3'
                        onChange={this.handleRadioInput}
                        type='radio'
                        className='radio-col-orange'
                        name='rejectReason'
                        value='Special instructions not possible'
                        checked={
                          this.state.rejectReasonRadioInput ===
                          'Special instructions not possible'
                        }
                      />
                      <label for='radio_3'>
                        Special instructions not possible
                      </label>
                    </div>

                    <div className='custome_check'>
                      <input
                        id='radio_4'
                        onChange={this.handleRadioInput}
                        type='radio'
                        className='radio-col-orange'
                        name='rejectReason'
                        value='Order quantity too large'
                        checked={
                          this.state.rejectReasonRadioInput ===
                          'Order quantity too large'
                        }
                      />
                      <label for='radio_4'>Order quantity too large</label>
                    </div>

                    <div className='custome_check'>
                      <input
                        id='radio_5'
                        onChange={this.handleRadioInput}
                        type='radio'
                        className='radio-col-orange'
                        name='rejectReason'
                        value='Sold Out'
                        checked={
                          this.state.rejectReasonRadioInput === 'Sold Out'
                        }
                      />
                      <label for='radio_5'>Sold Out</label>
                    </div>

                    <div className='custome_check'>
                      <input
                        id='radio_6'
                        onChange={this.handleRadioInput}
                        type='radio'
                        className='radio-col-orange'
                        name='rejectReason'
                        value='Other'
                        checked={this.state.rejectReasonRadioInput === 'Other'}
                      />

                      <label for='radio_6'>Other </label>
                    </div>
                  </div>
                  <div className='wrap-modal-slider'>
                    <form>
                      {this.state.rejectReasonRadioInput === 'Other' && (
                        <div className='form-group'>
                          <textarea
                            id='reason-textarea'
                            className='form-control'
                            placeholder='Enter reason here'
                            defaultValue={this.state.otherRejectReason}
                            disabled={this.state.reasonTextDisabled}
                            onChange={this.reasonTextareaChange}
                          ></textarea>

                          {/* <span className='text-danger'>{reasonFormErr}</span> */}
                        </div>
                      )}
                      <div className='form-group text-center mb-0'>
                        <button
                          type='button'
                          onClick={async () => {
                            var userId = localStorage.getItem('userID');
                            var token = localStorage.getItem('accessToken');
                            console.log();
                            const rejectResult = await rejectHoldOrder(
                              userId,
                              this.state.orderNumber,
                              this.state.rejectReasonRadioInput ||
                              this.state.otherRejectReason,
                              token
                            );
                            console.log(rejectResult.data.error);
                            if (rejectResult.status === 200) {
                              NotificationManager.success(
                                'Hold order rejected successfully',
                                'Success'
                              );
                            } else if (
                              rejectResult &&
                              rejectResult.data &&
                              rejectResult.data.error
                            ) {
                              NotificationManager.error(
                                rejectResult.data.error,
                                'Error'
                              );
                            }
                            this.setState(
                              {
                                rejectReasonRadioInput:
                                  'Ingredients not available',
                                otherRejectReason: ''
                              },
                              () => {
                                this.getOrderDetailData();

                                document
                                  .getElementById('closeMenuFormButton')
                                  .click();
                              }
                            );
                          }}
                          disabled={this.state.reasonSubmitBtnDisabled}
                          className='btn'
                        >
                          Reject
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OrderDetailPage;
